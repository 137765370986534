import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { faCircleNotch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ApexChart, ChartType } from 'ng-apexcharts';
import { Observable, concatMap, map, of, tap } from 'rxjs';
import { GeneralControlService } from 'src/app/core/services/http/general-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  spinnerIcon = faCircleNotch;
  todayDate: string;

  dashData$: Observable<any>;

  constructor(private generalControlService: GeneralControlService, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.dashData$ = of({}).pipe(
      concatMap(_ => this.loadAllEmpStatus()),
      concatMap(_ => this.loadEmployeeStatistics()),
      concatMap(_ => this.loadUsersStatistics())
    );

    this.todayDate = formatDate(new Date(), 'MM/dd/YYYY', 'en');
  }

  private loadAllEmpStatus() {
    return this.generalControlService.GetAllEmpStatus().pipe(
      tap(res => {
        if (!res.status) {
          this.notificationService.error(res.message);
          return;
        }

        let { active, cobra, termination } = res.response;
        this.totalEmployeesStatistics.series = [active, termination, cobra];
      })
    );
  }

  private loadEmployeeStatistics() {
    return this.generalControlService.GetEmployeeStatistics().pipe(
      tap(res => {
        if (!res.status) {
          this.notificationService.error(res.message);
          return;
        }

        let { AllReport, periodReport } = res.response;

        this.userCountStatistics.dependentsCount = AllReport.DEP;
        this.userCountStatistics.employeeCount = AllReport.EE;
        this.userCountStatistics.employersCount = AllReport.ER;

        this.employeeEnrollmentStatistics.series[0].data = periodReport.map(x => ({
          x: x.date,
          y: x.value,
        }));
      })
    );
  }

  private loadUsersStatistics() {
    return this.generalControlService.GetUsersStatistics().pipe(
      tap(res => {
        // Success - Admin
        this.userLoginStatistics.series[0].data[0].y = res.response.ADU[0].successlogin;
        // Failed - Admin
        this.userLoginStatistics.series[1].data[0].y = res.response.ADU[0].failedlogin;

        // Success - Employer
        this.userLoginStatistics.series[0].data[1].y = res.response.ERU[0].successlogin;
        // Failed - Employer
        this.userLoginStatistics.series[1].data[1].y = res.response.ERU[0].failedlogin;
      })
    );
  }

  userCountStatistics = {
    employeeCount: 0,
    employersCount: 0,
    dependentsCount: 0,
  };

  userLoginStatistics = {
    series: [
      {
        name: 'Success',
        data: [
          {
            x: 'Admin User',
            y: 0,
          },
          {
            x: 'Employer User',
            y: 0,
          },
        ],
      },
      {
        name: 'Failed',
        data: [
          {
            x: 'Admin User',
            y: 0,
          },
          {
            x: 'Employer User',
            y: 0,
          },
        ],
      },
    ],
    labels: ['Admin User', 'Employer User'],
    colors: ['#36bd00', '#f00'],
  };

  totalEmployeesStatistics = {
    series: [0, 0, 0],
    labels: ['Active', 'Terminated', 'Cobra'],
    colors: ['#36bd00', '#f00', '#3eabff'],
  };

  employeeEnrollmentStatistics = {
    series: [
      {
        name: 'Day Time',
        data: [
          { x: '2023-05-10', y: 1 },
          { x: '2023-05-11', y: 2 },
        ],
      },
    ],
  };
}
