import EmptyStringValidator from './EmptyStringValidator';
import HireDateGlobalValidator from './HireDateValidator';
import SpecialCharactersValidator from './SpecialCharactersValidator';
import StringWithNumbersValidator from './StringWithNumbersValidator';

const CustomValidator = {
  HireDateGlobalValidator,
  SpecialCharactersValidator,
  EmptyStringValidator,
  StringWithNumbersValidator
};

export default CustomValidator;
