import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faEdit, faPencil } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';
import { DashboardEmailData, DashboardsEmailResponse } from 'src/app/core/models/api/dashboard.email.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { EmailService } from 'src/app/core/services/http/email.service';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-content',
  styleUrls: ['./manage-content.component.scss'],
  template: `
    <app-content-card CustomTitle="Manage Content">
      <ng-container *ngIf="emailTemplates$ | withLoading | async as emailTemplates">
        <ng-container *ngIf="emailTemplates.loading">
          <app-table [DisplayedColumns]="columns" [LoadingColumns]="3" [IsLoading]="true"></app-table>
        </ng-container>
        <ng-container *ngIf="emailTemplates.value">
          <app-table [DisplayedColumns]="columns" [EnablePagination]="true" [Rows]="emailTemplates.value"></app-table>
        </ng-container>
      </ng-container>
    </app-content-card>

    <!-- Column Template Region -->
    <ng-template #actionColumnTemplate let-data>
      <fa-icon
        [icon]="editIcon"
        [routerLink]="[messageTemplateUrl, data]"
        style="cursor:pointer"
        title="Edit Content"></fa-icon>
    </ng-template>
  `,
})
export class ManageContentComponent implements OnInit {
  editIcon = faPencil;
  columns: DisplayedColumns[] = [];
  messageTemplateUrl: RouteEnum = RouteEnum.Message;
  emailTemplates$: Observable<DashboardEmailData[]>;
  constructor(private email: EmailService) {}

  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;

  ngOnInit() {
    this.columns = [
      { columnName: 'UserType', label: 'User Type', sortable: true },
      { columnName: 'Contents', label: 'Content', sortable: true },
      { columnName: 'Id', label: 'Action', template: this.actionColTemplate },
    ];
    this.emailTemplates$ = this.email.GetDashboards().pipe(map(res => res.Data));
  }

  data = [{ UserType: 'xpto', Content: 'content', Id: 13 }];
}
