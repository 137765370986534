<app-content-card CustomTitle="Frequently Asked Questions (FAQs)">
  <div ngClass="wrapper">
    <div ngClass="item_group">
      <div
        ngClass="item"
        (click)="selectedTab = 'delta_dental_ppo_and_ppo_plus_premier_faq'"
        [class.selected]="selectedTab === 'delta_dental_ppo_and_ppo_plus_premier_faq'">
        DELTA DENTAL PPO AND PPO PLUS PREMIER FAQ
      </div>
      <div
        ngClass="item"
        (click)="selectedTab = 'deltacare_usa_faq'"
        [class.selected]="selectedTab === 'deltacare_usa_faq'">
        DELTACARE USA FAQ
      </div>
      <div ngClass="item" (click)="selectedTab = 'cobra_faq'" [class.selected]="selectedTab === 'cobra_faq'">
        COBRA FAQ
      </div>
    </div>

    <ng-template [ngIf]="selectedTab === 'delta_dental_ppo_and_ppo_plus_premier_faq'">
      <div style="display: flex; flex-direction: column">
        <h4>
          <strong
            >Delta Dental PPO<sup>SM</sup> and PPO Plus Premier
            <br />
            Frequently Asked Questions</strong
          >
        </h4>
        <br />
        <strong>What is Delta Dental PPO<sup>SM</sup>?</strong><br />

        Delta Dental's preferred provider organization (PPO) plan, provides access to the largest network of its kind
        nationwide. Delta Dental PPO allows enrollees to visit any licensed dentist. However, enrollees receive their
        best benefits and usually have the lowest out-of-pocket costs when visiting a PPO dentist. PPO dentists are
        Delta Dental dentists who have agreed to charge PPO patients reduced fees. There are over 18,000 PPO dental
        offices in California (approximately 55% of the dental offices in California), making Delta Dental's PPO network
        one of the largest in California.<br /><br />

        <strong>What is Delta Dental PPO Plus Premier?</strong><br />

        With Delta Dental's unique PPO plan design feature, employers can take advantage of the savings from the PPO
        plan while providing employees with expanded access to Delta Dental dentists who can limit their out-of-pocket
        costs.
        <i>Delta Dental PPO Plus Premier</i> combines the PPO and Delta Dental Premier networks to maximize
        opportunities to save money. PPO dentists accept fees that are more deeply discounted than the fees accepted by
        dentists who participate in the larger Premier network. Employees who visit a non-PPO dentist may save more by
        visiting a Premier dentist than they can by visiting a non-Delta Dental dentist.
        <i>PPO plus Premier</i> provides maximum network access while offering deeper savings within the PPO network and
        additional cost protections with the Premier network.<br /><br />

        <h4>Eligibility and Enrollment</h4>
        <app-accordion [collapsing]="true">
          <accordion-item title="Who is eligible for benefits?" [expanded]="true">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  All employees (primary enrollees) of the company purchasing benefits who work on a permanent,
                  full-time basis as determined by the employer, are eligible for benefits, as are their dependents:
                  legal spouse or domestic partner (if offered by the employer) and dependent children to age 26.
                  Contractors (earnings reported on a 1099 form) are not eligible. Dependents in military service are
                  also not eligible.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Is there a maximum age for dependent children?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Dependents are eligible to the age of 26. Recently enacted federal legislation allows adult dependent
                  children to remain on a parent's plan until the attainment of age 26, provided that the adult
                  dependent does not have access to other employment-based health coverage. An older child who is
                  disabled (i.e., unable to engage in any substantial gainful activity by reason of any medically
                  determinable physical or mental impairment) may also be considered an eligible dependent.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="How do I enroll new employees or dependents?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Employers or benefit administrators can enroll new members in their plans using our online system on
                  this web site, or by completing an Enrollment/Change Form and submitting it to
                  <a href="http://www.deltasba.com/c/contact_us.aspx"> Allied Administrators</a>
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="How do I change the status of an employee?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  The following changes in status can be performed on this web site, or by completing an Enrollment/
                  Change Form and submitting it to
                  <a routerLink="/dashboard/site-navigation/contact-us"> Allied Administrators </a>:
                </p>
                <ul>
                  <li>Enrollee Name</li>
                  <li>Add dependent*</li>
                  <li>Delete dependent</li>
                  <li>Update address</li>
                </ul>
                Social security number changes cannot be performed through this website. They must be submitted in
                writing to
                <a routerLink="/dashboard/site-navigation/contact-us"> Allied Administrators </a>. New enrollments or
                changes to existing enrollees may take up to five days to take effect. * Dependents can only be added on
                the website during open enrollment and if the enrollee has not elected dependent coverage. Any
                dependents needing to be enrolled outside open enrollment due to a qualifying event must be submitted to
                Allied Administrators at cs@alliedadministrators.com.
                <p></p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item
            title="Can an employee who lost coverage under a spouse's plan enroll in Delta
          Dental's Small Business Program?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes. If an eligible employee loses dental coverage through his or her spouse, coverage by Delta Dental
                  begins on the first day of the month following loss of coverage. To initiate coverage, you must submit
                  proof of loss of coverage and follow the standard enrollment procedures. Loss of coverage can be shown
                  by a termination letter (pink slip) from the spouse's employer, or a termination of dental benefits
                  letter from the insurance carrier of the spouse's employer.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="When are new employees eligible for benefits?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Eligibility begins following completion of a waiting period determined by the employer. New hires and
                  their dependents are covered on the first day of the month following completion of the waiting period.
                  New enrollments or changes to existing enrollees, submitted through this web site or through the
                  standard Enrollment/Change Form and Enrollee Eligibility Statement, may take up to five days to take
                  effect.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Does Delta Dental support shorter eligibility waiting periods?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes, Delta Dental does support shorter eligibility waiting periods, including date of hire. However,
                  new members can only be enrolled on the first day of the month. Therefore, while the effective date of
                  eligibility will be the date you request, you will be billed as if the new members were enrolled for
                  the entire month.
                </p>
              </div>
            </ng-template>
          </accordion-item>
        </app-accordion>
        <h4>Termination</h4>
        <app-accordion>
          <accordion-item title="How do I terminate benefits for employees who leave the company?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  You can terminate coverage for departing employees right on this web site, or by submitting an
                  Enrollment/Change form to

                  <a routerLink="/dashboard/site-navigation/contact-us"> Allied Administrators.</a>
                </p>
                <p></p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="What are the rules regarding group termination?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>Delta Dental requires 45 days written notification prior to the actual date of termination.</p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Can members be terminated or enrolled retroactively?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Retroactive terminations and enrollments are discouraged, but will be considered on a case-by-case
                  basis.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Can groups be terminated or enrolled retroactively?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>No.</p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Can I terminate a member in the middle of the month?">
            <ng-template accordionContent>
              <div class="uk-accordion-content">
                <div ngClass="accordion_content">
                  <p>
                    You can submit a termination at any time, but the effective date of termination from the plan will
                    be the first day of the following month.
                  </p>
                </div>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Are dental benefits covered under COBRA?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes, dental benefits are covered under the COBRA laws. Please consult our information on
                  <a (click)="selectedTab = 'cobra_faq'"> Administering COBRA</a> for more details.
                </p>
              </div>
            </ng-template>
          </accordion-item>
        </app-accordion>
        <h4>Billing and Payments</h4>
        <app-accordion>
          <accordion-item title="When are payments for monthly invoices due?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Statements are generated the first of each month and mailed the first week of the month, taking into
                  consideration weekends and holidays. Payments must be received at our bank on or before the 25th of
                  the month. Please remember that your Delta Dental coverage is a prepaid benefit: your payment received
                  on the 25th of the month pays for your coverage for the coming month.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Will coverage be affected if payments are made late?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes. If payments are not received by the due date, your employees will not be shown as eligible for
                  coverage until: 1) The payments are remitted, and 2) your group's files have been updated. Please keep
                  in mind that if your company's dues are delinquent, reporting of eligibility will be delayed.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Are there any penalties for late payments or returned checks?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  If your company’s dues are not received by the due date, there is no penalty for making a late payment. Returned checks are subject to a $20.00 charge.  If sufficient funds are not received immediately, coverage may be terminated.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="How are employers notified when payments are late or delinquent?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  If payment is not received by the 25th of the month, a late notice will be sent. If payment is not
                  received by the fifth of the current month of coverage, termination notices will be sent within the
                  next 3-5 business days. This letter will notify you that your company's dental coverage will be
                  terminated if dues are not received within 15 days.
                  <strong
                    >Please note that since this cancellation would be caused by your failure to pay the premium
                    required to keep the plan in force, you will be responsible for notifying each enrollee of that
                    cancellation.</strong
                  >
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Can benefits be reinstated in cases of cancellation?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>Yes, as long as there is no outstanding balance and the group has a good payment history.</p>
              </div>
            </ng-template>
          </accordion-item>
        </app-accordion>
        <h4>Miscellaneous Questions</h4>
        <app-accordion>
          <accordion-item
            title="Does the Delta Dental Small Business Program support changes or upgrades
          to plans while a contract is in effect?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  We can accommodate plan changes and upgrades on a case by case basis, at the time of renewal. Any
                  proposed changes to your plan must be requested in writing. Please contact your broker for more
                  information.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Can Delta Dental's Small Business Program accommodate dual plans?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes we can, subject to certain regulations. Please contact
                  <a routerLink="/dashboard/site-navigation/contact-us">Allied Administrators</a>
                  for details.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item
            title="Can employees be enrolled simultaneously in more than one dental plan with
          more than one carrier?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>Yes. There are two scenarios:</p>
                <b><i>Example A</i></b>
                <p>
                  Jeannie works for Bob's Tent Supply, a company who has a dental carrier. Jeannie's husband works for
                  Standard Olive Oil, whose carrier is Delta Dental. Jeannie could be enrolled as a dependent of her
                  husband on his Delta Dental plan, and as a primary member with her own employer's plan.
                </p>
                <br />
                <b><i>Example B</i></b>
                <p>
                  Andrea works for Walker Co., and is enrolled as a primary member in that company's Delta Dental plan.
                  Andrea also works for PCG Corp., and also qualifies for benefits with that company's dental plan.
                  Andrea can be enrolled with both carriers as a primary member.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item title="Does Delta Dental's Small Business Program offer orthodontia benefits?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>Yes, orthodontia is offered in some plans, and only for groups with a minimum of 10 employees.</p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item
            title="Does Delta Dental's Small Business Program insure employees who are
          located outside the United States?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes, subject to the program rules. All claims are subject to prevailing currency exchange rates at the
                  date of service.
                </p>
              </div>
            </ng-template>
          </accordion-item>
          <accordion-item
            title="Does Delta Dental's Small Business Program support dummy social security
          numbers?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>No.</p>
              </div>
            </ng-template>
          </accordion-item>
        </app-accordion>
      </div>
    </ng-template>

    <ng-template [ngIf]="selectedTab === 'deltacare_usa_faq'">
      <div style="display: flex; flex-direction: column">
        <h4><strong>DeltaCare® USA Frequently Asked Questions</strong></h4>
        <br />
        <strong>What is DeltaCare® USA? </strong>
        <br />
        DeltaCare® USA is Delta Dental's prepaid plan that features set copayments, no annual deductibles and no
        maximums for covered benefits. Enrollees must select a primary care dentist in the DeltaCare® USA network from
        whom they receive treatment as in a traditional HMO. With DeltaCare® USA, businesses enjoy higher cost controls,
        while still providing employees with comprehensive dental benefits.
        <br />
        <br />
        DeltaCare® USA delivers quality care for less cost than traditional fee-for-service plans. DeltaCare® USA
        dentists undergo a comprehensive credentialing process to ensure they meet high-quality standards. Most
        diagnostic and basic restorative services are covered at little or no cost to the enrollee.
        <br />
        <br />
        <h4>Eligibility and Enrollment</h4>
        <app-accordion>
          <accordion-item title="Who is eligible for benefits?" [expanded]="true">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  All employees (primary enrollees) of the company purchasing benefits who work on a permanent,
                  full-time basis as determined by the employer, are eligible for benefits, as are their dependents:
                  legal spouse or domestic partner (if offered by the employer) and dependent children to age 26.
                  Contractors (earnings reported on a 1099 form) are not eligible. Dependents in military service are
                  also not eligible.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="Is there a maximum age for dependent children?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Dependents are eligible to the age of 26. Recently enacted federal legislation allows adult dependent
                  children to remain on a parent's plan until the attainment of age 26, provided that the adult
                  dependent does not have access to other employment-based health coverage. An older child who is
                  disabled (i.e., unable to engage in any substantial gainful activity by reason of any medically
                  determinable physical or mental impairment) may also be considered an eligible dependent.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="How do I enroll new employees or dependents?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Employers or benefit administrators can enroll new members in their plans using our online system on
                  this web site, or by completing a DeltaCare® USA Change/Enrollment form and submitting it to
                  <a routerLink="/dashboard/site-navigation/contact-us">Allied Administrators.</a>
                  Employees electing dependent coverage must enroll all their eligible dependents at the same time as
                  the primary enrollee. New dependents can be enrolled the first of the month following qualification.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="How do I change the status of an employee?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  The following changes in status can be performed on this web site, or by completing an Enrollment/
                  Change Form and submitting it to
                  <a routerLink="/dashboard/site-navigation/contact-us"> Allied Administrators </a>:
                </p>
                <ul>
                  <li>Enrollee Name</li>
                  <li>Add dependent*</li>
                  <li>Delete dependent</li>
                  <li>Update address</li>
                </ul>
                Social security number changes cannot be performed through this website. They must be submitted in
                writing to
                <a routerLink="/dashboard/site-navigation/contact-us"> Allied Administrators </a>. New enrollments or
                changes to existing enrollees may take up to five days to take effect.
                <p></p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="When are new employees eligible for benefits?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Current employees become eligible on the effective date of the dental program. New hires and their
                  dependents are eligible to receive benefits after completing any eligibility waiting period required
                  by the employer. In addition, a business may customize its program to be the first day of the month
                  following a specified number of months of continuous employment. However, employers must submit an
                  Enrollment/Change form or enter the new employees on this website for coverage to commence.
                </p>
              </div>
            </ng-template>
          </accordion-item>
        </app-accordion>

        <h4>Termination</h4>
        <app-accordion>
          <accordion-item title="How do I terminate benefits for employees who leave the company?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  You may terminate employees on this website, or by submitting an Enrollment/Change form. If you need
                  to terminate an employee's coverage after you've sent in the employee's monthly premiums / enrollment,
                  the change must be received by Allied Administrators no later than the first of the month in which
                  you'd like the termination to take effect. After the first of the month, termination will be made
                  effective the first of the following month.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="What are the rules regarding group termination?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  In order to terminate benefits for an entire group, 30 days written notification, prior to the actual
                  date of termination is required. The employer is responsible for notifying each enrollee of the
                  cancellation.
                  <strong>Retroactive terminations are not allowed.</strong>
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="Can I terminate a member in the middle of the month?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  You can submit a termination at any time, but the effective date of termination from the plan will be
                  the first day of the following month.
                </p>
              </div>
            </ng-template>
          </accordion-item>
        </app-accordion>

        <h4>Billing and Payments</h4>
        <app-accordion>
          <accordion-item title="When are payments for monthly invoices due?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Statements are issued at the beginning of each month. Premiums must be received by our bank on or
                  before the 25th of the month. Your DeltaCare® USA coverage is a prepaid benefit and your payment
                  received on the 25th of the month pays the coverage for the following month.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="Will coverage be affected if payments are made late?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes. If premiums are not received by the due date, your enrollees will not be shown as eligible for
                  coverage until the premiums are remitted, and your group's files have been updated. As a result, a
                  dental office will not be able to confirm eligibility and treatment may be delayed.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="Are there any penalties for late payments or returned checks?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes. If your company's premiums are not received by the due date, your account may be considered
                  delinquent and a $20.00 late fee may be assessed. In addition, returned checks are subject to a $20.00
                  charge. If sufficient funds are not received immediately, coverage may be terminated.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="How are employers notified when payments are late or delinquent?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  If payment is not received by the 25th of the month, a late notice will be sent. If payment is not
                  received by the fifth of the next month of coverage, termination notices will be sent in the next 3-5
                  business day. This letter will notify you that your company's dental coverage will be terminated and
                  enrollees will be unable to schedule appointments on or after the first day of the month, except for
                  completion of treatment prior to that date.
                  <strong
                    >In the event your dental plan is cancelled, you will be responsible for notifying each enrollee of
                    the cancellation.</strong
                  >
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="Can benefits be reinstated in cases of cancellation?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  If it is determined that you may reinstate your plan after it has been cancelled, both delinquent and
                  current premiums are due in full. It is important to note that premiums are due for the entire time
                  enrollees remained covered under the plan, regardless of whether or not the plan is reinstated.
                </p>
              </div>
            </ng-template>
          </accordion-item>
        </app-accordion>

        <h4>Miscellaneous Questions</h4>
        <app-accordion>
          <accordion-item
            title="Does DeltaCare® USA support changes or upgrades to plans while a contract
          is in effect?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  We can accommodate plan changes and upgrades on a case-by-case basis, at the time of renewal. Any
                  proposed changes to your plan must be requested in writing. Please contact your broker for more
                  information.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="Can DeltaCare® accommodate Dual Plans?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>
                  Yes, subject to certain regulations. Contact
                  <a routerLink="/dashboard/site-navigation/contact-us">Allied Administrators</a>
                  for details.
                </p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item title="Does DeltaCare® USA offer orthodontia benefits?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>Yes. DeltaCare® USA offers orthodontia benefits for children to age 26, as well as adults.</p>
              </div>
            </ng-template>
          </accordion-item>

          <accordion-item
            title="Does DeltaCare® USA insure employees who are located outside of the United
          States?">
            <ng-template accordionContent>
              <div ngClass="accordion_content">
                <p>No.</p>
              </div>
            </ng-template>
          </accordion-item>
        </app-accordion>
      </div>
    </ng-template>

    <ng-template [ngIf]="selectedTab === 'cobra_faq'">
      <div style="display: flex; flex-direction: column">
        <h4><strong>COBRA Information</strong></h4>
        <strong>What is COBRA?</strong><br />
        The Consolidated Omnibus Budget Reconciliation Act of 1985 (COBRA) is a federal law enacted to help prevent gaps
        in healthcare coverage. COBRA requires employers who provide employees with healthcare coverage (including
        dental) to permit employees to continue the coverage under certain circumstances when it would otherwise
        terminate. The employee is responsible for paying the full cost of the continued coverage, in addition to an
        administrative fee.<br /><br />

        <strong>Does COBRA affect all companies?</strong><br />

        COBRA applies, in general, to companies that employ 20 or more employees on at least 50% of the working days
        during the preceding calendar year.<strong
          >Please note that under COBRA,
          <i> employers are required to administer continuation of coverage benefits.</i></strong
        >
        To obtain more information about COBRA, including responsibilities as an employer and the duration of the
        continuation periods, please contact your own legal counsel.<br /><br />

        <strong> Who qualifies for COBRA benefits?</strong><br />
        Any employee or dependent who is enrolled in a Delta Dental PPO or PPO Plus Premier plan on the day before
        termination of standard benefits is eligible. Also qualifying are children who are born to or placed for
        adoption with a qualified COBRA beneficiary during the period of continued coverage, provided such child is
        enrolled within 30 days of birth or placement for adoption.<br /><br />
        <strong> What events can trigger COBRA coverage?</strong><br />
        There are five Qualifying Events that can initiate continued coverage: <br />
        <ol style="margin-left: -15px">
          <li>Termination of employment (other than termination for gross misconduct), or reduction in work hours;</li>
          <li>Death;</li>
          <li>Divorce or legal separation;</li>
          <li>Loss of dependent status under the plan; or,</li>
          <li>As to dependents only, an employee's entitlement to Medicare.</li>
        </ol>
        <br />
        <strong> How long do COBRA benefits last?</strong><br />
        Employees who are qualified beneficiaries may continue coverage for 18 months following the month in which
        Qualifying Event 1 occurs. For details on qualifying for extended COBRA benefits, please contact your plan
        administrator. Dependents who are qualified beneficiaries may continue coverage for 36 months following the
        occurrence of Qualifying Events 2, 3, 4 or 5.<br /><br />

        <strong> Do COBRA beneficiaries receive the same Delta Dental coverage as active employees?</strong><br />

        Yes. The benefits under the continued coverage will be the same as those provided to active employees and their
        dependents who are still enrolled in the dental plan. If the employer changes the coverage for active employees,
        the continued coverage will change as well. Dues will be adjusted to reflect the changes made.<br /><br />
        <strong> What is Cal-COBRA? </strong><br />

        The California Continuation Benefits Replacement Act (Cal-COBRA), which became effective on January 1, 1998,
        requires that every health care service plan contract with a small employer provide a similar opportunity to
        continue that coverage. The employee, as in the case of COBRA, is responsible for paying the full cost of the
        continued coverage in addition to an administrative fee.<br /><br />
        <strong> How is "small employer" defined under Cal-COBRA? </strong><br />

        A small employer is defined as one that employed between 2 and 19 employees on at least 50% of the working days
        during the preceding calendar year. If you are a small employer, either you or Delta Dental can distribute a
        copy of the disclosure and election form to each employee enrolled in your dental program at the time an
        enrollee qualifies for Cal-COBRA.<strong
          >If you choose Delta Dental to send the Cal-COBRA form, please provide Allied Administrators (Delta Dental's
          designated plan administrator) with the employee's and/or dependent's name and address to send the
          forms.</strong
        ><br /><br />
        <strong> Should employers notify Delta Dental when an employee qualifies for Cal-COBRA? </strong><br />

        Yes. Cal-COBRA requires a small employer to notify Delta Dental's designated administrator, Allied
        Administrators, of any employee or dependent who has experienced a "qualifying event" within 31 days after such
        loss of coverage.<br /><br />
        <strong> How should employers notify Allied Administrators when an employee qualifies for Cal-COBRA? </strong
        ><br />

        Employers can notify Allied Administrators via email
        <a href="mailto:info@alliedadminsitrators.com">(info@alliedadministrators.com)</a> when an employee qualifies
        for Cal-COBRA coverage. The employer must provide the employee with a Cal-COBRA Disclosure and Election Form or
        request that Allied Administrators send one to the employee by providing a name and address to whom it should be
        sent. The employee is responsible for sending the completed form and premium to Allied Administrators.<br /><br />
      </div>
    </ng-template>
  </div>
</app-content-card>
