export default [
  {
    StateCd: '  ',
    StateName: '  ',
    Region: null,
  },
  {
    StateCd: 'AK',
    StateName: 'AK',
    Region: null,
  },
  {
    StateCd: 'AL',
    StateName: 'AL',
    Region: 'DDIC',
  },
  {
    StateCd: 'AR',
    StateName: 'AR',
    Region: null,
  },
  {
    StateCd: 'AZ',
    StateName: 'AZ',
    Region: null,
  },
  {
    StateCd: 'CA',
    StateName: 'CA',
    Region: 'DDCA',
  },
  {
    StateCd: 'CO',
    StateName: 'CO',
    Region: null,
  },
  {
    StateCd: 'CT',
    StateName: 'CT',
    Region: 'DDNJ',
  },
  {
    StateCd: 'DC',
    StateName: 'DC',
    Region: 'DDPA',
  },
  {
    StateCd: 'DE',
    StateName: 'DE',
    Region: 'DDPA',
  },
  {
    StateCd: 'FL',
    StateName: 'FL',
    Region: 'DDIC',
  },
  {
    StateCd: 'GA',
    StateName: 'GA',
    Region: 'DDIC',
  },
  {
    StateCd: 'HI',
    StateName: 'HI',
    Region: null,
  },
  {
    StateCd: 'IA',
    StateName: 'IA',
    Region: null,
  },
  {
    StateCd: 'ID',
    StateName: 'ID',
    Region: null,
  },
  {
    StateCd: 'IL',
    StateName: 'IL',
    Region: null,
  },
  {
    StateCd: 'IN',
    StateName: 'IN',
    Region: null,
  },
  {
    StateCd: 'KS',
    StateName: 'KS',
    Region: null,
  },
  {
    StateCd: 'KY',
    StateName: 'KY',
    Region: null,
  },
  {
    StateCd: 'LA',
    StateName: 'LA',
    Region: 'DDIC',
  },
  {
    StateCd: 'MA',
    StateName: 'MA',
    Region: null,
  },
  {
    StateCd: 'MD',
    StateName: 'MD',
    Region: 'DDPA',
  },
  {
    StateCd: 'ME',
    StateName: 'ME',
    Region: null,
  },
  {
    StateCd: 'MI',
    StateName: 'MI',
    Region: null,
  },
  {
    StateCd: 'MN',
    StateName: 'MN',
    Region: null,
  },
  {
    StateCd: 'MO',
    StateName: 'MO',
    Region: null,
  },
  {
    StateCd: 'MS',
    StateName: 'MS',
    Region: null,
  },
  {
    StateCd: 'MT',
    StateName: 'MT',
    Region: 'DDIC',
  },
  {
    StateCd: 'NC',
    StateName: 'NC',
    Region: null,
  },
  {
    StateCd: 'ND',
    StateName: 'ND',
    Region: null,
  },
  {
    StateCd: 'NE',
    StateName: 'NE',
    Region: null,
  },
  {
    StateCd: 'NH',
    StateName: 'NH',
    Region: null,
  },
  {
    StateCd: 'NJ',
    StateName: 'NJ',
    Region: 'DDNJ',
  },
  {
    StateCd: 'NM',
    StateName: 'NM',
    Region: null,
  },
  {
    StateCd: 'NV',
    StateName: 'NV',
    Region: 'DDIC',
  },
  {
    StateCd: 'NY',
    StateName: 'NY',
    Region: 'DDPA',
  },
  {
    StateCd: 'OH',
    StateName: 'OH',
    Region: null,
  },
  {
    StateCd: 'OK',
    StateName: 'OK',
    Region: null,
  },
  {
    StateCd: 'OR',
    StateName: 'OR',
    Region: null,
  },
  {
    StateCd: 'PA',
    StateName: 'PA',
    Region: 'DDPA',
  },
  {
    StateCd: 'PR',
    StateName: 'PR',
    Region: null,
  },
  {
    StateCd: 'RI',
    StateName: 'RI',
    Region: null,
  },
  {
    StateCd: 'SC',
    StateName: 'SC',
    Region: null,
  },
  {
    StateCd: 'SD',
    StateName: 'SD',
    Region: null,
  },
  {
    StateCd: 'TN',
    StateName: 'TN',
    Region: null,
  },
  {
    StateCd: 'TX',
    StateName: 'TX',
    Region: 'DDIC',
  },
  {
    StateCd: 'UT',
    StateName: 'UT',
    Region: 'DDIC',
  },
  {
    StateCd: 'VA',
    StateName: 'VA',
    Region: null,
  },
  {
    StateCd: 'VT',
    StateName: 'VT',
    Region: null,
  },
  {
    StateCd: 'WA',
    StateName: 'WA',
    Region: null,
  },
  {
    StateCd: 'WI',
    StateName: 'WI',
    Region: null,
  },
  {
    StateCd: 'WV',
    StateName: 'WV',
    Region: 'DDPA',
  },
  {
    StateCd: 'WY',
    StateName: 'WY',
    Region: null,
  },
];
