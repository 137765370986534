import { Injectable } from '@angular/core';
import moment from 'moment';
import { filter, timer } from 'rxjs';
import { LastActiveService } from './last-active.service';
import { AuthService } from './http/auth/auth.service';
import { UserViewService } from './user-view.service';
import { VerticalMenuService } from './vertical-menu.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { RouteEnum } from '../models/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class UserLogoutService {
  private timerTickMs: number = environment.inactivityValidationTimerTickMs;
  private inactivityLogoutTimeoutS: number = environment.inactivityLogoutTimeoutSeconds;

  constructor(
    private lastActiveService: LastActiveService,
    private authService: AuthService,
    private userViewService: UserViewService,
    private verticalMenuService: VerticalMenuService,
    private route: Router
  ) {}

  public setUpInactivityLogout() {
    console.log(this.timerTickMs, this.inactivityLogoutTimeoutS);
    timer(0, this.timerTickMs)
      .pipe(filter(_ => this.authService.IsLoggedIn())) // Validates only when user is logged
      .subscribe(_ => {
        this.validateActivity();
      });
  }

  public validateActivity(){
    const currentDate = moment(new Date());
        const lastActiveDate = this.lastActiveService.getLastActiveDate();
        console.debug(`[Logout Service] Current Date: ${currentDate} Last Active Date: ${lastActiveDate}`); // TODO remove this log (just for testing)
        if (moment.duration(currentDate.diff(lastActiveDate)).asSeconds() > this.inactivityLogoutTimeoutS) {
          console.debug(`[Logout Service] Trying to log out`);
          this.logOut();
        }
  }

  public logOut() {
    console.log('Logging out due inactivity');
    this.authService.Logout();
    this.userViewService.RemoveUserState();
    this.verticalMenuService.RemoveVerticalMenuState();
    this.route.navigate([RouteEnum.SignIn]);
  }
}
