import { Component, OnInit } from '@angular/core';
import saveAs from 'file-saver';
import { ReportService } from 'src/app/core/services/http/report.service';

@Component({
  selector: 'app-federal-cobra-report',
  styleUrls: ['./federal-cobra-report.component.scss'],
  template: `
    <app-content-card CustomTitle="Report log">
      <div style="display: flex; flex-direction: column; gap: 10px;">
        This report shows groups that have both Federal and State COBRA.
        <custom-button
          [forceCapitalization]="true"
          [IsLoading]="isReportLoading"
          [disabled]="isReportLoading"
          (onClick)="onGetReport()"
          label="GENERATE REPORT"
          style="align-self: flex-start;"></custom-button>
      </div>
    </app-content-card>
  `,
})
export class FederalCobraReportComponent implements OnInit {
  isReportLoading: boolean;
  constructor(private reportService: ReportService) {}

  ngOnInit() {}
  onGetReport() {
    this.isReportLoading = true;

    this.reportService.FederalCobraReport().subscribe(buffer => {
      this.isReportLoading = false;
      const data: Blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(data, 'FederalCOBRA_report.csv');
    });
  }
}
