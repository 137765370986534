export interface GetRegions {
  Code: RegionCodes;
  Key: string;
}

export interface GetRegionsResponse {
  status: boolean;
  response: GetRegions[];
}

export enum RegionCodes {
  Admin = 'Admin',
  DDCA = 'DDCA',
  DDIC = 'DDIC',
  DDPA = 'DDPA',
  DDNJ = 'DDNJ',
  Onboarding = 'Onboarding',
  CMS = 'CMS',
  MP = 'MP',
  Producer = 'Producer',
  None = 'None',
}

export enum RegionEnum {
  Common,
  DDCA,
  DDIC,
  DDPA,
  MP,
  Undefined,
}
