import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/http/auth/auth.service';
import { Router } from '@angular/router';
import { UserViewService } from '../services/user-view.service';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userView: UserViewService,
    private notificationService: NotificationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = this.authService.GetToken();

    if (!token) return next.handle(request);

    const cloned = request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + token),
    });

    return next.handle(cloned).pipe(
      catchError(err => {
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          console.log('this is an error in the code');
          if (err.status === 401) {
            this.router.navigate(['/auth/signin']);
            this.authService.Logout();
            this.userView.RemoveUserState();
            this.notificationService.error('Session expired, please log in again', false);
          }
        } else {
          console.log('this is an error return by the server', err);
        }
        return throwError(() => err);
      })
    );
  }
}
