import { Component, Input, OnInit } from '@angular/core';
import { faCircleNotch, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { RegionCodes } from 'src/app/core/models/api/auth/responses/get-regions.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { fader } from 'src/app/route-animations';
import { ChangeViewByRegion, parseStringToRegionCode } from '../../helpers/user-view.helper';

@Component({
  selector: 'app-module-picker',
  animations: [fader],
  styleUrls: ['./module-picker.component.scss'],
  template: `
    <div ngClass="container">
      <div ngClass="inner">
        <div *ngIf="IsLoading$; else loaded">
          <fa-icon [icon]="faCircleNotch" [spin]="true" [size]="'3x'"></fa-icon>
        </div>
        <ng-template #loaded>
          <div ngClass="title">Select Module to Access</div>
          <div ngClass="card_container">
            <a
              ngClass="card"
              *ngFor="let item of CardInfoList"
              [ngClass]="!item.IsEmpty ? 'card' : 'empty_card'"
              (click)="!item.IsEmpty && OnClick(item)">
              <ng-container *ngIf="!item.IsEmpty">
                <fa-icon [icon]="faLocationDot"></fa-icon>
                <span>{{ item.Title }}</span>
                <span>({{ item.Description }})</span>
              </ng-container>
            </a>
          </div>
        </ng-template>
      </div>
    </div>
  `,
})
export class ModulePickerComponent implements OnInit {
  faLocationDot = faLocationDot;
  faCircleNotch = faCircleNotch;

  @Input()
  CardInfoList: CardsInfo[] = [];
  IsLoading$: boolean = false;

  constructor(private authService: AuthService, private userViewService: UserViewService) {}
  OnClick(cardInfo: CardsInfo) {
    console.log(cardInfo);
    ChangeViewByRegion(
      this.userViewService,
      cardInfo.Description ? parseStringToRegionCode(cardInfo.Description) : RegionCodes.None
    );
  }

  ngOnInit() {
    this.IsLoading$ = true;
    this.authService.GetRegions().subscribe(regions => {
      this.CardInfoList = regions.response.map<CardsInfo>(region => ({
        Title: `${region.Key}`,
        Description: region.Code,
      }));

      if (regions.response.length % 2 != 0) this.CardInfoList.push({ IsEmpty: true });

      this.IsLoading$ = false;
    });
  }
}

type CardsInfo = {
  Title?: string;
  Description?: string;
  IsEmpty?: boolean;
};
