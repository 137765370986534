import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, lastValueFrom } from 'rxjs';
import { StaticPage } from 'src/app/core/helpers/view-helper';
import { ViewType } from 'src/app/core/models/user-view.model';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { EmailService } from 'src/app/core/services/http/email.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-employer-home',
  templateUrl: './employer-home.component.html',
  styleUrls: ['./employer-home.component.scss'],
})
export class EmployerHomeComponent implements OnInit {
  staticPageAsync$: Observable<EmployersDashboardPagesHtmlAsString>;
  showAbsNewsFeed: boolean;
  pictureData: string;
  buttons = [
    { btnText: 'Enroll new hires and change member status', link: '/dashboard/employees' },
    { btnText: 'Qualifying Event', link: '/dashboard/employee/qualifying-event' },
    { btnText: 'Update and edit your account information', link: '/dashboard/company-profile' },
    { btnText: 'View Bill', link: '/dashboard/invoice' },
    { btnText: 'Pay bill online', link: '/dashboard/online-payment' },
    { btnText: 'Benefits eligibility and coverage', link: '/dashboard/site-navigation/faq' },
    { btnText: 'View Documents', link: '/dashboard/employer/documents' },
    { btnText: 'Order plan supplies', link: '/dashboard/supplies' },
    { btnText: 'Want to know more?', link: '/dashboard/site-navigation/contact-us' },
  ];
  @ViewChild('content') contentHtml: ElementRef;


  constructor (
    private emailService: EmailService,
    private viewService: UserViewService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit () {
    this.manageButtonList();
    this.loadEmployersHome();
    this.loadEmployerPicture();
  }

  manageButtonList () {
    this.viewService.CurrentUserViewState.subscribe(viewState => {
      if (viewState?.QualifyingEvent === false) {
        this.buttons = this.buttons.filter(x => x.btnText !== 'Qualifying Event');
      }  

    });
    
    if (this.authService.IsMP()) {
      this.buttons = this.buttons.filter(x => x.btnText !== 'Pay bill online');
    }
  }

  openLink (link: string) {
    this.router.navigate([link]);
  }

  private loadEmployerPicture() {
    this.emailService.GetActivePicture().subscribe(res => {
      this.pictureData = `data:image/jpeg;base64, ${res.Data}`
    });
  }

  private loadEmployersHome () {
    let employerDashboardLId = this.viewService.GetUserStaticPageIdByName(StaticPage.EmployerDashboardL);
    let employerDashboardRId = this.viewService.GetUserStaticPageIdByName(StaticPage.EmployerDashboardR);
    if (!employerDashboardLId || !employerDashboardRId) return;
    this.staticPageAsync$ = from(
      this.getEmployerDashboardPagesHtmlAsString(employerDashboardLId, employerDashboardRId)
    );
  }

  async getEmployerDashboardPagesHtmlAsString (
    erDashboardL: number,
    erDashboardR: number
  ): Promise<EmployersDashboardPagesHtmlAsString> {
    let employerDashboardL = (await lastValueFrom(this.emailService.GetDashboard(erDashboardL))).Data.TextEditor;
    let employerDashboardR = (await lastValueFrom(this.emailService.GetDashboard(erDashboardR))).Data.TextEditor;

    return { employerDashboardL, employerDashboardR };
  }
}
type EmployersDashboardPagesHtmlAsString = { employerDashboardL: string; employerDashboardR: string };
