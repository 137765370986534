import { query } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserViewService } from './core/services/user-view.service';

@Component({
  template: ''
})
export class RedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router, private userViewService: UserViewService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const queryParam = params['q'];

      if(queryParam === null || queryParam === undefined)
      {
        setTimeout(() => {
          var route = this.userViewService.GetPrimaryLink();
          this.router.navigate([route]);  
        }, 500);
        
        return
      }

      // Navigate to the other route while preserving the query parameter
      this.router.navigate(['/auth/signin'], { queryParams: { q:queryParam } });
    });
  }
}