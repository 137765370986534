import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faArrowRight, IconLookup } from '@fortawesome/free-solid-svg-icons';
import saveAs from 'file-saver';
import { map, tap } from 'rxjs';
import { ValuesResponse } from 'src/app/core/models/api/values.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { GeneralService } from 'src/app/core/services/http/general.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-online-payment',
  styleUrls: ['./online-payment.component.scss'],
  template: `
    <app-content-card CustomTitle="Online Payment">
      <div ngClass="wrapper">
        <div>
          Welcome to Online Payments. Here you may easily make one-time payments or set up recurring payments for your
          monthly statements. Online payments will be made from your bank account via ACH only, credit card payments are
          not accepted.
        </div>
        <br />
        <div>
          We are now using a new and improved payment platform that makes it easier than ever to pay your bill online!
          Please see the <a style="cursor:pointer" (click)="downloadFile('TBS User Instructions.pdf')"> <b>User Guide </b></a> for help with
          navigating the new platform.
        </div>
        <br />
        <div>
          For security purposes, when you click Continue below you will be taken to a secure site to enter this
          information. Please have your bank account and ABA routing number available. This information can be found on
          the bottom of a check that draws on this account.
        </div>
        <br />
        <div>
          You will see payments post to your bank account within 24 hours of the date the payment is scheduled. Allied
          Administrators will process the payment and mark your account as paid within two business days. If you have
          any questions or issues regarding this process or need help, please email
          <a href="mailto:invoice@alliedadministrators.com"> invoice@alliedadministrators.com </a>
          or call our toll free customer service number at (877) 472-2669.
        </div>
        <br />
        <div>
          By clicking Continue you agree to the <a style="cursor:pointer" (click)="openTermOfUseModal = true">
            <b style="color: #4078c0;">Terms and Conditions</b>
          </a>
          of the site.
        </div>
        <!--<div>-->
        <!--    [CuteEditor license is not valid for this host name] <br>-->
        <!--    By clicking Continue you agree to the <i style="color: #4078c0;">Terms and Conditions </i>of the site.-->
        <!--</div>-->
        <br />
        <div style="width: 150px;">
          <custom-button
            [forceCapitalization]="true"
            (onClick)="DoPayement()"
            label="Continue"
            [icon]="icon"
            [primary]="true"
            iconPosition="right"></custom-button>
        </div>
        <br />
        <div>
          Notice: If you opt to pay by ACH, you will leave the Allied Administrators website and enter a secure website
          created, operated and maintained by the Union Bank of California, a federally regulated financial institution.
          The information that Union Bank collects and maintains may differ from the information that Allied
          Administrators collects and maintains. Allied Administrators does not assume liability for the collection,
          storage, or dissemination of the information that you provide. If you have any concerns, we recommend you
          review Union Bank's privacy and security policies to fully understand what information is required by the bank
          and how it is used and maintained.
        </div>
      </div>
    </app-content-card>
    <app-modal [open]="openTermOfUseModal" [modalProps]="termsModalProps" modalHeader="Terms and Condition">
      <div class="terms-modal">
      <h5>Allied Administrators contracts with MUFG Union Bank, N.A. to provide an online payment service powered
                by a third party service provider (“the Payment Service Provider”). You will access the online payment
                site directly from Allied Administrator’s website, but Allied Administrators exercises no control over
                the services provided by the Payment Service Provider.</h5><br>
            <h5>In order to utilize online bill pay you must have and provide a U.S. (domestic) bank account to be
                electronically debited and send payments to Allied Administrators. When you use this service you hereby
                authorize Allied Administrators to debit the account in order to pay your designated payments. When you
                authorize a payment through this service, you are requesting an electronic fund transfer from your bank
                account. When making such a pre-authorized payment, Allied Administrators will make electronic transfers
                via the Automated Clearing House (“ACH”) system from your U.S. bank account in accordance with the
                National Automated Clearing House Association (“NACHA”) rules. You agree that you are bound by such
                rules. You must provide the requested information that is current, true, accurate and complete. You are
                not permitted to use this Service on behalf of another party or use an unauthorized name.</h5><br>
            <h5>You agree that all transactions must comply with applicable state and federal laws or regulations and
                warrant that you will not transmit any transaction that violates the laws of the United States,
                including, without limitation, anti-money laundering laws and regulations of the Office of Foreign Asset
                Control (OFAC). As part of the handling of ACH transactions, Union Bank has policies and procedures that
                identify suspicious transactions. You acknowledge and agree that (a) these policies and procedures and
                (b) other circumstances beyond the reasonable control of Allied Administrators may result in delays or
                failures in the processing or settlement of your transactions. In the event that Union Bank requests
                Allied Administrators for specific information regarding any of your transactions, you agree to promptly
                provide such information without reservation. In addition, if Union Bank requests Allied Administrators
                to provide the original, copy or other accurate record of your authorization to Allied Administrators
                for its use or for the use of a receiving depository financial institution requesting the information,
                you agree that we may do so.</h5><br>
            <h5>If it is determined that any of the information you provide is outdated, false, inaccurate, or
                incomplete, Allied Administrators may, in its sole discretion, terminate your use of the service
                immediately and for all future use. This action does not limit other remedies to pursue and recover
                costs, losses, or other expenses incurred as a result of the information or lack of information you
                provide.</h5><br>
            <h5>Access to the site, and online payment, may from time to time be unavailable, delayed, limited or slowed
                for reasons beyond the control of Allied Administrators. You will be solely responsible to remit payment
                timely to Allied Administrators regardless of your ability to access the site.</h5><br>
            <h5>Pre-Authorized Payments: If you sign up for recurring payments out of your bank account, in order to
                stop any of these payments, you must log into the Allied Administrators site and select the Online
                Payment option to make any revisions, including deletions, to your recurring payment schedule.</h5><br>
            <h5>In Case of Errors or Questions About your Electronic Payments: Notify Allied Administrators immediately
                via email at info@alliedadministrators.com or phone at (877) 418-8669 if you have questions or if you
                believe your electronic payment was made in error.</h5><br>
            <h5>Limitation of Liability: Allied Administrators shall not be held liable for any losses or damages which
                arise as a result of your use of the service, or any associated delays or failures, including, without
                limitation, incidental, special or consequential damages under any theory of liability, except for
                actual damages occurred as a direct result of Allied Administrator’s negligence.</h5><br>
</div>
    
  </app-modal>
  `,
})
export class OnlinePaymentComponent implements OnInit {
  icon = faArrowRight;
  openTermOfUseModal: boolean = false;
  termsModalProps: ModalProps = {
    disableButtons: false,
    CloseButton: {
      OnAction: () => this.openTermOfUseModal = false
    },
    OkButton: {
      show: false
    }
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userViewService: UserViewService,
    private router: Router,
    private generalService: GeneralService
  ) {}

  DoPayement() {
    // TODO need to understand what this method does
    this.getPaymentRedirectUrl(redirectUrl => {
      window.location.assign(redirectUrl);
    });
  }

  downloadFile(fileName: string ){
    this.generalService.DownloadFilePdf(fileName).subscribe(blob => {
      const data: Blob = new Blob([blob], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(data, fileName);
    });
  }

  private getPaymentRedirectUrl(callback: (redirectUrl: string) => any) {
    let currentUser = this.authService.userSession;
    if (!currentUser) return;

    currentUser.subscribe(user => {
      let currentView = this.userViewService.GetCurrentUserViewState();
      if (!currentView) {
        console.log('View not setted, cannot place order');
        return;
      }

      let ssoEnpoint = this.authService.GetSSOEndpoint(currentView.ActualRegion);

      if (!user?.UserID) return;

      this.http
        .post<string>(
          `${environment.alliedApi.rootUrl}/${ssoEnpoint}/api/Values?UserID=${user.UserID}&UserType=${user.Type}&EmployerID=${currentView.EmployerId}&v=1`,
          {}
        )
        .subscribe(res => callback(res));
    });
  }
  ngOnInit() {}
}
