import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MasterProducerEmployersApiResponse } from '../../models/api/master-producer/master-producer-employers-api.response';
import { Observable } from 'rxjs';
import { MasterProducerEmployersEligibilityApiRequest } from '../../models/api/master-producer/master-producer-employers-eligibility.request';
import { MasterProducerResponse, Producer } from '../../models/api/master-producer/master-producer.response';
import { MasterProducersRequest } from '../../models/api/master-producer/master-producers.request';
import { MasterProducersSearchResponse } from '../../models/api/master-producer/master-producers.response';
import { MasterProducerRegionResponse } from '../../models/api/master-producer/masterproducer.regions.response';

@Injectable({
  providedIn: 'root',
})
export class MasterProducerService {
  constructor(private http: HttpClient) {}

  GetProducerLanvera(): Observable<any> {
    return this.http.get(`${environment.alliedApi.baseUrl}/MasterProducers/download`, {
      headers: new HttpHeaders().set('Content-Type', 'text/csv'),
      responseType: 'arraybuffer',
    });
  }

  GetMasterProducers(
    page: number,
    pageSize: number,
    statusFilter: number = 1,
    search: string = '',
    orderBy: 'asc' | 'desc' = 'asc',
    orderByColumn: string = 'Name'
  ) {
    return this.http.post<MasterProducersSearchResponse>(
      `${environment.alliedApi.baseUrl}/masterproducers/search`,
      new MasterProducersRequest({
        orderByColumnName: orderByColumn,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search,
        orderBy: orderBy,
        statusFilter: statusFilter,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }

  GetEmployers(employerId: string) {
    return this.http.get<MasterProducerEmployersApiResponse>(
      `${environment.alliedApi.baseUrl}/masterproducers/employers/${employerId}`
    );
  }

  GetProducerRegions(producerId: string) {
    return this.http.get<MasterProducerRegionResponse>(
      `${environment.alliedApi.baseUrl}/masterproducers/regions/${producerId}`
    );
  }

  GetMasterProducer(employerId: string) {
    return this.http.get<MasterProducerResponse>(`${environment.alliedApi.baseUrl}/masterproducers/${employerId}`);
  }

  UpdateMasterProducer(request: Producer) {
    return this.http.put<MasterProducerResponse>(`${environment.alliedApi.baseUrl}/masterproducers`, request);
  }

  UpdateEmployersEligibility(request: MasterProducerEmployersEligibilityApiRequest) {
    return this.http.put<{ Message: string; Status: boolean }>(
      `${environment.alliedApi.baseUrl}/masterproducers/employers/eligibility`,
      request
    );
  }
}
