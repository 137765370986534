import { Component, OnInit } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSadTear } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-under-development',
  templateUrl: './under-development.component.html',
  styleUrls: ['./under-development.component.css'],
})
export class UnderDevelopmentComponent implements OnInit {
  icon: IconProp = faSadTear;

  constructor() {}

  ngOnInit() {}
}
