import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ArchiveApiResponse, SingleArchiveApiResponse } from '../../models/api/archive.response';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArchiveService {
  constructor(private http: HttpClient) {}

  GetIsUserAbleToDownload(archiveId: string) {
    return this.http.get<{ response: boolean }>(
      `${environment.alliedApi.baseUrl}/XmlExcelDocuments/UserAbleToDownloadFile?id=${archiveId}`
    );
  }

  GetArchiveById(archiveId: string) {
    return this.http.get<SingleArchiveApiResponse>(
      `${environment.alliedApi.baseUrl}/archives/${archiveId}`
    );
  }

  GetArchives(employerId: string, archiveType: 'excel' | 'xml') {
    return this.http.get<ArchiveApiResponse>(
      `${environment.alliedApi.baseUrl}/archives/employers/${archiveType}&${employerId}`
    );
  }

  GetMasterArchives(){
    return this.http.get<ArchiveApiResponse>(
      `${environment.alliedApi.baseUrl}/archives/region/common`
    );
  }

  GetArchive(archiveId: string) {
    return this.http.get<SingleArchiveApiResponse>(`${environment.alliedApi.baseUrl}/archives/${archiveId}`);
  }
  DownloadArchive(archiveId: string): Observable<any> {
    return this.http
      .post(
        `${environment.alliedApi.baseUrl}/XmlExcelDocuments/DownloadDocument`,
        {
          documentId: archiveId,
        },
        { responseType: 'arraybuffer' }
      )
      .pipe(
        map(res => {
          return new Blob([res]);
        })
      );
  }

  DownloadErrorReport(fileID: string): Observable<any> {
    return this.http
      .get(`${environment.alliedApi.baseUrl}/archives/download/${fileID}`, { responseType: 'arraybuffer' })
      .pipe(
        map(res => {
          return new Blob([res]);
        })
      );
  }
  GetArchiveErrors(fileID: string) {
    return this.http.get<{ Data: { ArchiveErrors: any[] }; Status: boolean }>(
      `${environment.alliedApi.baseUrl}/archives/${fileID}`
    );
  }
}
