import { FormGroup } from '@angular/forms';
import { OperatorFunction, concatMap, of } from 'rxjs';

export const markAllFormFieldsAsTouched = (form: FormGroup) => {
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    control?.markAsTouched({ onlySelf: true });
  });
};

export function tapOnce<T>(tapFn: (t: T) => void, tapIndex = 0): OperatorFunction<T, T> {
  return source$ =>
    source$.pipe(
      concatMap((value, index) => {
        if (index === tapIndex) {
          tapFn(value);
        }
        return of(value);
      })
    );
}
