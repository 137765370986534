import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import saveAs from 'file-saver';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { GetCobraReportRequest } from 'src/app/core/models/api/get-cobra-report.request';
import { ReportService } from 'src/app/core/services/http/report.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { FileHandle } from 'src/app/shared/directives/dragDrop.directive';

@Component({
  selector: 'app-cobra-reports',
  styleUrls: ['./cobra-reports.component.scss'],
  template: `
    <div>
      <app-content-card CustomTitle="Report Log">
        <div ngClass="cobra_report_wrapper">
          <div>
            <app-radio-button
              (onRadioButtonClick)="onSelectReportType($event)"
              [labels]="reportTypes"
              [checked]="selectedReportTypeSubject.value"></app-radio-button>
          </div>
          <form
            *ngIf="selectedReportTypeSubject.value == 'Ad-hoc report'"
            [formGroup]="rangeDateForm"
            ngClass="search_section">
            <app-form-input
              fieldName="fromDate"
              ngClass="form_item wide"
              title="From Date"
              type="date"
              [Wide]="true"
              formControlName="fromDate"
              [parentForm]="rangeDateForm"></app-form-input>
            <app-form-input
              fieldName="toDate"
              ngClass="form_item wide"
              title="To Date"
              type="date"
              [Wide]="true"
              formControlName="toDate"
              [parentForm]="rangeDateForm"></app-form-input>
          </form>
          <custom-button
            [forceCapitalization]="true"
            [IsLoading]="isReportLoading"
            [disabled]="isReportLoading"
            label="GENERATE REPORT"
            style="align-self: flex-start;"
            (onClick)="onGetReport()"></custom-button>
        </div>
      </app-content-card>
      <br />
      <app-content-card CustomTitle="Wex COBRA File">
        <div ngClass="cobra_report_wrapper">
          <app-drag-drop-download (onFileDropped)="onFileUploaded($event)"></app-drag-drop-download>
          <custom-button
            [forceCapitalization]="true"
            [IsLoading]="isWexUploading"
            [disabled]="wexFile == null || wexFile == undefined"
            label="CONVERT FILE"
            style="align-self: flex-end;"
            (onClick)="onWexCobraUpload()"></custom-button>
        </div>
      </app-content-card>
    </div>
  `,
})
export class CobraReportsComponent implements OnInit {
  reportTypes = ['Weekly report', 'Ad-hoc report'];
  selectedReportTypeSubject: BehaviorSubject<string> = new BehaviorSubject('Weekly report');

  constructor(
    private formBuilder: FormBuilder,
    private reportService: ReportService,
    private notificationService: NotificationService
  ) {}
  report: Observable<Blob>;
  isReportLoading: boolean = false;
  isWexUploading: boolean = false;
  wexFile?: File;

  rangeDateForm: FormGroup = this.formBuilder.group({
    fromDate: ['', Validators.required],
    toDate: ['', Validators.required],
  });

  uploadFileForm: FormGroup = this.formBuilder.group({
    file: [],
  });
  ngOnInit() {}

  onSelectReportType($event: string) {
    this.selectedReportTypeSubject.next($event);
  }

  onGetReport() {
    let request: GetCobraReportRequest = {};
    if (this.selectedReportTypeSubject.value == 'Ad-hoc report' && this.rangeDateForm.valid) {
      this.isReportLoading = true;

      request.reportType = '2';
      request.startDate = this.rangeDateForm.value.fromDate;
      request.endDate = this.rangeDateForm.value.toDate;

      this.report = this.reportService.GetCobraReport(request);
    } else {
      this.isReportLoading = true;

      request.reportType = '1';
      this.report = this.reportService.GetCobraReport(request);
    }

    this.report.subscribe(buffer => {
      this.isReportLoading = false;
      const data: Blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(data, 'COBRA_report.csv');
    });
  }

  onFileUploaded(file: FileHandle) {
    this.wexFile = file.file;
  }

  onWexCobraUpload() {
    if (!this.wexFile) return;
    this.isWexUploading = true;
    this.reportService
      .UploadWexFile(this.wexFile)
      .pipe(
        catchError(err => {
          this.isWexUploading = false;
          this.notificationService.error(
            'It was not possible to convert the file, please verify if all the fields are correct, or contact Allied Support Team.'
          );
          return throwError(() => err);
        })
      )
      .subscribe(res => {
        this.isWexUploading = false;
        var blob = new Blob([res], { type: 'text/csv' });
        if (res && res !== '') saveAs(blob, 'wex_file.txt');
      });
  }
}
