import { Environment } from './IEnvironment';
const _30_MINUTES_IN_SECONDS: number = 1800;

export const environment: Environment = {
  production: true,

  inactivityLogoutTimeoutSeconds: _30_MINUTES_IN_SECONDS,
  inactivityValidationTimerTickMs: 60000,

  alliedApi: {
    baseUrl: 'https://secure.alliedadministrators.com/ProductionAllied4RAPI',
    rootUrl: 'https://secure.alliedadministrators.com',
    timeout: 0,
  },
  onboardingApi: {
    baseUrl: 'https://onboarding.alliedadministrators.com/Checklist',
  },

  cmsApi: {
    baseUrl: '',
  },
  logger: {
    enableDebug: false,
    enableInfo: false,
    enableError: true,
    enableWarning: false,
  },
};
