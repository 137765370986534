import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SuppliesResponse } from '../../models/api/supply/supplies.response';
import { SupplyDeleteResponse } from '../../models/api/supply/supply.delete.response';
import { SupplyApiResponse, SupplyData } from '../../models/api/supply/supply.response';
import { SupplySendEmailRequest } from '../../models/api/supply/supply-send-email.request';

@Injectable({
  providedIn: 'root',
})
export class SupplyService {
  constructor(private http: HttpClient) {}
  GetSuppliesByEmployer(planNumber: string, employerId: string): Observable<SuppliesResponse> {
    return this.http.get<SuppliesResponse>(
      `${environment.alliedApi.baseUrl}/supplies/employer/${planNumber}&${employerId}`
    );
  }

  GetAllSupplies() {
    return this.http.get<SuppliesResponse>(`${environment.alliedApi.baseUrl}/supplies`);
  }

  GetSupply(supplyId: number) {
    return this.http.get<SupplyApiResponse>(`${environment.alliedApi.baseUrl}/supplies/${supplyId}`);
  }

  AddSupply(supply: SupplyData) {
    if (supply.SupplyId) return this.http.put<SupplyApiResponse>(`${environment.alliedApi.baseUrl}/supplies`, supply);

    return this.http.post<SupplyApiResponse>(`${environment.alliedApi.baseUrl}/supplies`, supply);
  }

  DeleteSupply(id: string) {
    return this.http.delete<SupplyDeleteResponse>(`${environment.alliedApi.baseUrl}/supplies/delete/${id}`);
  }

  SendEmail(request: SupplySendEmailRequest) {
    // This should be implemented e
    return this.http.post<{ status: boolean; message: string }>(
      `${environment.alliedApi.baseUrl}/Userctrl/supplies`,
      request
    );
  }

  DownloadSupply(supplyId: number, employerId: string) {
    return this.http
      .get<Blob>(`${environment.alliedApi.baseUrl}/supplies/download/${supplyId}&${employerId}`, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        map(res => {
          return new Blob([res], { type: 'application/pdf' });
        })
      );
  }
}
