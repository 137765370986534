import { trigger, transition, style, query, group, animateChild, animate, keyframes } from '@angular/animations';

export const fader = trigger('routeAnimations', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0, display: 'none' })], {
      optional: true,
    }),
    query(':leave', [style({ opacity: 1 }), animate('0.1s', style({ opacity: 0, display: 'none' }))], {
      optional: true,
    }),
    query(
      ':enter',
      [style({ opacity: 0 }), animate('0.1s', style({ opacity: 1, position: 'relative', display: 'auto' }))],
      { optional: true }
    ),
  ]),
]);
