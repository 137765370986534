import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faEye, faPencil } from '@fortawesome/free-solid-svg-icons';
import { Observable, map, of, take } from 'rxjs';
import { GeneralAgentsResponse } from 'src/app/core/models/api/general-agent/general-agents.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { FileService } from 'src/app/core/services/file.service';
import { GeneralAgentsService } from 'src/app/core/services/http/general-agents.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-general-agents',
  styleUrls: ['./manage-general-agents.component.scss'],
  template: `
    <app-content-card CustomTitle="Manage GA">
      <ng-container *ngIf="data$ | withLoading | async as data">
        <app-table *ngIf="data.loading" [IsLoading]="true" [LoadingColumns]="3" [DisplayedColumns]="displayedColumns">
        </app-table>
        <app-table
          *ngIf="data.value?.Data"
          [Rows]="data.value?.Data"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [EnableLocalSort]="true"
          [EnablePagination]="true"
          [EnableSearch]="true"
          [EnableLocalSearch]="true">
        </app-table>
      </ng-container>
    </app-content-card>

    <!-- Column Template Region -->
    <ng-template #actionColumnTemplate let-data>
      <div style="display: flex; gap: 10px;">
        <a style="color: var(--brandColor);" [routerLink]="[viewGaUrl, data.Gaid]"
          ><fa-icon [icon]="viewIcon"></fa-icon
        ></a>
        <a style="color: var(--brandColor);" [routerLink]="[editwGaUrl, data.Gaid]"
          ><fa-icon [icon]="editIcon"></fa-icon
        ></a>
      </div>
    </ng-template>
    <ng-template #absColumnTemplate let-data>
      {{ data ? 'Y' : 'N' }}
    </ng-template>
  `,
})
export class ManageGeneralAgentsComponent implements OnInit {
  editIcon = faPencil;
  viewIcon = faEye;
  displayedColumns: DisplayedColumns[];
  downloadButtons: TableDownloadButtons;

  editwGaUrl = RouteEnum.EditGeneralAgentUser;
  viewGaUrl = RouteEnum.ViewGeneralAgentUser;

  data$: Observable<GeneralAgentsResponse>;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('absColumnTemplate', { static: true }) absColumnTemplate: TemplateRef<unknown>;

  constructor(private generalAgentsService: GeneralAgentsService, private fileService: FileService) {}

  ngOnInit() {
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              Ganame: 'GA Name',
              Ganr: 'GA Number',
              Garegion: 'Region',
              AbsnewsFeed: 'ABS',
            },
          ];

          this.data$.pipe(take(1)).subscribe(res => {
            let data = res.Data.map(ga => ({
              Ganame: ga.Ganame,
              Ganr: ga.Ganr,
              Garegion: ga.Garegion,
              AbsnewsFeed: ga.AbsnewsFeed,
            }));

            this.fileService.GeneratePDF('Allied Administrators - GA', header, data).save('Allied Administrators - GA');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.data$.pipe(take(1)).subscribe(res => {
            let data = res.Data.map(ga => ({
              Ganame: ga.Ganame,
              Ganr: ga.Ganr,
              Garegion: ga.Garegion,
              AbsnewsFeed: ga.AbsnewsFeed,
            }));

            this.fileService.GenerateXLSX('Allied Administrators - GA', data);
          });
        },
      },
    };
    this.displayedColumns = [
      { columnName: 'Ganame', label: 'GA Name', sortable: true },
      { columnName: 'Ganr', label: 'GA Number', sortable: true },
      { columnName: 'Garegion', label: 'Region', sortable: true },
      { columnName: 'AbsnewsFeed', label: 'ABS', template: this.absColumnTemplate, sortable: true },
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      },
    ];

    this.data$ = this.generalAgentsService.GetGeneralAgents();
  }
}
