import moment from 'moment';

/**
Formats a given Date object as a string with the format "MM/DD/YYYY HH:MM A".
@param {Date} date - The date object to be formatted.
@returns {string} The formatted string.
*/
export const formatDate = (date: Date | string, format: string = 'MM/DD/YYYY hh:mm A'): string => {
  let momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format(format) : '';
};
