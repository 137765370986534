import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import {
  BaManageGroupData,
  GetPendingsByEmployerAPIResponse,
} from 'src/app/core/models/api/benefit-administrators/get-pendings-by-employer.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { BenefitAdministratorService } from 'src/app/core/services/http/benefit-administrator.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-eligibility-access',
  styleUrls: ['./eligibility-access.component.scss'],
  template: `
    <app-content-card CustomTitle="BA Status">
      <ng-container *ngIf="rows$ | withLoading | async as data">
        <app-skeleton-input *ngIf="data.loading"></app-skeleton-input>
        <div *ngIf="data.value">
          <p *ngIf="!hasBaManaging(data.value?.response)">
            Currently, this employer group is not being managed by any BA.
          </p>

          <app-table
            *ngIf="hasBaManaging(data.value?.response)"
            [Rows]="data.value?.response"
            [IsLoading]="data.loading ?? false"
            [DisplayedColumns]="displayedColumns"
            [EnableLocalSearch]="true"
            [EnableSearch]="true"
            [EnableLocalSort]="true">
          </app-table>

        </div>
      </ng-container>
      <ng-template #actionColumnTemplate let-data>
        <div [routerLink]="[approvePendingBaUrl, data.BaManageGroupId]">
          <fa-icon ngClass="view_as_user_eye" *ngIf="data.IsMpUser === false" [matTooltip]="data.StatusView === 'Approved' ? 'Disable': 'Approve'" [icon]="editIcon"></fa-icon>
        </div>
      </ng-template>
    </app-content-card>
  `,
})
export class EligibilityAccessComponent implements OnInit {
  rows$: Observable<GetPendingsByEmployerAPIResponse>;
  displayedColumns: DisplayedColumns[];

  editIcon = faPencil;

  approvePendingBaUrl = RouteEnum.ApprovePendingBaAccess;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;

  constructor(
    private userViewService: UserViewService,
    private benefitAdministratorService: BenefitAdministratorService
  ) {}

  ngOnInit(): void {
    let view = this.userViewService.GetCurrentUserViewState();
    if (view?.EmployerId) this.rows$ = this.benefitAdministratorService.GetPendingsByEmployer(view.EmployerId);

    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'BaName', label: 'Ba Name', sortable: true },
      { columnName: 'Region', label: 'Region', sortable: true },
      { columnName: 'StatusView', label: 'Status', sortable: true },
      { columnName: 'DateCreationFormated', label: 'Data Creation', sortable: true },
    ];
  }
  hasBaManaging(baData?: BaManageGroupData[]) {
    return baData && baData.length > 0;
  }
}
