import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, catchError, debounceTime, map, tap } from 'rxjs';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';

@Component({
  selector: 'app-change-password',
  styleUrls: ['./change-password.component.scss'],
  template: `
    <div ngClass="modal_background" [class.open]="open">
      <div ngClass="wrapper">
        <div (click)="onCloseModal()" style="align-self: flex-end;">
          <fa-icon [icon]="closeIcon" ngClass="closeBtn"></fa-icon>
        </div>
        <form [formGroup]="passwordChangeForm">
          <app-form-input
            type="password"
            [primary]="true"
            title="Current Password"
            [Wide]="true"
            placeholder="Current Password"
            formControlName="currentPassword"
            [parentForm]="passwordChangeForm"
            [enableVisibilityToggle]="true"
            fieldName="currentPassword"></app-form-input>
          <div>
            <app-form-input
              type="password"
              [primary]="true"
              title="New Password"
              [Wide]="true"
              placeholder="New Password"
              formControlName="newPassword"
              [parentForm]="passwordChangeForm"
              [enableVisibilityToggle]="true"
              fieldName="newPassword"></app-form-input>
            <app-form-input
              type="password"
              [primary]="true"
              title="Confirm Password"
              [Wide]="true"
              placeholder="Confirm Password"
              formControlName="confirmPassword"
              [parentForm]="passwordChangeForm"
              [enableVisibilityToggle]="true"
              fieldName="confirmPassword"></app-form-input>
          </div>
        </form>
        <custom-button
          [forceCapitalization]="true"
          [primary]="true"
          label="Update"
          style="align-self: flex-end; margin-top: 20px;"
          (onClick)="onSubmit(passwordChangeForm.value)"
          [disabled]="disableUpdateButton.value"></custom-button>
      </div>
    </div>
  `,
})
export class ChangePasswordComponent implements OnInit {
  closeIcon = faClose;
  disableUpdateButton: BehaviorSubject<boolean> = new BehaviorSubject(true);

  passwordChangeForm: FormGroup = this.formBuilder.group(
    {
      currentPassword: ['', Validators.required],
      newPassword: [{ value: '' }, [Validators.minLength(8), PasswordValidator.strong, Validators.required]],
      confirmPassword: [{ value: '' }, [Validators.minLength(8), Validators.required]],
    },
    {
      validators: [PasswordValidator.match('newPassword', 'confirmPassword')],
    }
  );

  @Input() open: boolean = false;
  @Output()
  onClose = new EventEmitter<boolean>();

  onCloseModal() {
    this.onClose.emit(true);
  }
  constructor(
    private authService: AuthService,
    private userControlService: UserControlService,
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  onSubmit(value: ChangePasswordForm) {
    if (value.newPassword === value.currentPassword) {
      this.notificationService.error('Password must not be the same as the current one', false);
      return;
    }
    this.authService.userSession.subscribe(x => {
      if (!this.passwordChangeForm.valid) {
        console.log('Not valid');
      }
      if (x?.UserID)
        this.userControlService
          .ChangePassword(value.currentPassword, value.newPassword, value.confirmPassword, x.UserID)
          .subscribe(res => {
            if (!res.status) {
              this.notificationService.error(res.message, false);
              return;
            }
            this.notificationService.success(res.message);
            this.router.navigate([RouteEnum.SignIn]);
            this.authService.Logout();
            setTimeout(() => {
              window.location.reload();
            }, 200);
            console.log('Password changed, user was logout user and redirected to login page!');
          });
    });
  }
  ngOnInit() {
    this.passwordChangeForm.valueChanges.pipe(debounceTime(500)).subscribe(_ => {
      if (this.passwordChangeForm.valid) this.disableUpdateButton.next(false);
      else this.disableUpdateButton.next(true);
    });
  }
}

type ChangePasswordForm = {
  currentPassword: string;
  confirmPassword: string;
  newPassword: string;
};
