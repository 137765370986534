import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { Observable, concatMap, debounceTime, of, skip, timer } from 'rxjs';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { EmployerToManage, EmployersToManageApiResponse } from 'src/app/core/models/api/employers-to-manage.response';
import { BenefitAdministratorService } from 'src/app/core/services/http/benefit-administrator.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-ba-groups-manually',
  styleUrls: ['./ba-groups-manually.component.scss'],
  template: `
    <div style="display: flex; flex-direction: column; gap: 10px;">
      <app-select
        Label="Select Region"
        [OptionList]="optionList"
        (onSelectOutput)="onRegionSelect($event)"
        style="align-self: flex-start;"></app-select>
      <form [formGroup]="baGroupsManuallyForm">
        <app-form-input
          [parentForm]="baGroupsManuallyForm"
          fieldName="searchEmployers"
          [Wide]="true"
          formControlName="searchEmployers"
          title="Search by entering at least three characters of the employer's name or number"></app-form-input>
      </form>
      <ng-container *ngIf="showEmployersTable">
        <div *ngIf="rows$ | withLoading | async as rows">
          <app-table
            [DisplayedColumns]="displayedColumns"
            [IsLoading]="rows.loading ?? false"
            [EnableLocalSearch]="true"
            [EnableSearch]="true"
            [EnablePagination]="true"
            [Rows]="rows.value?.response"></app-table>
        </div>
      </ng-container>

      <ng-template #selectEmployerTemplate let-data>
        <app-checkbox
          (onCheck)="onCheckEmployer(data)"
          (onRemoveCheck)="onRemoveCheckEmployer(data)"
          [enableOnRemoveCheckEvent]="true"></app-checkbox>
      </ng-template>
      <span *ngIf="saveResponse">{{ saveResponse }}</span>
      <custom-button
        (onClick)="onSubmit()"
        label="save"
        [IsLoading]="saveLoading"
        [disabled]="saveLoading"
        [forceCapitalization]="true"
        [icon]="saveIcon"
        style="align-self: flex-end; padding-top: 10px;"></custom-button>
    </div>
  `,
})
export class BaGroupsManuallyComponent implements OnInit {
  saveIcon = faFloppyDisk;

  optionList: SelectOptions<string>[] = [
    {
      key: 'DDIC',
      optionText: 'DDIC',
    },
    {
      key: 'DDPA',
      optionText: 'DDPA',
    },
    {
      key: 'DDCA',
      optionText: 'DDCA',
    },
  ];

  baGroupsManuallyForm: FormGroup<any>;
  displayedColumns: DisplayedColumns[];

  showEmployersTable: boolean;

  rows$: Observable<EmployersToManageApiResponse>;
  selectedEmployers: Set<EmployerToManage>;
  selectedRegion: string;
  saveResponse?: string;
  saveLoading: boolean = false;

  @ViewChild('selectEmployerTemplate', { static: true }) selectEmployerTemplate: TemplateRef<unknown>;

  constructor(private fb: FormBuilder, private baService: BenefitAdministratorService) {}

  ngOnInit(): void {
    this.displayedColumns = [
      {
        columnName: 'Selected',
        label: 'Select Company',
        template: this.selectEmployerTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'EmployerNr', label: 'Employer Number' },
      { columnName: 'Name', label: 'Company Name' },
    ];

    this.baGroupsManuallyForm = this.fb.group({
      searchEmployers: [{ value: '', disabled: true }],
    });

    this.onSearchEmployers();

    this.selectedEmployers = new Set<any>();
  }

  loadData(employerId: string) {
    if (IsStringNullOrEmpty(employerId)) {
      this.rows$ = of({ response: [], message: '', status: false } as EmployersToManageApiResponse)
      return;
    }

    this.rows$ = this.baService.GetEmployersToManage(employerId, this.selectedRegion);
  }

  onSubmit() {
    this.saveLoading = true;
    let request = Array.from(this.selectedEmployers).map(x => ({ ...x, Selected: true }));

    this.baService.RequestEmployersToManage(request, this.selectedRegion).subscribe(res => {
      this.saveResponse = res.message;
      this.saveLoading = false;
    });
  }

  onRegionSelect($event: string) {
    this.selectedRegion = $event;
    this.searchEmployerField?.enable();
  }

  onSearchEmployers() {
    let searchEmployersChange = this.searchEmployerField?.valueChanges.pipe(debounceTime(400), skip(1));

    searchEmployersChange?.subscribe((searchEmployer: string) => {
        this.loadData(searchEmployer);
        this.showEmployersTable = true;
    });
  }

  onCheckEmployer(employer: any) {
    if (!this.selectedEmployers.has(employer)) this.selectedEmployers.add(employer);

    console.log(this.selectedEmployers);
  }

  onRemoveCheckEmployer(employer: any) {
    if (this.selectedEmployers.has(employer)) this.selectedEmployers.delete(employer);

    console.log(this.selectedEmployers);
  }

  get searchEmployerField() {
    return this.baGroupsManuallyForm.get('searchEmployers');
  }
}
