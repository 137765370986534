import { animate, query, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleUp, faCake, faUser } from '@fortawesome/free-solid-svg-icons';
import { VerticalMenuService } from 'src/app/core/services/vertical-menu.service';

@Component({
  selector: 'app-menu-item',
  styleUrls: ['./menu-item.component.scss'],
  animations: [trigger('openClose', [transition(':enter', [style({ maxHeight: '0' }), animate('0.4s ease')])])],
  template: `
    <div
      *ngIf="Link"
      [class.active]="Link.Active && Link.Link"
      [class.open_with_submenu]="Link.Collapsed && Link.SubMenuLinks?.length">
      <div ngClass="menu_item_wrapper" (click)="OnItemClick(Link)">
        <fa-icon *ngIf="icon" ngClass="icon" [icon]="icon" [fixedWidth]="true"></fa-icon>
        <a>{{ Link.Label }}</a>
        <fa-icon
          *ngIf="Link.SubMenuLinks?.length"
          ngClass="open_angle_icon"
          [icon]="Link.Active ? angleUpIcon : angleDownIcon"
          size="1x"
          [fixedWidth]="true"></fa-icon>
      </div>

      <div [class.open]="hasSubLinks(Link.SubMenuLinks) && Link.Active" [@openClose] ngClass="sub_menu_items_wrapper">
        <ng-container *ngFor="let item of Link.SubMenuLinks">
          <app-menu-item [Link]="item" [icon]="item.Icon" (onClick)="OnItemClick($event)"></app-menu-item>
        </ng-container>
      </div>
    </div>

    <div *ngIf="isLoading" style="padding: 10px 10px; text-align: left;">
      <app-skeleton-input></app-skeleton-input>
    </div>
    <!-- <ng-template isLoading>
    </ng-template> -->
  `,
})
export class MenuItemComponent implements OnInit {
  Open: boolean = false;
  alreadyOpened: string[] = [];
  labelClicked?: string;
  angleUpIcon = faAngleUp;
  angleDownIcon = faAngleDown;

  @Input() isLoading: boolean = false;
  @Input() icon?: IconProp;
  @Input() Link?: MenuLink;

  @Output()
  onClick = new EventEmitter<MenuLink>();

  constructor() {}

  OnItemClick(menuLink: MenuLink) {
    // if(this.hasSubLinks(menuLink.SubMenuLinks)){
    //   this.toggleMenu(menuLink);
    // }
    this.onClick.emit(menuLink);
  }
  toggleMenu(menuLink: MenuLink) {
    menuLink.Active = menuLink.Active === true ? false : true;
  }

  ngOnInit() {}

  hasSubLinks(menuLinks?: MenuLink[]): boolean {
    return menuLinks != undefined && menuLinks.length > 0;
  }
}

export type MenuLink = {
  Label: string;
  Link?: string;
  Icon?: IconProp;
  Active?: boolean;
  Collapsed?:boolean;
  SubMenuLinks?: MenuLink[];
};
