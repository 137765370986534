import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { first, Observable } from 'rxjs';
import { AuthService } from '../../services/http/auth/auth.service';
import { UserViewService } from '../../services/user-view.service';
import { VerticalMenuService } from '../../services/vertical-menu.service';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userView: UserViewService,
    private verticalMenuService: VerticalMenuService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let view = this.userView.GetCurrentUserViewState();
    if (view !== null) {
      if (state.url.includes('module-picker') && !view.ShowModulePickerView) this.router.navigate(['/dashboard']);
    }

    if (!this.authService.IsLoggedIn()) {
      this.authService.Logout();
      this.userView.RemoveUserState();
      this.verticalMenuService.RemoveVerticalMenuState();
      this.router.navigate(['/auth/signin']);

    }

    return true;
  }
}
