import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';
import saveAs from 'file-saver';
import moment from 'moment';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { RegionCodes } from 'src/app/core/models/api/auth/responses/get-regions.response';
import { Employer } from 'src/app/core/models/api/employer/employer';
import { EmployersSearchResponse } from 'src/app/core/models/api/employer/employers-search.response';
import { ViewType } from 'src/app/core/models/user-view.model';
import { FileService } from 'src/app/core/services/file.service';
import { DocumentService } from 'src/app/core/services/http/document.service';
import { EmployersService } from 'src/app/core/services/http/employers.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-employer-users',
  styleUrls: ['./select-employer.component.scss'],
  template: `
    <app-content-card CustomTitle="Employers">
      <ng-container *ngIf="tableInfo$ | withLoading | async as tableInfo">
        <div style="display: flex; justify-content: space-between; padding: 10px;">
          <div ngClass="search_filter">
            <span>Select or search Employer to navigate to the group.</span>
            <app-radio-button
              (onRadioButtonClick)="onFilter($event)"
              [labels]="['All', 'Active', 'Terminated']"
              [checked]="checkedFilter.value"></app-radio-button>
          </div>
          <custom-button
            [forceCapitalization]="true"
            style="align-self: flex-end;"
            label="Download Lanvera Employer List"
            [color]="'quaternary'"
            [icon]="downloadIcon"
            (onClick)="downloadLanvera()"></custom-button>
        </div>

        <app-table
          [DisplayedColumns]="displayedColumns"
          (PaginationEvent)="onPaginatorEvent($event)"
          (onSearchEvent)="onSearch($event)"
          (onSort)="onSort($event)"
          [Pagination]="Pagination"
          [SearchDebounceTime]="800"
          [EnablePagination]="true"
          [EnableSearch]="true"
          [searchedText]="searchedText.value"
          [Rows]="tableInfo.value?.data"
          [DownloadButtons]="DownloadButtons"></app-table>
      </ng-container>
    </app-content-card>

    <!-- Column Template Region -->
    <ng-template #actionColumnTemplate let-data>
      <div (click)="onImpersonateUser(data.EmployerID)">
        <fa-icon ngClass="view_as_user_eye" [title]="'View ' + data.Name + ' as ER'" [icon]="viewIcon"></fa-icon>
      </div>
    </ng-template>

    <ng-template #employerNumberTemplate let-data>
      <a
        (click)="onImpersonateUser(data.EmployerID)"
        ngClass="table_cell_link"
        [title]="'View ' + data.Name + ' as ER'"
        >{{ data.Name }}</a
      >
    </ng-template>
  `,
})
export class SelectEmployerComponent implements OnInit, OnDestroy {
  viewIcon = faEye;
  downloadIcon = faDownload;
  displayedColumns: DisplayedColumns[] = [];
  tableInfo$: Observable<EmployersSearchResponse>;
  Pagination?: ManualPagination;
  checkedFilter: BehaviorSubject<string> = new BehaviorSubject('All');
  searchedText: BehaviorSubject<string> = new BehaviorSubject('');
  tableSearchObs: Observable<string>;
  tableLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  actualUserView: RegionCodes;

  sortColumn?: string;
  sortDirection: 'asc' | 'desc';

  DownloadButtons: TableDownloadButtons = {
    DownloadPDF: {
      callback: () => {
        this.tableInfo$.pipe(take(1)).subscribe(res => {
          this.fileService
            .GeneratePDF(
              'Allied Administrators - Employers',
              [
                {
                  EmployerNR: 'Employer Number',
                  Name: 'Company Name',
                  ErGroupState: 'Group Contract State',
                  Employees: 'Employees',
                  Dental: 'Dental',
                  Vision: 'Vision',
                  Life: 'Life',
                  LTD: 'LTD',
                  P3: 'P3',
                  STD: 'STD',
                  ContractTerminationDate: 'Termination Date',
                  GANR: 'GANR'
                },
              ],
              this.mapToDownloadTableObject(res.data),
              'l'
            )
            .save('Allied Administrators - Employers.pdf');
        });
      },
    },
    DownloadXLSX: {
      callback: () => {
        this.tableInfo$.pipe(take(1)).subscribe(res => {
          this.fileService.GenerateXLSX('Allied Administrators - Employers', this.mapToDownloadTableObject(res.data), [
            'Employer Number',
            'Company Name',
            'Group Contract State',
            'Employees',
            'Dental',
            'Vision',
            'Life',
            'LTD',
            'P3',
            'STD',
            'Termination Date',
            'GANR'
          ]);
        });
      },
    },
  };
  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('employerNumberTemplate', { static: true }) employerNumberColTemplate: TemplateRef<unknown>;
  userViewStateSubscription: Subscription;

  constructor(
    private employerService: EmployersService,
    private documentService: DocumentService,
    private userViewService: UserViewService,
    private fileService: FileService
  ) {}

  downloadLanvera() {
    this.documentService.GetLanvera().subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });

      let userView = this.userViewService.GetCurrentUserViewState();
      if (!userView?.ActualRegion) return;
      var fileName = userView.ActualRegion + '-Users-' + moment().format('MM-DD-YYYY') + '.csv';
      saveAs(data, fileName);
    });
  }

  onSort(sort: Sort) {
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction === 'asc' ? 'asc' : 'desc';

    this.tableInfo$ = this.employerService
      .SearchEmployers(
        this.Pagination?.Index ?? 0,
        this.Pagination?.PageSize ?? 10,
        this.getStatusFilter(this.checkedFilter.value),
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(debounceTime(500), this.filterTableData());

      return true;
  }

  onPaginatorEvent(event: PageEvent) {
    console.log('pagination', event);
    //TODO fix pagination to get search text
    this.tableInfo$ = this.employerService
      .SearchEmployers(
        event.pageIndex,
        event.pageSize,
        this.getStatusFilter(this.checkedFilter.value),
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        debounceTime(500),
        this.filterTableData(),
        tap(res => {
          if (this.Pagination)
            this.Pagination = {
              Index: event.pageIndex,
              ActualPage: event.pageIndex,
              Total: event.length,
              PageSize: event.pageSize,
            };
        })
      );
  }

  onImpersonateUser(employerId: string) {
    console.log('impersonating', employerId);

    this.employerService.GetEmployer(employerId).subscribe(res => {
      this.userViewService
        .Propagate(false)
        .ChangeEmployerId(employerId)
        .ChangeEmployerNumber(res.Data.EmployerNR)
        .ChangePlanNumber(res.Data.PlanNr)
        .ChangeCompanyName(res.Data.Name)
        .ChangeWaitingPeriod(res.Data.WaitingPeriod)
        .ChangeEmployerOeMonth(res.Data.Oemonth)
        .ChangeEmployerManageEligibility(res.Data.ManageEligibility)
        .ChangeImpersonationState(true)
        .ChangeView(ViewType.EmployerView)
        .Propagate(true)
        .NavigateToPageView(this.userViewService.GetPrimaryLink().toString());
    });
    // TODO Get Feature Flag. Implement this after add employee merge.
  }

  onFilter(checkedItem: string) {
    let filters: Map<string, number> = new Map([
      ['All', 0],
      ['Terminated', 1],
      ['Active', 2],
    ]);

    // Clean search
    this.searchedText.next('');

    this.checkedFilter.next(checkedItem);
    this.tableInfo$ = this.employerService
      .SearchEmployers(0, 10, filters.get(checkedItem), this.searchedText.value, this.sortDirection, this.sortColumn)
      .pipe(
        this.filterTableData(),
        tap(res => {
          this.Pagination = { Total: res.recordsTotal, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
        })
      );
  }
  ngOnDestroy() {
    this.userViewStateSubscription.unsubscribe();
  }

  ngOnInit() {
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'EmployerNR', label: 'Employer Number', sortable: true },
      {
        columnName: 'Name',
        label: 'Company Name',
        template: this.employerNumberColTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      },
      { columnName: 'ErGroupState', label: 'Group Contract State', sortable: true },
      { columnName: 'Employees', label: 'Employees' },
      { columnName: 'Dental', label: 'Dental' },
      { columnName: 'Vision', label: 'Vision' },
      { columnName: 'Life', label: 'Life' },
      { columnName: 'LTD', label: 'LTD' },
      { columnName: 'P3', label: 'P3' },
      { columnName: 'STD', label: 'STD' },
      { columnName: 'ContractTerminationDate', label: 'Termination Date', sortable: true }, // TODO [SORT] this sort doesnt work from api
      { columnName: 'Ganr', label: 'GANR' },
    ];

    this.tableSearchObs &&
      this.tableSearchObs.pipe(debounceTime(200)).subscribe(searchText => {
        this.searchedText.next(searchText);
        this.loadData();
      });

    this.userViewStateSubscription = this.userViewService.CurrentUserViewState.subscribe(view => {
      let employerRegions = [RegionCodes.DDCA, RegionCodes.DDIC, RegionCodes.DDIC, RegionCodes.DDNJ, RegionCodes.DDPA];

      if (
        view?.ActualRegion &&
        employerRegions.includes(view.ActualRegion) &&
        view.ActualRegion !== this.actualUserView
      ) {
        this.actualUserView = view.ActualRegion;
        this.loadData();
      }
    });
  }

  onSearch(searchText: string) {
    console.log(searchText);
    this.searchedText.next(searchText);
    this.tableInfo$ = this.employerService
      .SearchEmployers(
        0,
        10,
        this.getStatusFilter(this.checkedFilter.value),
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        this.filterTableData(),
        tap(res => {
          this.Pagination = { Total: res.recordsTotal, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
        })
      );
  }

  private loadData() {
    this.tableLoading.next(true);
    this.tableInfo$ = this.employerService
      .SearchEmployers(
        0,
        10,
        this.getStatusFilter(this.checkedFilter.value),
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        this.filterTableData(),
        tap(res => {
          this.Pagination = { Total: res.recordsTotal, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
        })
      );
  }

  private filterTableData() {
    return map<EmployersSearchResponse, EmployersSearchResponse>(res => {
      return {
        ...res,
        data: res.data.map(employer => {
          employer.Employees = `${employer.ActiveEmp}/${employer.Total}`;

          if (employer.ContractTerminationDate)
            employer.ContractTerminationDate = formatDate(employer.ContractTerminationDate, 'MM/DD/YYYY');

          return employer;
        }),
      };
    });
  }

  private mapToDownloadTableObject(res: Employer[]) {
    return res.map(employer => ({
      EmployerNR: employer.EmployerNR,
      Name: employer.Name,
      ErGroupState: employer.ErGroupState,
      Employees: employer.Employees,
      Dental: employer.Dental,
      Vision: employer.Vision,
      Life: employer.Life,
      LTD: employer.LTD,
      P3: employer.P3,
      STD: employer.STD,
      ContractTerminationDate: employer.ContractTerminationDate ?? null,
      GANR: employer.Ganr
    }));
  }

  private getStatusFilter(checkedItem: string) {
    return (
      new Map<string, number>([
        ['All', 0],
        ['Terminated', 1],
        ['Active', 2],
      ]).get(checkedItem) || 0
    );
  }
}
