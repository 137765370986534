import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  styleUrls: ['./checkbox.component.scss'],
  template: `
    <div [class.disableAndKeepColor]="disableAndKeepColor">
      <mat-checkbox [checked]="checked" [disabled]="disabled || disableAndKeepColor" (change)="toggleCheck(name)">
        {{ name }}
      </mat-checkbox>
    </div>
  `,
})
export class CheckboxComponent implements OnInit {
  @Input() name: string = '';
  @Input() checked: boolean = false;
  @Input() disabled: boolean = false;
  @Input() disableAndKeepColor: boolean = false;
  @Input() enableOnRemoveCheckEvent: boolean = false;
  @Output() onCheck: EventEmitter<string> = new EventEmitter();
  @Output() onRemoveCheck: EventEmitter<string> = new EventEmitter();

  toggleCheck(checked: string) {
    this.checked = !this.checked;
    if (this.enableOnRemoveCheckEvent) {
      if (this.checked) this.onCheck.emit(checked);
      else this.onRemoveCheck.emit(checked);
      return;
    }
    this.onCheck.emit(checked);
  }
  constructor() {}

  ngOnInit() {}
}
