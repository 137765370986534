import { RegionCodes } from '../models/api/auth/responses/get-regions.response';

export enum StaticPage {
  EmployerDashboardL = 'Employer DashboardL',
  EmployerDashboardR = 'Employer DashboardR',
  BusinessProgram = 'Business Program',
  Links = 'Links',
  ContactUS = 'Contact US',
  ProducerDashboard = 'Producer Dashboard',
  AlliedBenefitSuite = 'Allied Benefit Suite',
  ABSNewsFeed = 'ABS News Feed',
  VersionDetails = 'Version Details',
  DeltaDentalSBP = 'Delta Dental SBP',
}

export interface StaticPagesByRegionMapper {
  Region: RegionCodes[];
  StaticPages: { id: number; page: StaticPage }[];
}

const StaticPagesByRegionMapper: StaticPagesByRegionMapper[] = [
  {
    Region: [RegionCodes.DDNJ],
    StaticPages: [
      {
        id: 2,
        page: StaticPage.EmployerDashboardL,
      },
      {
        id: 3,
        page: StaticPage.EmployerDashboardR,
      },
      {
        id: 7,
        page: StaticPage.BusinessProgram,
      },
      {
        id: 20,
        page: StaticPage.Links,
      },
      {
        id: 24,
        page: StaticPage.ContactUS,
      },
    ],
  },
  {
    Region: [RegionCodes.DDCA],
    StaticPages: [
      {
        id: 11,
        page: StaticPage.EmployerDashboardL,
      },
      {
        id: 12,
        page: StaticPage.EmployerDashboardR,
      },
      {
        id: 13,
        page: StaticPage.BusinessProgram,
      },
      {
        id: 23,
        page: StaticPage.Links,
      },
      {
        id: 25,
        page: StaticPage.ContactUS,
      },
    ],
  },
  {
    Region: [RegionCodes.DDPA],
    StaticPages: [
      {
        id: 17,
        page: StaticPage.EmployerDashboardL,
      },
      {
        id: 29,
        page: StaticPage.EmployerDashboardR,
      },
      {
        id: 19,
        page: StaticPage.BusinessProgram,
      },
      {
        id: 22,
        page: StaticPage.Links,
      },
      {
        id: 27,
        page: StaticPage.ContactUS,
      },
    ],
  },
  {
    Region: [RegionCodes.DDIC],
    StaticPages: [
      {
        id: 14,
        page: StaticPage.EmployerDashboardL,
      },
      {
        id: 28,
        page: StaticPage.EmployerDashboardR,
      },
      {
        id: 16,
        page: StaticPage.BusinessProgram,
      },
      {
        id: 21,
        page: StaticPage.Links,
      },
      {
        id: 26,
        page: StaticPage.ContactUS,
      },
    ],
  },
  {
    Region: [RegionCodes.Admin, RegionCodes.Producer],
    StaticPages: [
      {
        id: 1,
        page: StaticPage.ProducerDashboard,
      },
      {
        id: 4,
        page: StaticPage.AlliedBenefitSuite,
      },
      {
        id: 5,
        page: StaticPage.ABSNewsFeed,
      },
      {
        id: 6,
        page: StaticPage.VersionDetails,
      },
      {
        id: 8,
        page: StaticPage.DeltaDentalSBP,
      },
      {
        id: 9,
        page: StaticPage.Links,
      },
      {
        id: 10,
        page: StaticPage.ContactUS,
      },
    ],
  },
];

export const getStaticPageByRegionAndPageName = (region: RegionCodes, pageName: StaticPage) => {
  const mapper = StaticPagesByRegionMapper.find(item => item.Region.includes(region));
  if (mapper) {
    const pageItem = mapper.StaticPages.find(staticPage => staticPage.page === pageName);
    if (pageItem) return pageItem.id;
  }
  return undefined;
};
