interface GetAllRegistersRequestI {
  actualPage: number;
  orderByColumnName: string;
  search: string;
  statusFilter: number;
  start: number;
  length: number;
  orderBy: 'asc' | 'desc';
}

export class GetAllRegistersRequest {
  actualPage: number;
  orderByColumnName: string;
  search: string;
  statusFilter: number;
  start: number;
  length: number;
  orderBy: 'asc' | 'desc';

  /** Used to define the column index in order to match the property column[x][data] with the order[0][column] = x*/
  private fixedIndex: number;

  constructor(request: GetAllRegistersRequestI) {
    this.actualPage = request.actualPage;
    this.orderByColumnName = request.orderByColumnName;
    this.search = request.search;
    this.statusFilter = request.statusFilter;
    this.start = request.start;
    this.length = request.length;
    this.orderBy = request.orderBy;
    this.fixedIndex = 1;
  }

  toRequest(): string {
    const params = new URLSearchParams();

    params.append('draw', this.actualPage.toString());
    params.append(`columns[${this.fixedIndex}][data]`, this.orderByColumnName);
    params.append('search[value]', this.search);
    params.append(`order[0][dir]`, this.orderBy);
    params.append('statusFilter', this.statusFilter.toString());

    params.append(`order[0][column]`, this.fixedIndex.toString());
    params.append('start', this.start.toString());
    params.append('length', this.length.toString());
    return params.toString();
  }
}
