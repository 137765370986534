import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEnvelope, faListCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import { Observable, catchError, take, tap, throwError } from 'rxjs';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { MasterProducerResponse, Producer } from 'src/app/core/models/api/master-producer/master-producer.response';
import { API_ERROR_MESSAGE } from 'src/app/core/models/constants/error-messages';
import { ViewType } from 'src/app/core/models/user-view.model';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { MasterProducerService } from 'src/app/core/services/http/master-producer.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-master-producer-profile',
  styleUrls: ['./master-producer-profile.component.scss'],
  template: `
    <form [formGroup]="profileForm">
      <div *ngIf="masterProducerInfo$ | withLoading | async as masterProducerInfo">
        <app-content-card [CustomTitle]="customTitle">
          <div style="padding: 20px">
            <app-form-input
              [loading]="masterProducerInfo.loading ?? false"
              title="Name"
              fieldName="name"
              [parentForm]="profileForm"
              [Icon]="nameIcon"
              formControlName="name">
            </app-form-input>
            <app-form-input
              [loading]="masterProducerInfo.loading ?? false"
              title="TIN/SSN"
              fieldName="tinssn"
              [parentForm]="profileForm"
              [Icon]="listIcon"
              formControlName="tinssn">
            </app-form-input>
          </div>
        </app-content-card>
        <app-content-card CustomTitle="Contact">
          <app-form-input
            [loading]="masterProducerInfo.loading ?? false"
            type="email"
            title="Email"
            fieldName="email"
            [parentForm]="profileForm"
            [Icon]="emailIcon"
            [width]="400"
            formControlName="email">
          </app-form-input>
        </app-content-card>
        <app-content-card CustomTitle="Benefit Administrator" *ngIf="masterProducerInfo.value && isAdmin">
          <div style="display: flex; flex-direction: column; padding: 20px;">
            <app-form-checkbox 
              [parentForm]="profileForm"
              fieldName="isBaUser"
              formControlName="isBaUser"
              name="Become Ba User"></app-form-checkbox>
            
          </div>
        </app-content-card>
        <app-content-card [HasTopBorder] = "false" *ngIf="masterProducerInfo.value">
        <div style="display: flex; flex-direction: column; padding: 20px;">
        <custom-button
              [forceCapitalization]="true"
              label="SAVE"
              style="align-self: flex-end;"
              (onClick)="onSubmit()"></custom-button>
              </div>
        </app-content-card>

      </div>
    </form>
  `,
})
export class MasterProducerProfileComponent implements OnInit {
  profileForm: FormGroup;
  nameIcon = faUser;
  listIcon = faListCheck;
  emailIcon = faEnvelope;
  customTitle = 'Master Producer Profile';

  masterProducerInfo$: Observable<MasterProducerResponse>;
  isAdmin: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private userViewService: UserViewService,
    private authService: AuthService,
    private masterProducerService: MasterProducerService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.isAdmin = this.authService.IsAdmin()
    this.profileForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }, []],
      tinssn: [{ value: '', disabled: true }, []],
      isBaUser: [{ value: false }, []],
      email: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
    });

    let currentView = this.userViewService.GetCurrentUserViewState();
    if (!currentView || !currentView.MasterProducerId) return;
    this.masterProducerInfo$ = this.masterProducerService.GetMasterProducer(currentView.MasterProducerId);

    this.masterProducerInfo$.subscribe(res => {
      this.profileForm.patchValue({
        name: res.Data.Name,
        tinssn: res.Data.TinSsn,
        email: res.Data.Email,
        isBaUser: res.Data.IsBaUser,
      });
    });

    this.authService.userSession.pipe(take(1)).subscribe(session => {
      this.customTitle =
        session && currentView?.ActualView === ViewType.ProducerView ? 'Master Producer Profile' : 'BA Profile';
    });
  }

  onSubmit() {
    //TODO Validate backend, when sending IsBaUser as true. Backend is returning error
    this.profileForm.markAllAsTouched();
    if (this.profileForm.valid) {
      this.masterProducerInfo$.pipe(take(1)).subscribe(res => {
        let masterProducer: Producer = {
          ...res.Data,
          Email: this.profileForm.value.email,
          IsBaUser: this.profileForm.value.isBaUser,
        };

        this.masterProducerService
          .UpdateMasterProducer(masterProducer)
          .pipe(
            catchError(err => {
              this.notificationService.error(API_ERROR_MESSAGE);
              return throwError(() => err);
            })
          )
          .subscribe(updated => {
            if (updated.Status) this.notificationService.success(updated.Message);
            else this.notificationService.error(updated.Message);
          });
      });
    }
  }
}
