import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-icon-button',
  styleUrls: ['./icon-button.component.scss'],
  template: `
    <div (click)="onButtonClick($event)" *ngIf="customIcon; else default">
      <fa-icon [ngClass]="cssClasses" [title]="title" [icon]="customIcon"></fa-icon>
    </div>
    <ng-template #default>
      <div>
        <fa-icon [ngClass]="cssClasses" [title]="title"></fa-icon>
      </div>
    </ng-template>
  `,
})
export class IconButtonComponent implements OnInit {
  @Input() title?: string;
  @Input() buttonId?: string;
  @Input() customIcon?: IconProp;
  @Input() color: 'primary' | 'secondary' = 'primary';
  @Output() onClick: EventEmitter<string> = new EventEmitter();

  constructor() {}

  onButtonClick($event: MouseEvent) {
    this.onClick.emit(this.buttonId);
  }

  get cssClasses() {
    let classes = ['custom_icon_button'];
    if (this.color == 'primary') classes.push('color--primary');
    if (this.color == 'secondary') classes.push('color--secondary');
    return classes;
  }

  ngOnInit() {}
}
