import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { FileEvent, ShowButtons, ShowColumns } from '../documents/documents.component';
import { IDocument } from 'src/app/core/models/api/documents.response';
import { map, Observable, take } from 'rxjs';
import { EmailService } from 'src/app/core/services/http/email.service';
import { IManageContentPicture, ManageContentPictureApiResponse } from 'src/app/core/models/manage-content-picture.response';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import saveAs from 'file-saver';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-manage-content-picture',
  template: `<ng-container *ngIf="documents$ | withLoading | async as documents">
    <ng-container *ngIf="documents.loading">
      <app-documents
        [showButtons]="showButtons"
        [modalProps]="modalProps"
        [openUploadDocumentModal]="openUploadDocumentModal"
        [showColumns]="showColumns"
        headlineLabel=" "
        [documents$]="[]"
        [isLoading]="true"
        (documentDropped)="onUploadDocument($event)"
        (onDownloadDocumentEvent)="onDownloadDocument($event)"
        [showArchived]="false"
        (onDeleteFileEvent)="onDeleteFile($event)"
        [showViewJournal]="false">
      </app-documents>
    </ng-container>

    <ng-container *ngIf="documents.value">
      <app-documents
        [showButtons]="showButtons"
        [modalProps]="modalProps"
        [openUploadDocumentModal]="openUploadDocumentModal"
        [showColumns]="showColumns"
        [headlineLabel]="headline"
        [documents$]="documents.value"
        [isLoading]="false"
        (documentDropped)="onUploadDocument($event)"
        (onDownloadDocumentEvent)="onDownloadDocument($event)"
        (onDeleteFileEvent)="onDeleteFile($event)"
        [showArchived]="false"
        (OnActivateDocumentEvent)="onActivateDocumentEvent($event)"
        [showViewJournal]="false">
      </app-documents>
    </ng-container>
    <ng-component *ngIf="!documents.value">
      <p>No documents found</p>
    </ng-component>
  </ng-container>`,
  styleUrls: ['./manage-content-picture.component.scss']
})
export class ManageContentPictureComponent implements OnInit {
  userId?: string;
  fileType: string;
  file: File;
  openUploadDocumentModal: boolean;
  employerId: string;

  documents$: Observable<IDocument[]>;
  rows$: IDocument[];

  showButtons: ShowButtons;
  showColumns: ShowColumns;
  modalProps: ModalProps;
  headline: string;
  constructor(
    private authService: AuthService,
    private emailService: EmailService,
    private userViewService: UserViewService,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) { }

  ngOnInit(): void {

    this.headline = 'Upload pictures be to displayed on Employer Home';
    this.openUploadDocumentModal = false;
    let userViewState = this.userViewService.GetCurrentUserViewState();
    if (userViewState && userViewState.EmployerId) this.employerId = userViewState.EmployerId;
    this.authService.userSession.pipe(take(1)).subscribe(session => {
      this.userId = session?.UserID.toString();
    });

    this.showColumns = {
      showUserNameField: false,
      showCount: false,
      showSize: false,
      showActive: true
    };

    this.showButtons = {
      ShowArchiveRowButton: false,
      ShowUnarchiveRowButton: false,
      ShowUploadDocumentButton: true,
      ShowDeleteRowButton: false,
      ShowSendNotificationButton: false,
    };

    this.modalProps = {
      OkButton: {
        Label: 'UPLOAD',
        OnAction: () => {
          
          
          this.emailService.UploadPicture(this.file).subscribe(res => {
            if (res.Status) {
            } else this.notificationService.error('Something went wrong');
            this.updateDocuments();
          });
        },
      },
      CloseButton: {
        OnAction: () => {
          this.openUploadDocumentModal = false;
        },
        show: false,
      },
      CanCloseModal: true,
    };
    this.updateDocuments();
  }

  private updateDocuments () {
    this.documents$ = this.emailService.GetContentPictures().pipe(
      this.mapToTableRows(),
      map(res => {
        this.rows$ = res;
        return res;
      })
    );
  }

  onUploadDocument (file: File) {
    this.file = file;
    this.openUploadDocumentModal = false;
  }

  mapToTableRows () {
    return map<ManageContentPictureApiResponse, IDocument[]>(res => {
      return res.Data.map((document: IManageContentPicture) => {
        return {
          FileId: document.Id,
          ProducerId: 0,
          FileName: document.FileName,
          IsCurrent: document.Active,
          Deleted: document.Deleted,
          Archived: false,
          DeletedBy: null,
          DeletedDate: null,
          Size: document.Size,
          Downloads: 0,
          UploadedBy: document.UploadedBy,
          UploadedDate: formatDate(document.UploadedDate),
          Active: document.Active === true ? "Yes" : "No"
        };
      });
    });
  }

  onActivateDocumentEvent(fileEvent: FileEvent) {
    this.emailService.ActivePicture(fileEvent.fileId).subscribe(res => {
      this.updateDocuments();
    })
  }

  onDownloadDocument (fileEvent: FileEvent) {
    this.emailService.DownloadContentPicture(fileEvent.fileId).subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'image/jpg',
      });
      saveAs(data, fileEvent.fileName);
    });
  }

  onDeleteFile (fileEvent: FileEvent) {
    this.notificationService.confirmation('Do you want to delete this Invoice?', () => {
      this.emailService.DeletePicture(fileEvent.fileId).subscribe(res => {
        this.notificationService.success("Invoice Deleted Successfully");
        this.updateDocuments();
      });
    });
  }

}
