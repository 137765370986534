<app-content-card CustomTitle="Qualifying Event Add Employee">
  <form [formGroup]="qualifyingEventForm" style="display: flex; flex-direction: column; padding: 20px;">
    <div style="display: flex; gap: 20px; ">
      <app-form-select
        FieldName="qualifyingEvent"
        style="width: 300px;"
        Label="Qualifying Event"
        [parentForm]="qualifyingEventForm"
        [Options]="options"></app-form-select>
      <app-form-input-date
      *ngIf="showQeDate"
        fieldName="qualifyingEventDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [parentForm]="qualifyingEventForm"
        title="Qualifying Event Date"></app-form-input-date>
    </div>
  </form>
</app-content-card>
<app-add-employee *ngIf="showEmployeeForm"
[qeSelected]="true"
[qeDate]="qualifyingEventForm.get('qualifyingEventDate')?.value"
[qualifyingEvent]="qualifyingEventForm.get('qualifyingEvent')?.value"
(formEvent)="setForm($event)"
></app-add-employee>
