export interface IInvoiceDocumentProgress {
    progress: number;
    startedBy: string;
}
export class InvoiceDocumentProgressApiResponse {
    public Data: IInvoiceDocumentProgress;
    public Status: boolean;

    constructor(data: IInvoiceDocumentProgress, status: boolean){
        this.Data = data;
        this.Status = status;
    }
}