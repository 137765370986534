import { UploadEligibilityDocument, UploadEligibilityType } from './manage-eligibility-upload-xml-excel.component';

export class EligibilityFactory {

  static make(uploadEligibilityType: UploadEligibilityType): UploadEligibilityDocument {
    if (uploadEligibilityType === 'EligibilityExcel') return this.makeExcelEligibility();
    if (uploadEligibilityType === 'EligibilityXML') return this.makeXmlEligibility();
    if (uploadEligibilityType === 'EligibilityMaster') return this.makeMasterEligibility();
    throw new Error(`Something went wrong using ${uploadEligibilityType} to make UploadEligibilityDocument`)
  }

  private static makeExcelEligibility(): UploadEligibilityDocument {
    return {
      CardTitle: 'Manage Eligibility Upload',
      DownloadTemplateButtonLabel: 'SAMPLE ELIGIBILITY UPLOAD TEMPLATE',
      Description:
        'To add Employees and Dependents, please download the template below and make appropriate changes in the template, then select ‘Upload’ to upload the file.',
      EligibilityTemplateUrl: '/uploaddocument/geteligibilitytemplate',
      EligibilityUploadUrl: '/uploaddocument/postexceldocument',
      EligilityTemplateFileName: 'EligibilityTemplate.xlsx',
      DroppedFileErrorMessage: 'The file is not allowed. (XLS, XLSX only)',
    };
  }

  private static makeXmlEligibility(): UploadEligibilityDocument {
    return {
      CardTitle: 'Manage XML Interface',
      DownloadTemplateButtonLabel: 'SAMPLE XML INTERFACE TEMPLATE',
      Description:
        'Please download the template to add Employees and Dependents and re-upload in the box below by selecting "Upload"',
      EligibilityTemplateUrl: '/uploaddocument/getxmltemplate',
      EligibilityUploadUrl: '/uploaddocument/postxmldocument',
      EligilityTemplateFileName: 'InterfaceXmlTemplate.xml',
      DroppedFileErrorMessage: 'The file is not allowed. (XML only)',
    };
  }

  private static makeMasterEligibility(): UploadEligibilityDocument {
    return {
      CardTitle: 'Manage Eligibility Upload',
      DownloadTemplateButtonLabel: 'SAMPLE ELIGIBILITY UPLOAD TEMPLATE',
      Description:
        'Output file from Allied Smart Reader portal must be uploaded in this section. Please refer sample file below for the layout of the file',
      EligibilityTemplateUrl: '/uploaddocument/getmastereligibilityTemplate',
      EligibilityUploadUrl: '/uploaddocument/postMasterEligibility',
      EligilityTemplateFileName: 'master-eligibility-template.xlsx',
      DroppedFileErrorMessage: 'The file is not allowed. (XLS, XLSX only)',
    };
  }
}
