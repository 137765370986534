import { Injectable } from '@angular/core';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dependent, EmployeeFormFields } from './employee-form.component';
import { FormGroupWarn, FormControlWarn } from 'src/app/shared/components/form-elements/form-group-warn';
import moment from 'moment';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { after } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class EmployeeFormService {
  /** Employee Fields */
  employeeFields: BehaviorSubject<EmployeeFormFields>;
  employeeFieldsObservable: Observable<EmployeeFormFields>;

  editing: boolean = false;
  viewOnly: boolean = false;
  employeeReplicated: boolean = false;

  constructor(private fb: FormBuilder, private authenticationService: AuthService) {
    this.employeeFields = new BehaviorSubject<EmployeeFormFields>({} as EmployeeFormFields);
    this.employeeFieldsObservable = this.employeeFields.asObservable();
  }

  GetDependentField(index: number) {
    return this.employeeFields.value.dependents[index];
  }

  UpdateEmployeeFormFields(newState: (prevState: EmployeeFormFields) => EmployeeFormFields) {
    this.employeeFields.next(newState(this.employeeFields.value));
  }

  GetQualifyingEventMinDate(): Date {
    return moment().add(-59, 'day').toDate();
  }

  GetQualifyingEventMaxDate(): Date {
    return new Date();
  }

  shouldUserNotViewSSN() {
    return this.authenticationService.IsAdmin() !== true && this.viewOnly === false && this.editing === true;
  }
  shouldUserSeeMaskedSSNOnly() {
    return this.authenticationService.IsAdmin() !== true && this.editing === true && this.employeeReplicated === true;
  }

  getDependentMinQualifyingEventDate() {
    return moment().add(-60, 'days').toDate();
  }

  getDependentMaxQualifyingEventDate() {
    return moment().add(60, 'days').toDate();
  }

  setControlGroupPlansError(controlGroup: FormGroupWarn, error: string)
  {
    if (IsStringNullOrEmpty(error)) {
        controlGroup.setErrors({ plansError: null });
        controlGroup.updateValueAndValidity();
    } else {
        controlGroup.setErrors({ plansError: error});
        controlGroup.markAsDirty();
    }
  }

  setControlError(control: FormControlWarn, error: string, modalMessage?: string) {
    if (IsStringNullOrEmpty(error)) {
      setTimeout(() => {
        control.setErrors(null);
      }, 100);
    } else {
      setTimeout(() => {
        control.setErrors({ customErrorMessage: error,
        modalErrorMessage: modalMessage });
        control.markAsDirty();
        control.markAsTouched();
      }, 100);
    }
  }

  getHireDateRangeFromWaitingPeriod(waitingPeriod: any){
    if(IsStringNullOrEmpty(waitingPeriod) === true) {
      return {
        minDate: moment().add(-2, 'month').startOf('month').format('L'),
        maxDate: moment().add(2, 'month').endOf('month').format('L')
      }
    }
    let maxDate = moment().add(2,'month').endOf('month');
    let minDate = moment();
    if(isNaN(waitingPeriod)){
      switch(waitingPeriod){
        case 'C':
        minDate = moment().add(-2, 'month').startOf('month');
          break;
        case "N":
        minDate = moment().add(-3, 'month').startOf('month');
          break;
      }
    }else{
      let numberWp = parseInt(waitingPeriod);
      let defaultWp = numberWp + 3;

      if(defaultWp === 3){
        minDate = moment().add(-2, 'month').startOf('month');
      }else{
        minDate = moment().add(defaultWp * -1, 'month').startOf('month');
      }
    }

    return {
      maxDate: maxDate.format('L'),
      minDate: minDate.format('L')
    }
  }


  isDateAfter(date: string, afterDate: string) {
   if(  IsStringNullOrEmpty(date) === true) return false;
   if(IsStringNullOrEmpty(afterDate) === true) return false;

   let firstDate = moment(date,'L').startOf('day');

   let endDate = moment(afterDate,'L').startOf('day');
   
   return firstDate.isAfter(endDate);
  }
}
