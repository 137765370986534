import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faArrowLeft, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { catchError, subscribeOn, throwError } from 'rxjs';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { SubProducerService } from 'src/app/core/services/http/sub-producer.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-request-acess',
  styleUrls: ['./request-acess.component.scss'],
  template: ` <div style="display: flex; justify-content: flex-end">
      <custom-button
        [forceCapitalization]="true"
        [routerLink]="[subProducersAccessUrl]"
        style="align-self: flex-end; margin-bottom: 10px"
        color="secondary"
        [icon]="arrowLeft"
        label="BACK"></custom-button>
    </div>

    <app-content-card CustomTitle="Request Access for Sub Producer">
      <form [formGroup]="subProducerForm" style="display: flex; flex-direction: column; padding: 15px 30px;">
        <div ngClass="form_wrapper">
          <app-form-input
            type="text"
            style="flex: 1"
            [primary]="true"
            title="First Name"
            [Wide]="true"
            placeholder="First Name"
            formControlName="firstName"
            [parentForm]="subProducerForm"
            fieldName="firstName">
          </app-form-input>
          <app-form-input
            type="text"
            style="flex: 1"
            [primary]="true"
            title="Last Name"
            [Wide]="true"
            placeholder="Last Name"
            formControlName="lastName"
            [parentForm]="subProducerForm"
            fieldName="lastName">
          </app-form-input>
          <app-form-input
            type="text"
            [primary]="true"
            title="Email"
            style="flex: 1"
            [Wide]="true"
            placeholder="email"
            formControlName="email"
            [parentForm]="subProducerForm"
            fieldName="email"></app-form-input>
          <app-form-input
            type="text"
            [primary]="true"
            title="Phone Number"
            inputmode="numeric"
            [Wide]="true"
            mask="(000) 000-0000"
            style="flex: 1"
            placeholder="phone"
            formControlName="phone"
            [parentForm]="subProducerForm"
            fieldName="phone"></app-form-input>
        </div>

        <div style="display: flex; justify-content: center; margin-top: 10px">
          <custom-button
            [forceCapitalization]="true"
            label="SAVE"
            [Wide]="false"
            [primary]="true"
            [IsLoading]="saveLoading"
            [icon]="saveIcon"
            [disabled]="!subProducerForm.dirty"
            (onClick)="onSubmit()"></custom-button>
        </div>
      </form>
    </app-content-card>`,
})
export class RequestAcessComponent implements OnInit {
  arrowLeft = faArrowLeft;
  masterProducerId: string | undefined;
  subProducersAccessUrl: string = RouteEnum.SubProducersAccess;
  subProducerForm: FormGroup;
  saveLoading: boolean;
  saveIcon = faFloppyDisk;

  constructor(private userViewService: UserViewService, private fb: FormBuilder,
    private subProducerService: SubProducerService,
    private notificationService: NotificationService,
    private router: Router) {}

  ngOnInit(): void {
    this.masterProducerId = this.userViewService.GetCurrentUserViewState()?.MasterProducerId;

    this.initForm();
  }

  private initForm() {
    this.subProducerForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
    });
  }

  onSubmit()
  {

    if(this.subProducerForm.valid !== true)
    return;



    this.saveLoading = true;

    let {firstName, lastName, email, phone} = this.subProducerForm.value;

    


    this.subProducerService.RequestSubProducerAccess(firstName, lastName, email, phone,this.masterProducerId ?? '0')
    .pipe(catchError(error => {
      this.notificationService.error('Something went wrong');
      this.saveLoading = false;
      return throwError(() => error);
    }))
    .subscribe( response => {
      this.saveLoading = false;

      this.notificationService.success("Sub Producer access requested");
      this.router.navigate([this.subProducersAccessUrl]);

    });




  }
}
