import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getErrorMessage } from '../form-field-errors/CustomErrorMessages';

@Component({
  selector: 'app-form-slide-toggle',
  styleUrls: ['./form-slide-toggle.component.scss'],
  template: `
    <div style="display: flex; flex-direction: column;" [style.width.px]="width">
      <mat-slide-toggle [formControl]="formField" (change)="onChange($event.checked)" [checked]="checked">{{
        label
      }}</mat-slide-toggle>
      <mat-error>{{ setErrorMessage() }}</mat-error>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSlideToggleComponent),
      multi: true,
    },
  ],
})
export class FormSlideToggleComponent implements OnInit, ControlValueAccessor {
  @Input() public parentForm?: FormGroup;
  @Input() fieldName: string = '';
  @Input() label: string = '';
  @Input() checked: boolean = false;
  @Input() width: number = 200;

  get formField(): FormControl {
    return this.parentForm?.get(this.fieldName) as FormControl;
  }
  constructor() {}
  onSlide(checked: boolean) {
    console.log(checked);
    this.checked = !checked;
    // this.writeValue(!checked)
    // this.ref.markForCheck()
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  writeValue(obj: any): void {
    this.checked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // setDisabledState?(isDisabled: boolean): void {
  //   isDisabled ? this.formField.disable() : this.formField.enable();
  // }

  ngOnInit() {}

  setErrorMessage() {
    return getErrorMessage(this.formField);
  }
}
