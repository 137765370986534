import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { UpdateRegionProductsRequest } from 'src/app/core/models/api/auth/requests/update-region-products.request.model';
import { RegionProductsResponse } from 'src/app/core/models/api/auth/responses/region-products.response.model';
import { GeneralControlService } from 'src/app/core/services/http/general-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';

@Component({
  selector: 'app-products-management',
  styleUrls: ['./products-management.component.scss'],
  template: `
    <app-content-card CustomTitle="Products Management">
      <form ngClass="wrapper" [formGroup]="ProductManagementFormGroup">
        <div ngClass="region_select">
          <app-form-select
            [Options]="Options"
            Label="Select Region"
            FieldName="region"
            style="max-width: 300px;"
            (ngModelChange)="OnInputChange()"
            [parentForm]="ProductManagementFormGroup"
            formControlName="region"></app-form-select>
        </div>
        <div ngClass="checkbox_group">
          <div ngClass="checkbox_group_inner">
            <ng-container *ngIf="isLoading; else checkbox">
              <app-skeleton-input *ngFor="let item of ProductState"></app-skeleton-input>
            </ng-container>
            <ng-template #checkbox>
              <app-checkbox
                *ngFor="let item of ProductState"
                [checked]="item.checked"
                [name]="item.name"
                (onCheck)="OnCheck(item.name)"></app-checkbox>
            </ng-template>
          </div>
        </div>
        <custom-button
          [forceCapitalization]="true"
          ngClass="button"
          type="submit"
          class="btn"
          label="Save"
          [primary]="true"
          [icon]="icon"
          (onClick)="OnSubmit()"></custom-button>
      </form>
    </app-content-card>
  `,
})
export class ProductsManagementComponent implements OnInit {
  icon = faSave;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private generalControlService: GeneralControlService,
    private notificationService: NotificationService
  ) {}
  Options: SelectOptions<string>[] = [
    { key: 'DDCA', optionText: 'DDCA' },
    { key: 'DDIC', optionText: 'DDIC' },
    { key: 'DDPA', optionText: 'DDPA' },
    { key: 'DDNJ', optionText: 'DDNJ' },
  ];

  ProductState: ProductsState[] = [
    { name: 'Delta Dental', checked: false },
    { name: 'Life', checked: false },
    { name: 'Vision', checked: false },
    { name: 'Short-Term Disability', checked: false },
    { name: 'Personal Protection', checked: false },
    { name: 'Long-Term Disability', checked: false },
  ];

  regionProductsResponse?: RegionProductsResponse;

  ProductManagementFormGroup: FormGroup = this.formBuilder.group({
    region: ['', Validators.required],
  });

  OnSubmit() {
    if (this.ProductManagementFormGroup.invalid) {
      this.ProductManagementFormGroup.markAllAsTouched();
      return;
    }

    if (this.regionProductsResponse)
      this.generalControlService
        .UpdateRegion(
          this.regionProductsResponse.response.map<UpdateRegionProductsRequest>(product => ({
            Id: product.Id,
            PlanCode: product.PlanCode,
            PlanName: product.PlanName,
            Region: product.Region,
            Status: product.Status,
          }))
        )
        .subscribe(response => {
          if (!response.status) this.notificationService.error(response.response);
          this.notificationService.success(response.response);
        });
  }

  OnInputChange() {
    this.isLoading = true;
    if (this.ProductManagementFormGroup.valid)
      this.generalControlService.GetRegions(this.ProductManagementFormGroup.value.region).subscribe(regionProducts => {
        this.ProductState = regionProducts.response.map<ProductsState>(regionProduct => ({
          name: regionProduct.PlanName,
          checked: regionProduct.Status,
        }));
        this.regionProductsResponse = regionProducts;
        this.isLoading = false;
      });
  }

  OnCheck(name: string) {
    this.ProductState = this.ProductState.map(product => {
      if (product.name == name) product.checked = !product.checked;
      return product;
    });

    if (this.regionProductsResponse) {
      this.regionProductsResponse.response = this.regionProductsResponse.response.map(product => {
        if (product.PlanName == name) product.Status = !product.Status;
        return product;
      });
    }
  }

  ngOnInit() {}
}

type ProductsState = {
  name: string;
  checked: boolean;
};
