import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GetAllEmployeeResponse } from '../../models/api/employee/get-all-employees.response';
import { IPostEmployee } from 'src/app/features/user-dashboard/pages/employee/add-employee/employee-form.request';
import { IPostEmployeeResponse } from 'src/app/features/user-dashboard/pages/employee/add-employee/employee-form.response';
import { GetEmployeeResponse } from '../../models/api/employee/get-employee.response';
import { ViewUserJournal } from '../../models/api/user/view-user-journal.reponse';
import { DataResponse, ReinstateEmployeeResponse } from '../../models/api/reinstate-ee-response';
import { IQualifyingEventOption } from 'src/app/features/user-dashboard/pages/employee/qualifying-event/qualifying-event.component';
import { EmployerProduct } from '../../models/api/master-producer/master-producer-employers-api.response';
import { ApiResponse } from '../../models/api/api-response';
import { GetAllEmployeeRequest } from '../../models/api/employee/get-all-employees.request';

@Injectable({
  providedIn: 'root',
})
export class EmployeeControlService {
  constructor(private http: HttpClient) {}

  GetAllEmployees(input: GetAllEmployeesI) {
    let { employerId, orderBy, page, pageSize, search, statusFilter, orderByColumnName = 'EmployeeID' } = input;

    return this.http.post<GetAllEmployeeResponse>(
      `${environment.alliedApi.baseUrl}/EmpCtrl/GetAllEmployees`,
      new GetAllEmployeeRequest({
        orderByColumnName: orderByColumnName,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search ?? ' ',
        orderBy: orderBy ?? 'asc',
        statusFilter: statusFilter ?? 0,
        employerId: employerId,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }

  DeleteEmployee(employeeId: number) {
    return this.http.get<DataResponse>(`${environment.alliedApi.baseUrl}/EmpCtrl/employeeDelete?id=` + employeeId);
  }

  checkSSNExists(ssn: string, employeeId: string, employerId: string){
    return this.http.post<DataResponse>(`${environment.alliedApi.baseUrl}/EmpCtrl/ssn`, {
      ssn: ssn,
      e_id: employeeId,
      er_id: employerId
    });
  }

  ReinstateEmployee(employeeId: number, employerId: string) {
    return this.http.post<ReinstateEmployeeResponse>(`${environment.alliedApi.baseUrl}/EmpCtrl/empReinstate`, {
      Id: employeeId,
      EmployerId: employerId,
    });
  }

  GetQualifyingEvent() {
    return this.http.get<IQualifyingEventOption[]>(`${environment.alliedApi.baseUrl}/QualifyingEvent`);
  }

  PostEmployee(employee: IPostEmployee) {
    return this.http.post<IPostEmployeeResponse>(`${environment.alliedApi.baseUrl}/EmpCtrl/Emp`, employee);
  }

  GetEmployee(employeeId: number, employerId: string) {
    return this.http.get<GetEmployeeResponse>(
      `${environment.alliedApi.baseUrl}/EmpCtrl/EmpProfile/?EMP_ID=${employerId}&id=${employeeId}`
    );
  }

  GetEmployerPlansForEmployee(employerId: number) {
    return this.http.get<ApiResponse<EmployerProduct[]>>(
      `${environment.alliedApi.baseUrl}/EmpCtrl/getERplan/?employerID=${employerId}`
    );
  }

  getViewJournal(employeeId: number) {
    return this.http.post<ViewUserJournal>(`${environment.alliedApi.baseUrl}/EmpCtrl/view_journ`, {
      id: employeeId,
    });
  }
}

export type GetAllEmployeesI = {
  page: number;
  pageSize: number;
  employerId: string;
  statusFilter?: number;
  search?: string;
  orderBy?: 'asc' | 'desc';
  orderByColumnName?: string;
};
