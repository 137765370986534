import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { faBell, faRing, faShield } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, debounceTime, map, take, tap } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import {
  GetAllRegisters,
  GetAllRegistersResponseData,
} from 'src/app/core/models/api/user/new-user-registration/get-all-registers.response';
import { FileService } from 'src/app/core/services/file.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-new-user-registration',
  styleUrls: ['./new-user-registration.component.scss'],
  template: `
    <app-content-card CustomTitle="New Registration">
      <div ngClass="search_filter">
        <app-radio-button
          (onRadioButtonClick)="selecetedFilter.next($event)"
          [labels]="['Employer', 'Producer', 'Pending', 'Completed']"
          [checked]="selecetedFilter.value"></app-radio-button>
      </div>
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <app-table
          (onSearchEvent)="searchText.next($event)"
          (onSort)="onSort($event)"
          [searchedText]="searchText.value ?? undefined"
          [SearchDebounceTime]="0"
          [EnableSearch]="true"
          (PaginationEvent)="onPagination($event)"
          [DisplayedColumns]="displayedColumns"
          [Rows]="rows.value?.data"
          [EnablePagination]="true"
          [Pagination]="pagination"
          [DownloadButtons]="downloadButtons">
        </app-table>
      </ng-container>
    </app-content-card>
    <ng-template #actionColumnTemplate let-data>
      <a (click)="updateRegister(data.id)" style="cursor:pointer" title="Update Register">
        <i style="color: var(--brandColor)" class="md-icon material-icons addIcon">{{
          data.Pending ? 'verified_user' : 'add_alert'
        }}</i>
      </a>
    </ng-template>

    <ng-template #dateColumnTemplate let-data>
      {{ formatDateField(data) }}
    </ng-template>
  `,
})
export class NewUserRegistrationComponent implements OnInit {
  // Table Controls
  bellIcon = faBell;
  displayedColumns: DisplayedColumns[];
  rows$: Observable<GetAllRegistersResponseData>;
  pagination: ManualPagination;
  downloadButtons: TableDownloadButtons;
  selecetedFilter: BehaviorSubject<string>;
  searchText: BehaviorSubject<string | null>;
  sortColumn?: string;
  sortDirection: 'asc' | 'desc';

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('dateColumnTemplate', { static: true }) dateColumnTemplate: TemplateRef<unknown>;

  constructor(
    private userControlService: UserControlService,
    private notificationService: NotificationService,
    private fileService: FileService
  ) {}
  formatDateField(date: Date) {
    return formatDate(date);
  }
  ngOnInit(): void {
    this.searchText = new BehaviorSubject<string | null>(null);
    this.selecetedFilter = new BehaviorSubject<string>('Pending');

    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              companyName: 'companyName',
              userType: 'userType',
              firstName: 'firstName',
              lastName: 'lastName',
              email: 'email',
              EmployerNo: 'EmployerNo',
              reqUserName: 'reqUserName',
              createdDate: 'createdDate',
            },
          ];
          this.rows$.pipe(take(1)).subscribe(_ => {
            this.fileService
              .GeneratePDF('Allied Administrators - New User Registration', header, this.mapRowToDictionary(_.data))
              .save('Allied Administrators - New User Registration');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.rows$.pipe(take(1)).subscribe(_ => {
            this.fileService.GenerateXLSX(
              'Allied Administrators - New User Registration',
              this.mapRowToDictionary(_.data)
            );
          });
        },
      },
    };

    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'companyName', label: 'Company / Agency Name', sortable: true },
      { columnName: 'userType', label: 'User Type', sortable: true },
      { columnName: 'firstName', label: 'First Name', sortable: true },
      { columnName: 'lastName', label: 'Last Name', sortable: true },
      { columnName: 'email', label: 'Email', sortable: true },
      { columnName: 'EmployerNo', label: 'Employer Number', sortable: true },
      { columnName: 'reqUserName', label: 'Requested User Name', sortable: true },
      { columnName: 'createdDate', label: 'Date', template: this.dateColumnTemplate, sortable: true },
    ];

    this.selectedFilterObserver();
    this.searchTextObserver();
  }

  selectedFilterObserver() {
    // It will render the table for the first time with filter pending
    this.selecetedFilter.subscribe(_ => {
      this.loadData();
    });
  }

  searchTextObserver() {
    this.searchText.subscribe(text => {
      if (text) this.loadData();
    });
  }

  loadData() {
    this.rows$ = this.userControlService
      .GetAllRegisters(0, 10, this.statusFilter, this.searchText.value ?? '', this.sortDirection, this.sortColumn)
      .pipe(
        debounceTime(500),
        tap(res => {
          this.pagination = { Total: res.recordsFiltered, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
        })
      );
  }

  onSort(sort: Sort) {
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction === 'asc' ? 'asc' : 'desc';
    console.log(`Sort`, this.pagination);
    this.rows$ = this.userControlService.GetAllRegisters(
      this.pagination?.Index ?? 0,
      this.pagination?.PageSize ?? 10,
      this.statusFilter,
      this.searchText.value ?? '',
      this.sortDirection,
      this.sortColumn
    );
  }

  onPagination(pageEvent: PageEvent) {
    this.rows$ = this.userControlService
      .GetAllRegisters(
        pageEvent.pageIndex,
        pageEvent.pageSize,
        this.statusFilter,
        this.searchText.value ?? undefined,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        debounceTime(500),
        tap(res => {
          if (this.pagination)
            this.pagination = {
              Index: pageEvent.pageIndex,
              ActualPage: pageEvent.pageIndex,
              Total: pageEvent.length,
              PageSize: pageEvent.pageSize,
            };
        })
      );
  }

  updateRegister(registerId: number) {
    this.userControlService.UpdateRegister(registerId).subscribe(res => {
      if (res.status == true) {
        this.notificationService.success(res.message);
        this.loadData();
      } else this.notificationService.error(res.message ?? 'Something went wrong!');
    });
  }

  get statusFilter() {
    return (
      new Map<string, number>([
        ['Employer', 1],
        ['Producer', 2],
        ['Pending', 3],
        ['Completed', 4],
      ]).get(this.selecetedFilter.value) || 1
    );
  }

  private mapRowToDictionary(data: GetAllRegisters[]) {
    return data.map(register => ({
      companyName: register.companyName,
      userType: register.userType,
      firstName: register.firstName,
      lastName: register.lastName,
      email: register.email,
      EmployerNo: register.EmployerNo,
      reqUserName: register.reqUserName,
      createdDate: formatDate(register.createdDate),
    }));
  }
}
