import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Observable, combineLatest, concatMap, map, switchMap, tap } from 'rxjs';
import AvailableStates from 'src/app/core/common/AvailableStates';
import { UserRightCodeEnum } from 'src/app/core/models/api/auth/responses/user/user-rights/user-right-code.enum';
import { Employer } from 'src/app/core/models/api/employer/employer';
import { EmployersApiResponse } from 'src/app/core/models/api/employer/employer.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { CobraService } from 'src/app/core/services/http/cobra.service';
import { EmployersService } from 'src/app/core/services/http/employers.service';
import { FeatureFlagService } from 'src/app/core/services/http/feature-flag.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { EmployerJournal } from 'src/app/shared/components/view-journal/view-journal.component';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
})
export class CompanyProfileComponent implements OnInit {
  historyIcon = faClockRotateLeft;

  isAdmin: boolean;
  showCobraType: boolean = true;
  hasMasterProducerEditRightCode: boolean;
  openJournal: boolean = false;

  companyProfileForm: FormGroup;

  stateOptions: SelectOptions<any>[] = AvailableStates.map<SelectOptions<string>>(x => ({
    key: x.StateName,
    optionText: x.StateCd,
  }));
  openEnrollmentOptions: SelectOptions<any>[] = this.OpenEnrollmentOptions;

  cobraOptions: SelectOptions<any>[] = [];
  employerDataInfo: Employer;

  pageData$: Observable<any>;
  journalInput: EmployerJournal;

  constructor(
    private fb: FormBuilder,
    private employerService: EmployersService,
    private authService: AuthService,
    private userViewService: UserViewService,
    private cobraService: CobraService,
    private featureFlag: FeatureFlagService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.initForm({} as CompanyForm);

    this.isAdmin = this.authService.IsAdmin();
    this.authService.userRights.subscribe(rights => {
      this.hasMasterProducerEditRightCode = rights
        ? rights.response[0].rights.filter(right => right.RightCode == UserRightCodeEnum.MasterProducerEdit).length > 0
        : false;
    });

    this.featureFlag
      .IsFlagEnabled(this.featureFlag.COBRATYPES)
      .subscribe(showCobraType => (this.showCobraType = showCobraType));

    let view = this.userViewService.GetCurrentUserViewState();
    if (view && view.EmployerId && view.ActualRegion) {
      this.journalInput = { EmployerId: Number(view.EmployerId) };

      this.pageData$ = this.employerService.GetEmployer(view.EmployerId).pipe(
        tap(x => {
          this.loadFormWithEmployerData(x);
          this.employerDataInfo = x.Data;
        }),
        concatMap(_ => this.loadCobraTypes()),
        concatMap(_ => this.loadEmployerPlans(view?.EmployerId ?? '', view?.ActualRegion ?? ''))
      );
    }
  }

  onSubmit() {
    if (this.companyProfileForm.invalid) {
      this.companyProfileForm.markAllAsTouched();
      return;
    }
    
    // Basic info
    let { companyName, region, groupContractState, originalEffectiveDate, cobraType } =
      this.companyProfileForm.getRawValue();

    this.employerDataInfo.Name = companyName?.value  === undefined ? companyName : companyName?.value;
    this.employerDataInfo.Region = region;
    this.employerDataInfo.ErGroupState = groupContractState;
    this.employerDataInfo.OriginalEffectiveDate = originalEffectiveDate;
    this.employerDataInfo.CobraTypeId = cobraType;

    // Contact Info
    let { address1, address2, city, state, zip4, zip5 } = this.companyProfileForm.getRawValue();
    this.employerDataInfo.Address1 = (address1?.value === undefined ? address1 : address1?.value).replace(/\s+/g, ' ');
    this.employerDataInfo.Address2 = (address2?.value === undefined ? address2 : address2?.value).replace(/\s+/g, ' ');
    this.employerDataInfo.City = city?.value === undefined ? city : city?.value;
    this.employerDataInfo.State = state;
    this.employerDataInfo.Zip4 = zip4?.value === undefined ? zip4 : zip4?.value;
    this.employerDataInfo.Zip5 = zip5?.value === undefined ? zip5 : zip5?.value;

    // Billing Details
    let { billingContact, billingEmail, phone, fax, openEnrollment } = this.companyProfileForm.getRawValue();

    this.employerDataInfo.Contact = billingContact?.value  === undefined ? billingContact : billingContact?.value;
    this.employerDataInfo.ContactEmail = billingEmail;
    this.employerDataInfo.Phone = phone;
    this.employerDataInfo.Fax = fax?.value === undefined ? fax : fax?.value;
    this.employerDataInfo.OEMonth = openEnrollment;
    this.employerDataInfo.Oemonth = Number(openEnrollment);

    // Manage Eligibility
    let {
      manageBaXmlEligibility,
      manageMPXmlEligibility,
      manageErXmlEligibility,
      manageBaEligibilityUpload,
      manageMpEligibilityUpload,
      manageErEligibilityUpload,
      manageEligibility,
      manageBaEligibility,
    } = this.companyProfileForm.getRawValue();

    this.employerDataInfo.ManageBaXmlEligibility = manageBaXmlEligibility;
    this.employerDataInfo.ManageMPXmlEligibility = manageMPXmlEligibility;
    this.employerDataInfo.ManageErXmlEligibility = manageErXmlEligibility;

    this.employerDataInfo.ManageBaEligibilityUpload = manageBaEligibilityUpload;
    this.employerDataInfo.ManageMpEligibilityUpload = manageMpEligibilityUpload;
    this.employerDataInfo.ManageErEligibilityUpload = manageErEligibilityUpload;

    this.employerDataInfo.ManageEligibility = manageEligibility;
    this.employerDataInfo.ManageBaEligibility = manageBaEligibility;

    this.employerService.PutEmployer(this.employerDataInfo).subscribe(x => {
      if (x.Status) {
        this.notificationService.success(x.Message);
        window.location.reload();
      } else this.notificationService.error(x.message);
    });
    console.log(`Employer Form`, this.companyProfileForm, this.companyProfileForm.getRawValue());
    console.log(`Employer Data info`, this.employerDataInfo);
  }

  isVisionProduct(productName: string) {
    return productName == 'EyeMed' || productName == 'VSP' || productName == 'Delta Vision';
  }

  private getNewHireWaitingPeriod(hireWaitingPeriod: string) {
    if (hireWaitingPeriod == '0' || hireWaitingPeriod == 'C') {
      return 'Date of Hire';
    } else if (hireWaitingPeriod == 'N') {
      return 'First of Month Following Date of Hire ';
    } else if (hireWaitingPeriod == 'X') {
      return 'Special Waiting period: Please contact your Account Coordinator.';
    } else {
      var d = parseInt(hireWaitingPeriod) * 30;
      return 'First of Month Following' + ' ' + d + ' ' + 'Days';
    }
  }

  private loadFormWithEmployerData(x: EmployersApiResponse) {
    this.initForm({
      companyName: x.Data.Name,
      region: x.Data.Region,
      groupContractState: x.Data.ErGroupState,
      originalEffectiveDate: x.Data.OriginalEffectiveDate,
      cobraType: x.Data.CobraTypeId?.toString(),
      address1: x.Data.Address1,
      address2: x.Data.Address2,
      city: x.Data.City,
      state: x.Data.State,
      zip5: x.Data.Zip5,
      zip4: x.Data.Zip4,
      ganr: x.Data.Ganr,

      brokerFirstName: x.Data.BrokerFirstName,
      brokerLastName: x.Data.BrokerLastName,
      billingContact: x.Data.Contact,
      billingEmail: x.Data.ContactEmail,
      phone: x.Data.Phone,
      fax: x.Data.Fax,
      newHireWaitingPeriod: this.getNewHireWaitingPeriod(x.Data.WaitingPeriod),

      openEnrollment: x.Data.OEMonth,

      manageEligibility: x.Data.ManageEligibility ?? false,

      manageBaEligibility: x.Data.ManageBaEligibility ?? false,
      manageBaEligibilityUpload: x.Data.ManageBaEligibilityUpload ?? false,
      manageBaXmlEligibility: x.Data.ManageBaXmlEligibility ?? false,

      manageErEligibilityUpload: x.Data.ManageErEligibilityUpload ?? false,
      manageErXmlEligibility: x.Data.ManageErXmlEligibility ?? false,
      manageMpEligibilityUpload: x.Data.ManageMPXmlEligibility ?? false,
      manageMPXmlEligibility: x.Data.ManageMPXmlEligibility ?? false,
      P3Mandatory: x.Data.P3Mandatory,
      P3Voluntary: !x.Data.P3Mandatory,
    } as CompanyForm);
  }

  private loadEmployerPlans(employerId: string, region: string) {
    return this.employerService.GetEmployerPlans(employerId, region).pipe(
      tap(res => {
        res.Data.sort((a, b) => a.Sort - b.Sort);
        let visionIndex = 0;
        res.Data.forEach(x => {
          this.productForm.push(
            this.fb.group({
              name: [{ value: x.Description, disabled: true }],
              code: [{ value: x.ProductCode, disabled: true }],
              status: [{ value: x.Status, disabled: true }],
              vision: [{ value: this.isVisionProduct(x.Description), disabled: true }],
              visionIndex: [{ value: this.isVisionProduct(x.Description) ? visionIndex : 0, disabled: true }],
            })
          );

          if (x.ProductCode === 'PPP' && x.Status === false) {
            this.companyProfileForm.patchValue({
              P3Mandatory: false,
              P3Voluntary: false,
            });
          }

          if (this.isVisionProduct(x.Description)) visionIndex++;
        });
      })
    );
  }

  private loadCobraTypes() {
    return this.cobraService.GetCobraTypes().pipe(
      map(res => {
        this.cobraOptions = res.map<SelectOptions<string>>(x => ({
          key: x.Id.toString(),
          optionText: x.Description,
        }));
        this.cobraOptions.unshift({ key: '', optionText: 'Select' });
      })
    );
  }

  private initForm(companyForm: CompanyForm) {
    if (this.isAdmin === undefined) {
      this.isAdmin = this.authService.IsAdmin();
    }
    let cannotEditManageElibility = !this.isAdmin || (this.isAdmin && !this.hasMasterProducerEditRightCode);

    let cannotManageEligibilityAndIsNotAdmin = companyForm.manageEligibility === false && !this.isAdmin;

    this.companyProfileForm = this.fb.group({
      companyName: [{ value: companyForm.companyName, disabled: !this.isAdmin  }, [Validators.maxLength(35)]],
      region: [{ value: companyForm.region, disabled: true }],
      groupContractState: [{ value: companyForm.groupContractState, disabled: true }],
      originalEffectiveDate: [{ value: companyForm.originalEffectiveDate, disabled: true }],
      cobraType: [{ value: companyForm.cobraType, disabled: !this.isAdmin }],
      Ganr: [{ value: companyForm.ganr, disabled: true }],

      brokerFirstName: [{ value: companyForm.brokerFirstName, disabled: true }],
      brokerLastName: [{ value: companyForm.brokerLastName, disabled: true }],

      address1: [{ value: companyForm.address1, disabled: cannotManageEligibilityAndIsNotAdmin  },[Validators.maxLength(30)]],
      address2: [{ value: companyForm.address2, disabled: cannotManageEligibilityAndIsNotAdmin },[Validators.maxLength(30)]],
      city: [{ value: companyForm.city, disabled: cannotManageEligibilityAndIsNotAdmin },[Validators.maxLength(15)]],
      state: [{ value: companyForm.state, disabled: cannotManageEligibilityAndIsNotAdmin }],
      zip5: [{ value: companyForm.zip5, disabled: cannotManageEligibilityAndIsNotAdmin},[Validators.maxLength(5)]],
      zip4: [{ value: companyForm.zip4, disabled: cannotManageEligibilityAndIsNotAdmin },[Validators.maxLength(4)]],

      billingContact: [{ value: companyForm.billingContact, disabled: cannotManageEligibilityAndIsNotAdmin },[Validators.maxLength(20)]],
      billingEmail: [{ value: companyForm.billingEmail, disabled: cannotManageEligibilityAndIsNotAdmin }, [Validators.maxLength(50)]],
      phone: [{ value: companyForm.phone, disabled: cannotManageEligibilityAndIsNotAdmin }],
      fax: [{ value: companyForm.fax, disabled: cannotManageEligibilityAndIsNotAdmin },[Validators.maxLength(10)]],
      newHireWaitingPeriod: [{ value: companyForm.newHireWaitingPeriod, disabled: true }],
      openEnrollment: [{ value: companyForm.openEnrollment, disabled: !this.isAdmin }],

      P3Mandatory: [{ value: companyForm.P3Mandatory, disabled: true }],
      P3Voluntary: [{ value: companyForm.P3Voluntary, disabled: true }],

      products: companyForm.products?.length
        ? this.fb.array([
            companyForm.products.map<FormGroup>(product =>
              this.fb.group({
                name: [{ value: product.name, disabled: true }],
                status: [{ value: product.status, disabled: true }],
              })
            ),
          ])
        : this.fb.array([]),

      // Can be edited if company isn't terminated, needs to be admin and needs to have right code master producer edit

      manageBaXmlEligibility: [{ value: companyForm.manageBaXmlEligibility, disabled: cannotEditManageElibility }],
      manageMPXmlEligibility: [{ value: companyForm.manageMpEligibilityUpload, disabled: cannotEditManageElibility }],
      manageErXmlEligibility: [{ value: companyForm.manageErEligibilityUpload, disabled: cannotEditManageElibility }],
      manageBaEligibilityUpload: [
        { value: companyForm.manageBaEligibilityUpload, disabled: cannotEditManageElibility },
      ],
      manageMpEligibilityUpload: [
        { value: companyForm.manageMpEligibilityUpload, disabled: cannotEditManageElibility },
      ],
      manageErEligibilityUpload: [
        { value: companyForm.manageErEligibilityUpload, disabled: cannotEditManageElibility },
      ],
      manageEligibility: [{ value: companyForm.manageEligibility, disabled: cannotEditManageElibility }],
      manageBaEligibility: [{ value: companyForm.manageBaEligibility, disabled: cannotEditManageElibility }],
    },{
      updateOn: 'change'
    });
  }

  get productForm() {
    return this.companyProfileForm.get('products') as FormArray<FormGroup>;
  }

  get OpenEnrollmentOptions() {
    return [
      {
        key: '0',
        optionText: 'OFF',
      },
      {
        key: '1',
        optionText: 'January',
      },
      {
        key: '2',
        optionText: 'February',
      },
      {
        key: '3',
        optionText: 'March',
      },
      {
        key: '4',
        optionText: 'April',
      },
      {
        key: '5',
        optionText: 'May',
      },
      {
        key: '6',
        optionText: 'June',
      },
      {
        key: '7',
        optionText: 'July',
      },
      {
        key: '8',
        optionText: 'August',
      },
      {
        key: '9',
        optionText: 'September',
      },
      {
        key: '10',
        optionText: 'October',
      },
      {
        key: '11',
        optionText: 'November',
      },
      {
        key: '12',
        optionText: 'December',
      },
    ] as SelectOptions<string>[];
  }
}

type CompanyForm = {
  companyName: string;
  region: string;
  groupContractState: string;
  originalEffectiveDate: string;
  cobraType: string;
  ganr: string;

  address1: string;
  address2: string;
  city: string;
  state: string;
  zip4: string;
  zip5: string;

  brokerFirstName: string;
  brokerLastName: string;

  billingContact: string;
  billingEmail: string;
  phone: string;
  fax: string;
  newHireWaitingPeriod: string;
  openEnrollment: string;

  products: ProductForm[];

  manageBaXmlEligibility: boolean;
  manageMPXmlEligibility: boolean;
  manageErXmlEligibility: boolean;
  manageBaEligibilityUpload: boolean;
  manageMpEligibilityUpload: boolean;
  manageErEligibilityUpload: boolean;
  manageEligibility: boolean;
  manageBaEligibility: boolean;

  P3Mandatory: boolean;
  P3Voluntary: boolean;
};

type ProductForm = {
  name: string;
  status: boolean;
};
