import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-card',
  styleUrls: ['./content-card.component.scss'],
  template: `
    <div ngClass="wrapper" [class.top_border]="HasTopBorder" [class.full_width]="HasFullWidth" [style.maxWidth]="MaxWidth" [style.margin]="MaxWidth ? '0 auto' : 'unset'">
      <div *ngIf="CustomTitle != ''" style="display: flex; margin-right: 10px">
        <div ngClass="title">
          {{ CustomTitle }}
        </div>
        <div ngClass="parallelogram"></div>
      </div>
      <div ngClass="content">
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class ContentCardComponent implements OnInit {
  constructor() {}
  @Input() CustomTitle: string = '';
  @Input() HasTopBorder: boolean = true;
  @Input() HasFullWidth: boolean = true;
  @Input() MaxWidth?: string;

  ngOnInit() {

  }
}
