export interface BaManageGroupData {
  BaManageGroupId: number;
  MasterProducerId: number;
  EmployerId: number;
  Region: string;
  DateCreation: string;
  DateApproval: string;
  ApprovedBy: string;
  Status: StatusBaUser;
  BaName: string;
  IsMpUser: boolean;
  StatusView: string;
  DateCreationFormated: string;
  DateApprovalFormated: string;
}

export interface GetPendingsByEmployerAPIResponse {
  status: boolean;
  response: BaManageGroupData[];
  message: string;
}

export enum StatusBaUser
{
    Approved,
    NotApproved,
    Disabled
}
