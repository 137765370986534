import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GeneralAgent, GeneralAgentApiResponse } from '../../models/api/general-agent/general-agent.response';
import { GeneralAgentsResponse } from '../../models/api/general-agent/general-agents.response';

@Injectable({
  providedIn: 'root',
})
export class GeneralAgentsService {
  constructor(private http: HttpClient) {}

  UpsertGeneralAgent(generalAgent: GeneralAgent) {
    return this.http.put<GeneralAgentsResponse>(`${environment.alliedApi.baseUrl}/generalagents`, generalAgent);
  }

  GetGeneralAgents() {
    return this.http.get<GeneralAgentsResponse>(`${environment.alliedApi.baseUrl}/generalagents`);
  }

  GetGeneralAgent(id: number) {
    return this.http.get<GeneralAgentApiResponse>(`${environment.alliedApi.baseUrl}/generalagents/${id}`);
  }
}
