import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReplicationStatusService {
  constructor(private http: HttpClient) {}

  GetReplicationResults(type: string, date: string) {
    return this.http.get<any>(`${environment.alliedApi.baseUrl}/replicationresults?type=${type}&date=${date}`);
  }
}
