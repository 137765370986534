import { AbstractControl, ValidationErrors } from '@angular/forms';

export default function StringWithNumbersValidator (control: AbstractControl): ValidationErrors | null {
  const inputValue = control.value;

  const hasNumber = /\d/;

  if (hasNumber.test(inputValue)) {
    return { customErrorMessage: "Value invalid, please verify. Numbers not allowed" };
  }

  return null;
}
