import { Injectable } from '@angular/core';
import { BaUsersApiResponse } from '../../models/api/ba-users.response';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BaUserApiResponse } from '../../models/api/ba-user.response';
import { EmployerToManage, EmployersToManageApiResponse } from '../../models/api/employers-to-manage.response';
import { GetEmployerManagementAPIResponse } from '../../models/api/benefit-administrators/get-employer-management.response';
import { tap } from 'ramda';
import { GetBaEmployersAPIResponse } from '../../models/api/benefit-administrators/get-ba-employers.response';
import { BaManageGroupData, GetPendingsByEmployerAPIResponse } from '../../models/api/benefit-administrators/get-pendings-by-employer.response';
import { GetPendingByEmployerAPIResponse } from '../../models/api/benefit-administrators/get-pending-by-employer.response';

@Injectable({
  providedIn: 'root',
})
export class BenefitAdministratorService {
  constructor(private http: HttpClient) {}

  GetBaUsers() {
    return this.http.get<BaUsersApiResponse>(`${environment.alliedApi.baseUrl}/BenefitAdministrator/getbausers`);
  }

  GetPendingsByEmployer(employerId: string) {
    return this.http.get<GetPendingsByEmployerAPIResponse>(`${environment.alliedApi.baseUrl}/BenefitAdministrator/getpendingsbyemployer`,
    {
      params: {
        employerId: employerId
      }
    });
  }

  GetPending(baManageGroupId: string) {
    return this.http.get<GetPendingByEmployerAPIResponse>(`${environment.alliedApi.baseUrl}/BenefitAdministrator/getpending`,
    {
      params: {
        id: baManageGroupId
      }
    });
  }

  DisableBaRequest(baManageGroup: BaManageGroupData) {
    return this.http.post<{ status: boolean, message: string }>(`${environment.alliedApi.baseUrl}/BenefitAdministrator/disablebarequest`, baManageGroup);
  }

  ApproveBaRequest(baManageGroup: BaManageGroupData) {
    return this.http.post<{ status: boolean, message: string }>(`${environment.alliedApi.baseUrl}/BenefitAdministrator/approvebarequest`, baManageGroup);
  }

  GetEmployerManagement(id: string, region: string) {
    return this.http.get<GetEmployerManagementAPIResponse>(`${environment.alliedApi.baseUrl}/BenefitAdministrator/GetEmployerManagement`,
    {
      params: {
        id: id,
        region: region
      }
    });
  }

  GetBaEmployers(masterProducerId: string) {
    return this.http.get<GetBaEmployersAPIResponse>(`${environment.alliedApi.baseUrl}/BenefitAdministrator/getbaemployers`, {
      params: {
        MasterProducerId: masterProducerId
      }
    })
  }

  GetBaUser(baUserId: number) {
    return this.http.get<BaUserApiResponse>(
      `${environment.alliedApi.baseUrl}/BenefitAdministrator/getbauser?id=${baUserId}`
    );
  }

  GetEmployersToManage(employerName: string, region: string) {
    return this.http.get<EmployersToManageApiResponse>(
      `${environment.alliedApi.baseUrl}/BenefitAdministrator/getemployerstomanage?employerName=${employerName}&region=${region}`
    );
  }

  RequestEmployersToManage(employersToManage: EmployerToManage[], region: string) {
    let request = {
      employers: employersToManage,
      region: region,
    };
    return this.http.post<{ message: string; status: boolean }>(
      `${environment.alliedApi.baseUrl}/BenefitAdministrator/RequestEmployersToManage`,
      request
    );
  }

  UploadEmployerTemplateFile(file: File, userId: string, region: string) {
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('region', region);
    formdata.append('userId', userId);

    return this.http.post<{ status: boolean; message: string }>(
      `${environment.alliedApi.baseUrl}/BenefitAdministrator/postexceldocument`,
      formdata
    );
  }
}
