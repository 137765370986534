import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { EmployersApiResponse } from 'src/app/core/models/api/employer/employer.response';
import { EmployerService } from 'src/app/core/services/http/employer.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { Plan } from '../../employee-form/employee-form.component';

@Component({
  selector: 'employee-benefits-form-section',
  styleUrls: ['./benefits-form-section.component.scss', '../styles.scss'],
  template: `
    <div ngClass="benefit_grid">
      <ng-container *ngFor="let plan of plans; let indexOfelement = index">
        <ng-container *ngIf="plan.productCode.show">
          <ng-container
            *ngIf="
              (plan.productCode.value === 'VSP' ||
              plan.productCode.value === 'DeltaVision' ||
              plan.productCode.value === 'EyeMed') && indexOfelement === 1
            ">
            <div ngClass="benefit_title">Vision</div>
            <div></div>
          </ng-container>

          <div
            [class.with_sub_benefit_title]="
              plan.productCode.value === 'VSP' ||
              plan.productCode.value === 'DeltaVision' ||
              plan.productCode.value === 'EyeMed'
            ">
            <div ngClass="benefit_title">{{ plan.productName.value }}</div>
          </div>

          <form
            [formGroup]="getFormGroupAtIndex(indexOfelement)"
            style="gap: 40px !important; "
            ngClass="flex align_center wrap">
            <div [style.width.%]="plan.effectiveDate.show ? 'unset' : 15">
              <app-form-input-date
                *ngIf="plan.effectiveDate.show"
                title="Effective Date"
                [readonly]="plan.effectiveDate.readonly"
                placeholder="EffectiveDate"
                formControlName="effectiveDate"
                fieldName="effectiveDate"
                [parentForm]="getFormGroupAtIndex(indexOfelement)">
              </app-form-input-date>
            </div>
            <div [style.width.%]="plan.waiverDate.show ? 'unset' : 15">
              <app-form-input-date
                *ngIf="plan.waiverDate.show"
                title="Waiver Date"
                placeholder="WaiverDate"
                [readonly]="plan.waiverDate.readonly"
                formControlName="waiverDate"
                fieldName="waiverDate"
                [parentForm]="getFormGroupAtIndex(indexOfelement)">
              </app-form-input-date>
            </div>
            <div [style.width.%]="plan.dentalOffice.show ? 'unset' : 15">
              <app-form-input
                *ngIf="plan.dentalOffice.show"
                type="text"
                [primary]="true"
                title="Dental Office"
                placeholder="Dental Office"
                formControlName="dentalOffice"
                [forceCaps]="true"
                [parentForm]="getFormGroupAtIndex(indexOfelement)"
                fieldName="dentalOffice"></app-form-input>
            </div>
            <div [style.width.%]="plan.doNotEnroll.show ? 'unset' : 15">
              <app-form-checkbox
                *ngIf="plan.doNotEnroll.show"
                [parentForm]="getFormGroupAtIndex(indexOfelement)"
                fieldName="doNotEnroll"
                formControlName="doNotEnroll"
                name="Do Not Enroll"></app-form-checkbox>
            </div>
            <div [style.width.%]="plan.terminationDate.field.show ? 'unset' : 15">
              <app-form-input-date
                *ngIf="plan.terminationDate.field.show && !plan.terminationDate.showTerminationDateAsSelect"
                title="Termination Date"
                placeholder="terminationDate"
                [readonly]="plan.terminationDate.field.readonly"
                formControlName="terminationDate"
                fieldName="terminationDate"
                [parentForm]="getFormGroupAtIndex(indexOfelement)">
              </app-form-input-date>
            </div>
            <div [style.width.%]="plan.terminationDate.field.show ? 'unset' : 15">
              <app-form-select
                *ngIf="plan.terminationDate.field.show && plan.terminationDate.showTerminationDateAsSelect"
                style="flex-grow: 1;"
                title="Termination Date"
                [Options]="plan.terminationDate.terminationDateOptions ?? []"
                formControlName="terminationDate"
                Label="Termination Date"
                [readonly]="plan.terminationDate.field.readonly"
                [parentForm]="getFormGroupAtIndex(indexOfelement)"
                FieldName="terminationDate"></app-form-select>
            </div>
          </form>
        </ng-container>
      </ng-container>
    </div>
  `,
})
export class BenefitsFormSectionComponent implements OnInit {
  employer$: Observable<EmployersApiResponse>;
  employerId: string;

  @Input() parentForm: FormGroup;
  @Input() plans: Plan[];
  _hasVision: boolean = false;

  hasVision(plan: Plan): boolean {
    if (this._hasVision) return false;
    if (
      plan.productCode.value === 'VSP' ||
      plan.productCode.value === 'DeltaVision' ||
      plan.productCode.value === 'EyeMed'
    ) {
      return true;
    }

    return true;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {}
  getFormGroupAtIndex(index: number): FormGroup {
    return (this.parentForm.get('plans') as FormArray).at(index) as FormGroup;
  }
}

export type BenefitsFormSection = {
  ProductName: string;
  formGroup: FormGroup;

  waiverDateField: Field;
  effectiveDateField: Field;
  dentalOfficeField: Field;
  doNotEnroll: Field;
  status: Field;
  productCode: Field;
  terminationDate: Field;
  terminationReason: Field;
};

type Field = {
  show: boolean;
  disable: boolean;
};
