import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  ApexChart,
  ApexDataLabels,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ApexResponsive,
  ApexStates,
  ApexStroke,
  ApexTheme,
  ApexTitleSubtitle,
  ChartComponent,
} from 'ng-apexcharts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  // responsive: ApexResponsive[];
  labels: any;
  // fill: any;
  // stroke: ApexStroke;
  // states: ApexStates;
  colors: any[];
  fill: any;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  // theme: ApexTheme;
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'app-donut-chart',
  styleUrls: ['./donut-chart.component.scss'],
  template: `
    <apx-chart
      [title]="chartOptions.title"
      [plotOptions]="chartOptions.plotOptions"
      [dataLabels]="chartOptions.dataLabels"
      [labels]="chartOptions.labels"
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [colors]="chartOptions.colors"
      [fill]="chartOptions.fill"></apx-chart>
  `,
})
export class DonutChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: ChartOptions;

  @Input() title: string = '';

  /** Needs to match the Apex Series pattern, see documentation https://apexcharts.com/docs/series/*/
  @Input() series: any[];

  /** Needs to match the Apex label pattern, see documentation */
  @Input() labels: string[];

  @Input() colors: string[];

  constructor() {}

  ngOnInit(): void {
    this.chartOptions = {
      fill: {
        colors: this.colors,
      },
      colors: this.colors,
      chart: {
        type: 'donut',
        width: '100%',
        height: 400,
      } as ApexChart,
      dataLabels: {},
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
              },
            },
          },
          offsetY: 20,
        },
      },
      title: {
        text: this.title,
        style: {
          fontSize: '12px',
        },
      },
      series: this.series,
      labels: this.labels,
      legend: {
        position: 'left',
        offsetY: 80,
      },
    };
  }
}
