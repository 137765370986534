import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faUser, faEnvelope, faPhone, faKey, faFloppyDisk, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';

@Component({
  selector: 'app-add-edit-ba-user',
  styleUrls: ['./add-edit-ba-user.component.scss'],
  template: `
   <div style="display: flex; justify-content: flex-end">
      <custom-button
        [routerLink]="[benefitAdministratorSelectUrl]"
        style="align-self: flex-end; margin-bottom: 10px"
        color="secondary"
        [icon]="arrowLeft"
        label="BACK"></custom-button>
    </div>
    <ng-container *ngIf="!isEdittingUser">
      <app-add-ba></app-add-ba>
    </ng-container>
    <ng-container *ngIf="isEdittingUser">
      <app-edit-ba></app-edit-ba>
    </ng-container>
  `,
})
export class AddEditBaUserComponent implements OnInit {
  personIcon = faUser;
  emailIcon = faEnvelope;
  celphoneIcon = faPhone;
  keyIcon = faKey;
  floppyDisk = faFloppyDisk;
  arrowLeft = faArrowLeft;

  benefitAdministratorForm: FormGroup<any>;
  userId: number;
  isEdittingUser: boolean;

  benefitAdministratorSelectUrl = RouteEnum.SelectBaUsers;

  constructor(private fb: FormBuilder, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.userId = Number(this.route.snapshot.paramMap.get('id'));
    this.isEdittingUser = this.userId !== 0;
  }
}
