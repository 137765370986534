import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { EmployerUsersComponent } from './employer-users/employer-users.component';
import { ManageAdminUsersComponent } from './manage-admin-users/manage-admin-users.component';
import { ManageMpUsersComponent } from './manage-mp-users/manage-mp-users.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ManageEmployerUsersComponent } from './manage-employer-users/manage-employer-users.component';
import { NgxMaskModule } from 'ngx-mask';
import { ManageAllMpUsersComponent } from './manage-all-mp-users/manage-all-mp-users.component';

@NgModule({
  declarations: [
    EmployerUsersComponent,
    ManageAdminUsersComponent,
    ManageMpUsersComponent,
    ManageAllMpUsersComponent,
    ManageEmployerUsersComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
})
export class UsersModule {}
