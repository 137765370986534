<app-content-card CustomTitle="Supplies">
  <div class="heading_h4">This page displays the list of all Supplies.</div>
  <div ngClass="content">
    <ng-container *ngIf="Supplies$ | withLoading | async as supplies">
      <form [formGroup]="suppliesFormGroup">
        <table ngClass="custom_table">
          <thead>
            <tr>
              <th>Qty</th>
              <th>Supply</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            <ng-template [ngIf]="supplies.loading">
              <tr>
                <td align="justify" width="10%">
                  <app-skeleton-input></app-skeleton-input>
                </td>
                <td>
                  <app-skeleton-input></app-skeleton-input>
                  <app-skeleton-input></app-skeleton-input>
                </td>
                <td width="10%">
                  <app-skeleton-input></app-skeleton-input>
                </td>
              </tr>
            </ng-template>
            <ng-template [ngIf]="supplies.value">
              <tr *ngFor="let item of supplies.value; let indexOfelement = index">
                <td align="justify" width="10%">
                  <input style="width: 40px; height: 30px" type="number" min="0" [formControl]="getQtyFormControlAtIndex(indexOfelement)"/>
                </td>
                <td>
                  <b class="ng-binding">{{ item.Name }}</b>
                  <p class="ng-binding">{{ item.Description }}</p>
                  <p *ngIf="hasQuantityError(item, indexOfelement)" [class.danger]="hasQuantityError(item, indexOfelement)">
                    The maximum number of supply items that can be ordered is {{ item.LimitAbs }}.
                  </p>
                </td>
                <td width="10%">
                  <a ngClass="download_button" (click)="onDownload(item.SupplyId, item.FileName)"
                    ><i ngClass="md-icon material-icons icon">cloud_download</i></a
                  >
                </td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </form>
      <ng-template [ngIf]="supplies.value">
        <custom-button [forceCapitalization]="true"
          style="align-self: flex-end"
          label="PLACE ORDER"
          [icon]="icon"
          [iconPosition]="'left'"
          [IsLoading]="placeOrderLoading"
          [primary]="true"
          [Wide]="false"
          (onClick)="sendEmail()"></custom-button>
      </ng-template>
    </ng-container>
  </div>
</app-content-card>
