<div style="flex: 1" *ngIf="openJournal">
  <ng-container *ngIf="journalData$ | withLoading | async as journalData">
    <app-table
      *ngIf="journalData.value"
      [DisplayedColumns]="displayedColumns"
      [EnablePagination]="true"
      [IsLoading]="false"
      [Rows]="journalData.value"
      ></app-table>
    <ng-template> </ng-template>
    <app-table
      *ngIf="journalData.loading"
      [DisplayedColumns]="displayedColumns"
      [IsLoading]="true"
      [LoadingColumns]="3"></app-table>
    <ng-template> </ng-template>
  </ng-container>
</div>

<!-- Column Templates -->
<ng-template #loggedInTemplate let-data>
  <div *ngIf="isEmployerObject">
    <div>{{ data.FirstName }} {{ data.Lastname }}</div>
    <div *ngIf="data.UserName !== '' && data.UserName !== undefined">{{ data.UserName }}</div>
    <div>({{ data.UserId }})</div>
  </div>

  <div *ngIf="userLoggedObject || isEmployeeObject || isMarketingResourceJournal">
    <div>{{ data.logged_in.Username }}</div>
    <div *ngIf="isEmployeeObject || isMarketingResourceJournal">({{ data.logged_in.UserID }})</div>
  </div>

</ng-template>

<ng-template #actionOnTemplate let-data>
  <div *ngIf="userLoggedObject || isEmployeeObject">
    <div>{{ data.action_on.FirstName }} {{ data.action_on.Lastname }}</div>
    <div>({{ data.action_on.SSN || data.action_on.UserId }})</div>
  </div>
</ng-template>

<ng-template #timeTemplate let-date>
  {{ FormatDate(date) }}
</ng-template>
