import { Component, OnInit } from '@angular/core';
import { map, Observable, take } from 'rxjs';
import { IDocument } from 'src/app/core/models/api/documents.response';
import { IInvoiceDocument, InvoiceDocumentApiResponse } from 'src/app/core/models/invoice-documents.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { DocumentService } from 'src/app/core/services/http/document.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { FileEvent, ShowButtons, ShowColumns } from '../../documents/documents.component';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import saveAs from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-employer-invoices',
  template: `<ng-container *ngIf="documents$ | withLoading | async as documents">
    <ng-container *ngIf="documents.loading">
      <app-documents
        [showButtons]="showButtons"
        [modalProps]="modalProps"
        [openUploadDocumentModal]="openUploadDocumentModal"
        [showColumns]="showColumns"
        headlineLabel=" "
        [documents$]="[]"
        customTitle="Invoices"
        [isLoading]="true"
        (documentDropped)="onUploadDocument($event)"
        (onDownloadDocumentEvent)="onDownloadDocument($event)"
        [showArchived]="false"
        (onDeleteFileEvent)="onDeleteFile($event)"
        [showViewJournal]="false">
      </app-documents>
    </ng-container>

    <ng-container *ngIf="documents.value">
      <app-documents
        [showButtons]="showButtons"
        [modalProps]="modalProps"
        [openUploadDocumentModal]="openUploadDocumentModal"
        [showColumns]="showColumns"
        [headlineLabel]="headline"
        [documents$]="documents.value"
        [isLoading]="false"
        customTitle="Invoices"
        (documentDropped)="onUploadDocument($event)"
        (onDownloadDocumentEvent)="onDownloadDocument($event)"
        (onDeleteFileEvent)="onDeleteFile($event)"
        [showArchived]="false"
        [showViewJournal]="false">
      </app-documents>
    </ng-container>
    <ng-component *ngIf="!documents.value">
      <p>No documents found</p>
    </ng-component>
  </ng-container>`,
  styleUrls: ['./employer-invoices.component.scss'],
})
export class EmployerInvoicesComponent implements OnInit {
  userId?: string;
  fileType: string;
  file: File;
  openUploadDocumentModal: boolean;
  employerId: string;

  documents$: Observable<IDocument[]>;
  rows$: IDocument[];

  showButtons: ShowButtons;
  showColumns: ShowColumns;
  modalProps: ModalProps;
  headline: string;

  constructor (
    private authService: AuthService,
    private documentService: DocumentService,
    private userViewService: UserViewService,
    private route: ActivatedRoute
  ) {}

  ngOnInit (): void {
    //get route

    this.route.url.subscribe(url => {
      if (url.find(x => x.path === 'pdf')) {
        this.fileType = 'pdf';
        this.headline = 'On this page, you can view a concise summary of your invoice without the inclusion of detailed transaction information. It includes a high-level summary of charges, giving you a clear snapshot of your balance without delving into detailed transaction specifics.'
      }
      if (url.find(x => x.path === 'excel')) {
        this.fileType = 'xlsx';
        this.headline = 'You can now access and download a detailed invoice in Excel format. It includes detailed information on all charges, payments received, and adjustments providing a complete record with the flexibility of spreadsheet analysis.'

      }
    });

    this.openUploadDocumentModal = false;
    let userViewState = this.userViewService.GetCurrentUserViewState();
    if (userViewState && userViewState.EmployerId) this.employerId = userViewState.EmployerId;
    this.authService.userSession.pipe(take(1)).subscribe(session => {
      this.userId = session?.UserID.toString();
    });

    this.showColumns = {
      showUserNameField: false,
      showCount: false,
      showSize: false,
      showActive: false
    };

    this.showButtons = {
      ShowArchiveRowButton: false,
      ShowUnarchiveRowButton: false,
      ShowUploadDocumentButton: false,
      ShowDeleteRowButton: false,
      ShowSendNotificationButton: false,
    };

    this.modalProps = {
      OkButton: {},
      CloseButton: {
        OnAction: () => {
          this.openUploadDocumentModal = false;
        },
        show: false,
      },
      CanCloseModal: true,
    };
    this.updateDocuments();
  }

  private updateDocuments () {
    this.documents$ = this.documentService.GetInvoiceDocuments(this.employerId, this.fileType).pipe(
      this.mapToTableRows(),
      map(res => {
        this.rows$ = res;
        return res;
      })
    );
  }

  mapToTableRows () {
    return map<InvoiceDocumentApiResponse, IDocument[]>(res => {
      return res.Data.map((document: IInvoiceDocument) => {
        return {
          FileId: document.Id,
          ProducerId: 0,
          FileName: document.FileName,
          IsCurrent: true,
          Deleted: document.Deleted,
          Archived: false,
          DeletedBy: null,
          DeletedDate: document.DeletedDate,
          Size: document.Size,
          Downloads: 0,
          UploadedBy: document.UploadedBy,
          UploadedDate: formatDate(document.UploadedDate),
        };
      });
    });
  }

  onUploadDocument (file: File) {
    this.file = file;
    this.openUploadDocumentModal = false;
  }

  onDownloadDocument (fileEvent: FileEvent) {
    this.documentService.DownloadInvoiceDocument(this.employerId, fileEvent.fileId).subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(data, fileEvent.fileName);
    });
  }

  onDeleteFile (fileEvent: FileEvent) {
    // this.notifcationService.confirmation('Do you want to delete this Invoice?', () => {
    //   this.documentService.DeleteInvoice(fileEvent.fileId).subscribe(res => {
    //     this.notifcationService.success("Invoice Deleted Successfully");
    //     this.updateDocuments();
    //   });
    // });
  }
}
