import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VerticalMenuService } from 'src/app/core/services/vertical-menu.service';
import { MenuLink } from '../menu-item/menu-item.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-item-wrapper',
  styleUrls: ['./menu-item-wrapper.component.css'],
  template: `
    <div *ngIf="isLoading">
      <div ngClass="wrapper" *ngFor="let _ of [].constructor(loadingMenuCount)">
        <app-menu-item [isLoading]="true"></app-menu-item>
      </div>
    </div>

    <ng-container *ngIf="!isLoading">
      <div ngClass="wrapper" *ngFor="let item of Links">
        <app-menu-item [Link]="item" [icon]="item.Icon" (onClick)="OnClick($event)"></app-menu-item>
      </div>
    </ng-container>
  `,
})
export class MenuItemWrapperComponent implements OnInit {
  @Input()
  Links: MenuLink[] = [];

  @Input() isLoading: boolean = false;
  @Input() loadingMenuCount: number = 4;

  constructor(private verticalMenuService: VerticalMenuService, private router: Router) {}

  OnClick(clickedMenuLink: MenuLink) {
    this.verticalMenuService.SetActiveMenuLink(clickedMenuLink)
    if (clickedMenuLink.Link) this.router.navigate([clickedMenuLink.Link]);

  }
  ngOnInit() {}
}
