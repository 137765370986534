<form
  [formGroup]="adminUserFormGroup"
  *ngIf="userData$ | withLoading | async as userData"
  autocomplete="off"
  style="display: flex; flex-direction: column">
  <custom-button [forceCapitalization]="true"
    [routerLink]="[adminUsersUrl]"
    style="align-self: flex-end; margin-bottom: 10px"
    color="secondary"
    [icon]="arrowLeft"
    label="BACK"></custom-button>
  <div ngClass="wrapper" *ngIf="userData.loading === false">
    <div style="display: flex; flex-direction: column; gap: 10px">
      <app-content-card CustomTitle="Edit Admin User">
        <div style="padding: 10px; display: flex; flex-direction: column">
          <!-- User Info -->
          <app-form-input
            type="text"
            [Icon]="personIcon"
            [loading]="userData.loading || false"
            [primary]="true"
            title="User Name"
            [Wide]="true"
            placeholder="userName"
            formControlName="userName"
            [disableAutoFill]="true"
            [parentForm]="adminUserFormGroup"
            fieldName="userName"></app-form-input>
          <div style="display: flex; gap: 10px; flex-wrap: wrap">
            <app-form-input
              type="text"
              style="flex: 1"
              [primary]="true"
              [Icon]="personIcon"
              [loading]="userData.loading || false"
              title="First Name"
              [Wide]="true"
              placeholder="firstName"
              formControlName="firstName"
              [parentForm]="adminUserFormGroup"
              fieldName="firstName"></app-form-input>
            <app-form-input
              type="text"
              style="flex: 1"
              [primary]="true"
              [Icon]="personIcon"
              [loading]="userData.loading || false"
              title="Last Name"
              [Wide]="true"
              placeholder="lastName"
              formControlName="lastName"
              [parentForm]="adminUserFormGroup"
              fieldName="lastName"></app-form-input>
          </div>
          <app-form-input
            type="text"
            [primary]="true"
            [loading]="userData.loading || false"
            title="Email"
            [Icon]="emailIcon"
            [Wide]="true"
            placeholder="email"
            formControlName="email"
            [parentForm]="adminUserFormGroup"
            fieldName="email"></app-form-input>

          <app-form-input
            type="text"
            [primary]="true"
            [loading]="userData.loading || false"
            title="Phone Number"
            [Icon]="phoneIcon"
            inputmode="numeric"
            [Wide]="true"
            mask="(000) 000-0000"
            placeholder="phoneNumber"
            formControlName="phoneNumber"
            [parentForm]="adminUserFormGroup"
            fieldName="phoneNumber"></app-form-input>
          <div
            style="display: flex; gap: 10px; align-items: center"
            *ngIf="!isEdittingUser; else showResetPasswordButton">
            <app-form-input
              type="password"
              style="flex: 1"
              [primary]="true"
              [loading]="userData.loading || false"
              title="Password"
              [enableVisibilityToggle]="!isEdittingUser"
              [Icon]="keyIcon"
              [Wide]="true"
              [disableAutoFill]="true"
              placeholder="password"
              formControlName="password"
              [parentForm]="adminUserFormGroup"
              fieldName="password"></app-form-input>
            <a *ngIf="userData.value" (click)="onGeneratePassword()" title="Generate Password"
              ><fa-icon [icon]="generatePWIcon"></fa-icon
            ></a>
          </div>
          <ng-template #showResetPasswordButton style="display: flex; align-self: flex-start;">
            <custom-button [forceCapitalization]="true"
              *ngIf="userData.value && isEdittingUser"
              [icon]="resetPasswordButtonIcon"
              (onClick)="showResetPasswordModal = true"
              style="align-self: flex-start"
              label="RESET PASSWORD"></custom-button>
          </ng-template>
          <div style="display: flex; justify-content:space-between; padding-top:20px">
            <div >
                <custom-button *ngIf="userLocked && isEdittingUser"
                [icon]="lockedUserIcon"
                style="align-self: flex-start;"
                (onClick)="unlockUser()"
                label="UNLOCK USER">
                </custom-button>
                <span *ngIf="badLoginAttempts > 0" style="align-self: flex-start;"><i class="md-list-addon-icon material-icons uk-text-danger color danger">remove_circle</i>Total Failed Attempts: {{badLoginAttempts}}</span>
            </div>
          </div>

          <!-- Log Details -->
          <app-skeleton-input *ngIf="userData.loading"></app-skeleton-input>
          <app-log-details *ngIf="userData.value && isEdittingUser" [logDetailsInfo]="logDetailsInfo"></app-log-details>

          <div>
            <app-form-checkbox
              *ngIf="userData.value"
              [parentForm]="manageUserPrivilegeForm"
              fieldName="isChecked"
              formGroupName="privileges"
              [name]="manageUserPrivilegeForm.get('regionLabel')?.value"></app-form-checkbox>

            <app-form-checkbox
              *ngIf="userData.value"
              [parentForm]="adminUserFormGroup"
              fieldName="disabled"
              name="Disabled"></app-form-checkbox>
          </div>
          <br />
          <!-- Region Privileges -->
          <div ngClass="privilege_regions" *ngIf="userData.value">
            <span>Privilege Regions:</span>
            <div>
              <app-form-checkbox
                *ngFor="let region of regionPrivilegesForm.controls"
                [parentForm]="getRegionFormGroup(region)"
                fieldName="isChecked"
                formGroupName="regionPrivileges"
                [name]="region.get('regionLabel')?.value"></app-form-checkbox>
            </div>
          </div>

          <!-- Buttons -->
          <div style="display: flex; justify-content: center; margin-top: 10px">
            <custom-button [forceCapitalization]="true"
              *ngIf="userData.value && isEdittingUser"
              label="VIEW JOURNAL"
              [color]="'secondary'"
              [Wide]="false"
              (onClick)="onViewJournalClick()"
              [icon]="historyIcon"></custom-button>
            <custom-button [forceCapitalization]="true"
              label="SAVE"
              [Wide]="false"
              [primary]="true"
              [IsLoading]="saveLoading.value"
              [icon]="saveIcon"
              [disabled]="!adminUserFormGroup.dirty"
              (onClick)="onSubmit()"></custom-button>
          </div>
          <!-- View Journal -->
          <div *ngIf="userData.value && isEdittingUser">
            <app-view-journal
              *ngIf="openJournal.value"
              [openJournal]="openJournal.value"
              [journalInput]="journalInput"></app-view-journal>
          </div>
        </div>
      </app-content-card>

      <app-content-card CustomTitle="Onboarding Groups" *ngIf="showOnboarding">
        <app-skeleton-input *ngIf="userData.loading"></app-skeleton-input>
        <div *ngIf="userData.value">
          <app-form-checkbox
            *ngFor="let region of userGroupForm.controls"
            [parentForm]="getRegionFormGroup(region)"
            fieldName="enrolled"
            formGroupName="userGroups"
            [name]="region.get('name')?.value"></app-form-checkbox>
        </div>
      </app-content-card>
    </div>

    <app-content-card CustomTitle="Privileges">
      <app-skeleton-input *ngIf="userData.loading"></app-skeleton-input>
      <div ngClass="privileges" *ngIf="userData.value">
        <ng-container *ngFor="let region of privilegesForm.controls">
          <app-form-checkbox
            *ngIf="region.get('regionName')?.value !== 'ManageUsers'"
            [parentForm]="getRegionFormGroup(region)"
            fieldName="isChecked"
            formGroupName="privileges"
            [name]="region.get('regionLabel')?.value"></app-form-checkbox>
        </ng-container>
      </div>
    </app-content-card>
  </div>
  <app-password-modal
    [showResetPasswordModal]="showResetPasswordModal"
    [userForm]="adminUserFormGroup"
    [isLoading]="changePasswordLoading"
    (onGeneratePassword)="onGeneratePassword()"
    (onResetPassword)="onResetPassword()"
    (onClose)="showResetPasswordModal = false"></app-password-modal>
</form>
