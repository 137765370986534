import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, take } from 'rxjs';
import { SubProducersResponse } from 'src/app/core/models/api/user/sub-producers.response';
import { UserViewState } from 'src/app/core/models/user-view-state.model';
import { SubProducerService } from 'src/app/core/services/http/sub-producer.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';
import { faIdCard, faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FileService } from 'src/app/core/services/file.service';
import { Router } from '@angular/router';
import { UserTypeEnum } from 'src/app/core/models/user-type.enum';

@Component({
  selector: 'app-sub-producers-access',
  styleUrls: ['./sub-producers-access.component.scss'],
  template: `
    <app-content-card CustomTitle="Manage Sub Producers Access Requests">
      <div style="display: flex; justify-content: flex-end; padding: 10px;">
        <custom-button
          *ngIf="allowedToRequestAccess()"
          [forceCapitalization]="true"
          label="Request Sub Producer access" 
          (click)="requestAccess()"></custom-button>
      </div>

      <ng-container *ngIf="data$ | withLoading | async as data">
        <app-table
          [IsLoading]="data.loading ?? false"
          [EnableSearch]="true"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [Rows]="data.value"
          [EnablePagination]="true"
          [EnableLocalSearch]="true"
          [EnableSearch]="true"
          [EnableLocalSort]="true"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionColumnTemplate let-data>
      <div *ngIf="data.UserCreated !== true && allowedToCreateUser()" > 
      <a  style="cursor:pointer" title="Create User" (click)="createUser(data.Id)">
        <fa-icon [icon]="createIcon" style="color: var(--brandColor);"></fa-icon>
      </a>

      </div>
      <div *ngIf="data.UserCreated !== true && allowedToCreateUser() == false" >
      <button class="user-pending">Pending</button>
      </div>
      <div *ngIf="data.UserCreated === true" >
        <button class="user-created">User created</button>
        <!-- <span class="center-align">User already created</span> -->
      </div>
    </ng-template>
  
  `
})
export class SubProducersAccessComponent implements OnInit {

  constructor(private userViewService: UserViewService,
    private subProducerService: SubProducerService,
    private fileService: FileService,
    private router: Router) { }


  data$: Observable<SubProducersResponse[]>;
  downloadButtons: TableDownloadButtons;
  displayedColumns: DisplayedColumns[];

  createIcon =faUserPlus;

  viewState: UserViewState | null;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;

  ngOnInit(): void {

    this.viewState = this.userViewService.GetCurrentUserViewState();
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let headers = [
            {
              firstName: 'FirstName',
              lastName: 'LastName',
              email:'Email',
              phone:'Phone',
              userCreated:'UserCreated'
            },
          ];

          this.data$.pipe(take(1)).subscribe(x => {
            this.fileService
              .GeneratePDF('Allied Administrators - Sub Producers access requested', headers, this.mapTableToObject(x))
              .save('Allied Administrators - Sub Producers access requested');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.data$.pipe(take(1)).subscribe(x => {
            this.fileService.GenerateXLSX('Allied Administrators - Sub Producers access requested', this.mapTableToObject(x));
          });
        },
      },
    };



    //LOAD TABLE
    this.displayedColumns = [
      { columnName: 'FirstName', label: 'First Name', sortable: true },
      { columnName: 'LastName', label: 'Last Name', sortable: true },
      { columnName: 'Email', label: 'Email', sortable: true },
      { columnName: 'Phone', label: 'Phone' },
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];

    if (this.viewState?.MasterProducerId)
      this.data$ = this.subProducerService.GetRequestedAcessSubProducers(Number(this.viewState.MasterProducerId));
  }

  allowedToCreateUser(){
    if(this.viewState?.IsAdmin() === true) return true;

    return false;
  }

  allowedToRequestAccess(){
    if(this.viewState?.UserType === UserTypeEnum.Producer) return true;

    return false;
  }

  createUser(id: number){
    this.router.navigate(['/dashboard/user/add-sub-producer-user/'+id]);

  }

  requestAccess(){
    this.router.navigate(['/dashboard/sub-producers-request-access/']);
  }


  private mapTableToObject(subProducerData: SubProducersResponse[]){
    return subProducerData.map(producer => ({
      firstName: producer.FirstName,
      lastName: producer.LastName,
      email: producer.Email,
      phone: producer.Phone,
      userCreated: producer.UserCreated === true ? 'Yes' : 'No'
    }));
  }

}
