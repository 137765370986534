import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  /**
   * Generates a PDF file from a table of data.
   * @param {string} pageTitle - The title of the PDF document.
   * @param {RowInput[]} header - The header row of the table.
   * @param {RowInput[]} body - The body rows of the table.
   * @returns {jsPDF} The generated PDF document.
   * @example
   * // Generate a PDF of email templates and save it as 'templates.pdf'
   * this.fileService.GeneratePDF(
   *   'Email Templates',
   *   [
   *     {id: 'ID', name: 'Name', date: 'Date'},
   *   ],
   *   [
   *     {id: 1, name: 'Template 1', date: '2022-01-01'},
   *     {id: 2, name: 'Template 2', date: '2022-02-01'},
   *     {id: 3, name: 'Template 3', date: '2022-03-01'},
   *   ]
   * ).save('templates.pdf');
   */
  GeneratePDF(pageTitle: string, header: RowInput[], body: RowInput[], orientation: 'p' | 'l' = 'p') {
    var doc = new jsPDF(orientation);
    var totalPagesExp = '{total_pages_count_string}';

    autoTable(doc, {
      head: header,
      body: body,
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.text(pageTitle, data.settings.margin.left, 22);

        // Footer
        var str = 'Page ' + (doc as any).internal.getNumberOfPages();

        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp;
        }
        doc.setFontSize(10);

        // jsPDF 1.4+ uses getHeight, <1.4 uses .height
        //make this page on landscape orientation

        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
      margin: { top: 30 },
    });

    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    return doc;
  }

  /**
   * Generates an XLSX file from an array of objects.
   * @param {string} fileName - The name of the XLSX file.
   * @param {object[]} rows - An array of objects to convert to an XLSX file.
   * @example
   * // Generate an XLSX file of email templates and download it as 'templates.xlsx'
   * this.fileService.GenerateXLSX(
   *   'Email Templates',
   *   [
   *     {id: 1, name: 'Template 1', date: '2022-01-01'},
   *     {id: 2, name: 'Template 2', date: '2022-02-01'},
   *     {id: 3, name: 'Template 3', date: '2022-03-01'},
   *   ]
   * );
   */
  GenerateXLSX(fileName: string, rows: object[], headers: string[] = []) {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    //add custom headers to XLSX file
    let ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows);

    if (headers !== undefined && headers.length > 0) {
      ws = XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
    }

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
}
