import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VerticalMenuService } from 'src/app/core/services/vertical-menu.service';
import { MenuLink } from './components/menu-item/menu-item.component';
import { BehaviorSubject, Observable, Subscription, distinctUntilChanged, filter } from 'rxjs';

export type VerticalMenuItem = {
  name: string;
  link: string;
};

export type VerticalMenuItensGroup = {
  groupName: string;
  verticalMenuItem: VerticalMenuItem[];
  verticalMenuItemNew: MenuLink[];
};

@Component({
  selector: 'app-vertical-menu',
  styleUrls: ['./vertical-menu.component.scss'],
  template: `
    <div ngClass="vertical-menu-container" [class.open]="Open">
      <div ngClass="logo-area" (click)="navigateToAlliedWebsite()">
        <img ngClass="logo-header" src="assets/img/logo-header.png" />
      </div>
      <div ngClass="inner">
        <div *ngIf="IsLoading">
          <app-menu-item-wrapper [isLoading]="true"></app-menu-item-wrapper>
        </div>
        <div *ngIf="!IsLoading">
          <ng-template ngFor let-menuItem [ngForOf]="verticalMenuService.state.value.MenuLinksGroups">
            <div ngClass="links-group">{{ menuItem.groupName }}</div>
            <app-menu-item-wrapper [Links]="menuItem.verticalMenuItemNew"></app-menu-item-wrapper>
          </ng-template>
        </div>
      </div>
    </div>
  `,
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  @Input() Open: boolean = true;
  @Input() IsLoading: boolean = false;

  selectedMenuLinkSubscription: Subscription;
  constructor(
    public verticalMenuService: VerticalMenuService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.selectedMenuLinkSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.selectedMenuLinkSubscription = this.verticalMenuService.state
      .pipe(distinctUntilChanged((prev, curr) => {
        return JSON.stringify(prev) === JSON.stringify(curr)
      }))
      .subscribe(state => {
        let { selectedMenuLink, MenuLinksGroups } = state;

        let   menuAlreadySelected = false;
        let menuLinkSelectionStateUpdated = MenuLinksGroups?.map(menuLinkGroup => {
          menuLinkGroup.verticalMenuItemNew = menuLinkGroup.verticalMenuItemNew.map(menuLink => {
            let hasSubMenuItens = menuLink.SubMenuLinks && menuLink.SubMenuLinks?.length > 0

            let isSelectedMenuLink = (menuLink: MenuLink) =>
              selectedMenuLink && menuLink.Label === selectedMenuLink.Label && menuLink.Link === selectedMenuLink.Link;


            if (isSelectedMenuLink(menuLink))
            {
              menuLink.Collapsed = true;

              if (hasSubMenuItens)
              {
                menuLink.Active = !menuLink.Active;
                return menuLink;
              }
              else if (menuLink.Active && !hasSubMenuItens) menuAlreadySelected = true;
              else menuLink.Active = true;

            } else {
              menuLink.Active = false;
              menuLink.Collapsed = false;
            };


            // Buscar nos submenus e marcar pai e filho como aberto
            menuLink.SubMenuLinks = menuLink.SubMenuLinks?.map(subMenuLink => {

              if (isSelectedMenuLink(subMenuLink))
              {
                if (subMenuLink.Active) menuAlreadySelected = true;
                subMenuLink.Active = true;
                menuLink.Active = true;
                menuLink.Collapsed = true;

              } else {
                subMenuLink.Active = false;
              }

              return subMenuLink;
            });

            return menuLink;
          });
          return menuLinkGroup;
        });

        if (!menuAlreadySelected) {
          this.verticalMenuService.UpdateState(old => {
            return { ...old, MenuLinksGroups: menuLinkSelectionStateUpdated };
          });
        }
      });
  }

  navigateToAlliedWebsite() {
    window.open('http://www.alliedadministrators.com/', '_blank');
  }
}
