import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-enrollment',
  template: `<app-add-employee [isOpenEnrollment]="true"></app-add-employee>`,
  styleUrls: ['./open-enrollment.component.scss'],
})
export class OpenEnrollmentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
