import { ContentChild, Directive, Input } from '@angular/core';
import { AccordionContentDirective } from './accordion-content.directive';
import { AccordionHeaderDirective } from './accordion-header.directive';
import { AccordionTitleDirective } from './accordion-title.directive';

@Directive({
  selector: 'accordion-item',
})
export class AccordionItemDirective {
  @Input() title = '';
  @Input() disabled = false;
  @Input() expanded = false;
  @ContentChild(AccordionContentDirective) content: AccordionContentDirective;
  @ContentChild(AccordionTitleDirective) customTitle: AccordionTitleDirective;
  @ContentChild(AccordionHeaderDirective)
  customHeader: AccordionHeaderDirective;

  // constructor(
  //   content: AccordionContentDirective,
  //   customTitle: AccordionTitleDirective,
  //   customHeader: AccordionHeaderDirective
  // ) {
  //   this.content = content;
  //   this.customTitle = customTitle;
  //   this.customHeader = customHeader;
  // }
}
