import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faEdit, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Observable, distinctUntilChanged, of, take } from 'rxjs';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { IEmailManagementApiResponse } from 'src/app/core/models/api/email/email-management.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { EmailService } from 'src/app/core/services/http/email.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-email-management',
  styleUrls: ['./email-management.component.scss'],
  template: `
    <app-content-card CustomTitle="Email Management">
      <div style="display: flex; flex-direction: column; padding: 10px;" *ngIf="rows$ | withLoading | async as rows">
        Add, update or delete your email addresses here.
        <custom-button
          [forceCapitalization]="true"
          label="+ ADD"
          color="secondary"
          style="align-self: flex-end; padding: 15px;"
          (onClick)="onAddEmail()"></custom-button>
        <app-table
          [DisplayedColumns]="displayedColumns"
          [Rows]="rows.value?.Data"
          [IsLoading]="rows.loading || false"
          [EnableLocalSearch]="true"
          [EnableLocalSort]="true"
          [EnableSearch]="true"
          [EnablePagination]="true"></app-table>
      </div>
    </app-content-card>

    <ng-template #actionColumnTemplate let-data>
      <div style="display: flex; gap: 10px;">
        <div (click)="onEdit(data.Email, data.EmailManagementId)">
          <fa-icon title="Edit" [icon]="editIcon"></fa-icon>
        </div>
        <div (click)="onDelete(data.EmailManagementId)">
          <fa-icon title="Delete" [icon]="deleteIcon"></fa-icon>
        </div>
      </div>
    </ng-template>

    <app-modal [modalProps]="modalProps" [open]="openEmailForm">
      <form [formGroup]="emailForm" ngClass="email_form">
        <app-form-input title="Email" [parentForm]="emailForm" [Wide]="true" fieldName="email"></app-form-input>
        <custom-button
          [forceCapitalization]="true"
          label="Save"
          (onClick)="onSaveEmail()"
          [disabled]="emailForm.invalid || emailSaveDisabled"></custom-button>
      </form>
    </app-modal>
  `,
})
export class EmailManagementComponent implements OnInit {
  editIcon = faPencil;
  deleteIcon = faTrash;

  displayedColumns: DisplayedColumns[];
  rows$: Observable<IEmailManagementApiResponse>;
  modalProps: ModalProps;
  emailForm: FormGroup<any>;
  oldEmail : string;
  openEmailForm: boolean;
  employerId?: string;
  emailId?: string;
  emailSaveDisabled: boolean = false;

  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;

  constructor(
    private fb: FormBuilder,
    private emailService: EmailService,
    private userViewService: UserViewService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    let userView = this.userViewService.GetCurrentUserViewState();
    this.employerId = userView?.EmployerId;

    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'Email', label: 'Email Adress', sortable: true },
    ];

    this.modalProps = {
      CloseButton: {
        OnAction: () => {
          this.openEmailForm = false;
        },
      },
      disableButtons: true,
    };

    this.emailForm = this.fb.group({ email: ['', [Validators.required, Validators.email]]},{updateOn: 'change'});
    this.onChanges();
    this.loadTable();
  }

  onDelete(emailId: string) {
    this.notificationService.confirmation('Are you sure you want to delete this email?', () => {
      this.emailService.DeleteEmailManagements(emailId).subscribe(res => {
        if (res.Status) this.notificationService.success(res.Message);
        else this.notificationService.error(res.Message);
  
        this.loadTable();
        this.openEmailForm = false;
      });
    })
    
  }

  onChanges(){
    let emailChanges = this.emailForm.get('email')?.valueChanges.pipe(distinctUntilChanged());

    emailChanges && emailChanges.subscribe(email => {
      this.checkEmailExists();
    })
  }

  onEdit(email: string, emailId: string) {
    this.emailForm.reset();
    this.oldEmail = email;
    this.emailForm.get('email')?.patchValue(email);
    this.emailId = emailId;
    this.openEmailForm = true;
  }

  checkEmailExists(){
    let email = this.emailForm.get('email')?.value;

    if(email === this.oldEmail || email === null) return;

    this.emailSaveDisabled = true;

    if(IsStringNullOrEmpty(email)) return;

    this.emailService.checkEmailExists(email, parseInt(this.employerId ?? '0')).subscribe(response => {
      if(response.Status === false){
        this.emailForm.get('email')?.setErrors({customErrorMessage: 'Email already exists.'});
      } else{
        this.emailForm.get('email')?.setErrors({customErrorMessage: null});
        this.emailForm.get('email')?.updateValueAndValidity();

      }
      this.emailSaveDisabled = false;
    });

  }
  onSaveEmail() {
    console.log(this.emailForm);
    if (this.emailForm.valid) {
      let { email } = this.emailForm.value;
      // if (this.employerId) {
        this.authService.userInfo.pipe(take(1)).subscribe(info => {
          this.emailService
            .CreateOrUpdateEmailManagement({
              UserName: info?.Username ?? '',
              Email: email,
              EmployerId: IsStringNullOrEmpty(this.employerId) ? 0 : parseInt(this.employerId ?? '0'),
              EmailManagementId: this.emailId,
            })
            .subscribe(res => {
              if (res.Status) this.notificationService.success(res.Message);
              else{
                if(res.Message !== null && res.Message !== undefined)
                  this.notificationService.error(res.Message);
                else if(res.Errors !== null && res.Errors !== undefined && res.Errors.length > 0)
                  this.notificationService.error(res.Errors[0])
              
              } 

              this.loadTable();
              this.openEmailForm = false;
            });
        });
      // }
    }
  }

  onAddEmail() {
    this.emailForm.reset();
    this.openEmailForm = true;
  }

  private loadTable() {
    // if (!this.employerId) {
    //   this.rows$ = of();
    //   return;
    // }
    this.rows$ = this.emailService.GetEmailManagements(!this.employerId?'0':this.employerId);
  }
}
