import { FormControl } from '@angular/forms';

export const getErrorMessage = (formControl: FormControl) => {
  if (formControl.hasError('required')) return 'This is a required field.';
  if (formControl.hasError('email')) return 'Email format is invalid.';
  if (formControl.hasError('email')) return 'Email format is invalid.';
  if (formControl.hasError('maxlength'))     
    return `Must not exceed ${formControl.errors?.maxlength.requiredLength} characters long`;

  if (formControl.hasError('minlength'))
    return `Must be ${formControl.errors?.minlength.requiredLength} characters long`;
  if (formControl.hasError('number')) return `Your password is not strong enough. It must have a number`;
  if (formControl.hasError('upper')) return `It must have a upper case letter`;
  if (formControl.hasError('lower')) return `It must have a lower case letter`;
  if (formControl.hasError('matching')) return `Passwords does not match!`;
  if (formControl.hasError('plansError')) return `At least one plan must be active!`;
  if (formControl.hasError('customErrorMessage')) {
    return formControl.errors?.customErrorMessage || '';
  }
  if (formControl.hasError('usernameTaken')) return `User name already exist, try different one`;
  return '';
};

export const GetModalErrorMessage = (formControl: FormControl) => {
  if(formControl.hasError('modalErrorMessage')) return formControl.errors?.modalErrorMessage || '';
};
