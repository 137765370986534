import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { DashboardEmailData, DashboardEmailResponse } from 'src/app/core/models/api/dashboard.email.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { EmailService } from 'src/app/core/services/http/email.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-message',
  styleUrls: ['./message.component.scss'],
  template: `
    <app-content-card CustomTitle="Dashboard Template">
      <div style="padding: 20px; display:flex; flex-direction: column; gap: 10px;">
        <ng-container *ngIf="dashboardTemplate$ | withLoading | async as dashboardTemplate">
          <p ngClass="heading_h3">Instructions</p>
          Enter necessary information, then click Save.
          <app-skeleton-input *ngIf="dashboardTemplate.loading"></app-skeleton-input>
          <editor
            *ngIf="dashboardTemplate.value"
            [(ngModel)]="body"
            [init]="{
              height: 500,
              menubar: false,
              base_url: '/tinymce',
              suffix: '.min',
              plugins: ['advlist', 'autolink', 'lists', 'link', 'fullscreen', 'insertdatetime', 'table', 'preview'],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
            }"></editor>
          <custom-button
            [forceCapitalization]="true"
            *ngIf="dashboardTemplate.value"
            style="align-self: flex-end;"
            label="SAVE"
            (onClick)="onSave(dashboardTemplate.value.Data)"></custom-button>
        </ng-container>
      </div>
    </app-content-card>
  `,
})
export class MessageComponent implements OnInit {
  dashboardId: number;
  dashboardTemplate$: Observable<DashboardEmailResponse>;
  body: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailService: EmailService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.dashboardId = Number(this.route.snapshot.paramMap.get('id'));
    this.dashboardTemplate$ = this.emailService
      .GetDashboard(this.dashboardId)
      .pipe(tap(res => (this.body = res.Data.TextEditor)));
  }

  onSave(data: DashboardEmailData) {
    this.emailService
      .CreateOrUpdateDashboard({ ...data, TextEditor: this.body } as DashboardEmailData)
      .subscribe(res => {
        if (res.Status) {
          this.notificationService.success(res.Message ?? 'Dashboard template updated successfully!');
          this.router.navigateByUrl(RouteEnum.ManageContent);
        } else this.notificationService.error('Some error ocurred. Please contact allied administrators ');
      });
  }
}
