import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetCobraReportRequest } from '../../models/api/get-cobra-report.request';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {}

  GetCobraReport(request: GetCobraReportRequest): Observable<any> {
    let params: any = {};
    if (request.startDate) params['startDate'] = request.startDate;
    if (request.endDate) params['endDate'] = request.endDate;
    if (request.reportType) params['reportType'] = request.reportType;
    return this.http.get(`${environment.alliedApi.baseUrl}/reports/CobraReport`, {
      headers: new HttpHeaders().set('Content-Type', 'text/csv'),
      responseType: 'arraybuffer',
      params: params,
    });
  }
  UploadWexFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<string>(`${environment.alliedApi.baseUrl}/reports/wexfile`, formData, {
      responseType: 'text' as any,
    });
  }
  FederalCobraReport(): Observable<any> {
    return this.http.get(`${environment.alliedApi.baseUrl}/reports/FederalCobraReport`, {
      headers: new HttpHeaders().set('Content-Type', 'text/csv'),
      responseType: 'arraybuffer',
    });
  }

  OpenEnrollmentReport(): Observable<any> {
    return this.http.get(`${environment.alliedApi.baseUrl}/reports/GetOpenEnrollmentReport`, {
      headers: new HttpHeaders().set('Content-Type', 'text/csv'),
      responseType: 'arraybuffer',
    });
  }

  GetContactInformationReport(region: string): Observable<any> {
    return this.http.get(`${environment.alliedApi.baseUrl}/reports/GetContactInformationReport?region=`+region);
  }


}
