<span ngClass="term_of_use_link" (click)="openTermOfUseModal = true">Term of use</span>

<app-modal [modalProps]="termOfUseModalProps" [open]="openTermOfUseModal">
  <div ngClass="modal_term_of_use">
    <h4>Term of Use</h4>
    <div style="white-space: normal;">
      This website presents general information intended to help you understand the administration of your dental
      benefit program. Not all plan provisions, limitations, and exclusions are included. In the event of any conflict
      between the information on this site and the plan provisions, the plan documents will govern.
      <br /><br />
      Allied Administrators will not disclose or share your company information or any personal information about your
      employees to commercial markets. All information provided to us will be used solely for the purposes of
      administrative activities and providing customer service.
      <br /><br />
      You will be provided with a username and password when your company initially enrolls in your Delta Dental plan.
      By logging into and making changes on the site you are accepting responsibility for the validity and accuracy of
      all such changes in accordance with contracted provisions of your dental plan. You are entirely responsible for
      maintaining the confidentiality of your password and who has access to your account.
      <br /><br />
      This website may also include links to other websites. These links are provided for your convenience to provide
      further information. We have no responsibility for the content or security of linked sites.
      <br /><br />
      For security-related questions, information, or reporting, contact security by emailing
      <a href="mailto:info@alliedadministrators.com"> info@alliedadministrators.com</a> <br /><br />
      All content contained in this website is the property of Allied Administrators, Inc., 501 14th Street | Suite 200
      | Oakland, CA 94612 .
    </div>
  </div>
</app-modal>
