import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmployersApiResponse } from '../../models/api/employer/employer.response';
import { EmployersSearchResponse } from '../../models/api/employer/employers-search.response';
import { EmployersSearchRequest } from '../../models/api/employer/employers-search.request';
import { EmployersUsersApiResponse } from '../../models/api/employer/employers-users.response';
import { EmployerPlansApiResponse } from '../../models/api/employer/employer-plans.response';
import { Employer } from '../../models/api/employer/employer';
import { IsStringNullOrEmpty } from '../../helpers/validation-helpers';

@Injectable({
  providedIn: 'root',
})
export class EmployersService {
  constructor(private http: HttpClient) {}

  SearchEmployers(
    page: number,
    pageSize: number,
    statusFilter: number = 0,
    search: string = '',
    orderBy: 'asc' | 'desc' = 'asc',
    orderByColumnName: string = 'EmployerNR'
  ) {
    if (IsStringNullOrEmpty(search)) search = ' ';

    return this.http.post<EmployersSearchResponse>(
      `${environment.alliedApi.baseUrl}/employers/search`,
      new EmployersSearchRequest({
        orderByColumnName: orderByColumnName,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search,
        orderBy: orderBy,
        statusFilter: statusFilter,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }

  GetEmployersUsers(
    page: number,
    pageSize: number,
    statusFilter: number = 0,
    search: string = '',
    orderBy: 'asc' | 'desc' = 'asc',
    orderByColumnName: string = 'UserName'
  ) {
    if (IsStringNullOrEmpty(search)) search = ' ';

    return this.http.post<EmployersUsersApiResponse>(
      `${environment.alliedApi.baseUrl}/UserPrivileges/GetAllERUsers`,
      new EmployersSearchRequest({
        orderByColumnName: orderByColumnName,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search,
        orderBy: orderBy,
        statusFilter: statusFilter,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }

  GetEmployer(employerId: string) {
    return this.http.get<EmployersApiResponse>(`${environment.alliedApi.baseUrl}/employers/${employerId}`);
  }

  PutEmployer(employer: Employer) {
    return this.http.put<EmployersApiResponse>(`${environment.alliedApi.baseUrl}/employers`, employer);
  }

  GetEmployerJournal(employerId: string) {
    return this.http.get<EmployersApiResponse>(`${environment.alliedApi.baseUrl}/employers/journal/${employerId}`);
  }

  GetEmployerPlans(employerId: string, region: string) {
    return this.http.get<EmployerPlansApiResponse>(
      `${environment.alliedApi.baseUrl}/employers/plans/${employerId}&${region}`
    );
  }

  ShouldSelectCobraType(employerId: string) {
    return this.http.get<boolean>(`${environment.alliedApi.baseUrl}/employers/ShouldSelectCobraType/${employerId}`);
  }
}
