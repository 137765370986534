import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SuppliesRoutingModule } from './supplies-routing.module';
import { SuppliesComponent } from './supplies.component';
import { ManageSuppliesComponent } from './manage-supplies/manage-supplies.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddEditSupplyComponent } from './add-edit-supplies/add-edit-supply.component';

@NgModule({
  declarations: [SuppliesComponent, ManageSuppliesComponent, AddEditSupplyComponent],
  imports: [CommonModule, SuppliesRoutingModule, SharedModule, FontAwesomeModule, FormsModule, ReactiveFormsModule],
})
export class SuppliesModule {}
