import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class PasswordValidator {
  public static strong(control: FormControl): ValidationResult | null {
    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    // console.log('Num, Upp', hasNumber, hasUpper);
    const valid = hasNumber && hasUpper;
    if (!hasNumber) return { number: !hasNumber };
    if (!hasUpper) return { upper: !hasUpper };
    return null;
  }

  public static match(controlName: string, matchControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const matchControl = controls.get(matchControlName);

      if (!matchControl?.errors && control?.value !== matchControl?.value) {
        matchControl?.setErrors({
          matching: true,
        });
        return { matching: true };
      }
      return null;
    };
  }
}
