import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserDashboardRoutingModule } from './user-dashboard-routing.module';
import { UserDashboardComponent } from './user-dashboard.component';
import { SharedModule } from '../../shared/shared.module';
import { ModulePickerComponent } from './pages/module-picker/module-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProductsManagementComponent } from './pages/products-management/products-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SiteNavigationModule } from './pages/site-navigation/site-navigation.module';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { UnderDevelopmentComponent } from './pages/under-development/under-development.component';
import { OnlinePaymentComponent } from './pages/online-payment/online-payment.component';
import { NgxMaskModule } from 'ngx-mask';
import { SelectEmployerComponent } from './pages/employers/select-employer/select-employer.component';
import { ManageContentComponent } from './pages/manage-content/manage-content.component';
import { WebAnalyticsComponent } from './pages/web-analytics/web-analytics.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { CommissionStatementsComponent } from './pages/comission-statements/commission-statements.component';
import { YourClientsComponent } from './pages/your-clients/your-clients.component';
import { MarketingResourcesComponent } from './pages/marketing-resources/marketing-resources.component';
import { MasterProducerProfileComponent } from './pages/master-producer-profile/master-producer-profile.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { EmployerDocumentsComponent } from './pages/documents/employer-documents/employer-documents.component';
import { ProducerDocumentsComponent } from './pages/documents/producer-documents/producer-documents.component';
import { NewUserRegistrationComponent } from './pages/new-user-registration/new-user-registration.component';
import { CmsComponent } from './pages/cms/cms.component';
import { ReplicationStatusComponent } from './pages/replication-status/replication-status.component';
import { ManageGeneralAgentsComponent } from './pages/manage-general-agents/manage-general-agents.component';
import { SelectMasterProducerComponent } from './pages/select-master-producer/select-master-producer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CompanyProfileComponent } from './pages/company-profile/company-profile.component';
import { UserModule } from './pages/user/user.module';
import { UsersModule } from './pages/users/users.module';
import { SuppliesModule } from './pages/supplies/supplies.module';
import { MessageComponent } from './pages/manage-content/message/message.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DonutChartComponent } from './pages/dashboard/components/donut-chart/donut-chart.component';
import { LineChartComponent } from './pages/dashboard/components/line-chart/line-chart.component';
import { BarChartComponent } from './pages/dashboard/components/bar-chart/bar-chart.component';
import { CardNumberComponent } from './pages/dashboard/components/card-number/card-number.component';
import { LastActiveService } from 'src/app/core/services/last-active.service';
import { UserLogoutService } from 'src/app/core/services/user-logout.service';
import { SelectBaUsersComponent } from './pages/benefit-administrator/select-ba-users/select-ba-users.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VerticalMenuService } from 'src/app/core/services/vertical-menu.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { OnboardingComponent } from './pages/onboarding/onboarding.component';
import { ViewLatestComponent } from './pages/version-history/view-latest/view-latest.component';
import { ViewArchivedComponent } from './pages/version-history/view-archived/view-archived.component';

import { EligibilityArchiveLogsXmlExcelComponent } from './pages/eligibility/eligibility-archive-logs-xml-excel/eligibility-archive-logs-xml-excel.component';
import { FileManagementComponent } from './pages/eligibility/file-management/file-management.component';
import { ManageEligibilityUploadXmlExcelComponent } from './pages/eligibility/manage-eligibility-upload-xml-excel/manage-eligibility-upload-xml-excel.component';
import { AddEditMarketingResourceComponent } from './pages/marketing-resources/add-edit-marketing-resource/add-edit-marketing-resource.component';
import { ManageBaGroupsComponent } from './pages/benefit-administrator/manage-ba-groups/manage-ba-groups.component';
import { BaGroupsByExcelComponent } from './pages/benefit-administrator/manage-ba-groups/components/ba-groups-by-excel/ba-groups-by-excel.component';
import { BaGroupsManuallyComponent } from './pages/benefit-administrator/manage-ba-groups/components/ba-groups-manually/ba-groups-manually.component';
import { ApprovePendingBaComponent } from './pages/benefit-administrator/approve-pending-ba/approve-pending-ba.component';
import { EligibilityAccessComponent } from './pages/benefit-administrator/eligibility-access/eligibility-access.component';
import { SubProducersAccessComponent } from './pages/sub-producers/sub-producers-access/sub-producers-access.component';
import { SubProducersGroupsComponent } from './pages/sub-producers/sub-producers-groups/sub-producers-groups.component';
import { RequestAcessComponent } from './pages/sub-producers/request-acess/request-acess.component';
import { AssignGroupsSubProducerComponent } from './pages/sub-producers/assign-groups-sub-producer/assign-groups-sub-producer.component';
import { EmployerInvoiceDocumentsComponent } from './pages/documents/employer-invoice-documents/employer-invoice-documents.component';
import { EmployerInvoicesComponent } from './pages/invoices/employer-invoices/employer-invoices.component';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import { ManageContentPictureComponent } from './pages/manage-content-picture/manage-content-picture.component';


@NgModule({
  declarations: [
    UserDashboardComponent,
    ModulePickerComponent,
    ProductsManagementComponent,
    InvoicesComponent,
    UnderDevelopmentComponent,
    OnlinePaymentComponent,
    SelectEmployerComponent,
    ManageContentComponent,
    WebAnalyticsComponent,
    EmployeesComponent,
    CommissionStatementsComponent,
    YourClientsComponent,
    MarketingResourcesComponent,
    MasterProducerProfileComponent,
    DocumentsComponent,
    EmployerDocumentsComponent,
    ProducerDocumentsComponent,
    NewUserRegistrationComponent,
    CmsComponent,
    ReplicationStatusComponent,
    ManageGeneralAgentsComponent,
    SelectMasterProducerComponent,
    DashboardComponent,
    CompanyProfileComponent,
    EligibilityAccessComponent,
    FileManagementComponent,
    MessageComponent,
    ManageEligibilityUploadXmlExcelComponent,
    DonutChartComponent,
    LineChartComponent,
    BarChartComponent,
    CardNumberComponent,
    SelectBaUsersComponent,
    EligibilityArchiveLogsXmlExcelComponent,
    OnboardingComponent,
    ViewLatestComponent,
    ViewArchivedComponent,
    AddEditMarketingResourceComponent,
    ManageBaGroupsComponent,
    BaGroupsByExcelComponent,
    BaGroupsManuallyComponent,
    ApprovePendingBaComponent,
    SubProducersAccessComponent,
    SubProducersGroupsComponent,
    RequestAcessComponent,
    AssignGroupsSubProducerComponent,
    EmployerInvoiceDocumentsComponent,
    EmployerInvoicesComponent,
    ManageContentPictureComponent,
  ],
  imports: [
    CommonModule,
    UserDashboardRoutingModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SiteNavigationModule,
    UserModule,
    UsersModule,
    SuppliesModule,
    EditorModule,
    NgApexchartsModule,
    MatTooltipModule,
    MatProgressBarModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LastActiveService, UserLogoutService, VerticalMenuService, LoggerService],
      useFactory:
        (
          lastActiveService: LastActiveService,
          logoutService: UserLogoutService,
          verticalMenuService: VerticalMenuService,
          logger: LoggerService
        ) =>
        () => {
          //validate the last time user logged out
          logoutService.validateActivity();
          lastActiveService.setUp();
          logoutService.setUpInactivityLogout();
          verticalMenuService.SetUp();
          logger.setUp();
        },
    },
  ],
})
export class UserDashboardModule {}
