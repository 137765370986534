import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import saveAs from 'file-saver';
import moment from 'moment';
import { Observable, BehaviorSubject, map, debounceTime, tap, take } from 'rxjs';
import { RegionCodes } from 'src/app/core/models/api/auth/responses/get-regions.response';
import { EmployersSearchResponse } from 'src/app/core/models/api/employer/employers-search.response';
import { MasterProducer, MasterProducersSearchResponse } from 'src/app/core/models/api/master-producer/master-producers.response';
import { ViewType } from 'src/app/core/models/user-view.model';
import { FileService } from 'src/app/core/services/file.service';
import { MasterProducerService } from 'src/app/core/services/http/master-producer.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-select-master-producer',
  styleUrls: ['./select-master-producer.component.scss'],
  template: `
    <app-content-card CustomTitle="Master Producer">
      <ng-container *ngIf="tableInfo$ | withLoading | async as tableInfo">
        <div style="display: flex; justify-content: flex-end; padding: 10px;">
          <custom-button
            [forceCapitalization]="true"
            style="align-self: flex-end;"
            label="DOWNLOAD LANVERA PRODUCER LIST"
            [color]="'quaternary'"
            [icon]="downloadIcon"
            (onClick)="downloadLanvera()"></custom-button>
        </div>
        <ng-container *ngIf="tableInfo.value"> </ng-container>
        <app-table
          [DisplayedColumns]="displayedColumns"
          (PaginationEvent)="onPaginatorEvent($event)"
          (onSearchEvent)="onSearch($event)"
          (onSort)="onSort($event)"
          [EnableSearch]="true"
          [searchedText]="searchedText.value"
          [Pagination]="Pagination"
          [EnablePagination]="true"
          [IsLoading]="tableInfo.loading ?? false"
          [Rows]="tableInfo.value?.data"
          [DownloadButtons]="DownloadButtons"></app-table>
      </ng-container>
    </app-content-card>
    <ng-template #actionColumnTemplate let-data>
      <div
        [matTooltip]="'View as Master Producer'"
        (click)="onImpersonateUser(data.MasterProducerId, data.Name, data.Region, data.ABSNewsFeed)">
        <fa-icon ngClass="view_as_user_eye" [icon]="viewIcon"></fa-icon>
      </div>
    </ng-template>

    <ng-template #producerNameTemplate let-data>
      <a
        [matTooltip]="'View ' + data.Name + ' as Master Producer'"
        (click)="onImpersonateUser(data.MasterProducerId, data.Name, data.Region, data.ABSNewsFeed)"
        ngClass="table_cell_link"
        >{{ data.Name }}</a
      >
    </ng-template>

    <ng-template #conditionColumnTemplate let-condition>
      {{ condition ? 'Y' : 'N' }}
    </ng-template>
  `,
})
export class SelectMasterProducerComponent implements OnInit {
  viewIcon = faEye;
  downloadIcon = faDownload;
  displayedColumns: DisplayedColumns[] = [];
  tableInfo$: Observable<MasterProducersSearchResponse>;
  Pagination?: ManualPagination;
  searchedText: BehaviorSubject<string> = new BehaviorSubject('');
  sortFilter: BehaviorSubject<'asc' | 'desc'> = new BehaviorSubject<'asc' | 'desc'>('asc');
  sortColumn?: string;
  sortDirection: 'asc' | 'desc';

  DownloadButtons: TableDownloadButtons = {
    DownloadPDF: {
      callback: () => {
        this.tableInfo$.pipe(take(1)).subscribe(res => {
          this.fileService
            .GeneratePDF(
              'Allied Administrators - Employers',
              [
                {
                  Name: 'Name',
                  TinSsn: 'TinSsn',
                  Email: 'Email',
                  Region: 'Region',
                  ProducerUsersCount: 'ProducerUsersCount',
                  ABSNewsFeed: 'ABSNewsFeed',
                },
              ],
              this.mapToDownloadTableObject(res.data)
            )
            .save('Allied Administrators - Master Producers.pdf');
        });
      },
    },
    DownloadXLSX: {
      callback: () => {
        this.tableInfo$.pipe(take(1)).subscribe(res => {
          this.fileService.GenerateXLSX(
            'Allied Administrators - Master Producers',
            this.mapToDownloadTableObject(res.data)
          );
        });
      },
    },
  };

  constructor(
    private fileService: FileService,
    private masterProducerService: MasterProducerService,
    private userViewService: UserViewService
  ) {}
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('conditionColumnTemplate', { static: true }) conditionColumnTemplate: TemplateRef<unknown>;
  @ViewChild('producerNameTemplate', { static: true }) producerNameTemplate: TemplateRef<unknown>;

  ngOnInit(): void {
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      {
        columnName: 'Name',
        label: 'Name',
        enableFullDataColumnTemplateContext: true,
        template: this.producerNameTemplate,
        sortable: true,
      },
      { columnName: 'TinSsn', label: 'TIN', sortable: true },
      { columnName: 'Email', label: 'Email ID', sortable: true },
      { columnName: 'Region', label: 'Region' },
      { columnName: 'ProducerUsersCount', label: 'Users' },
      { columnName: 'ABSNewsFeed', label: 'ABS', template: this.conditionColumnTemplate },
    ];

    this.tableInfo$ = this.masterProducerService.GetMasterProducers(0, 10).pipe(
      this.filterTableData(),
      tap(res => {
        this.Pagination = { Total: res.recordsFiltered, ActualPage: 0, Index: 0, PageSize: 10 };
      })
    );
  }

  downloadLanvera() {
    this.masterProducerService.GetProducerLanvera().subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });

      let userView = this.userViewService.GetCurrentUserViewState();
      if (!userView?.ActualRegion) return;
      var fileName = userView.ActualRegion + '-Users-' + moment().format('MM-DD-YYYY') + '.csv';
      saveAs(data, fileName);
    });
  }

  onImpersonateUser(masterProducerId: string, producerName: string, region: string, absNewsFeed: boolean) {
    this.userViewService
      .Propagate(false)
      .ChangeImpersonationState(true)
      .ChangeMasterProducerId(masterProducerId)
      .ChangeRegion(RegionCodes.Producer)
      .ChangeAbsNewFeed(absNewsFeed)
      .ChangeProducerNameAndRegion(producerName, region)
      .ChangeView(ViewType.ProducerView)
      .Propagate(true)
      .NavigateToPageView(this.userViewService.GetPrimaryLink().toString());
  }

  onSort(sort: Sort) {
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction === 'asc' ? 'asc' : 'desc';
    this.tableInfo$ = this.masterProducerService
      .GetMasterProducers(
        this.Pagination?.Index ?? 0,
        this.Pagination?.PageSize ?? 10,
        undefined,
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(debounceTime(500), this.filterTableData());
  }

  onPaginatorEvent(event: PageEvent) {
    console.log('pagination', event);
    this.tableInfo$ = this.masterProducerService
      .GetMasterProducers(
        event.pageIndex,
        event.pageSize,
        undefined,
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        debounceTime(500),
        this.filterTableData(),
        tap(res => {
          if (this.Pagination)
            this.Pagination = {
              Index: event.pageIndex,
              ActualPage: event.pageIndex,
              Total: res.recordsFiltered,
              PageSize: event.pageSize,
            };
        })
      );
  }

  onSearch(searchText: string) {
    this.searchedText.next(searchText);
    this.tableInfo$ = this.masterProducerService
      .GetMasterProducers(0, 10, undefined, this.searchedText.value, this.sortDirection, this.sortColumn)
      .pipe(
        this.filterTableData(),
        tap(res => {
          this.Pagination = { Total: res.recordsFiltered, ActualPage: 0, Index: 0, PageSize: 10 };
        })
      );
  }

  private filterTableData() {
    return map<MasterProducersSearchResponse, MasterProducersSearchResponse>(res => {
      return {
        ...res,
        data: res.data.map(producer => {
          producer.Name = producer.Name.toUpperCase();
          return producer;
        }),
      };
    });
  }

  private toggleSort() {
    this.sortFilter.next(this.sortFilter.value == 'asc' ? 'desc' : 'asc');
  }

  private mapToDownloadTableObject(res: MasterProducer[]) {
    return res.map(producer => ({
      Name: producer.Name,
      TinSsn: producer.TinSsn,
      Email: producer.Email,
      Region: producer.Region,
      ProducerUsersCount: producer.ProducerUsersCount,
      ABSNewsFeed: producer.ABSNewsFeed,
    }));
  }
}
