import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  faClockRotateLeft,
  faEnvelope,
  faFloppyDisk,
  faKey,
  faPhone,
  faUser,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
import { UserRightCodeEnum } from 'src/app/core/models/api/auth/responses/user/user-rights/user-right-code.enum';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import R from 'ramda';
import { BehaviorSubject, map, Observable, take, tap } from 'rxjs';
import { UserResponse } from 'src/app/core/models/api/auth/responses/user/info/user-info.response.model';
import { mapUserTypeEnumReadableName } from 'src/app/core/helpers/user-model-helpers';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { UpdateUserProfileRequest } from 'src/app/core/models/api/user/update-user-profile.resquest';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';
import { ViewUserJournalResponse } from 'src/app/core/models/api/user/view-user-journal.reponse';
import { UserPrivilegesResponse } from 'src/app/core/models/api/auth/responses/user/info/user-privileges.response.model';
import { formatDate } from 'src/app/core/helpers/date-helpers';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  keyIcon = faKey;
  personIcon = faUser;
  personCheckIcon = faUserCheck;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;
  saveIcon = faFloppyDisk;
  historyIcon = faClockRotateLeft;
  show2FA: boolean = false;

  DATA_UPDATED: string = 'Data Updated';

  userProfileForm: FormGroup = this.formBuilder.group({
    userName: [{ value: '', disabled: true }],
    userType: [{ value: '', disabled: true }],
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    phoneNumber: ['', Validators.required],
    mfaEnabled: [{ value: '', show: !this.authService.IsAdmin() }],
  });

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private userControlService: UserControlService
  ) {}

  //Controls
  openJournal: boolean;
  maxUserRightsPerCol: number = 11;
  OpenPasswordModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  saveLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  //Async data
  userProfileInformation$: Observable<UserResponse | null>;
  rightsCols$: Observable<Privilege[][]>;
  userPrivilegesInformation$: Observable<UserPrivilegesResponse | null>;
  journalData$: Observable<ViewUserJournalResponse[]>;

  // Columns Templates
  @ViewChild('loggedInTemplate', { static: true }) loggedInTemplate: TemplateRef<unknown>;
  @ViewChild('actionOnTemplate', { static: true }) actionOnTemplate: TemplateRef<unknown>;
  @ViewChild('timeTemplate', { static: true }) timeTemplate: TemplateRef<unknown>;
  DisplayedColumns: DisplayedColumns[] = [];

  ngOnInit() {
    console.log(`user-profile`);
    this.setUserProfileInformationObservable();
    this.setPrivilegeColsObservable();
    this.setPrivilegeInfoObservable();

    this.show2FA = this.authService.IsAdmin() === false;

    this.DisplayedColumns = [
      { columnName: 'Time', label: 'Time', template: this.timeTemplate },
      { columnName: 'EventType', label: 'Event Type' },
      { columnName: 'Title', label: 'Event' },
      { columnName: 'action_on', label: 'Action On', template: this.actionOnTemplate },
      { columnName: 'logged_in', label: 'Logged In', template: this.loggedInTemplate },
      { columnName: 'Event', label: 'Detail' },
    ];
    console.debug('Loaded user profile component');
  }
  setPrivilegeInfoObservable() {
    let userPrivileges = this.authService.GetUserPrivileges()?.pipe(map(res => res.response[0]));
    if (userPrivileges) this.userPrivilegesInformation$ = userPrivileges;
  }

  onPasswordClick() {
    this.OpenPasswordModal.next(true);
  }

  onPasswordModalClose() {
    this.OpenPasswordModal.next(false);
  }

  onViewJournalClick() {
    console.debug('open journal clicked');
    this.authService.userSession.subscribe(user => {
      if (!this.openJournal && user?.UserID) {
        this.journalData$ = this.userControlService.ViewJournal(user.UserID, user.Type).pipe(
          map(res =>
            res.response.map(journey => {
              journey.EventType = this.DATA_UPDATED;
              journey.Event = journey.Event?.replace(/<[^>]*>/g, ''); // Removes html tags
              return journey;
            })
          )
        );
        this.openJournal = true;
      }
    });
  }

  onSubmit(values: UserProfileForm, userReponse: UserResponse) {
    if (this.userProfileForm.invalid) {
      this.notificationService.alert('Invalid changes');
      console.debug(`invalid UserProfileForm values: ${values}`);
    }
    if (!userReponse) return;

    let userUpdate: UpdateUserProfileRequest = {
      ...userReponse.toRequest(),
      Email: values.email,
      FirstName: values.firstName,
      LastName: values.lastName,
      Phone: (values.phoneNumber + '').trim().match(/\d+/g)?.join('') || values.phoneNumber,
      MfaEnabled: values.mfaEnabled,
    };

    this.saveLoading.next(true);

    this.userControlService.UpdateUserProfile(userUpdate).subscribe(response => {
      if (!response.status) {
        this.notificationService.error(response.message);
        this.saveLoading.next(false);
        return;
      }
      this.notificationService.success(response.message);

      this.authService
        .GetUserInfo()
        ?.pipe(tap(res => !res.status && this.notificationService.error(res.message)))
        ?.subscribe(_ => {
          this.saveLoading.next(false);
          window.location.reload();
        });
    });
  }

  FormatDate = (date: Date) => formatDate(date);

  get isUserAdmin() {
    return this.authService.IsAdmin();
  }

  private setUserProfileInformationObservable() {
    let userInfo$ = this.authService.userInfo;
    if (userInfo$)
      this.userProfileInformation$ = userInfo$.pipe(
        map(userInfo => {
          console.log(userInfo);
          if (userInfo) {
            this.userProfileForm.patchValue({
              userName: userInfo.Username,
              userType: mapUserTypeEnumReadableName(userInfo.Type),
              firstName: userInfo.FirstName,
              lastName: userInfo.LastName,
              email: userInfo.Email,
              phoneNumber: userInfo.Phone,
              mfaEnabled: userInfo.MfaEnabled,
            });
            return userInfo;
          }
          return null;
        })
      );
  }

  private setPrivilegeColsObservable() {
    this.rightsCols$ = this.authService.userRights.pipe(
      map(userRight => {
        let userRights: Privilege[] = [];
        let privilegeCols: Privilege[][] = [];

        return (
          userRight?.response.map(user => {
            userRights = user.rights.map(right => {
              return { right: right.RightCode, assigned: right.assigned == 1 || false };
            });

            let numberOfColumns = Math.ceil(userRights.length / this.maxUserRightsPerCol);

            // Loop through range of columns and add N rows defined by maxUserRightsPerCol
            R.range(0, numberOfColumns - 1).forEach(_ => {
              privilegeCols.push(userRights.splice(0, this.maxUserRightsPerCol));
            });

            if (userRights.length) privilegeCols.push(userRights);

            return privilegeCols;
          })[0] || []
        );
      })
    );
  }
}

type UserProfileForm = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  mfaEnabled: boolean;
  phoneNumber: string;
};

type Privilege = { right: UserRightCodeEnum; assigned: boolean };
