import { Component, OnInit } from '@angular/core';
import { Observable, of, take } from 'rxjs';
import Regions from 'src/app/core/common/Regions';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { CommissionStatementService } from 'src/app/core/services/http/commission-statement.service';
import { MasterProducerService } from 'src/app/core/services/http/master-producer.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';

@Component({
  selector: 'app-commission-statements',
  styleUrls: ['./commission-statements.component.scss'],
  template: `
    <app-content-card CustomTitle="Commission Statements">
      <div style="display: flex; flex-direction: column; gap: 15px; margin: 20px;">
        <span
          >Please click here if your documents do not appear
          <custom-button
            [forceCapitalization]="true"
            [Wide]="false"
            label="OPEN"
            (onClick)="OpenNewUrl()"></custom-button
        ></span>
        <span
          >If you have questions on your commission statement, please contact Allied Administrators at
          brokers@alliedadministrators.com or via phone at (510) 962-7736.</span
        >

        <div style="width:20%">
          <app-select
            #select
            Label="Region"
            [value]="defaultRegion"
            (onSelectOutput)="onSelectRegion(select.SelectedItem)"
            [OptionList]="regionOptions"></app-select>
        </div>
        <iframe title="invoices" height="1000px" width="100%" [src]="commissionStatementUrl | safe"></iframe>
      </div>
    </app-content-card>
  `,
})
export class CommissionStatementsComponent implements OnInit {
  commissionStatementUrl: string = '';
  producerId: string | undefined;
  defaultRegion: string;
  regionOptions: SelectOptions<string>[] = [];
  constructor(
    private commissionStatementService: CommissionStatementService,
    private masterProducerService: MasterProducerService,
    private viewService: UserViewService
  ) {}

  ngOnInit() {
    let view = this.viewService.GetCurrentUserViewState();
    if (!view || !view.MasterProducerId) return;

    this.producerId = view.MasterProducerId;
    this.loadRegionsForProducer();
  }

  onSelectRegion(region: string | undefined) {
    this.commissionStatementService
      .GetCommissionStatement(Number(this.producerId), region || '')
      .subscribe(response => (this.commissionStatementUrl = response.url));
  }

  loadRegionsForProducer() {
    this.masterProducerService
      .GetProducerRegions(this.producerId || '')
      .pipe(take(1))
      .subscribe(response => {
        if (response.Status === true) {
          this.regionOptions = response.Data.map<SelectOptions<string>>(region => ({
            key: region,
            optionText: region,
          }));

          if (this.regionOptions.length > 0) {
            this.defaultRegion = this.regionOptions[0].key;
            this.onSelectRegion(this.defaultRegion);
          }
        }
      });
  }

  OpenNewUrl() {
    window.open(this.commissionStatementUrl, '_blank');
  }
}
