import { ChangeDetectorRef, Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getErrorMessage } from '../form-field-errors/CustomErrorMessages';

@Component({
  selector: 'app-form-radio-buttom',
  styleUrls: ['./form-radio-buttom.component.scss'],
  template: `
    <!-- <mat-form-field appearance="standard" [style.width.%]="wide ? 100 : 'unset'">
      </mat-form-field> -->
    <div style="display: flex; flex-direction: column; gap: 10px;">
      <mat-label>{{ title }}</mat-label>
      <mat-radio-group
        aria-label="Select an option"
        [formControl]="formField"
        (change)="onChange($event.value)"
        [(ngModel)]="checked">
        <mat-radio-button
          style="margin-left: 10px;"
          *ngFor="let item of labels"
          [value]="item"
          [checked]="checked === item"
          >{{ item }}</mat-radio-button
        >
        <mat-error>{{ setErrorMessage() }}</mat-error>
      </mat-radio-group>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormRadioButtomComponent),
      multi: true,
    },
  ],
})
export class FormRadioButtomComponent implements OnInit, ControlValueAccessor {
  @Input() labels: string[] = [];
  @Input() type = 'text';
  @Input() title: string = '';
  @Input() wide: boolean = false;
  @Input() checked: string;
  @Input() fieldName: string = '';

  @Input() public parentForm?: FormGroup;
  get formField(): FormControl {
    return this.parentForm?.get(this.fieldName) as FormControl;
  }

  constructor(private ref: ChangeDetectorRef) {}
  ngOnInit() {
    console.log('init', this.checked);
  }
  setErrorMessage() {
    return getErrorMessage(this.formField);
  }
  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    if (!obj) {
      this.formField.setValue(this.checked);
      this.ref.markForCheck();
      return;
    }

    this.checked = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // setDisabledState?(isDisabled: boolean): void {
  //   isDisabled ? this.formField.disable() : this.formField.enable();
  // }
}
