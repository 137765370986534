export abstract class RegisterBaseRequest {
  usertype: 'Producer' | 'Employer';
  firstname: string;
  lastname: string;
  email: string;
  companyname: string;
  agencyname: string;

  constructor(
    usertype: 'Producer' | 'Employer',
    firstname: string,
    lastname: string,
    email: string,
    companyname: string,
    agencyName: string = ''
  ) {
    this.usertype = usertype;
    this.firstname = firstname;
    this.lastname = lastname;
    this.email = email;
    this.companyname = companyname;
    this.agencyname = agencyName;
  }
}
