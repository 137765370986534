import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { OpenEnrollmentComponent } from './open-enrollment/open-enrollment.component';
import { QualifyingEventComponent } from './qualifying-event/qualifying-event.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { ViewEmployeeComponent } from './view-employee/view-employee.component';

const routes: Routes = [
  { path: 'add-employee', component: AddEmployeeComponent, title: 'Allied Administrators - Add Employee' },
  { path: 'open-enrollment', component: OpenEnrollmentComponent, title: 'Allied Administrators - Open Enrollment' },
  { path: 'qualifying-event', component: QualifyingEventComponent, title: 'Allied Administrators - Qualifying Event' },
  { path: 'edit-employee/:id', component: EditEmployeeComponent, title: 'Allied Administrators - Edit Employee' },
  { path: 'view-employee/:id', component: ViewEmployeeComponent, title: 'Allied Administrators - View Employee' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeeRoutingModule {}
