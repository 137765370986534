import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserViewService } from '../services/user-view.service';
import { RegionCodes } from '../models/api/auth/responses/get-regions.response';

export const InterceptorSkipRegionHeader = 'X-Skip-Region';

@Injectable()
export class RegionInterceptor implements HttpInterceptor {
  constructor(private userViewService: UserViewService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has(InterceptorSkipRegionHeader)) return next.handle(request);

    let authView = this.userViewService.GetCurrentUserViewState();
    if (!authView || authView.ActualRegion === RegionCodes.None) return next.handle(request);

    const cloned = request.clone({
      headers: request.headers.set('region', authView.ActualRegion),
    });

    return next.handle(cloned);
  }
}
