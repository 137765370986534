import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { EmailLogComponent } from './email-log/email-log.component';
import { EmailTemplatesComponent } from './email-template/email-template.component';
import { EmailManagementComponent } from './email-management/email-management.component';
import { EditEmailComponent } from './edit-email/edit-email.component';
import { EditEmailTemplateComponent } from './edit-email-template/edit-email-template.component';
import { ManageEmailsComponent } from './manage-emails/manage-emails.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'email-log',
        component: EmailLogComponent,
        canActivate: [],
        title: 'Allied Administrators - Email Log',
      },
      {
        path: 'email-template',
        component: EmailTemplatesComponent,
        canActivate: [],
        title: 'Allied Administrators - Email Templates',
      },
      {
        path: 'email-configuration',
        component: EmailConfigurationComponent,
        canActivate: [],
        title: 'Allied Administrators - Email Configuration',
      },
      // {
      //   path: 'email-template',
      //   component: EmailTemplateComponent,
      //   canActivate: [],
      // },
      {
        path: 'emails-management',
        component: ManageEmailsComponent,
        canActivate: [],
        title: 'Allied Administrators - Emails Management',
      },
      {
        path: `eligibility`,
        children: [
          {
            path: 'master-email-management', // From Elibibilty menu group. Define a better route name
            component: EmailManagementComponent,
            canActivate: [],
          },
          {
            path: 'email-management', // From Elibibilty menu group. Define a better route name
            component: EmailManagementComponent,
            canActivate: [],
            title: 'Allied Administrators - Email Management',
          },
          {
            path: 'xml-email-management', // From Elibibilty menu group. Define a better route name
            component: EmailManagementComponent,
            canActivate: [],
            title: 'Allied Administrators - XML Email Management',
          },
        ],
      },
      {
        path: 'edit-email',
        component: EditEmailComponent,
        canActivate: [],
        title: 'Allied Administrators - Edit Email',
      },
      {
        path: 'edit-email-template/:id',
        component: EditEmailTemplateComponent,
        canActivate: [],
        title: 'Allied Administrators - Edit Email Template',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmailRoutingModule {}
