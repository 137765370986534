import { ContentChild, Directive, TemplateRef } from '@angular/core';
import { LeftSideDirectiveContent } from './left-side-content.directive';

@Directive({
  selector: 'navbar-left-side',
})
export class LeftSideDirective {
  // constructor(public templateRef: TemplateRef<any>) {}
  @ContentChild(LeftSideDirectiveContent) content?: LeftSideDirectiveContent;
}
