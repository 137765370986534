import { Pipe, PipeTransform } from '@angular/core';
import { catchError, map, Observable, of, startWith } from 'rxjs';

export interface ObsWithStatusResult<T> {
  loading?: boolean;
  value?: T;
  error?: string;
}

const defaultError = 'Something went wrong';

@Pipe({
  name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
  constructor() {}
  transform<T = any>(val: Observable<T>): Observable<ObsWithStatusResult<T>> {
    return val.pipe(
      map((value: any) => {
        return {
          loading: false,
          value: value,
        };
      }),
      startWith({ loading: true }),
      catchError(error => {
        console.log(error);
        return of({ loading: false, error: typeof error === 'string' ? error : defaultError });
      })
    );
  }
}
