import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CobraService {
  constructor(private http: HttpClient) {}

  GetCobraTypes() {
    return this.http.get<{ Id: number; Description: string; VerifyAddress: boolean }[]>(
      `${environment.alliedApi.baseUrl}/CobraTypes`
    );
  }
}
