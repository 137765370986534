import { Component, OnInit } from '@angular/core';

type ManagementChoice = 'Manage groups using Excel file' | 'Manage groups manually';

@Component({
  selector: 'app-manage-ba-groups',
  styleUrls: ['./manage-ba-groups.component.scss'],
  template: `
    <app-content-card CustomTitle="Manage Groups">
      <div style="display: flex; flex-direction: column; gap: 10px; padding: 10px;">
        <app-radio-button
          (onRadioButtonClick)="onFilter($event)"
          [labels]="radioButtomLabels"
          [checked]="selectedManagementChoice"></app-radio-button>
        <div style="padding: 10px;">
          <app-ba-groups-by-excel
            *ngIf="selectedManagementChoice === 'Manage groups using Excel file'"></app-ba-groups-by-excel>
          <app-ba-groups-manually
            *ngIf="selectedManagementChoice === 'Manage groups manually'"></app-ba-groups-manually>
        </div>
      </div>
    </app-content-card>
  `,
})
export class ManageBaGroupsComponent implements OnInit {

  radioButtomLabels: string[] = ['Manage groups using Excel file', 'Manage groups manually'];
  selectedManagementChoice: string = 'Manage groups using Excel file';

  onFilter = (choice: string) => (this.selectedManagementChoice = choice);

  constructor() {}

  ngOnInit(): void {}
}
