import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmailRoutingModule } from './email-routing.module';
import { EditEmailTemplateComponent } from './edit-email-template/edit-email-template.component';
import { EditEmailComponent } from './edit-email/edit-email.component';
import { EmailConfigurationComponent } from './email-configuration/email-configuration.component';
import { EmailLogComponent } from './email-log/email-log.component';
import { EmailManagementComponent } from './email-management/email-management.component';
import { EmailTemplatesComponent } from './email-template/email-template.component';
import { ManageEmailsComponent } from './manage-emails/manage-emails.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    EmailLogComponent,
    EmailTemplatesComponent,
    EmailConfigurationComponent,
    EmailTemplatesComponent,
    ManageEmailsComponent,
    EmailManagementComponent,
    EditEmailComponent,
    EditEmailTemplateComponent,
  ],
  imports: [
    CommonModule,
    EmailRoutingModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    NgxMaskModule.forRoot(),
  ],
  // providers: [
  //   { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  // ]
})
export class EmailModule {}
