import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOptions } from '../../select/select.component';
import { getErrorMessage } from '../form-field-errors/CustomErrorMessages';
import { CustomErrorStateMatcher } from '../form-field-errors/CustomErrorStateMatcher';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import moment from 'moment';
import { FormControlWarn } from '../form-group-warn';

type StyleMode = 'primary' | 'secondary';

@Component({
  selector: 'app-form-select',
  styleUrls: ['./form-select.component.scss'],
  template: `
    <div style="display: flex; gap: 10px; align-items: center;">
      <fa-icon style="color: #444;" *ngIf="icon" [icon]="icon"></fa-icon>
      <div ngClass="form_select_wrapper" style="flex: 1;">
        <mat-form-field appearance="standard"
        [class.readonly-field]="readonly"
    [floatLabel]="readonly === true ? 'always' : 'auto'">
          <mat-label>{{ Label }}</mat-label>
          <mat-select
            #select
            [disabled]="readonly === true"
            (selectionChange)="onChange(select.value)"
            [value]="value"
            [formControl]="formField"
            [multiple]="Multiple">
            <mat-option *ngFor="let item of Options" [value]="item.key">
              {{ item.optionText }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{ setErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true,
    },
  ],
})
export class FormSelectComponent<T> implements OnInit, ControlValueAccessor {
  setErrorMessage() {
    return getErrorMessage(this.formField);
  }

  @Input() icon: IconProp;
  @Input() Options: SelectOptions<T>[] = [];
  @Input() Label: string = '';
  @Input() public parentForm?: FormGroup;
  @Input() FieldName: string = '';
  @Input() StyleMode: StyleMode = 'primary';
  @Input() Multiple: boolean = false;
  @Input() readonly?: boolean = false;


  value: any;
  errorStateMatcher = new CustomErrorStateMatcher();
  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() {}
  writeValue(obj: any): void {

    if(IsStringNullOrEmpty(obj) === false){
      if(obj instanceof Date){
        obj = moment(obj).format('L');
      }
    }
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  // setDisabledState?(isDisabled: boolean): void {
  //   // throw new Error('Method not implemented.');
  //   isDisabled ? this.formField.disable() : this.formField.enable();
  // }
  OnSelectOutput(arg0: any) {
    console.log(arg0);
    this.value = arg0;
  }

  ngOnInit(): void {}

  get formField(): FormControlWarn {
    return this.parentForm?.get(this.FieldName) as FormControlWarn;
  }

  get classes(): string[] {
    return ['input_field_control', this.MapModeToCss(this.StyleMode)];
  }

  private MapModeToCss = (mode: StyleMode): string =>
    new Map<StyleMode, string>([
      ['primary', 'select-primary'],
      ['secondary', 'select-secondary'],
    ]).get(mode) ?? '';
}
