import { Component, OnInit } from '@angular/core';
import saveAs from 'file-saver';
import { catchError, throwError, timeout } from 'rxjs';
import { FileService } from 'src/app/core/services/file.service';
import { ReportService } from 'src/app/core/services/http/report.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';

@Component({
  selector: 'app-contact-information-report',
  template: `
  <app-content-card CustomTitle="Report log">
      <div style="display: flex; flex-direction: column; gap: 10px;">
        This report shows contact information.
        
        <app-select
          Label="Select Region"
          [OptionList]="regionOptions"
          (onSelectOutput)="region = $event">
        </app-select>

        <custom-button
          [forceCapitalization]="true"
          [IsLoading]="isReportLoading"
          [disabled]="isReportLoading"
          (onClick)="onGetReport()"
          label="GENERATE REPORT"
          style="align-self: flex-start;"></custom-button>
      </div>
    </app-content-card>`,
  styleUrls: ['./contact-information-report.component.scss']
})
export class ContactInformationReportComponent implements OnInit {
  isReportLoading: boolean;
  region: string;
  regionOptions: SelectOptions<string>[] = [
    {
      key: 'DDCA',
      optionText: 'DDCA'
    },
    {
      key: 'DDIC',
      optionText: 'DDIC'
    },
    {
      key: 'DDPA',
      optionText: 'DDPA'
    }
  ] ;
  constructor(
    private reportService: ReportService,
    private fileService: FileService
  ) { }

  ngOnInit(): void {
  }

  onGetReport() {
    this.isReportLoading = true;

    this.reportService.GetContactInformationReport(this.region)
    .pipe(
      timeout(120000),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          console.error('Request timed out'); // Handle timeout error
        }
        return throwError(error);
      })
    ).subscribe(data => {
      this.isReportLoading = false;

      this.fileService.GenerateXLSX(`Contact Information Report - ${this.region}`, data,
      [
        'Employer Number',
        'Employer Name',
        'Address1',
        'Address2',
        'City',
        'State',
        'Zip4',
        'Billing Contact Name',
        'Billing contact email',
        'Billing contact phone',
        'ER Username',
        'ER user first name',
        'ER user last name',
        'ER User email',
        'ER User phone',
        'last login date',
        'last login time'
      ]);
    });
  }

}
