import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { RegionCodes } from 'src/app/core/models/api/auth/responses/get-regions.response';
import { BaUser, BaUsersApiResponse } from 'src/app/core/models/api/ba-users.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { UserViewState } from 'src/app/core/models/user-view-state.model';
import { ViewType } from 'src/app/core/models/user-view.model';
import { BenefitAdministratorService } from 'src/app/core/services/http/benefit-administrator.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-select-ba-users',
  styleUrls: ['./select-ba-users.component.scss'],
  template: `
    <app-content-card CustomTitle="Manage BA">
      <ng-container *ngIf="data$ | withLoading | async as userData">
        <app-table
          [searchedText]="searchText.value"
          [IsLoading]="userData.loading ?? false"
          [EnableSearch]="true"
          [EnableLocalSearch]="true"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [Rows]="userData.value?.response"
          [EnablePagination]="true"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionColumnTemplate let-data>
      <a [routerLink]="[editBaUserUrl, data.UserID]">
        <fa-icon [icon]="editIcon" style="color: var(--brandColor);"></fa-icon>
      </a>
    </ng-template>
    <ng-template #disabledColumnTemplate let-disabled>
      <span>{{ disabled ? 'Y' : 'N' }}</span>
    </ng-template>
    <ng-template #dateColumnTemplate let-data>
      {{ customFormatDate(data) }}
    </ng-template>
    <ng-template #nameColumnTemplate let-data>
      <a ngClass="name_link" (click)="onImpersonate(data)">{{ data.ProducerName }}</a>
    </ng-template>
  `,
})
export class SelectBaUsersComponent implements OnInit {
  pagination: ManualPagination;
  searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  sortFilter: BehaviorSubject<'asc' | 'dsc'> = new BehaviorSubject<'asc' | 'dsc'>('asc');

  editIcon = faPencil;
  editBaUserUrl = RouteEnum.EditBaUser;
  addBaUserUrl = RouteEnum.AddBaUser;

  view: ViewType | undefined;

  displayedColumns: DisplayedColumns[];
  downloadButtons: TableDownloadButtons;
  data$: Observable<BaUsersApiResponse>;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('disabledColumnTemplate', { static: true }) disabledColumnTemplate: TemplateRef<unknown>;
  @ViewChild('dateColumnTemplate', { static: true }) dateColumnTemplate: TemplateRef<unknown>;
  @ViewChild('nameColumnTemplate', { static: true }) nameColumnTemplate: TemplateRef<unknown>;
  viewState: UserViewState | null;

  constructor(private baService: BenefitAdministratorService, private userViewService: UserViewService) {}

  ngOnInit(): void {
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      {
        columnName: 'ProducerName',
        label: 'Ba Name',
        template: this.nameColumnTemplate,
        sortable: true,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'Email', label: 'Email', sortable: true },
      { columnName: 'Username', label: 'User Name', sortable: true },
      { columnName: 'Disabled', label: 'Disabled', template: this.disabledColumnTemplate, sortable: true },
      { columnName: 'Lastlogin', label: 'Last Login', template: this.dateColumnTemplate, sortable: true },
      { columnName: 'ABSNewsFeed', label: 'ABS', template: this.disabledColumnTemplate, sortable: true },
    ];

    this.loadData();
  }

  onImpersonate(baUser: BaUser) {
    this.userViewService
      .Propagate(false)
      //TODO Add here between propagate everything needed to impersonate a ba user
      .ChangeRegion(RegionCodes.Producer)
      .ChangeAbsNewFeed(baUser.ABSNewsFeed)
      .ChangeMasterProducerId(baUser.MasterProducerId.toString())
      .ChangeMasterProducerNr(baUser.MasterProducerNr ?? '')
      .ChangeMasterProducerName(baUser.ProducerName?.toUpperCase() ?? '')
      .ChangeIsNewBaUser(baUser.IsNewBaUser ?? false)
      .ChangeIsBaUser(baUser.IsNewBaUser ?? false)
      .ChangeView(baUser.IsNewBaUser ? ViewType.BenefitAdministratorNewBaUserView : ViewType.BenefitAdministratorView)
      .ChangeImpersonationState(true)
      .Propagate(true)
      .NavigateToPageView(this.userViewService.GetPrimaryLink().toString());
  }

  private loadData() {
    this.data$ = this.baService.GetBaUsers();
  }

  customFormatDate(date: string) {
    return formatDate(date);
  }
}
