import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { Observable, catchError, distinctUntilChanged, of, tap } from 'rxjs';
import { IEmailManagementApiResponse } from 'src/app/core/models/api/email/email-management.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { EmailService } from 'src/app/core/services/http/email.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { FileHandle } from 'src/app/shared/directives/dragDrop.directive';
import { environment } from 'src/environments/environment';
import { EligibilityFactory as EligibilityPropsFactory } from './EligibilityFactory';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';

//TODO Refactor this to use a common design component like (UploadEligibilityComponent) and create two different components for excel and xml that will reuse this container
// Dont create another "if", if it is needed to add another interface. Dont let this get too big!!!

/** THe name of the component and the folder are different trying to abstract both xml and excel download interfaces */
@Component({
  selector: 'app-manage-eligibility-upload-xml-excel',
  styleUrls: ['./manage-eligibility-upload-xml-excel.component.scss'],
  template: `
    <app-content-card [CustomTitle]="uploadEligibilityDocument.CardTitle">
      <div ngClass="flex column gap" style="padding: 20px;">
        <span>{{ uploadEligibilityDocument.Description }}</span>
        <custom-button
          [forceCapitalization]="true"
          (onClick)="onDownloadEligibilityUploadTemplateSample()"
          [label]="uploadEligibilityDocument.DownloadTemplateButtonLabel"
          color="secondary"
          style="align-self: flex-start;"></custom-button>

        <span>Upload File:</span>
        <app-drag-drop-download
          (onFileDropped)="onFileDropped($event)"
          [errorMessage]="uploadErrorMessage"></app-drag-drop-download>

        <custom-button
          [forceCapitalization]="true"
          label="UPLOAD"
          (onClick)="onOpenUpload()"
          [disabled]="fileUploaded === undefined"
          [icon]="addIcon"
          style="align-self: flex-end;"></custom-button>
      </div>
    </app-content-card>

    <app-modal [modalProps]="modalProps" [open]="openModal">
      <div ngClass="flex column gap">
        <div ngClass="flex gap" style="min-width: 300px; max-width: 500px;">
          <app-form-checkbox [parentForm]="eligibilityForm" fieldName="sendEmailAlert"></app-form-checkbox>
          <span>
            Check this box to send an email alert to <strong>{{ email }} </strong> when upload is finished. Also, you
            can add more email addresses to receive the alert by pressing <strong>+ADD EMAIL</strong>.</span
          >
        </div>
        <custom-button
          [forceCapitalization]="true"
          (onClick)="onAddEmail()"
          label="ADD EMAIL"
          [icon]="addIcon"
          style="align-self: flex-start;"></custom-button>

        <div *ngIf="emails$ | withLoading | async as email">
          <app-skeleton-input *ngIf="email.loading"></app-skeleton-input>

          <form [formGroup]="eligibilityForm" *ngIf="email.value">
            <div
              ngClass="flex gap center_aligned"
              *ngFor="let email of emailsForm.controls; let indexOfItem = index"
              formGroupName="emails">
              <app-form-input
                style="flex: 1;"
                [parentForm]="getEmailForm(indexOfItem)"
                fieldName="email"
                title="Email"
                [Wide]="true"></app-form-input>
              <app-icon-button [customIcon]="trashIcon" (onClick)="onRemoveEmail(indexOfItem)"></app-icon-button>
            </div>
          </form>
        </div>

        <div style="display: flex; gap: 10px; align-self: flex-end;">
          <custom-button
            [forceCapitalization]="true"
            label="CANCEL"
            [disabled]="uploadLoading || emailSaveDisabled === true"
            (onClick)="this.openModal = false"></custom-button>
          <custom-button
            [forceCapitalization]="true"
            label="UPLOAD FILE"
            [IsLoading]="uploadLoading"
            [disabled]="uploadLoading || emailSaveDisabled === true"
            (onClick)="onUploadFile()"></custom-button>
        </div>
      </div>
    </app-modal>
  `,
})
export class ManageEligibilityUploadXmlExcelComponent implements OnInit {
  addIcon = faPlus;
  trashIcon = faTrash;
  eligibilityForm: FormGroup<any>;
  email: string = 'test';
  userId: string;
  employerId: string;
  uploadLoading: boolean = false;
  uploadErrorMessage?: string;

  @Input() CardTitle = '';
  @Input() Description = '';

  @Output() onEligibilityFormCreated: EventEmitter<FormGroup>;
  emails$: Observable<IEmailManagementApiResponse>;
  isMasterEligibilityUpload: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService,
    private userViewService: UserViewService,
    private notificationService: NotificationService,
    private emailService: EmailService,
    private cdr: ChangeDetectorRef
  ) {}
  isExcelInterface: boolean = false;
  uploadEligibilityDocument: UploadEligibilityDocument;

  fileUploaded: File | undefined;
  openModal: boolean = false;
  modalProps: ModalProps;
  serverEmails : string[] = [];
  emailSaveDisabled: boolean = false;

  ngOnInit(): void {
    console.log(this.fileUploaded);
    this.modalProps = {
      CloseButton: {
        show: false,
      },
      CanCloseModal: false,
      disableButtons: true,
    };

    this.isExcelInterface = this.route.snapshot.url.findIndex(url => url.path.includes('manage-eligibility-template')) >= 0;

    this.isMasterEligibilityUpload = this.route.snapshot.url.findIndex(url => url.path.includes('manage-master-eligibility-template')) >= 0;

    this.authService.userInfo.subscribe(info => {
      this.email = info?.Email ?? '';
      this.userId = info?.UserID.toString() ?? '';
    });

    this.userViewService.CurrentUserViewState.subscribe(view => {
      this.employerId = view?.EmployerId ?? '';
    });

    this.onEligibilityFormCreated = new EventEmitter();

    this.eligibilityForm = this.fb.group({
      emails: this.fb.array<FormGroup>([]),
      sendEmailAlert: [],
    });

    this.onEligibilityFormCreated.emit(this.eligibilityForm);

    if (this.isExcelInterface)
      this.uploadEligibilityDocument = EligibilityPropsFactory.make('EligibilityExcel');
    else if (this.isMasterEligibilityUpload)
      this.uploadEligibilityDocument = EligibilityPropsFactory.make('EligibilityMaster');
    else
      this.uploadEligibilityDocument = EligibilityPropsFactory.make('EligibilityXML');

    this.emails$ = of();
  }

  onFileDropped(file: FileHandle) {
    let isNotXlsxFile = ['xlsx', 'xls'].filter(extension => file.file.name.includes(extension)).length === 0;
    let isNotXMLFile = ['xml'].filter(extension => file.file.name.includes(extension)).length === 0;
    let isXMLInterface = !this.isExcelInterface && !this.isMasterEligibilityUpload
    if (
        (this.isExcelInterface && isNotXlsxFile) ||
        (this.isMasterEligibilityUpload && isNotXlsxFile) ||
        (isXMLInterface && isNotXMLFile)
    )
    {
      this.uploadErrorMessage = this.uploadEligibilityDocument.DroppedFileErrorMessage;
      this.fileUploaded = undefined;
      return;
    }

    this.uploadErrorMessage = undefined;
    this.fileUploaded = file.file;
  }

  onOpenUpload() {
    //This needs to be refactored urgently in the backend. We need to create a specific endpoint to search for the user and not reuse this one!
    let searchByEmployer = this.isMasterEligibilityUpload ?  '0' : 'null';
    let searchIdRequest = IsStringNullOrEmpty(this.employerId) ? searchByEmployer : this.employerId

    this.emails$ = this.emailService.GetEmailManagements(searchIdRequest).pipe(
      tap(emailsResponse => {
        this.emailsForm.clear();
    
        emailsResponse.Data.forEach(x => {this.onAddEmail(x.Email);this.serverEmails.push(x.Email);});
      })
    );

    this.openModal = true;
  }

  onAddEmail(email: string = '') {
    this.emailsForm.push(this.fb.group({ email: [email, Validators.required] }));

    let  emailForm = this.getEmailForm(this.emailsForm.controls.length-1);

    let changes = emailForm.get('email')?.valueChanges.pipe(distinctUntilChanged());

    changes &&
    changes.subscribe(email => { 
      this.validateEmail(email, emailForm);
    })
    
  }

  validateEmail(email: string, control: FormGroup<any>){

    if(IsStringNullOrEmpty( this.serverEmails.find(x => x.toLowerCase() === email.toLowerCase())) === false ){

      if (this.emailsForm.controls.filter(x => x.get('email')?.value === email).length <= 1)
      {
        return;
      }
    }


    this.emailSaveDisabled = true;

    this.emailService.checkEmailExists(email, parseInt(this.employerId ?? '0')).subscribe(response => {
      if(response.Status === false){
        control.get('email')?.setErrors({customErrorMessage: 'Email already exists.'});
      } else{
        control.get('email')?.setErrors({customErrorMessage: null});
        control.get('email')?.updateValueAndValidity();

      }
      this.emailSaveDisabled = false;


    });

  }

  onRemoveEmail(index: number) {
    this.emailsForm.removeAt(index);
  }

  getEmailForm(index: number) {
    return this.emailsForm.at(index);
  }

  onDownloadEligibilityUploadTemplateSample() {
    this.getEligibilityTemplateFromApi().subscribe(res => {
      saveAs(new Blob([res]), `${this.uploadEligibilityDocument.EligilityTemplateFileName}`);
    });
  }

  onUploadFile() {
    if (this.eligibilityForm.invalid || this.fileUploaded === undefined) {
      this.eligibilityForm.markAllAsTouched();
      return;
    }

    let emails: string[] = this.emailsForm.value.map(email => email.email);

    this.uploadLoading = true;

    this.uploadEligibilityTemplateFile(
      this.fileUploaded,
      this.userId,
      this.eligibilityForm.get('sendEmailAlert')?.value ?? false,
      emails.toString(),
      this.employerId
    ).subscribe(res => {
      if (res.status) this.notificationService.success(res.message, 10000);
      else this.notificationService.error(res.message);
      this.openModal = false;
      this.uploadLoading = false;
    });
  }

  //#region http calls
  uploadEligibilityTemplateFile(
    file: File,
    userId: string,
    receiveEmailAlert: boolean,
    emailsToSend: string,
    employerId: string
  ) {
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('UploadedBy', userId);
    formdata.append('receiveEmailAlert', receiveEmailAlert + '');
    formdata.append('emailsToSend', emailsToSend);
    formdata.append('employerID', employerId);

    return this.http.post<{ status: boolean; message: string }>(
      `${environment.alliedApi.baseUrl}${this.uploadEligibilityDocument.EligibilityUploadUrl}`,
      formdata
    );
  }

  /** This endpoint cannot be abstracted very well. In a future refactoring, create a service */
  getEligibilityTemplateFromApi() {
    return this.http.get(`${environment.alliedApi.baseUrl}${this.uploadEligibilityDocument.EligibilityTemplateUrl}`, {
      responseType: 'arraybuffer',
    });
  }
  //#endregion

  get emailsForm() {
    return this.eligibilityForm.get('emails') as FormArray<FormGroup>;
  }
}

export type UploadEligibilityDocument = {
  CardTitle: string;
  Description: string;
  DownloadTemplateButtonLabel: string;
  EligibilityTemplateUrl: string;
  EligibilityUploadUrl: string;
  EligilityTemplateFileName: string;
  DroppedFileErrorMessage: string;
};

export type UploadEligibilityType = 'EligibilityExcel' | 'EligibilityXML' | 'EligibilityMaster';
