import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from './accordion.component';
import { AccordionItemDirective } from './diretives/accordion-item.directive';
import { AccordionContentDirective } from './diretives/accordion-content.directive';
import { AccordionHeaderDirective } from './diretives/accordion-header.directive';
import { AccordionTitleDirective } from './diretives/accordion-title.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    AccordionItemDirective,
    AccordionContentDirective,
    AccordionHeaderDirective,
    AccordionTitleDirective,
    AccordionComponent,
  ],
  exports: [
    AccordionItemDirective,
    AccordionContentDirective,
    AccordionHeaderDirective,
    AccordionTitleDirective,
    AccordionComponent,
  ],
})
export class AccordionModule {}
