import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { VersionHistoryResponse } from '../version-history.response';
import { Observable, take } from 'rxjs';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';
import { VersionHistoryService } from '../version-history.service';
import { tap } from 'ramda';
import { FileService } from 'src/app/core/services/file.service';
import moment from 'moment';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-view-archived',
  template: `
    <app-content-card CustomTitle="View Archived Versions">
      <div style="flex:1">
        <ng-container *ngIf="archivedVersionsData$ | withLoading | async as archivedData">
          <app-table
            *ngIf="archivedData.value"
            [DisplayedColumns]="displayedColumns"
            [EnablePagination]="true"
            [IsLoading]="false"
            [DownloadButtons]="downloadButtons"
            [Rows]="archivedData.value">
          </app-table>
          <app-table
            *ngIf="archivedData.loading"
            [DisplayedColumns]="displayedColumns"
            [IsLoading]="true"
            [LoadingColumns]="3"></app-table>
        </ng-container>
      </div>
    </app-content-card>

    <ng-template #dateTemplate let-data>
      {{ data | date : 'MM/dd/yyyy' }}
    </ng-template>
    <ng-template #contentTemplate let-data>
      <div ngClass="content-history" [innerHTML]="data">
      <!-- {{ data }} -->
      </div>
      
    </ng-template>

    <ng-template #actionTemplate let-data>
      <div (click)="openModal(data)" style="cursor:pointer" title="View Content">
      <fa-icon [icon]="fileIcon"></fa-icon>
      </div>
    </ng-template>

    <app-modal [open]="showHistoryContent" modalHeader="Archived Version History Content"
    [modalProps]="versionProps">
    <div [innerHTML]="modalHistoryContent"></div>
    </app-modal>
  `,

  styleUrls: ['./view-archived.component.scss'],
})
export class ViewArchivedComponent implements OnInit {
  archivedVersionsData$: Observable<VersionHistoryResponse[]>;
  displayedColumns: DisplayedColumns[];
  downloadButtons: TableDownloadButtons;
  showHistoryContent: boolean = false;
  modalHistoryContent: string;
  fileIcon =  faFile;
  versionProps: ModalProps;

  @ViewChild('dateTemplate', { static: true }) dateTemplate: TemplateRef<any>;
  @ViewChild('contentTemplate', { static: true }) contentTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: TemplateRef<any>;

  constructor(private versionHistoryService: VersionHistoryService, private fileService: FileService) {}

  ngOnInit(): void {

    this.versionProps = {
      OkButton: {
        show: false
      },
      CloseButton:{
        OnAction: () => {
          this.showHistoryContent = false;
        }
      },
      CanCloseModal: true
    }
    


    this.displayedColumns = [
      {columnName: 'Action', label: 'Action', template: this.actionTemplate, overrideValueFromColumn:'Content'},
      { columnName: 'VersionNumber', label: 'Version Number' },
      { columnName: 'Content', label: 'Content', template: this.contentTemplate },
      { columnName: 'CreateDate', label: 'Created Date', template: this.dateTemplate },
      { columnName: 'ArchivedDate', label: 'Archived Date', template: this.dateTemplate },
    ];

    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              VersionNumber: 'Version Number',
              Content: 'Content',
              CreateDate: 'Created Date',
              ArchivedDate: 'Archived Date',
            },
          ];
          this.archivedVersionsData$.pipe(take(1)).subscribe(_ => {
            this.fileService
              .GeneratePDF('Allied Administrators - Archived Versions', header, this.mapDataToDictionary(_))
              .save('Allied Administrators - Archived Versions');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          let header = ['Version Number', 'Content', 'Created Date', 'Archived Date'];
          this.archivedVersionsData$.pipe(take(1)).subscribe(_ => {
            this.fileService.GenerateXLSX(
              'Allied Administrators - Archived Versions',
              this.mapDataToDictionary(_),
              header
            );
          });
        },
      },
    };

    this.loadData();
  }

  openModal(content: any){
    this.modalHistoryContent = content;
    this.showHistoryContent = true;
  }

  loadData() {
    this.archivedVersionsData$ = this.versionHistoryService.getArchivedVersions().pipe(tap(res => {}));
  }

  mapDataToDictionary(data: VersionHistoryResponse[]) {
    return data.map(_ => ({
      VersionNumber: _.VersionNumber,
      Content: _.Content,
      CreateDate: moment(_.CreateDate).format('MM/DD/YYYY'),
      ArchivedDate: moment(_.ArchivedDate).format('MM/DD/YYYY'),
    }));
  }
}
