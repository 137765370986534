import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SubProducersResponse } from "../../models/api/user/sub-producers.response";
import { environment } from "src/environments/environment";
import { ProducerUserData } from "../../models/api/user/mp-all-users.response";
import { SubProducerGroup } from "../../models/api/sub-producers/sub-producer-groups.response";
import { SubProducerGroupsRequest } from "../../models/api/sub-producers/sub-producer-groups.request";

@Injectable({
    providedIn: 'root',
  })
  export class SubProducerService {
    constructor(private http: HttpClient){}




    GetRequestedAcessSubProducers(masterProducerId: number){
        return this.http.get<SubProducersResponse[]>
        (`${environment.alliedApi.baseUrl}/subproducers/getRequestedAcess/${masterProducerId}`)
    }

    GetRequestedAcessById(id: number){
      return this.http.get<SubProducersResponse>
        (`${environment.alliedApi.baseUrl}/subproducers/GetAccess/${id}`)
    }

    RequestSubProducerAccess(firstName: string, lastName: string, email: string, phone: string, masterProducerId: string){

      let input = {
        firstName,
        lastName,
        email,
        phone,
        masterProducerId
      }

      return this.http.post(`${environment.alliedApi.baseUrl}/subproducers/RequestAccess`,input);
    }


    GetSubProducers(masterProducerId: number){
      return this.http.get<ProducerUserData[]>
        (`${environment.alliedApi.baseUrl}/subproducers/GetSubProducers/${masterProducerId}`)
    }

    GetSubProducerGroups(userId: number)
    {
      return this.http.get<SubProducerGroup[]>
        (`${environment.alliedApi.baseUrl}/subproducers/GetGroups/${userId}`)
    }

    UpdateSubProducerGroups(payload: SubProducerGroupsRequest)
    {
      return this.http.post(`${environment.alliedApi.baseUrl}/subproducers/updateGroups`,payload);
    }

    IsUserAllowedToSeeCommissionStatements(){
      return this.http.get<boolean>
        (`${environment.alliedApi.baseUrl}/Userctrl/AllowedToSeeCommissionStatements/`)
    }

    UpdateSubProducerCommissionStatements(userId: number)
    {
      return this.http.post(`${environment.alliedApi.baseUrl}/subproducers/UpdateCommissionStatements`,userId);
      
    }

    UpdateSubProducerStatus(userId: number, disabled: boolean)
    {
      let input = {
        UserId: userId,
        Disabled: disabled
      }

      return this.http.post(`${environment.alliedApi.baseUrl}/subproducers/UpdateStatus`,input);
    }
    
  }