import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable, debounceTime, map, skip, take, tap } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { EmailLog, EmailLogApiResponse } from 'src/app/core/models/api/email/email-log.response';
import { FileService } from 'src/app/core/services/file.service';
import { EmailService } from 'src/app/core/services/http/email.service';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-email-log',
  styleUrls: ['./email-log.component.scss'],
  template: `
    <app-content-card CustomTitle="Email Log">
      <ng-container *ngIf="row$ | withLoading | async as rows">
        <app-table
          (onSearchEvent)="searchText.next($event)"
          (onSort)="onSort($event)"
          [searchedText]="searchText.value || ''"
          (PaginationEvent)="onPagination($event)"
          [DisplayedColumns]="displayedColumns"
          [EnablePagination]="true"
          [Pagination]="pagination"
          [EnableSearch]="true"
          [Rows]="rows.value?.Data?.data"
          [DownloadButtons]="downloadButtons"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #emailLinkTemplate let-data>
      <a ngClass="table_cell_link" (click)="openContentModal(data.Message)">{{ data.MailTo }}</a>
    </ng-template>
    <ng-template #dateColumnTemplate let-data>
      {{ formatDateField(data) }}
    </ng-template>

    <app-modal [modalProps]="modalProps" [open]="contentModalToggle">
      <div style="padding: 10px;" [innerHTML]="actualEmailContent.value"></div>
    </app-modal>
  `,
})
export class EmailLogComponent implements OnInit {
  // Table Controls
  displayedColumns: DisplayedColumns[];
  row$: Observable<EmailLogApiResponse>;
  pagination: ManualPagination;
  downloadButtons: TableDownloadButtons;
  searchText: BehaviorSubject<string | null>;
  sortColumn?: string;
  sortDirection: 'asc' | 'desc';

  actualEmailContent: BehaviorSubject<string | null>;
  contentModalToggle: boolean;
  modalProps: ModalProps = {
    CloseButton: {
      OnAction: () => (this.contentModalToggle = !this.contentModalToggle),
    },
    disableButtons: true,
  };

  @ViewChild('emailLinkTemplate', { static: true }) emailLinkTemplate: TemplateRef<unknown>;
  @ViewChild('dateColumnTemplate', { static: true }) dateColumnTemplate: TemplateRef<unknown>;

  constructor(private emailService: EmailService, private fileService: FileService) {}

  ngOnInit(): void {
    this.actualEmailContent = new BehaviorSubject<string | null>(null);
    this.searchText = new BehaviorSubject<string | null>(null);

    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              CreatedDt: 'CreatedDt',
              MailTo: 'MailTo',
              EmployerNr: 'EmployerNr',
              Subject: 'Subject',
            },
          ];
          this.row$.pipe(take(1)).subscribe(_ => {
            this.fileService
              .GeneratePDF('Allied Administrators - Email log', header, this.mapRowToDictionary(_.Data.data))
              .save('Allied Administrators - Email log');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.row$.pipe(take(1)).subscribe(_ => {
            this.fileService.GenerateXLSX('Allied Administrators - Email log', this.mapRowToDictionary(_.Data.data));
          });
        },
      },
    };

    this.displayedColumns = [
      { columnName: 'CreatedDt', label: 'Date', template: this.dateColumnTemplate, sortable: true },
      {
        columnName: 'MailTo',
        label: 'To Email',
        template: this.emailLinkTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      },
      { columnName: 'EmployerNr', label: 'Employer Number', sortable: true },
      { columnName: 'Subject', label: 'Type of Email' },
    ];

    this.searchText.subscribe(searchedText => {
      this.row$ = this.emailService
        .GetEmails(0, 10, undefined, searchedText ?? '', this.sortDirection, this.sortColumn)
        .pipe(
          map(res => {
            this.pagination = { Total: res.Data.recordsFiltered, ActualPage: 1, Index: 0, PageSize: 10 };
            return res;
          })
        );
    });
  }

  onSort(sort: Sort) {
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction === 'asc' ? 'asc' : 'desc';

    this.row$ = this.emailService.GetEmails(
      this.pagination.Index,
      this.pagination.PageSize,
      undefined,
      this.searchText.value ?? '',
      this.sortDirection,
      this.sortColumn
    );
  }

  openContentModal(content: string) {
    this.actualEmailContent.next(content);
    this.contentModalToggle = !this.contentModalToggle;
  }

  onPagination(pageEvent: PageEvent) {
    this.row$ = this.emailService
      .GetEmails(
        pageEvent.pageIndex,
        pageEvent.pageSize,
        undefined,
        this.searchText.value ?? '',
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        debounceTime(500),
        tap(res => {
          if (this.pagination)
            this.pagination = {
              Index: pageEvent.pageIndex,
              ActualPage: pageEvent.pageIndex,
              Total: pageEvent.length,
              PageSize: pageEvent.pageSize,
            };
        })
      );
  }

  formatDateField(date: Date) {
    return formatDate(date);
  }

  private mapRowToDictionary(emailLogs: EmailLog[]) {
    return emailLogs.map(emailLog => ({
      CreatedDt: emailLog.CreatedDt,
      MailTo: emailLog.MailTo,
      EmployerNr: emailLog.EmployerNr,
      Subject: emailLog.Subject,
    }));
  }
}
