import { AbstractControl, ValidationErrors } from '@angular/forms';

export default function HireDateGlobalValidator(control: AbstractControl): ValidationErrors | null {
  // let firstName = control.get(['info', 'firstName']);
  // firstName?.setErrors({ customErrorMessage: 'Teste error' });
  // firstName?.markAsTouched();

  // if (firstName?.value === 'caraca') {
  //   delete firstName.errors?.customErrorMessage;
  //   firstName.updateValueAndValidity()
  // }
  // let hireDate = control.get('info')?.get('hireDate')
  // console.log(hireDate)
  // control.setErrors({ plansError: 'Must be active....' });
  // return { plansError: 'Must be active....' };
  return null;
}
