import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class JwtTokenService {
  jwtToken?: string;
  decodedToken?: { [key: string]: string };

  constructor() {}

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
      this.decodeToken();
    }
  }

  private decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
    }
  }

  getDecodeToken(): string {
    return jwt_decode(this.jwtToken ?? '');
  }

  getExpiryTime(): Number {
    this.decodeToken();
    return this.decodedToken ? Number(this.decodedToken['exp']) : 0;
  }

  isTokenExpired(): boolean {
    const expiryTime: Number = this.getExpiryTime();
    if (expiryTime) {
      return 1000 * expiryTime.valueOf() - new Date().getTime() < 5000;
    } else {
      return false;
    }
  }
}
