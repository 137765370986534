import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubProducerService } from 'src/app/core/services/http/sub-producer.service';

@Component({
  selector: 'app-add-edit-sub-producer-user',
  template: `<app-add-edit-producer-user *ngIf="loading === false"
  [isSubProducer]="true" 
  [subProducerFirstName] = "firstName"
  [subProducerLastName] = "lastName"
  [subProducerEmail] = "email"
  [subProducerPhone] = "phone"
  [subProducerAccessId] = "subProducerAccessId"
  > </app-add-edit-producer-user>`,
  styleUrls:['add-edit-sub-producer-user.component.scss']
})
export class AddEditSubProducerUserComponent implements OnInit {

  loading: boolean = false;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  subProducerAccessId: number;

  constructor(
    private subProducerService: SubProducerService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {

    this.loading = true;

    this.subProducerAccessId = Number(this.route.snapshot.paramMap.get('id'));
    this.subProducerService.GetRequestedAcessById(this.subProducerAccessId).subscribe(response => {
      this.loading = false;

      this.firstName = response.FirstName;
      this.lastName = response.LastName;
      this.email = response.Email;
      this.phone = response.Phone;

    })

  }

}
