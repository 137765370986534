import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  DashboardEmailData,
  DashboardEmailResponse,
  DashboardsEmailResponse,
} from '../../models/api/dashboard.email.response';
import { EmailsResponse } from '../../models/api/email/emails-template.response';
import { EmailLogRequest } from '../../models/api/email/email-log.request';
import { SmtpConfigurationApiResponse } from '../../models/api/smtp-configuration/smtp-configuration.response';
import { SmtpConfiguration } from '../../models/api/smtp-configuration/stmp-configuration';
import { ManageEmailApiResponse } from '../../models/api/email/manage-email.response';
import { EmailLogApiResponse } from '../../models/api/email/email-log.response';
import { IEmailManagementApiResponse } from '../../models/api/email/email-management.response';
import { EmailTemplateApiResponse, EmailTemplateData } from '../../models/api/email/email-template.response';
import { ManageContentPictureApiResponse } from '../../models/manage-content-picture.response';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private http: HttpClient) {}

  GetDashboards() {
    return this.http.get<DashboardsEmailResponse>(`${environment.alliedApi.baseUrl}/emails/dashboards`);
  }

  
  GetContentPictures(){
    return this.http.get<ManageContentPictureApiResponse>(`${environment.alliedApi.baseUrl}/emails/pictures`);
  }

  DownloadContentPicture(pictureId: string) {
    return this.http.get(`${environment.alliedApi.baseUrl}/emails/pictures/${pictureId}`, {
      responseType: 'arraybuffer',
    }); 
  }

  ActivePicture(pictureId: string) { 
    return this.http.put(`${environment.alliedApi.baseUrl}/emails/pictures/activate/${pictureId}`, {})
  }

  UploadPicture(file: Blob) {
    var formdata = new FormData();
    formdata.append('file', file);
    return this.http.post<{ Status: boolean; Message: string }>(`${environment.alliedApi.baseUrl}/emails/pictures`, formdata);

  }

  DeletePicture(pictureId: string){
    return this.http.delete(`${environment.alliedApi.baseUrl}/emails/pictures/${pictureId}`); 
  }

  GetDashboard(id: number) {
    return this.http.get<DashboardEmailResponse>(`${environment.alliedApi.baseUrl}/emails/dashboards/${id}`);
  }

  GetActivePicture(){
    return this.http.get<{Data: string, Message: string}>(`${environment.alliedApi.baseUrl}/emails/pictures/active`);
  }

  CreateOrUpdateDashboard(dashboardData: DashboardEmailData) {
    return this.http.put<DashboardEmailResponse>(`${environment.alliedApi.baseUrl}/emails/dashboards`, dashboardData);
  }

  GetSmtpConfiguration() {
    return this.http.get<SmtpConfigurationApiResponse>(`${environment.alliedApi.baseUrl}/emails/smtpconfiguration`);
  }

  UpdateSmtpConfiguration(smtpConfiguration: SmtpConfiguration) {
    return this.http.post<SmtpConfigurationApiResponse>(
      `${environment.alliedApi.baseUrl}/emails/smtpconfiguration`,
      smtpConfiguration
    );
  }

  GetEmailsTemplates() {
    return this.http.get<EmailsResponse>(`${environment.alliedApi.baseUrl}/emails/templates`);
  }

  GetEmailTemplate(id: number) {
    return this.http.get<EmailTemplateApiResponse>(`${environment.alliedApi.baseUrl}/emails/template/${id}`);
  }

  CreateOrUpdateEmailTemplate(emailTemplateData: EmailTemplateData) {
    return this.http.post<EmailTemplateApiResponse>(
      `${environment.alliedApi.baseUrl}/emails/emailtemplate`,
      emailTemplateData
    );
  }

  //#eligibilityRegion
  CreateOrUpdateEmailManagement(request: {
    UserName: string;
    Email: string;
    EmployerId: number;
    EmailManagementId?: string;
  }) {
    if (request.EmailManagementId)
      return this.http.put<{
        Message: string;
        Status: boolean;
        Errors: string[];
      }>(`${environment.alliedApi.baseUrl}/emailmanagements`, request);

    return this.http.post<{
      Message: string;
      Status: boolean;
      Errors: string[];
    }>(`${environment.alliedApi.baseUrl}/emailmanagements`, request);
  }

  checkEmailExists(email: string, employerId: number){
    return this.http.get<IEmailManagementApiResponse>(`${environment.alliedApi.baseUrl}/emailmanagements/exists/${email}/${employerId}`)
  }

  GetEmailManagements(employerId: string) {
    return this.http.get<IEmailManagementApiResponse>(
      `${environment.alliedApi.baseUrl}/emailmanagements/employers/${employerId}`
    );
  }

  DeleteEmailManagements(emailManagementId: string) {
    return this.http.delete<{ Status: boolean; Message: string }>(
      `${environment.alliedApi.baseUrl}/emailmanagements/${emailManagementId}`
    );
  }

  GetEligibilityEmailsManagementByEmployer(employerId: string) {
    return this.http.get<ManageEmailApiResponse>(
      `${environment.alliedApi.baseUrl}/emailmanagements/employers/${employerId}`
    );
  }

  //#endeligibilityRegion

  GetEmailsManagement() {
    return this.http.get<ManageEmailApiResponse>(`${environment.alliedApi.baseUrl}/emails/manageemails`);
  }

  GetEmails(
    page: number,
    pageSize: number,
    statusFilter: number = 0,
    search: string = '',
    orderBy: 'asc' | 'desc' = 'desc',
    orderByColumnName: string = 'CreatedDt'
  ) {
    return this.http.post<EmailLogApiResponse>(
      `${environment.alliedApi.baseUrl}/emails/searchemaillogs`,
      new EmailLogRequest({
        orderByColumnName: orderByColumnName,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search,
        orderBy: orderBy,
        statusFilter: statusFilter,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }
}
