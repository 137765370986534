<!-- This page name does not make any sense -->
<app-content-card CustomTitle="Dashboard">
  <div ngClass="wrapper">
    <div ngClass="inner old_front_end_styles" *ngIf="staticPageAsync$ | withLoading | async as staticPage">
      <app-skeleton-input style="width: 100%;" *ngIf="staticPage.loading"></app-skeleton-input>
      <div style="padding: 30px" *ngIf="staticPage.value" [innerHtml]="staticPage.value.producerDashboard"></div>
      <div *ngIf="staticPage.value && staticPage.value?.absNewsFeed">
        <h3>Allied Benefit Suite</h3>
        <hr>
        <div [innerHtml]="staticPage.value.absNewsFeed"></div>
      </div>
    </div>
    <div *ngIf="staticPageAsync$ | withLoading | async as staticPage">
      <img style="margin: 0px -10px -14px -10px; width: calc(100% + 20px)" src="assets/img/IMG_2611.jpg" *ngIf="staticPage.value"/>
    </div>
  </div>
</app-content-card>
