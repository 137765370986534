import { RegisterBaseRequest } from './register-base.request.model';

export class RegisterEmployerRequest extends RegisterBaseRequest {
  employer_num: string;
  state: string;

  constructor(
    firstname: string,
    lastname: string,
    email: string,
    companyname: string,
    employer_Num: string,
    state: string
  ) {
    super('Employer', firstname, lastname, email, companyname);

    this.employer_num = employer_Num;
    this.state = state;
  }
}
