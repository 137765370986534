import { view } from 'ramda';
import { AuthorizedUserView } from '../models/user-view.model';

export class ViewConfigurationBuilder {
  private views: AuthorizedUserView[] = [];

  withView(authorizedView: AuthorizedUserView) {
    this.views.push(authorizedView);
    return this;
  }

  build() {
    return this.views;
  }
}
