import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import {
  faKey,
  faUser,
  faUserCheck,
  faEnvelope,
  faPhone,
  faFloppyDisk,
  faClockRotateLeft,
  faFlag,
} from '@fortawesome/free-solid-svg-icons';
import { Observable, of, tap } from 'rxjs';
import Regions from 'src/app/core/common/Regions';
import {
  GeneralAgent,
  GeneralAgentApiResponse,
  StateListView,
} from 'src/app/core/models/api/general-agent/general-agent.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { GeneralAgentsService } from 'src/app/core/services/http/general-agents.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';

@Component({
  selector: 'app-add-edit-general-agent-user',
  styleUrls: ['./add-edit-general-agent-user.component.scss'],
  template: `
    <app-content-card CustomTitle="GA Profile">
      <form [formGroup]="generalAgentForm" ngClass="wrapper" *ngIf="agentData$ | withLoading | async as gaData">
        <div ngClass="form">
          <app-form-input
            type="text"
            [Icon]="personIcon"
            [loading]="gaData.loading ?? false"
            [primary]="true"
            title="Name"
            [Wide]="true"
            placeholder="Name"
            formControlName="name"
            fieldName="name"
            [parentForm]="generalAgentForm"></app-form-input>
          <app-form-input
            [Icon]="personIcon"
            [loading]="gaData.loading ?? false"
            [primary]="true"
            title="Ga Number"
            [Wide]="true"
            placeholder="Ga Number"
            fieldName="gaNumber"
            formControlName="gaNumber"
            [parentForm]="generalAgentForm"></app-form-input>
          <app-form-select
            type="text"
            [icon]="flagIcon"
            *ngIf="gaData.value"
            [Options]="regionOptions"
            title="Region"
            Label="Region"
            FieldName="region"
            formControlName="region"
            [parentForm]="generalAgentForm"></app-form-select>
          <app-form-checkbox
            *ngIf="gaData.value"
            name="ABS"
            title="ABS"
            placeholder="abs"
            fieldName="abs"
            formControlName="abs"
            [parentForm]="generalAgentForm"></app-form-checkbox>
        </div>

        <div *ngIf="gaData.value">
          Select the state(s) associated with this GA:
          <app-form-checkbox
            *ngFor="let item of stateForm.controls; let indexOfItem = index"
            [name]="stateForm.at(indexOfItem).get('name')?.value"
            title="State"
            placeholder="state"
            fieldName="state"
            formGroupName="states"
            [parentForm]="stateForm.at(indexOfItem)"></app-form-checkbox>
        </div>
        <div style="display: flex; gap: 10px; align-self: flex-end;">
          <custom-button
            [forceCapitalization]="true"
            label="Back"
            [routerLink]="ManageGAUrl"
            color="secondary"></custom-button>
          <custom-button
            [forceCapitalization]="true"
            *ngIf="gaData.value && !IsViewing"
            (onClick)="onSave(gaData.value.Data)"
            label="Save"></custom-button>
        </div>
      </form>
    </app-content-card>
  `,
})
export class AddEditGeneralAgentUserComponent implements OnInit {
  generalAgentForm: FormGroup<any>;

  personIcon = faUser;
  flagIcon = faFlag;

  regionOptions: SelectOptions<string>[] = [];
  regionState: RegionStates[] = [];
  IsViewing: boolean;

  agentData$: Observable<GeneralAgentApiResponse>;

  ManageGAUrl = RouteEnum.ManageGA;
  gaId: string | null;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private generalAgentService: GeneralAgentsService,
    private notificationService: NotificationService
  ) {}

  get stateForm() {
    return this.generalAgentForm.get('states') as FormArray<FormGroup>;
  }

  ngOnInit(): void {
    this.IsViewing = this.route.snapshot.url.findIndex(url => url.path.includes('view')) >= 0;
    this.gaId = this.route.snapshot.paramMap.get('id');

    this.initForm();
    this.agentData$ = this.gaId
      ? this.generalAgentService.GetGeneralAgent(Number(this.gaId)).pipe(
          tap(res => {
            this.createRegionOptions(res);
            this.createRegionStateRelation(res);

            this.initForm({
              abs: res.Data.AbsnewsFeed,
              gaNumber: res.Data.Ganr,
              name: res.Data.Ganame,
              region: res.Data.Garegion,
              states: [],
            } as GaForm);
            this.onRegionChange();
            this.generalAgentForm.get('region')?.updateValueAndValidity();
            this.IsViewing && this.generalAgentForm.disable();
          })
        )
      : of({} as GeneralAgentApiResponse);
  }

  onSave(gaData: GeneralAgent) {
    let {
      abs,
      name,
      region,
      states,
    }: { abs: boolean; name: string; region: string; states: { name: string; state: boolean }[] } =
      this.generalAgentForm.value;

    let gaNumber = this.generalAgentForm.controls['gaNumber'].value;

    let request = {
      ...gaData,
      AbsnewsFeed: abs,
      Gaid: parseInt(this.gaId ?? '0'),
      Garegion: region,
      Ganame: name,
      Ganr: gaNumber,
      StateListView: gaData.StateListView.map(state => {
        let foundState = states.find(formState => state.Name === formState.name);
        if (foundState) {
          state.Status = foundState.state;
        }
        return state;
      }),
    } as GeneralAgent;

    this.generalAgentService.UpsertGeneralAgent(request).subscribe(res => {
      if (res.Status) {
        this.notificationService.success(res.Message);
        this.router.navigateByUrl(RouteEnum.ManageGA);
      } else this.notificationService.error(res.Message);
    });
  }

  private onRegionChange() {
    this.generalAgentForm.get('region')?.valueChanges.subscribe(region => {
      this.stateForm.clear();

      let foundRegionState = this.regionState.find(regionState => regionState.region === region);

      foundRegionState &&
        foundRegionState.states.forEach(state =>
          this.stateForm.push(this.fb.group({ name: state.Name, state: state.Status }))
        );
    });
  }

  private createRegionOptions(res: GeneralAgentApiResponse) {
    this.regionOptions = res.Data.StateListView.map(state => state.Region) // Map only region
      .filter((region, position, self) => self.indexOf(region) === position) // Removes duplicated
      .filter(region => region) // Return only filled fields
      .map<SelectOptions<string>>(region => ({
        key: region ?? '',
        optionText: region ?? '',
      }));
  }

  private createRegionStateRelation(res: GeneralAgentApiResponse) {
    this.regionOptions.forEach(region => {
      let foundStates = res.Data.StateListView.filter(state => state.Region === region.key);
      this.regionState.push({ region: region.key, states: foundStates });
    });
  }

  private initForm(form: GaForm = { states: [] }) {
    let states = this.fb.array<FormGroup>([]);
    form.states?.forEach(state => states.push(this.fb.group({ state: state.state, name: state.name })));

    this.generalAgentForm = this.fb.group({
      name: [form.name],
      region: [form.region],
      gaNumber: [{value: form.gaNumber, disabled: true } ],
      abs: [form.abs],
      states: states,
    });
  }
}

type RegionStates = {
  region: string;
  states: StateListView[];
};

type GaForm = {
  name?: string;
  region?: string;
  gaNumber?: string;
  abs?: boolean;
  states?: {
    state: string;
    name: string;
  }[];
};
