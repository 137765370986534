import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faCircleNotch, faFile, faRotateLeft, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FileHandle } from '../../directives/dragDrop.directive';

@Component({
  selector: 'app-drag-drop-download',
  styleUrls: ['./drag-drop-download.component.scss'],
  template: `
    <div appDrag (files)="filesDropped($event)" ngClass="drag_drop_wrapper">
      <div *ngIf="isLoading; else loaded" ngClass="flex">
        <fa-icon [icon]="loadingIcon" [spin]="true" size="2x"></fa-icon>
        <span>Uploading</span>
      </div>
      <ng-template #loaded>
        <div *ngIf="!files.length">
          <div ngClass="flex" style="color:red" *ngIf="hasError()">Click or drag and drop a file here.</div>
          <div ngClass="flex" *ngIf="!hasError()">Click or drag and drop a file here.</div>
        </div>
        <div *ngIf="files.length" ngClass="flex">
          <fa-icon [icon]="fileIcon" size="2x"></fa-icon>
          <span *ngFor="let item of files">{{ item.file.name }}</span>
        </div>
        <label for="upload" ngClass="input_upload">
          <input type="file" id="upload" style="display:none" (change)="onUploadFromInput($event)" />
        </label>
      </ng-template>
    </div>
    <div style="color:red" *ngIf="hasError()">{{ errorMessage }}</div>
  `,
})
export class DragDropDownloadComponent implements OnInit {
  fileIcon = faFile;
  loadingIcon = faCircleNotch;
  isLoading: boolean = false;
  files: FileHandle[] = [];

  @Input() multipleFiles: boolean = false;
  @Input() file: FileHandle;
  @Input() errorMessage?: string;

  @Output() onFileDropped: EventEmitter<FileHandle> = new EventEmitter<FileHandle>();

  constructor(private sanitizer: DomSanitizer) {}

  filesDropped(files: FileHandle[]): void {
    this.files = files;
    this.onFileDropped.emit(this.files[0]);
  }

  hasError(): boolean {
    return this.errorMessage !== undefined && this.errorMessage !== null && this.errorMessage !== '';
  }
  onUploadFromInput(event: any) {
    const file: File = event.target.files[0];
    const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
    if (file) {
      if (this.multipleFiles) this.files.push({ file, url });
      else this.files = [{ file, url }];
    }
    this.onFileDropped.emit(this.files[0]); // Refactor to emit multiple files.
  }
  ngOnInit() {
    this.file && this.files.push(this.file);
  }
}
