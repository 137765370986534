import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faEnvelope, faKey, faPhone, faRotate, faUser } from '@fortawesome/free-solid-svg-icons';
import randomatic from 'randomatic';

@Component({
  selector: 'app-user-info-form',
  styleUrls: ['./user-info-form.component.scss'],
  template: `
    <form ngClass="form_wrapper" [formGroup]="userForm">
      <app-form-input
        type="text"
        style="flex: 1"
        [primary]="true"
        [Icon]="personIcon"
        [loading]="isLoading"
        title="First Name"
        [Wide]="true"
        placeholder="firstName"
        formControlName="firstName"
        [parentForm]="userForm"
        fieldName="firstName"></app-form-input>
      <app-form-input
        type="text"
        style="flex: 1"
        [primary]="true"
        [Icon]="personIcon"
        [loading]="isLoading"
        title="Last Name"
        [Wide]="true"
        placeholder="lastName"
        formControlName="lastName"
        [parentForm]="userForm"
        fieldName="lastName"></app-form-input>
      <app-form-input
        type="text"
        [Icon]="personIcon"
        [loading]="isLoading"
        [primary]="true"
        title="User Name"
        [Wide]="true"
        placeholder="userName"
        formControlName="userName"
        [disableAutoFill]="true"
        [parentForm]="userForm"
        fieldName="userName"></app-form-input>

      <!--#region mp field  -->
      <app-form-input
        *ngIf="hasMpField"
        type="text"
        [primary]="true"
        [loading]="isLoading"
        title="Master Producer Name"
        inputmode="numeric"
        [Wide]="true"
        placeholder="masterProducerName"
        formControlName="masterProducerName"
        [parentForm]="userForm"
        fieldName="masterProducerName"></app-form-input>

      <div *ngIf="!hasMpField && !hasPasswordField && !hasBaNameField"></div>

      <div *ngIf="hasPasswordField">
        <div style="display: flex; gap: 10px; align-items: center;">
          <app-form-input
            type="password"
            style="flex: 1"
            [primary]="true"
            [loading]="isLoading"
            title="Password"
            [enableVisibilityToggle]="true"
            [Icon]="keyIcon"
            [Wide]="true"
            placeholder="password"
            [disableAutoFill]="true"
            formControlName="password"
            [parentForm]="userForm"
            fieldName="password"></app-form-input>
          <a *ngIf="userForm.value" (click)="onGeneratePassword()" title="Generate Password" style="cursor: pointer;"
            ><fa-icon [icon]="generatePWIcon"></fa-icon
          ></a>
        </div>
        <span style="font-size: 12px;">
          * Password must have one uppercase, one lowercase,8 characters, 1 number and/or special character.
        </span>
      </div>
      <!--#endregion -->

      <app-form-input
        *ngIf="hasBaNameField"
        type="text"
        [primary]="true"
        [loading]="isLoading"
        [Icon]="personIcon"
        title="BA Name"
        inputmode="numeric"
        [Wide]="true"
        placeholder="Ba Name"
        formControlName="baName"
        [parentForm]="userForm"
        fieldName="baName"></app-form-input>

      <app-form-input
        type="text"
        [primary]="true"
        [loading]="isLoading"
        title="Email"
        [Icon]="emailIcon"
        [Wide]="true"
        placeholder="email"
        formControlName="email"
        [parentForm]="userForm"
        fieldName="email"></app-form-input>

      <app-form-input
        type="text"
        [primary]="true"
        [loading]="isLoading"
        title="Phone Number"
        [Icon]="phoneIcon"
        inputmode="numeric"
        [Wide]="true"
        mask="(000) 000-0000"
        placeholder="phoneNumber"
        formControlName="phoneNumber"
        [parentForm]="userForm"
        fieldName="phoneNumber"></app-form-input>

      <app-form-checkbox
        *ngIf="!isLoading"
        [parentForm]="userForm"
        fieldName="disabled"
        name="Disabled"></app-form-checkbox>

      <app-form-checkbox
        *ngIf="!isLoading && hasMFA"
        [parentForm]="userForm"
        name="Two Factor Authentication (2FA)"
        fieldName="twoFactor"></app-form-checkbox>

      <app-form-checkbox
        *ngIf="!isLoading && isErUser"
        [parentForm]="userForm"
        name="Manage Eligibility"
        fieldName="manageEligibility"></app-form-checkbox>

      <app-form-checkbox *ngIf="isSubProducer === true"
        [parentForm]="userForm"
        name="Commission Statements"
        fieldName="allowedToSeeCommissionStatements"></app-form-checkbox>
    </form>
  `,
})
export class UserInfoFormComponent implements OnInit {
  personIcon = faUser;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;
  keyIcon = faKey;
  generatePWIcon = faRotate;

  @Input() userForm: FormGroup;
  @Input() isLoading: boolean = false;
  @Input() hasMpField: boolean = false;
  @Input() hasPasswordField: boolean = false;
  @Input() hasBaNameField: boolean = false;
  @Input() hasMFA: boolean = true
  @Input() isSubProducer: boolean = false;
  @Input() isErUser: boolean = false;

  constructor() {}

  ngOnInit() {}

  onGeneratePassword() {
    this.userForm.get('password')?.patchValue(randomatic('Aa0!', 10));
  }
}
