import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { StaticPage } from 'src/app/core/helpers/view-helper';
import { DashboardEmailResponse } from 'src/app/core/models/api/dashboard.email.response';
import { EmailService } from 'src/app/core/services/http/email.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss', '../old-front-end-styles.scss'],
})
export class LinksComponent implements OnInit {
  staticPageAsync$: Observable<DashboardEmailResponse>;

  @ViewChild('content') contentHtml: ElementRef;

  constructor(private emailService: EmailService, private viewService: UserViewService) {}

  ngOnInit() {
    let id = this.viewService.GetUserStaticPageIdByName(StaticPage.Links);
    if (!id) return;
    this.staticPageAsync$ = this.emailService.GetDashboard(id);
  }
}
