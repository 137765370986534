import { Component, Input, OnInit } from '@angular/core';
import { ApexChart } from 'ng-apexcharts';

@Component({
  selector: 'app-line-chart',
  styleUrls: ['./line-chart.component.scss'],
  template: `
    <apx-chart
      [series]="optionsLine.series"
      [chart]="optionsLine.chart"
      [title]="optionsLine.title"
      [xaxis]="optionsLine.xaxis"
      [yaxis]="optionsLine.yaxis"
      [legend]="optionsLine.legend"
      [fill]="optionsLine.fill"></apx-chart>
  `,
})
export class LineChartComponent implements OnInit {
  optionsLine: any;

  @Input() title: string;

  /** Needs to match the Apex Series pattern, see documentation https://apexcharts.com/docs/series/*/
  @Input() series: any[];

  constructor() {}

  ngOnInit(): void {
    this.optionsLine = {
      chart: {
        height: 340,
        type: 'line',
        zoom: {
          enabled: false,
        },
      } as ApexChart,
      plotOptions: {
        stroke: {
          width: 4,
          curve: 'smooth',
        },
      },
      series: this.series,
      title: {
        floating: false,
        text: this.title,
        style: {
          fontSize: '18px',
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        labels: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        tickAmount: 2,
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        min: 0,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetY: -20,
        offsetX: -30,
      },
    };
  }
}
