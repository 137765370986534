import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faFloppyDisk, faKey, faRotate } from '@fortawesome/free-solid-svg-icons';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-password-modal',
  styleUrls: ['./password-modal.component.scss', '../../styles.scss'],
  template: `
    <form [formGroup]="userForm">
      <app-modal [modalProps]="modalProps" [open]="showResetPasswordModal" modalHeader="Reset or Change Password">
        <div ngClass="password_modal_wrapper">
          <div style="padding: 30px;">
            <div style="display: flex; gap: 10px; align-items: center">
              <app-form-input
                type="password"
                style="flex: 1"
                [primary]="true"
                title="Password"
                [enableVisibilityToggle]="true"
                [Icon]="keyIcon"
                [Wide]="true"
                placeholder="password"
                formControlName="password"
                [parentForm]="userForm"
                fieldName="password"></app-form-input>

              <custom-button
                [forceCapitalization]="true"
                label="GENERATE PASSWORD"
                alignItens="center"
                [icon]="generatePWIcon"
                (onClick)="onGeneratePassword.emit()"></custom-button>
            </div>

            <span style="padding: 10px;"
              >* Password must have one uppercase, one lowercase, 8 characters, 1 number and/or special character.</span
            >
          </div>

          <custom-button
            [forceCapitalization]="true"
            label="SAVE"
            alignItens="center"
            [icon]="saveIcon"
            [IsLoading]="isLoading"
            [disabled]="isLoading"
            style="align-self: center;"
            (onClick)="onResetPassword.emit()"></custom-button>
        </div>
      </app-modal>
    </form>
  `,
})
export class PasswordModalComponent implements OnInit {
  saveLoading: boolean;

  generatePWIcon = faRotate;
  saveIcon = faFloppyDisk;
  keyIcon = faKey;

  @Input() isLoading: boolean;
  @Input() showResetPasswordModal: boolean = false;
  @Input() userForm: FormGroup;

  @Output() onResetPassword: EventEmitter<boolean> = new EventEmitter();
  @Output() onGeneratePassword: EventEmitter<boolean> = new EventEmitter();
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  modalProps: ModalProps;

  constructor() {}

  ngOnInit() {
    this.modalProps = {
      disableButtons: true,
      CloseButton: {
        OnAction: () => {
          this.showResetPasswordModal = false;
          this.onClose.emit(false);
        },
      },
    };
  }
}
