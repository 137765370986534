import { Routes, RouterModule } from '@angular/router';
import { UnderDevelopmentComponent } from '../under-development/under-development.component';
import { CobraReportsComponent } from './cobra-reports/cobra-reports.component';
import { FederalCobraReportComponent } from './federal-cobra-report/federal-cobra-report.component';
import { LoginReportsComponent } from './login-reports/login-reports.component';
import { OpenEnrollmentReportComponent } from './open-enrollment-report/open-enrollment-report.component';
import { ContactInformationReportComponent } from './contact-information-report/contact-information-report.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'login-report', component: LoginReportsComponent, title: 'Allied Administrators - Login Report' },
      { path: 'cobra-report', component: CobraReportsComponent, title: 'Allied Administrators - Cobra Report' },
      { path: 'federal-state-cobra-edit', component: FederalCobraReportComponent, title: 'Allied Administrators - Federal State Cobra Edit' },
      { path: 'open-enrollment', component: OpenEnrollmentReportComponent, title: 'Allied Administrators - Open Enrollment' },
      { path: 'contact-information', component: ContactInformationReportComponent, title: 'Allied Administrators - Contact Information' }
      
    ],
  },
];

export const ReportsRoutes = RouterModule.forChild(routes);
