import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faFloppyDisk, faSheetPlastic } from '@fortawesome/free-solid-svg-icons';
import saveAs from 'file-saver';
import { T } from 'ramda';
import { take } from 'rxjs';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { BenefitAdministratorService } from 'src/app/core/services/http/benefit-administrator.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { FileHandle } from 'src/app/shared/directives/dragDrop.directive';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ba-groups-by-excel',
  styleUrls: ['./ba-groups-by-excel.component.scss'],
  template: `
    <div style="display: flex; flex-direction: column; gap: 10px;">
      <span>Please download the template to add ER groups and re-upload in the box below by selecting "Upload"</span>
      <custom-button
        label="sample er upload template"
        [icon]="iconSheet"
        [IsLoading]="downloadTemplateLoading"
        [disabled]="downloadTemplateLoading"
        (onClick)="onDownloadTemplate()"
        [forceCapitalization]="true"
        color="secondary"
        style="align-self: flex-start;"></custom-button>
      <app-select
        Label="Select Region"
        [OptionList]="optionList"
        (onSelectOutput)="selectedRegion = $event"
        style="align-self: flex-start;"></app-select>

      <span>Upload File:</span>
      <app-drag-drop-download
        (onFileDropped)="onFileDropped($event)"
        [errorMessage]="dropFIleErrorMessage"></app-drag-drop-download>

      <span *ngIf="saveResponse">{{ saveResponse }}</span>

      <custom-button
        label="upload"
        [icon]="iconSave"
        (onClick)="onUpload()"
        [IsLoading]="uploadLoding"
        [disabled]="!selectedRegion || !fileDropped || uploadLoding"
        [forceCapitalization]="true"
        style="align-self: flex-end; padding-top: 10px;"></custom-button>
    </div>
  `,
})
export class BaGroupsByExcelComponent implements OnInit {
  iconSheet = faSheetPlastic;
  iconSave = faFloppyDisk;
  downloadTemplateLoading: boolean;
  uploadLoding: boolean;

  optionList: SelectOptions<string>[] = [
    {
      key: 'DDIC',
      optionText: 'DDIC',
    },
    {
      key: 'DDPA',
      optionText: 'DDPA',
    },
    {
      key: 'DDCA',
      optionText: 'DDCA',
    },
  ];
  selectedRegion: string;

  fileDropped: File;
  dropFIleErrorMessage?: string;
  userId: number;
  saveResponse: any;

  constructor(
    private http: HttpClient,
    private baService: BenefitAdministratorService,
    private authService: AuthService
  ) {}

  selectRegion: FormControl = new FormControl('');

  ngOnInit(): void {
    this.authService.userInfo.pipe(take(1)).subscribe(res => {
      if (res?.UserID) this.userId = res.UserID;
    });
  }

  onUpload() {
    this.uploadLoding = true;
    this.baService
      .UploadEmployerTemplateFile(this.fileDropped, this.userId.toString(), this.selectedRegion)
      .subscribe(res => {
        this.saveResponse = res.message;
        this.uploadLoding = false;
      });
  }
  onFileDropped(fileDropped: FileHandle) {
    let isNotXlsxFile = ['xlsx', 'xls'].filter(extension => fileDropped.file.name.includes(extension)).length === 0;
    if (isNotXlsxFile) {
      this.dropFIleErrorMessage = 'The file is not allowed. (XLS, XLSX only)';
      return;
    }
    this.dropFIleErrorMessage = undefined;

    this.fileDropped = fileDropped.file;
  }

  onDownloadTemplate() {
    this.downloadTemplateLoading = true;
    this.getAddEmployerTemplate().subscribe(res => {
      saveAs(new Blob([res]), `Allied Administrators - ER Users.xlsx`);
      this.downloadTemplateLoading = false;
    });
  }

  private getAddEmployerTemplate() {
    //uploaddocument/getaddemployertemplate
    return this.http.get(`${environment.alliedApi.baseUrl}/uploaddocument/getaddemployertemplate`, {
      responseType: 'arraybuffer',
    });
  }
}
