import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { GetPendingByEmployerAPIResponse as GetPendingBaAPIResponse } from 'src/app/core/models/api/benefit-administrators/get-pending-by-employer.response';
import {
  BaManageGroupData,
  StatusBaUser,
} from 'src/app/core/models/api/benefit-administrators/get-pendings-by-employer.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { BenefitAdministratorService } from 'src/app/core/services/http/benefit-administrator.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-approve-pending-ba',
  styleUrls: ['./approve-pending-ba.component.scss'],
  template: `
    <div style="display: flex; justify-content: flex-end">
      <custom-button
        [forceCapitalization]="true"
        [routerLink]="[eligibilityAccessUrl]"
        style="align-self: flex-end; margin-bottom: 10px"
        color="secondary"
        [icon]="arrowLeft"
        label="BACK"></custom-button>
    </div>
    <app-content-card CustomTitle="Disable Eligibility">
      <div *ngIf="data$ | withLoading | async as data">
        <app-skeleton-input *ngIf="data.loading"></app-skeleton-input>
        <div *ngIf="data.value">
          <div style="padding: 20px;" *ngIf="isApproved === false">
            Ba user <strong>{{ data.value.response.BaName }}</strong> has requested to manage your eligibility. Click on
            approve button below to approve.
          </div>
          <div style="padding: 20px;" *ngIf="isApproved === true">
            Ba user <strong>{{ data.value.response.BaName }}</strong> is managing your eligibility. Click on disable button below to disable user.
          </div>
          <div style="display: flex; justify-content: flex-end; padding: 20px 20px 5px 0px;">
            <custom-button
              (onClick)="isApproved ? onDisableBaRequest(data.value.response) : onApproveBaRequest(data.value.response)"
              [IsLoading]="saveLoading"
              [disabled]="saveLoading"
              [label]="activationButtonLabel"
              [forceCapitalization]="true"></custom-button>
          </div>
        </div>
      </div>
    </app-content-card>
  `,
})
export class ApprovePendingBaComponent implements OnInit {
  data$: Observable<GetPendingBaAPIResponse>;
  baManageGroupId: number;

  arrowLeft = faArrowLeft

  activationButtonLabel: string;
  saveLoading: boolean = false;
  isApproved: boolean = false;

  eligibilityAccessUrl = RouteEnum.EligibilityAccess

  constructor(
    private notificationService: NotificationService,
    private benefitAdministratorService: BenefitAdministratorService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.baManageGroupId = Number(this.route.snapshot.paramMap.get('id'));

    this.data$ = this.benefitAdministratorService.GetPending(this.baManageGroupId.toString()).pipe(
      tap(res => {
        this.isApproved = res.response.Status === StatusBaUser.Approved;
        this.activationButtonLabel = this.isApproved ? 'Disable BA' : 'Approve Request';
      })
    );

    // this.data$ = of({ baName: 'test' });
  }

  onApproveBaRequest(baData: BaManageGroupData) {
    this.saveLoading = true;
    this.benefitAdministratorService.ApproveBaRequest(baData).subscribe(res => {
      this.handleBaRequest(res.status, res.message);
      this.saveLoading = false;
      this.router.navigateByUrl(this.eligibilityAccessUrl)
    });
  }

  onDisableBaRequest(baData: BaManageGroupData) {
    this.saveLoading = true;
    this.benefitAdministratorService.DisableBaRequest(baData).subscribe(res => {
      this.handleBaRequest(res.status, res.message);
      this.saveLoading = false;
      this.router.navigateByUrl(this.eligibilityAccessUrl)
    });
  }

  private handleBaRequest(status: boolean, message: string) {
    if (status) this.notificationService.success(message);
    else this.notificationService.error(message);
  }
}
