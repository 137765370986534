import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SiteNavigationRoutes } from './site-navigation.routing';
import { SharedModule } from 'src/app/shared/shared.module';
import { BusinessProgramComponent } from './business-program/business-program.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LinksComponent } from './links/links.component';
import { FaqComponent } from './faq/faq.component';
import { AlliedBenefitSuiteComponent } from './allied-benefit-suite/allied-benefit-suite.component';
import { ProducerHomeComponent } from './home/components/producer-home/producer-home.component';
import { EmployerHomeComponent } from './home/components/employer-home/employer-home.component';

@NgModule({
  imports: [CommonModule, SiteNavigationRoutes, SharedModule],
  declarations: [
    HomeComponent,
    BusinessProgramComponent,
    ContactUsComponent,
    LinksComponent,
    FaqComponent,
    AlliedBenefitSuiteComponent,
    ProducerHomeComponent,
    EmployerHomeComponent,
  ],
})
export class SiteNavigationModule {}
