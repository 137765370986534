import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  selectedTab: 'delta_dental_ppo_and_ppo_plus_premier_faq' | 'deltacare_usa_faq' | 'cobra_faq' =
    'delta_dental_ppo_and_ppo_plus_premier_faq';

  constructor() {}

  ngOnInit() {}
}
