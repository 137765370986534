import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationUserType } from '../../models/api/auth/AuthenticationUserTypeEnum';
import { ChangePasswordResponse } from '../../models/api/user/change-password.response';
import { UpdateUserProfileResponse } from '../../models/api/user/update-user-profile.response';
import { UpdateUserProfileRequest } from '../../models/api/user/update-user-profile.resquest';
import { ViewUserJournal } from '../../models/api/user/view-user-journal.reponse';
import { EmployerUsersApiResponse } from '../../models/api/employer/employer-users.response';
import { GetAllRegistersRequest } from '../../models/api/user/new-user-registration/get-all-registers.request';
import { GetAllRegistersResponseData } from '../../models/api/user/new-user-registration/get-all-registers.response';
import { GetMasterProducerApiResponse } from '../../models/api/master-producer/get-master-producer.response';

@Injectable({
  providedIn: 'root',
})
export class UserControlService {
  constructor(private http: HttpClient) {}

  UpdateUserProfile(request: UpdateUserProfileRequest) {
    return this.http.post<UpdateUserProfileResponse>(`${environment.alliedApi.baseUrl}/Userctrl/profile`, request);
  }

  UpdateRegister(registerId: number) {
    return this.http.get<any>(`${environment.alliedApi.baseUrl}/Userctrl/Updateregister?id=${registerId}`);
  }

  CreateOrUpdateUser(request: any) {
    return this.http.post<any>(`${environment.alliedApi.baseUrl}/Userctrl/user`, request);
  }

  GetERUser(employerId: string) {
    return this.http.get<EmployerUsersApiResponse>(
      `${environment.alliedApi.baseUrl}/Userctrl/getERUser?id=${employerId}`
    );
  }

  ViewJournal(id: number, userType: AuthenticationUserType) {
    return this.http.post<ViewUserJournal>(`${environment.alliedApi.baseUrl}/Userctrl/user_view_journ`, {
      id: id,
      Type: userType,
    });
  }

  GetAllRegisters(
    page: number,
    pageSize: number,
    statusFilter: number = 0,
    search: string = '',
    orderBy: 'asc' | 'desc' = 'asc',
    orderByColumnName: string = 'companyName'
  ) {
    return this.http.post<GetAllRegistersResponseData>(
      `${environment.alliedApi.baseUrl}/Userctrl/GetAllRegisters`,
      new GetAllRegistersRequest({
        orderByColumnName: orderByColumnName,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search,
        orderBy: orderBy,
        statusFilter: statusFilter,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }

  GetMasterProducer(producerId: string) {
    return this.http.get<GetMasterProducerApiResponse>(
      `${environment.alliedApi.baseUrl}/Userctrl/GetMasterProducerUser?id=${producerId}`
    );
  }

  ChangePassword(currentPassword: string, newPassword: string, confirmPassword: string, userId: number) {
    return this.http.post<ChangePasswordResponse>(`${environment.alliedApi.baseUrl}/Userctrl/userResetpwd`, {
      cur_password: currentPassword,
      new_password: newPassword,
      con_password: confirmPassword,
      user_id: userId,
    });
  }

  ChangePasswordAfterReset(currentPassword: string, newPassword: string, userId: string, userName: string) {
    return this.http.post<ChangePasswordResponse>(`${environment.alliedApi.baseUrl}/Userctrl/userResetpassword`, {
      Password: currentPassword,
      resetpassword: newPassword,
      UserName: userName,
      UserID: userId,
    });
  }

  ResetpasswordEmail(UserId: string, password: string, type: string) {
    return this.http.post<any>(`${environment.alliedApi.baseUrl}/Userctrl/resetpasswordEmail`, {
      'UserID': UserId,
      'Password': password,
      'Type': type
  });
  }
}
