import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { SmtpConfigurationApiResponse } from 'src/app/core/models/api/smtp-configuration/smtp-configuration.response';
import { SmtpConfiguration } from 'src/app/core/models/api/smtp-configuration/stmp-configuration';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { EmailService } from 'src/app/core/services/http/email.service';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-email-configuration',
  styleUrls: ['./email-configuration.component.scss'],
  template: `
    <app-content-card CustomTitle="Email Configuration">
      <form
        [formGroup]="emailConfigurationForm"
        style="display: flex; flex-direction: column; padding: 20px;"
        *ngIf="emailData$ | withLoading | async as data">
        <app-form-input
          style="flex: 1;"
          [loading]="data.loading ? true : false"
          [Wide]="true"
          fieldName="userName"
          title="User Name"
          [parentForm]="emailConfigurationForm">
        </app-form-input>
        <app-form-input
          [Wide]="true"
          [loading]="data.loading ? true : false"
          type="password"
          fieldName="password"
          title="Password"
          [parentForm]="emailConfigurationForm"></app-form-input>
        <div style="display: flex; gap: 10px;">
          <app-form-input
            [Wide]="true"
            [loading]="data.loading ? true : false"
            style="flex: 1;"
            fieldName="serverName"
            title="Server Name"
            [parentForm]="emailConfigurationForm"></app-form-input>
          <app-form-input
            [Wide]="true"
            [loading]="data.loading ? true : false"
            style="flex: 1;"
            fieldName="protocol"
            title="Protocol"
            [parentForm]="emailConfigurationForm">
          </app-form-input>
        </div>
        <app-form-input
          [Wide]="true"
          [loading]="data.loading ? true : false"
          fieldName="port"
          title="Port"
          [parentForm]="emailConfigurationForm">
        </app-form-input>
        <app-form-input
          [Wide]="true"
          [loading]="data.loading ? true : false"
          fieldName="fromAddress"
          title="From Address"
          [parentForm]="emailConfigurationForm"></app-form-input>
        <app-form-input
          [Wide]="true"
          [loading]="data.loading ? true : false"
          fieldName="fromName"
          title="From Name"
          [parentForm]="emailConfigurationForm"></app-form-input>
        <div style="display: flex; gap: 10px; justify-content: center;">
          <custom-button
            [forceCapitalization]="true"
            [routerLink]="[returnUrl]"
            label="BACK"
            color="secondary"></custom-button>
          <custom-button [forceCapitalization]="true" (onClick)="onSave($event)" label="SAVE"></custom-button>
        </div>
      </form>
    </app-content-card>
  `,
})
export class EmailConfigurationComponent implements OnInit {
  emailConfigurationForm: FormGroup<any>;

  emailData$: Observable<SmtpConfigurationApiResponse>;
  returnUrl = RouteEnum.EmailTemplate;

  constructor(
    private fb: FormBuilder,
    private emailService: EmailService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.emailConfigurationForm = this.fb.group({
      id: [],
      userName: [],
      password: [],
      serverName: [],
      protocol: [],
      port: [],
      fromName: [],
      fromAddress: [],
    });

    this.updateContent();
  }

  onSave(_: Event) {
    if (this.emailConfigurationForm.valid) {
      let { id, userName, password, serverName, protocol, port, fromName, fromAddress } =
        this.emailConfigurationForm.value;
      console.log(userName, password);
      this.emailService
        .UpdateSmtpConfiguration({
          Id: id,
          Username: userName,
          Password: password,
          Servername: serverName,
          Protocol: protocol,
          Port: port,
          Fromname: fromName,
          Fromaddress: fromAddress,
        } as SmtpConfiguration)
        .subscribe(res => {
          if (res.Status) this.notificationService.success(res.Message ?? 'Email configuration updated successfully');
          else this.notificationService.error(res.Message ?? 'Something went wrong');
          this.updateContent();
        });
    }
  }

  private updateContent() {
    this.emailData$ = this.emailService.GetSmtpConfiguration().pipe(
      tap(configuration => {
        this.emailConfigurationForm.patchValue({
          id: configuration.Data.Id,
          userName: configuration.Data.Username,
          password: configuration.Data.Password,
          serverName: configuration.Data.Servername,
          protocol: configuration.Data.Protocol,
          port: configuration.Data.Port,
          fromName: configuration.Data.Fromname,
          fromAddress: configuration.Data.Fromaddress,
        });
      })
    );
  }
}
