import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InterceptorSkipRegionHeader } from '../../interceptors/region.interceptor';

@Injectable({
  providedIn: 'root',
})
export class CommissionStatementService {
  constructor(private http: HttpClient) {}

  GetCommissionStatement(masterProducerId: number, region: string) {
    let headers = new HttpHeaders();
    if (region) headers = headers.set('region', region).set(InterceptorSkipRegionHeader, '');

    return this.http.get<{ url: string }>(
      `${environment.alliedApi.baseUrl}/sso/commissionstatements/${masterProducerId}`,
      { headers: headers }
    );
  }
}
