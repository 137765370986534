import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { GetWebPortalPdfRequest, GetWebPortalUserType } from 'src/app/core/models/api/get-web-portal-pdf.request';
import {
  DependentWebPortalPdfResponse,
  EmployeeWebPortalPdfReponse,
  EmployerWebPortalPdfResponse,
  WebPortalPdfResponse,
} from 'src/app/core/models/api/log-report.response';
import { FileService } from 'src/app/core/services/file.service';
import { GeneralControlService } from 'src/app/core/services/http/general-control.service';
import { Tab } from 'src/app/shared/components/tab-menu/tab-menu.component';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-login-reports',
  styleUrls: ['./login-reports.component.scss'],
  template: `
    <app-content-card CustomTitle="Report Log">
      <div ngClass="login_report_wrapper">
        <div>
          <app-radio-button
            (onRadioButtonClick)="onSelectReportType($event)"
            [labels]="reportTypes"
            [checked]="selectedReportTypeSubject.value"></app-radio-button>
          <app-radio-button
            (onRadioButtonClick)="onSelectReportTimeBox($event)"
            [labels]="timeboxTypes"
            [checked]="selectedReportTimeboxSubject.value"></app-radio-button>
        </div>
        <div *ngIf="selectedReportTypeSubject.value === 'Web Portal Activity'">
          <app-checkbox *ngFor="let item of checkboxUserTypes" (onCheck)="onCheck($event)" [name]="item"></app-checkbox>
        </div>
        <form
          *ngIf="selectedReportTimeboxSubject.value == 'Custom Date Range Report'"
          [formGroup]="rangeDateForm"
          ngClass="search_section">
          <app-form-input
            fieldName="fromDate"
            ngClass="form_item wide"
            title="From Date"
            type="date"
            [Wide]="true"
            formControlName="fromDate"
            [parentForm]="rangeDateForm"></app-form-input>
          <app-form-input
            fieldName="toDate"
            ngClass="form_item wide"
            title="To Date"
            type="date"
            [Wide]="true"
            formControlName="toDate"
            [parentForm]="rangeDateForm"></app-form-input>
        </form>
        <custom-button
          [forceCapitalization]="true"
          label="GET REPORT"
          style="align-self: flex-start;"
          (onClick)="onGetReport()"></custom-button>
      </div>
    </app-content-card>
    <br />
    <div *ngIf="showTables">
      <ng-container *ngIf="webPortalPdfResponse$ | withLoading | async as webPortalPdfResponse">
        <div *ngIf="webPortalPdfResponse.loading">Loading</div>
        <div *ngIf="webPortalPdfResponse.value">
          <app-content-card>
            <app-tab-menu [tabs]="tabs" [firstItemOpened]="true" style="margin: 10px;"> </app-tab-menu>
          </app-content-card>
        </div>
      </ng-container>
    </div>

    <!-- tabs menu templates used in app-tab-menu component -->
    <ng-template #employer>
      <app-table
        style="margin-top: 10px;"
        [DisplayedColumns]="employerDisplayedColumns"
        [EnableSearch]="true"
        [EnableLocalSearch]="true"
        [EnableLocalSort]="true"
        [Rows]="employerRows"
        [DownloadButtons]="employerDownloadButtons"
        [EnablePagination]="true"></app-table>
    </ng-template>
    <ng-template #employee>
      <app-table
        style="margin-top: 10px;"
        [DisplayedColumns]="employeeDisplayedColumns"
        [Rows]="employeeRows"
        [EnableSearch]="true"
        [EnableLocalSearch]="true"
        [EnableLocalSort]="true"
        [DownloadButtons]="employeeDownloadButtons"
        [EnablePagination]="true"></app-table>
    </ng-template>
    <ng-template #dependent>
      <app-table
        style="margin-top: 10px;"
        [DisplayedColumns]="dependentDisplayedColumns"
        [Rows]="dependentRows"
        [DownloadButtons]="dependentDownloadButtons"
        [EnableSearch]="true"
        [EnableLocalSearch]="true"
        [EnableLocalSort]="true"
        [EnablePagination]="true"></app-table>
    </ng-template>
  `,
})
export class LoginReportsComponent implements OnInit, AfterViewInit {
  // Controls
  reportTypes = ['Web Portal Activity', 'Login Statistics'];
  selectedReportTypeSubject: BehaviorSubject<string> = new BehaviorSubject('Web Portal Activity');

  timeboxTypes = ['Last Month Report', 'Custom Date Range Report'];
  selectedReportTimeboxSubject: BehaviorSubject<string> = new BehaviorSubject('Last Month Report');

  checkboxUserTypes = ['Admin', 'ER', 'MP', 'BA', 'Replication'];
  checkedUserTypeSubject: BehaviorSubject<Set<GetWebPortalUserType>> = new BehaviorSubject<Set<GetWebPortalUserType>>(
    new Set()
  );

  // Async Response
  webPortalPdfResponse$: Observable<WebPortalPdfResponse>;

  // Employee Table
  employeeDisplayedColumns: DisplayedColumns[];
  employeeDownloadButtons: TableDownloadButtons;
  employeeRows: EmployeeWebPortalPdfReponse[];

  // Employer Table
  employerDisplayedColumns: DisplayedColumns[];
  employerDownloadButtons: TableDownloadButtons;
  employerRows: EmployerWebPortalPdfResponse[];

  // Dependent Table
  dependentDisplayedColumns: DisplayedColumns[];
  dependentDownloadButtons: TableDownloadButtons;
  dependentRows: DependentWebPortalPdfResponse[];

  tabs: Tab[];

  showTables: boolean;

  @ViewChild('employer', { static: true }) employerTabTemplate: TemplateRef<unknown>;
  @ViewChild('employee', { static: true }) employeeTabTemplate: TemplateRef<unknown>;
  @ViewChild('dependent', { static: true }) dependentTabTemplate: TemplateRef<unknown>;

  constructor(
    private generalControl: GeneralControlService,
    private formBuilder: FormBuilder,
    private fileService: FileService
  ) {}

  rangeDateForm: FormGroup = this.formBuilder.group({
    fromDate: ['', Validators.required],
    toDate: ['', Validators.required],
  });

  onSelectReportType($event: string) {
    this.selectedReportTypeSubject.next($event);
  }

  onSelectReportTimeBox($event: string) {
    this.selectedReportTimeboxSubject.next($event);
  }

  onCheck(checkedName: string) {
    let set = this.checkedUserTypeSubject.value;
    let userType = this.mapCheckedUserTypeToWebPortalUserType(checkedName);

    if (!userType) return;

    if (set.has(userType)) set.delete(userType);
    else set.add(userType);
    this.checkedUserTypeSubject.next(set);
  }

  onGetReport() {
    let request: GetWebPortalPdfRequest = {};

    if (this.selectedReportTimeboxSubject.value === 'Custom Date Range Report' && this.rangeDateForm.valid) {
      request.fromDate = this.rangeDateForm.value.fromDate;
      request.toDate = this.rangeDateForm.value.toDate;
    }

    let checkedUserTypes = this.checkedUserTypeSubject.value;
    if (checkedUserTypes.size > 0) {
      request.users = [];
      for (let checkedUserType of checkedUserTypes.values()) request.users.push(checkedUserType);
    }

    if (this.selectedReportTypeSubject.value === 'Web Portal Activity') {
      this.webPortalPdfResponse$ = this.generalControl.GetWebPortalPdf(request).pipe(
        tap(res => {
          this.employeeRows = res.response.Employee;
          this.employerRows = res.response.Employer;
          this.dependentRows = res.response.Dependent;
        })
      );
      this.showTables = true;
    }

    if (this.selectedReportTypeSubject.value === 'Login Statistics') {
      this.showTables = false;
      this.generalControl.GetReportPdf(request.fromDate, request.toDate).subscribe(res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      });
    }
  }

  ngAfterViewInit(): void {
    this.tabs = [
      { label: 'Employer', id: 'employer', content: this.employerTabTemplate },
      { label: 'Employee', id: 'employee', content: this.employeeTabTemplate },
      { label: 'Dependent', id: 'dependent', content: this.dependentTabTemplate },
    ];
  }

  ngOnInit() {
    this.initEmployerTable();
    this.initEmployeeTable();
    this.initDependentTable();
  }

  private initEmployeeTable() {
    this.employeeDisplayedColumns = [
      { columnName: 'EmployerNR', label: 'Employer Number', sortable: true },
      { columnName: 'CompanyName', label: 'Name', sortable: true },
      { columnName: 'EventType', label: 'Event Type', sortable: true },
      { columnName: 'Title', label: 'Title', sortable: true },
      { columnName: 'Event', label: 'Event', sortable: true },
      { columnName: 'ChangedBy', label: 'Changed By', sortable: true },
    ];
    let employeeObject = () =>
      this.employeeRows.map(employee => ({
        EmployerNR: employee.EmployerNR,
        CompanyName: employee.CompanyName,
        EventType: employee.EventType,
        Title: employee.Title,
        Event: employee.Event,
        ChangedBy: employee.ChangedBy,
      }));

    this.employeeDownloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              EmployerNR: 'EmployerNR',
              CompanyName: 'Name',
              EventType: 'Event Type',
              Title: 'Title',
              Event: 'Event',
              ChangedBy: 'Changed By',
            },
          ];

          this.fileService
            .GeneratePDF('Allied Administrators - Log Report', header, employeeObject())
            .save('Allied Administrators - Log Report');
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.fileService.GenerateXLSX('Allied Administrators - Log Report', employeeObject());
        },
      },
    };
  }

  private initEmployerTable() {
    let employerObject = () =>
      this.employerRows.map(employer => ({
        EmployerNR: employer.EmployerNR,
        CompanyName: employer.CompanyName,
        EventType: employer.EventType,
        Title: employer.Title,
        Event: employer.Event,
        ChangedBy: employer.ChangedBy,
      }));

    this.employerDisplayedColumns = [
      { columnName: 'EmployerNR', label: 'Employer Number', sortable: true },
      { columnName: 'CompanyName', label: 'Name', sortable: true },
      { columnName: 'EventType', label: 'Event Type', sortable: true },
      { columnName: 'Title', label: 'Title', sortable: true },
      { columnName: 'Event', label: 'Event', sortable: true },
      { columnName: 'ChangedBy', label: 'Changed By', sortable: true },
    ];

    this.employerDownloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              EmployerNR: 'Employer Number',
              CompanyName: 'Name',
              EventType: 'Event Type',
              Title: 'Title',
              Event: 'Event',
              ChangedBy: 'Changed By',
            },
          ];

          this.fileService
            .GeneratePDF('Allied Administrators - Log Report', header, employerObject())
            .save('Allied Administrators - Log Report');
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.fileService.GenerateXLSX('Allied Administrators - Log Report', employerObject());
        },
      },
    };
  }

  private initDependentTable() {
    let dependentObject = () =>
      this.dependentRows.map(dependent => ({
        EmployerNR: dependent.EmployerNR,
        CompanyName: dependent.CompanyName,
        EventType: dependent.EventType,
        Title: dependent.Title,
        Event: dependent.Event,
        ChangedBy: dependent.ChangedBy,
      }));

    this.dependentDisplayedColumns = [
      { columnName: 'EmployerNR', label: 'Employer Number', sortable: true },
      { columnName: 'CompanyName', label: 'Name', sortable: true },
      { columnName: 'EmployeeName', label: 'Employee Name', sortable: true },
      { columnName: 'EventType', label: 'Event Type', sortable: true },
      { columnName: 'Title', label: 'Title', sortable: true },
      { columnName: 'Event', label: 'Event', sortable: true },
      { columnName: 'CreatedBy', label: 'Created By', sortable: true },
      { columnName: 'ChangedBy', label: 'Changed By', sortable: true },
    ];
    this.dependentDownloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              EmployerNR: 'Employer Number',
              CompanyName: 'Name',
              EmployeeName: 'Employee Name',
              EventType: 'Event Type',
              Title: 'Title',
              Event: 'Event',
              CreatedBy: 'Created By',
              ChangedBy: 'Changed By',
            },
          ];

          this.fileService
            .GeneratePDF('Allied Administrators - Log Report', header, dependentObject())
            .save('Allied Administrators - Log Report');
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.fileService.GenerateXLSX('Allied Administrators - Log Report', dependentObject());
        },
      },
    };
  }

  private mapCheckedUserTypeToWebPortalUserType(checkedUserType: string) {
    return new Map<string, GetWebPortalUserType>([
      ['Admin', 'A'],
      ['ER', 'R'],
      ['MP', 'P'],
      ['BA', 'B'],
      ['Replication', 'RR'],
    ]).get(checkedUserType);
  }
}
