import { Component, OnInit } from '@angular/core';
import { ModalProps } from '../modal/modal.component';

@Component({
  selector: 'app-term-of-use-modal',
  templateUrl: './term-of-use-modal.component.html',
  styleUrls: ['./term-of-use-modal.component.scss'],
})
export class TermOfUseModalComponent implements OnInit {
  constructor() {}

  termOfUseModalProps: ModalProps = {
    disableButtons: true,
    CloseButton: { OnAction: () => (this.openTermOfUseModal = false) },
  };
  openTermOfUseModal: Boolean = false;

  ngOnInit() {}
}
