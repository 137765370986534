import { RegisterBaseRequest } from './register-base.request.model';

export class RegisterProducerRequest extends RegisterBaseRequest {
  reqUserName: string;
  tin: string;

  constructor(
    firstname: string,
    lastname: string,
    email: string,
    companyname: string,
    requsername: string,
    tin: string
  ) {
    super('Producer', firstname, lastname, email, companyname);
    this.reqUserName = requsername;
    this.tin = tin;
  }
}
