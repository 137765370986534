<div ngClass="wrapper">
  
  <div ngClass="old_front_end_styles">
    <div class="md-card uk-margin-medium-bottom">
      <div class="uk-grid">
          <div class="uk-width-medium-10-10 ">
              <div #content></div>
          </div>
      </div>
    </div>
  </div>

  <app-content-card>
    <div ngClass="inner">
      <div class="uk-width-medium-10-10" style="text-align: center !important">
        <h3 class="topmarginremove">ALLIED ADMINISTRATORS</h3>
        <h3>Delta Dental's Small Business Program</h3>
        <h3>Customer Services Team</h3>
        <br />
        <h5>
          <strong
            >Allied Administrators provides comprehensive expertise in the field of employee benefits
            administration.</strong
          >
        </h5>
        <h5>We strive to provide the highest level of service to our mutual customers.</h5>
        <h5>Contact our Customer Support Team for assistance with:</h5>
        <h5><strong> New Group Applications * Eligibility * Billing * Administrative Materials</strong></h5>
        <h5>Contact Allied Administrators <strong>toll free</strong> at (877) 472-2669, or (510) 962-7736</h5>
        <h5><strong>Mailing Address: </strong>PO Box 70930, Oakland, CA 94612</h5>
        <h5><strong>Street Address: </strong>501 14th Street, Suite 200, Oakland, CA 94612</h5>
        <br />
      </div>
      <div ngClass="table_view">
        <thead>
          <tr>
            <th style="width: 20%">
              <h5>Job Title</h5>
            </th>
            <th style="width: 20%">
              <h5>Name</h5>
            </th>
            <th style="width: 15%">
              <h5>Phone No.</h5>
            </th>
            <th>
              <h5>Email Address</h5>
            </th>
            <th style="width: 20%">
              <h5>Job Duties</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><strong>Associate Vice President of Operations and Services</strong></td>
            <td>Paul Wensloff</td>
            <td>(510) 962-7736</td>
            <td>pwensloff@alliedadministrators.com</td>
            <td>Daily Small Business Group Operations </td>
          </tr>
          <tr>
            <td><strong>Client Services Supervisor</strong></td>
            <td>Jill Louie</td>
            <td>(510) 962-7736</td>
            <td>jlouie@alliedadministrators.com</td>
            <td>Client Services Department Supervisor</td>
          </tr>
          <tr>
            <td><strong>Operations Supervisor</strong></td>
            <td>Vincent Smith</td>
            <td>(510) 962-7736</td>
            <td>vsmith@alliedadministrators.com</td>
            <td>Operations Department Supervisor</td>
          </tr>
          <tr>
            <td><strong>Account Coordinator</strong></td>
            <td>Christa Osier</td>
            <td>(510) 962-7736</td>
            <td>cs@alliedadministrators.com</td>
            <td>Customer Service</td>
          </tr>
          <tr>
            <td><strong>Account Coordinator</strong></td>
            <td>Elsa
              Gebremicael</td>
            <td>(510) 962-7736</td>
            <td>cs@alliedadministrators.com</td>
            <td>Customer Service</td>
          </tr>
          <tr>
            <td><strong>Account Coordinator</strong></td>
            <td>Pamela Hill</td>
            <td>(510) 962-7736</td>
            <td>cs@alliedadministrators.com</td>
            <td>Customer Service</td>
          </tr>
          <tr>
            <td><strong>Account Coordinator</strong></td>
            <td>Maria Medina</td>
            <td>(510) 962-7736</td>
            <td>cs@alliedadministrators.com</td>
            <td>Customer Service</td>
          </tr>

          <tr>
            <td><strong>Broker Services</strong></td>
            <td>Brandon Chao</td>
            <td>(510) 962-7736</td>
            <td>brokers@alliedadministrators.com</td>
            <td>Broker Changes</td>
          </tr>

          <tr>
            <td><strong>EDI Specialist</strong></td>
            <td>Tia Wilson</td>
            <td>(510) 962-7736</td>
            <td>edi@alliedadministrators.com</td>
            <td>EDI Specialist</td>
          </tr>

          <tr>
            <td><strong>Sr. Underwriter</strong></td>
            <td>Jackie Eusebio</td>
            <td>(510) 962-7736</td>
            <td>underwriting@alliedadministrators.com</td>
            <td>Underwriting</td>
          </tr>

          <tr>
            <td><strong>Sr. Underwriter</strong></td>
            <td>Irene Madayag</td>
            <td>(510) 962-7736</td>
            <td>underwriting@alliedadministrators.com</td>
            <td>Underwriting</td>
          </tr>

          <tr>
            <td><strong>Underwriting Assistant</strong></td>
            <td>Vickie Poquiz</td>
            <td>(510) 962-7736</td>
            <td>underwriting@alliedadministrators.com</td>
            <td>Underwriting/COBRA</td>
          </tr>

          <tr>
            <td><strong>Underwriting Assistant</strong></td>
            <td>Finann Habtom</td>
            <td>(510) 962-7736</td>
            <td>underwriting@alliedadministrators.com</td>
            <td>Underwriting/COBRA</td>
          </tr>

          <tr>
            <td><strong>Supplies Coordinator</strong></td>
            <td>Sydney Omictin</td>
            <td>(510) 962-7736</td>
            <td>supplies@alliedadministrators.com</td>
            <td>Admin Supplies</td>
          </tr>

          <tr>
            <td><strong>Renewal Coordinator</strong></td>
            <td>Finann Habtom</td>
            <td>(510) 962-7736</td>
            <td>renewals@alliedadministrators.com</td>
            <td>Contract Renewals</td>
          </tr>

          <tr>
            <td><strong>Accounting Assistant</strong></td>
            <td>Bong Torio</td>
            <td>(510) 962-7736</td>
            <td>accounting@alliedadministrators.com</td>
            <td>Billing & Delinquency</td>
          </tr>

          <tr>
            <td><strong>Commission Specialist</strong></td>
            <td>Marlene Deneal</td>
            <td>(510) 962-7736</td>
            <td>accounting@alliedadministrators.com</td>
            <td>Accounting/Commissions</td>
          </tr>
          
        </tbody>
      </div>
    </div>
  </app-content-card>
</div>
