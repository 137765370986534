import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import AvailableStates from 'src/app/core/common/AvailableStates';
import { RegisterEmployerRequest } from 'src/app/core/models/api/auth/requests/register/register-employer.request.model';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';

@Component({
  selector: 'app-employer-sign-up-form',
  styleUrls: ['./employer.component.scss', '../../../../forms.scss'],
  template: `
    <form
      ngClass="custom_form"
      [formGroup]="EmployerSignUpFormGroup"
      (ngSubmit)="OnSubmit(EmployerSignUpFormGroup.value)">
      <div ngClass="form_group_inline">
        <app-form-input
          ngClass="form_item"
          fieldName="yourFirstName"
          [Wide]="true"
          title="Your First Name"
          placeholder=" "
          formControlName="yourFirstName"
          [parentForm]="EmployerSignUpFormGroup"></app-form-input>

        <app-form-input
          ngClass="form_item"
          fieldName="yourLastName"
          [Wide]="true"
          title="Your Last Name"
          placeholder=" "
          formControlName="yourLastName"
          [parentForm]="EmployerSignUpFormGroup"></app-form-input>
        <app-form-select
          FieldName="state"
          Label="State"
          [Options]="Options"
          ngClass="form_item"
          formControlName="state"
          [parentForm]="EmployerSignUpFormGroup"></app-form-select>
        <app-form-input
          fieldName="yourCompanyName"
          ngClass="form_item"
          title="Your Company Name"
          placeholder=" "
          [Wide]="true"
          formControlName="yourCompanyName"
          [parentForm]="EmployerSignUpFormGroup"></app-form-input>

        <app-form-input
          fieldName="yourEmployerNumber"
          title="Your Employer Number"
          ngClass="form_item"
          placeholder=" "
          [Wide]="true"
          formControlName="yourEmployerNumber"
          [parentForm]="EmployerSignUpFormGroup"></app-form-input>
        <app-form-input
          fieldName="email"
          title="Your Email"
          ngClass="form_item"
          [Wide]="true"
          placeholder=" "
          formControlName="email"
          [parentForm]="EmployerSignUpFormGroup"></app-form-input>
      </div>
      <custom-button
        [forceCapitalization]="true"
        ngClass="form_item forms_button"
        type="submit"
        class="btn"
        label="Sign Up"
        [primary]="true"
        (onClick)="OnSubmit(EmployerSignUpFormGroup.value)"></custom-button>
    </form>
  `,
})
export class EmployerSignUpFormComponent implements OnInit {
  Options: SelectOptions<string>[] = [];

  get options(): SelectOptions<string>[] {
    return AvailableStates.map(
      (state): SelectOptions<string> => ({
        key: state.StateName,
        optionText: state.StateCd,
      })
    );
  }

  EmployerSignUpFormGroup: FormGroup = this.formBuilder.group({
    yourFirstName: ['', Validators.required],
    yourLastName: ['', Validators.required],
    yourCompanyName: ['', Validators.required],
    yourEmployerNumber: ['', [Validators.required, Validators.maxLength(5), Validators.minLength(4)]],
    email: ['', [Validators.required, Validators.email]],
    state: ['', Validators.required],
  });

  constructor(
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private authService: AuthService
  ) {}

  OnSubmit(request: EmployerSignUpForm) {
    if (this.EmployerSignUpFormGroup.invalid) {
      this.EmployerSignUpFormGroup.markAllAsTouched();
      return;
    } else {
      this.authService
        .Register(
          new RegisterEmployerRequest(
            request.yourFirstName,
            request.yourLastName,
            request.email,
            request.yourCompanyName,
            request.yourEmployerNumber,
            request.state
          )
        )
        .subscribe(response => {
          let { status: isRegistred, message } = response;

          if (!isRegistred) {
            this.notificationService.error(message, false);
            return;
          }

          this.notificationService.success(message);
          this.router.navigate(['/auth/signin']);
        });
    }

    // TODO Create employer user
  }

  ngOnInit(): void {
    this.Options = this.options;
  }
}

type EmployerSignUpForm = {
  yourFirstName: string;
  yourLastName: string;
  yourCompanyName: string;
  yourEmployerNumber: string;
  email: string;
  state: string;
};
