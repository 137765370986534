import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminUsersRequest } from '../../models/api/user/admin-users.request';
import { AdminUsersApiResponse } from '../../models/api/user/admin-users.response';
import { IsStringNullOrEmpty } from '../../helpers/validation-helpers';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService {
  constructor(private http: HttpClient) {}

  GetAllAdminUsers(
    page: number,
    pageSize: number,
    statusFilter: number = 1,
    search: string = '',
    orderBy: 'asc' | 'dsc' = 'asc',
    orderByColumn: string = 'UserName'
  ) {
    if (IsStringNullOrEmpty(search)) search = ' ';

    return this.http.post<AdminUsersApiResponse>(
      `${environment.alliedApi.baseUrl}/UserPrivileges/GetAllAdminUsers`,
      new AdminUsersRequest({
        orderByColumnName: orderByColumn,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search,
        orderBy: orderBy,
        statusFilter: statusFilter,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }
}
