import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { getErrorMessage } from '../form-field-errors/CustomErrorMessages';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-form-checkbox',
  styleUrls: ['./form-checkbox.component.scss'],
  template: `
    <div style="display: flex; gap: 10px;">
      <fa-icon *ngIf="icon" [icon]="icon"></fa-icon>
      <mat-checkbox
        matInput
        class="example-margin"
        [checked]="checked"
        [formControl]="formField"
        [value]="value"
        (change)="onChange($event.checked)">
        {{ name }}
      </mat-checkbox>
      <mat-error>{{ setErrorMessage() }}</mat-error>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent),
      multi: true,
    },
  ],
})
export class FormCheckboxComponent implements OnInit, ControlValueAccessor {
  value: any = null;

  @Input() checked: boolean = false;
  @Input() name: string = '';
  @Input() fieldName: string = '';
  @Input() primary = false;
  @Input() Wide: boolean = false;
  @Input() public parentForm?: FormGroup;
  @Input() icon: IconProp;
  get formField(): FormControl {
    return this.parentForm?.get(this.fieldName) as FormControl;
  }

  setAsChecked(arg0: boolean) {
    this.checked = true;
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  onInput(value: string) {
    this.value = value;
  }

  constructor() {}

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // setDisabledState?(isDisabled: boolean): void {
  //   throw new Error('Method not implemented.');
  // }

  setErrorMessage() {
    return getErrorMessage(this.formField);
  }

  ngOnInit() {}
}
