import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import {
  AuthenticationResponse,
  AuthenticationResponseResponse,
} from 'src/app/core/models/api/auth/responses/authentication.response.model';
import { RegionCodes, RegionEnum } from 'src/app/core/models/api/auth/responses/get-regions.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { UserTypeEnum } from 'src/app/core/models/user-type.enum';

import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewConfigurationService } from 'src/app/core/services/user-view-configuration.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { VersionHistoryService } from 'src/app/features/user-dashboard/pages/version-history/version-history.service';
import { ChangeViewByRegion, parseStringToRegionCode } from 'src/app/features/user-dashboard/helpers/user-view.helper';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SignInComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SignInComponent,
    },
  ],
})
export class SignInComponent implements OnInit {
  faCircleNotch = faCircleNotch;
  authenticationResponse$: Observable<AuthenticationResponseResponse | null>;

  //MFA
  openMfaAskModal: boolean = false;
  mfaEnabled: boolean = false;
  modalMfaProps?: ModalProps;
  mfaEmail: string;
  userId: string;
  userName: string;
  region: RegionCodes;
  regionEnum: RegionEnum;
  mfaForm: FormGroup;

  //Change Password after Reset
  OpenPasswordModal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  newPasswordModal: ModalProps = { disableButtons: true, CloseButton: { OnAction: () => this.onPasswordModalClose() } };
  changePasswordAfterResetLoading: boolean = false;

  loginForm: FormGroup;
  changePasswordAfterResetForm: FormGroup;

  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  returnUrl: string;
  showRedirectDisplay: boolean;

  currentVersion: string;


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private userControlService: UserControlService,
    private userViewService: UserViewService,
    private notificationService: NotificationService,
    private configurationService: UserViewConfigurationService,
    private versionHistoryService: VersionHistoryService,
    private cdr: ChangeDetectorRef
  ) {}

  onTouched: Function = () => {};
  onChangeSubs: Subscription[] = [];
  

  ngOnInit(): void {
    this.getLatestVersion();
    if (this.authService.IsLoggedIn()) {
      this.router.navigate(['/']);
    }

    this.route.queryParams.subscribe(params => {
      let encryptedData = params['q'];

      if (!encryptedData) return;
      this.redirectFromOnboarding(encryptedData);
    });

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.mfaForm = this.formBuilder.group({
      mfaDigits: ['', Validators.required],
    });

    this.changePasswordAfterResetForm = this.formBuilder.group({
      newPassword: ['', [Validators.minLength(8), PasswordValidator.strong, Validators.required]],
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    this.authenticationResponse$ = this.authService.userSession;

    this.modalMfaProps = {
      CloseButton: {
        Label: 'No',
        OnAction: () => {
          this.authService.SubmitMfaOptin(false, this.region).subscribe(_ => this.logUser());
          // Disable MFA
        },
      },
      OkButton: {
        Label: 'Yes',
        OnAction: () => {
          this.authService.SubmitMfaOptin(true, this.region).subscribe(_ => this.logUser());
        },
      },
    };
  }

  getLatestVersion() {
    this.versionHistoryService.getLatestVersion().subscribe(res => {
      this.currentVersion = res.VersionNumber.toString();
    });
  }


  onChangePasswordAfterReset() {
    if (this.changePasswordAfterResetForm.valid) {
      this.changePasswordAfterResetLoading = true;

      this.userControlService
        .ChangePasswordAfterReset(
          this.loginForm.value.password,
          this.changePasswordAfterResetForm.value.newPassword,
          this.userId,
          this.userName
        )
        .subscribe(res => {
          if (res.status) this.notificationService.success(res.message);
          else this.notificationService.error(res.message, false);
          this.changePasswordAfterResetLoading = false;
          this.OpenPasswordModal.next(false);
          this.loading.next(false);
          this.loginForm.patchValue({ password: '' });
        });
    }
    // Falta testar o loading, validar o request, e verificar se ja logo o usuario diretamente ou se preciso pedir pro usuario logar novamente
  }

  onSubmitMfaDigits() {
    if (!this.mfaForm.valid) return;
    this.loading.next(true);
    // Submit with mfa digits
    this.authService
      .LoginWithMFA(this.userId, this.mfaForm.controls.mfaDigits.value, this.regionEnum)
      .pipe(this.onAuthUserResponse())
      .subscribe(res => {
        if(res.status === true){
          this.logUser();
        }else{
          this.loading.next(false);
        }
      });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      console.log('Invalid');
      return;
    }
    this.loading.next(true);
    this.authService
      .Login(this.loginForm.value.username, this.loginForm.value.password)
      .pipe(this.onAuthUserResponse())
      .subscribe(res => {
        if (!res.status) {
          this.loading.next(false);
          return;
        }

        // Used in mfa and reset password
        this.userId = res.response[0].UserID.toString();
        this.region = parseStringToRegionCode(res.response[0].selectedRegion);
        this.regionEnum = res.response[0].Region;

        if (res.response[0].userResetPassword == 1 || res.response[0].userChangePassword === 1) {
          this.userName = this.loginForm.value.username; // Used in reset password flow
          this.OpenPasswordModal.next(true);
          return;
        }

        // Validate if has Mfa Enabled
        let IsMFANotDefined = res.response[0].MfaEnabled == null || res.response[0].MfaEnabled == undefined;

        if (IsMFANotDefined) {
          this.openMfaAskModal = true;
          this.mfaEmail = res.response[0].Email;
          return;
        }

        if (res.response[0].MfaEnabled) {
          this.mfaEnabled = true;
          this.loading.next(false);
          return;
        }

        this.logUser();
      });
  }

  onPasswordModalClose() {
    this.OpenPasswordModal.next(false);
  }

  private logUser() {
    this.authService.GetUserInfo()?.subscribe(res => {
      this.setFirstReturnLink(res.response[0].Type);
      this.router.navigate([this.returnUrl]);
      this.loading.next(false);
      this.showRedirectDisplay = false;
    });
  }

  private bindUserAuthenticationInformation(userAuthentication: AuthenticationResponseResponse) {
    if (userAuthentication.Disabled) {
      this.notificationService.error('Your account is disabled. Please contact allied administrator', false);
      return of(userAuthentication);
    }

    if (userAuthentication.userResetPassword == 1 || userAuthentication.userChangePassword === 1) {
      return of(userAuthentication);
    }

    this.userViewService.Propagate(false);

    if (userAuthentication.IsBaUser && !userAuthentication.IsNewBaUser) {
      this.userViewService.ShowUserInitialView(UserTypeEnum.BaProducer);
    }
    else if (userAuthentication.IsNewBaUser) {
      this.userViewService.ShowUserInitialView(UserTypeEnum.NewBaProducer);
    }
    else {
      this.userViewService.ShowUserInitialView(
        this.configurationService.MapAuthenticationUserTypeToUserTypeEnum(userAuthentication.Type)
      );
    }

    if (userAuthentication.EmployerNR) this.userViewService.ChangeEmployerNumber(userAuthentication.EmployerNR);

    if(IsStringNullOrEmpty(userAuthentication.WP) === false && userAuthentication.Type === AuthenticationUserType.Employer){
      this.userViewService.ChangeWaitingPeriod(userAuthentication.WP);
    }

    if(userAuthentication.OE !== null && userAuthentication.OE !== undefined && userAuthentication.Type === AuthenticationUserType.Employer){
      this.userViewService.ChangeEmployerOeMonth(userAuthentication.OE);
    }

    if (userAuthentication.EmployerID) this.userViewService.ChangeEmployerId(userAuthentication.EmployerID);

    if (userAuthentication.PlanNR) this.userViewService.ChangePlanNumber(userAuthentication.PlanNR);

    if(userAuthentication.IsSubProducer)
      this.userViewService.ChangeSubProducer();

    if (userAuthentication.MasterProducerID)
      this.userViewService.ChangeMasterProducerId(userAuthentication.MasterProducerID.toString());

    if (userAuthentication.selectedRegion)
      this.userViewService.ChangeRegion(parseStringToRegionCode(userAuthentication.selectedRegion));

    if (userAuthentication.ABSNewsFeed !== undefined) this.userViewService.ChangeAbsNewFeed(userAuthentication.ABSNewsFeed);

    if(userAuthentication.IsViewOnly !== null && userAuthentication.IsViewOnly !== undefined)
    {
      this.userViewService.ChangeViewOnly(userAuthentication.IsViewOnly === true);
    }

    if (userAuthentication.Type == AuthenticationUserType.Admin) {
      this.userViewService.ShowModulePickerView();

      return this.authService.GetUserRights(userAuthentication.UserID).pipe(
        switchMap(userRightsData => {
          this.userViewService.Propagate(true);
          return this.authService.GetSSOPrivilege().pipe(map(res => userAuthentication));
        })
      );
    }
    this.userViewService.Propagate(true);
    return of(userAuthentication);
  }

  private onAuthUserResponse() {
    return switchMap<AuthenticationResponse, Observable<AuthenticationResponse>>(authResponse => {
      if (!authResponse.status) {
        this.notificationService.error(authResponse.message, false);
        return of(authResponse);
      }
      return this.bindUserAuthenticationInformation(authResponse.response[0]).pipe(
        map(res => {
          // Refactor this to a better way
          authResponse.response = [res];
          return authResponse;
        })
      );
    });
  }

  private redirectFromOnboarding(encryptedData: string) {
    this.showRedirectDisplay = true;
    this.authService.PostSSOAuthentication(encryptedData).subscribe(x => {
      this.bindUserAuthenticationInformation(x.response[0]).subscribe(authResponse => {    
        
          
        ChangeViewByRegion(this.userViewService, x.destination);
        setTimeout(() => {
          window.location.reload();
        }, 100);

        // if (authResponse.userResetPassword == 1 || authResponse.userChangePassword === 1) {
        //   this.userName = this.loginForm.value.username; // Used in reset password flow
        //   this.OpenPasswordModal.next(true);
        //   return;
        // }

        // this.logUser();
      });
    });
  }

  private setFirstReturnLink(userType: AuthenticationUserType) {
    this.returnUrl =
      userType === AuthenticationUserType.Admin && !this.showRedirectDisplay
        ? RouteEnum.ModulePicker.toString()
        : this.userViewService.GetPrimaryLink().toString();
  }
}
