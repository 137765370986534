import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { ProducerUserData, ProducerUsersApiResponse } from 'src/app/core/models/api/user/mp-all-users.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { ViewType } from 'src/app/core/models/user-view.model';
import { FileService } from 'src/app/core/services/file.service';
import { MpUsersService } from 'src/app/core/services/http/mp-users.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-mp-users',
  styleUrls: ['./manage-all-mp-users.component.scss'],
  template: `
    <app-content-card CustomTitle="MP Users">
      <ng-container *ngIf="data$ | withLoading | async as data">
        <app-table
          (onSort)="onSort($event)"
          (onSearchEvent)="onSearch($event)"
          [IsLoading]="data.loading ?? false"
          [searchedText]="searchText.value"
          [EnableSearch]="true"
          (PaginationEvent)="onPagination($event)"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [Rows]="data.value?.data"
          [EnablePagination]="true"
          [Pagination]="pagination"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionColumnTemplate let-data>
      <a [routerLink]="[emailConfigurationLink, data.UserId]">
        <fa-icon [icon]="editIcon" style="color: var(--brandColor);"></fa-icon>
      </a>
    </ng-template>
    <ng-template #disabledColumnTemplate let-disabled>
      <span>{{ disabled ? 'Y' : 'N' }}</span>
    </ng-template>
    <ng-template #dateColumnTemplate let-data>
      {{ customFormatDate(data) }}
    </ng-template>
  `,
})
export class ManageAllMpUsersComponent implements OnInit {
  pagination: ManualPagination;
  searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  sortColumn?: string;
  sortDirection: 'asc' | 'dsc';

  editIcon = faPencil;
  emailConfigurationLink = RouteEnum.EditProducerUser;
  view: ViewType | undefined;

  displayedColumns: DisplayedColumns[];
  downloadButtons: TableDownloadButtons;
  data$: Observable<ProducerUsersApiResponse>;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('disabledColumnTemplate', { static: true }) disabledColumnTemplate: TemplateRef<unknown>;
  @ViewChild('dateColumnTemplate', { static: true }) dateColumnTemplate: TemplateRef<unknown>;

  constructor(private mpUsersService: MpUsersService, private fileService: FileService) {}

  ngOnInit(): void {
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let headers = [
            {
              Username: 'Username',
              FirstName: 'FirstName',
              Email: 'Email',
              ProducerName: 'ProducerName',
              Disabled: 'Disabled',
              LastLogin: 'LastLogin',
              ABSNewsFe: 'ABSNewsFeed',
            },
          ];

          this.data$.pipe(take(1)).subscribe(x => {
            this.fileService
              .GeneratePDF('Allied Administrators - Mp Users', headers, this.mapTableToObject(x.data))
              .save('Allied Administrators - Mp Users');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.data$.pipe(take(1)).subscribe(x => {
            this.fileService.GenerateXLSX('Allied Administrators - Mp Users', this.mapTableToObject(x.data));
          });
        },
      },
    };
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'Username', label: 'User Name', sortable: true },
      { columnName: 'FirstName', label: 'First Name', sortable: true },
      { columnName: 'Email', label: 'Email', sortable: true },
      { columnName: 'ProducerName', label: 'Producer Name' },
      { columnName: 'Disabled', label: 'Disabled', template: this.disabledColumnTemplate },
      { columnName: 'Lastlogin', label: 'Last Login', template: this.dateColumnTemplate },
      { columnName: 'ABSNewsFeed', label: 'ABS', template: this.disabledColumnTemplate },
    ];

    this.data$ = this.mpUsersService.GetAllMpUsers(0, 10, undefined, this.searchText.value).pipe(
      tap(res => {
        this.pagination = { ActualPage: 0, Index: 0, PageSize: 10, Total: res.recordsFiltered };
      })
    );
  }

  onPagination($event: PageEvent) {
    this.data$ = this.mpUsersService
      .GetAllMpUsers(
        $event.pageIndex,
        $event.pageSize,
        undefined,
        this.searchText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        tap(res => {
          this.pagination = {
            ActualPage: $event.pageIndex,
            Index: $event.pageIndex,
            PageSize: $event.pageSize,
            Total: res.recordsFiltered,
          };
        })
      );
  }
  onSearch($event: string) {
    this.searchText.next($event);

    this.data$ = this.mpUsersService
      .GetAllMpUsers(0, 10, undefined, this.searchText.value, this.sortDirection, this.sortColumn)
      .pipe(
        tap(res => {
          this.pagination = { ActualPage: 0, Index: 0, PageSize: 10, Total: res.recordsFiltered };
        })
      );
  }
  onSort(sort: Sort) {
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction === 'asc' ? 'asc' : 'dsc';
    this.data$ = this.mpUsersService.GetAllMpUsers(
      this.pagination.Index,
      this.pagination.PageSize,
      undefined,
      this.searchText.value,
      this.sortDirection,
      this.sortColumn
    );
  }
  customFormatDate(date: string) {
    return formatDate(date);
  }

  private mapTableToObject(producerData: ProducerUserData[]) {
    return producerData.map(producer => ({
      Username: producer.Username,
      FirstName: producer.FirstName,
      Email: producer.Email,
      ProducerName: producer.ProducerName,
      Disabled: producer.Disabled,
      LastLogin: producer.Lastlogin ?? 'Not Yet login',
      ABSNewsFeed: producer.ABSNewsFeed,
    }));
  }

  get isControllerView() {
    return this.view == ViewType.Controller;
  }
}
