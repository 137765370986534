import { Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { getErrorMessage } from '../form-field-errors/CustomErrorMessages';
import { FormControlWarn } from '../form-group-warn';

@Component({
  selector: 'app-form-input',
  template: `
    <div class="form_field" *ngIf="!loading">
      <div>
        <fa-icon *ngIf="Icon" ngClass="formIcon" [icon]="Icon"></fa-icon>
        <mat-form-field appearance="standard" [ngClass]="setClasses()" [style.width.px]="width">
          <mat-label>{{ title }}</mat-label>
          <input
            #inputElement
            matInput
            [formControl]="formField"
            [type]="type"
            [type]="forceVisibility && enableVisibilityToggle ? 'text' : type"
            [mask]="mask"
            [validation]="true"
            [hiddenInput]="hiddenInput"
            [autocomplete]="disableAutoFill ? 'new-password' : 'none'"
            (blur)="onTouched()"
            (input)="doInput(inputElement)"
            [title]="title" />
          <mat-error>{{ setErrorMessage() }}</mat-error>
          <mat-icon *ngIf="enableVisibilityToggle" matSuffix (click)="forceVisibility = !forceVisibility">{{
            forceVisibility ? 'visibility' : 'visibility_off'
          }}</mat-icon>
        </mat-form-field>
      </div>
      <div *ngIf="formField.warnings?.warningMessage" ngClass="warning">{{ formField.warnings.warningMessage }}</div>
    </div>
    <div *ngIf="loading" style="width: 100%;">
      <mat-label style="font-size: 11px; color: rgba(0, 0, 0, 0.6);">{{ title }}</mat-label>
      <app-skeleton-input></app-skeleton-input>
    </div>
  `,
  styleUrls: ['./form-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true,
    },
  ],
})
export class FormInputComponent implements OnInit, ControlValueAccessor {
  disabled: boolean = false;
  setErrorMessage() {
    return getErrorMessage(this.formField);
  }

  doInput(input: HTMLInputElement) {
    let value = input.value;

    value = this.normalizeInput(value);
    input.value = value;

    let inputValue = this.mask && this.sanitizeMask ? value.replace(/\D+/g, '') : value;

    this.onChange(inputValue);
  }
  constructor() {}
  forceVisibility: boolean = false;

  @Input() enableVisibilityToggle: boolean = false;
  @Input() mask: string | null | undefined;
  @Input() type = 'text';
  @Input() disableAutoFill: boolean = false;
  @Input() title: string = '';
  @Input() fieldName: string = '';
  @Input() primary = false;
  @Input() Wide: boolean = false;
  @Input() width?: number;
  @Input() Icon?: IconLookup;
  @Input() loading: boolean = false;
  @Input() forceCaps: boolean = false;
  @Input() hiddenInput: boolean = false;
  @Input() sanitizeMask: boolean = true;

  @Input() public parentForm?: FormGroup;
  get formField(): FormControlWarn {
    return this.parentForm?.get(this.fieldName) as FormControlWarn;
  }

  classes: string[] = [];

  @Input() value: any = null;

  onChange = (_: any) => {};
  onTouched = () => {};

  onInput(value: string) {
    value = this.normalizeInput(value);

    this.value = value;
  }

  writeValue(value: string): void {
    value = this.normalizeInput(value);
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.classes = this.setClasses();
  }

  normalizeInput(value: any) {
    if (this.forceCaps && value !== undefined && value !== null) {
      return value.toUpperCase();
    }
    return value;
  }

  public setClasses(): string[] {
    let classes = ['remove_autofill_background'];
    const mode = this.primary ? 'primary' : 'secondary';
    classes.push(mode);

    if (this.Wide) classes.push('wide');

    return classes;
  }
}
