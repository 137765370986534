import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticPage } from 'src/app/core/helpers/view-helper';
import { DashboardEmailResponse } from 'src/app/core/models/api/dashboard.email.response';
import { EmailService } from 'src/app/core/services/http/email.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-allied-benefit-suite',
  styleUrls: ['./allied-benefit-suite.component.scss'],
  template: `
    <app-content-card CustomTitle="Allied Benefit Suite">
      <ng-container *ngIf="html$ | withLoading | async as html">
        <app-skeleton-input *ngIf="html.loading"></app-skeleton-input>
        <div ngClass="wrapper" *ngIf="html.value" [innerHTML]="html.value.Data.TextEditor" #htmlDiv></div>
      </ng-container>
    </app-content-card>
  `,
})
export class AlliedBenefitSuiteComponent implements OnInit, AfterViewInit {
  html$: Observable<DashboardEmailResponse>;

  constructor(private emailService: EmailService, private viewService: UserViewService) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    console.debug('teste');
    this.GetEmailDashboard();
  }

  GetEmailDashboard() {
    let id = this.viewService.GetUserStaticPageIdByName(StaticPage.AlliedBenefitSuite);
    if (!id) return;

    this.html$ = this.emailService.GetDashboard(id);
  }
}
