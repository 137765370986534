import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { MpUser, MpUsersApiResponse } from 'src/app/core/models/api/user/mp-users.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { UserViewState } from 'src/app/core/models/user-view-state.model';
import { ViewType } from 'src/app/core/models/user-view.model';
import { FileService } from 'src/app/core/services/file.service';
import { MpUsersService } from 'src/app/core/services/http/mp-users.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-mp-users',
  styleUrls: ['./manage-mp-users.component.scss'],
  template: `
    <app-content-card CustomTitle="MP Users">
      <div style="display: flex; justify-content: flex-end; padding: 10px;">
        <custom-button
          [forceCapitalization]="true"
          label="+ ADD MP USER"
          [routerLink]="[addProducerUrl]"></custom-button>
      </div>
      <ng-container *ngIf="data$ | withLoading | async as data">
        <app-table
          [searchedText]="searchText.value"
          [IsLoading]="data.loading ?? false"
          [EnableSearch]="true"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [Rows]="data.value?.response"
          [EnablePagination]="true"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionColumnTemplate let-data>
      <a [routerLink]="[editProducerUrl, data.UserID]">
        <fa-icon [icon]="editIcon" style="color: var(--brandColor);"></fa-icon>
      </a>
    </ng-template>
    <ng-template #disabledColumnTemplate let-disabled>
      <span *ngIf="isControllerView">{{ disabled ? 'Y' : 'N' }}</span>
      <app-checkbox *ngIf="!isControllerView" [disabled]="true" [checked]="disabled"></app-checkbox>
    </ng-template>
    <ng-template #subProducerColumnTemplate let-data>
      <span> {{ data ? 'Y' : 'N' }}</span>
    </ng-template>
    <ng-template #commissionStatementsColumnTemplate let-data>
    <span> {{ data ? 'Y' : 'N' }}</span>
    </ng-template>
    <ng-template #dateColumnTemplate let-data>
      {{ customFormatDate(data) }}
    </ng-template>
  `,
})
export class ManageMpUsersComponent implements OnInit {
  pagination: ManualPagination;
  searchText: BehaviorSubject<string> = new BehaviorSubject<string>('');
  sortFilter: BehaviorSubject<'asc' | 'dsc'> = new BehaviorSubject<'asc' | 'dsc'>('asc');

  editIcon = faPencil;
  editProducerUrl = RouteEnum.EditProducerUser;
  addProducerUrl = RouteEnum.AddProducerUser;

  view: ViewType | undefined;

  displayedColumns: DisplayedColumns[];
  downloadButtons: TableDownloadButtons;
  data$: Observable<MpUsersApiResponse>;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('disabledColumnTemplate', { static: true }) disabledColumnTemplate: TemplateRef<unknown>;
  @ViewChild('dateColumnTemplate', { static: true }) dateColumnTemplate: TemplateRef<unknown>;
  @ViewChild('subProducerColumnTemplate', { static: true }) subProducerColumnTemplate: TemplateRef<unknown>;
  @ViewChild('commissionStatementsColumnTemplate', { static: true }) commissionStatementsColumnTemplate: TemplateRef<unknown>;
  viewState: UserViewState | null;

  constructor(
    private mpUsersService: MpUsersService,
    private fileService: FileService,
    private userViewService: UserViewService
  ) {}

  ngOnInit(): void {
    this.viewState = this.userViewService.GetCurrentUserViewState();

    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let headers = [
            {
              Username: 'Username',
              FirstName: 'FirstName',
              Email: 'Email',
              ProducerName: 'ProducerName',
              Disabled: 'Disabled',
              LastLogin: 'LastLogin',
              SubProducer: 'Sub Producer',
              CommissionStatements: 'Commission Statements'
            },
          ];

          this.data$.pipe(take(1)).subscribe(x => {
            this.fileService
              .GeneratePDF('Allied Administrators - Mp Users', headers, this.mapTableToObject(x.response))
              .save('Allied Administrators - Mp Users');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.data$.pipe(take(1)).subscribe(x => {
            this.fileService.GenerateXLSX('Allied Administrators - Mp Users', this.mapTableToObject(x.response));
          });
        },
      },
    };
    this.displayedColumns = [
      { columnName: 'Username', label: 'UserName', sortable: true },
      { columnName: 'FirstName', label: 'First Name' },
      { columnName: 'Email', label: 'Email', sortable: true },
      { columnName: 'Phone', label: 'Phone' },
      { columnName: 'Lastlogin', label: 'Last Login', template: this.dateColumnTemplate },
      { columnName: 'IsSubProducer', label: 'Sub Producer', template: this.subProducerColumnTemplate },
      { columnName: 'AllowedToSeeCommissionStatements', label: 'Commission Statements', template: this.commissionStatementsColumnTemplate },
      { columnName: 'Disabled', label: 'Disabled', template: this.disabledColumnTemplate },
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];

    if (this.viewState?.MasterProducerId)
      this.data$ = this.mpUsersService.GetMpUsers(Number(this.viewState.MasterProducerId));
  }
  customFormatDate(date: string) {
    return formatDate(date);
  }
  private toggleSort() {
    this.sortFilter.next(this.sortFilter.value == 'asc' ? 'dsc' : 'asc');
  }

  private mapTableToObject(producerData: MpUser[]) {
    return producerData.map(producer => ({
      Username: producer.Username,
      FirstName: producer.FirstName,
      Email: producer.Email,
      ProducerName: producer.ProducerName,
      Disabled: producer.Disabled,
      LastLogin: this.customFormatDate(producer.Lastlogin) ?? 'Not Yet login',
      SubProducer: producer.IsSubProducer === true ? 'Y' :'N',
      CommissionStatements: producer.AllowedToSeeCommissionStatements=== true ? 'Y' :'N'
    }));
  }

  get isControllerView() {
    return this.view == ViewType.Controller;
  }
}
