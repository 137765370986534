import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-number',
  styleUrls: ['./card-number.component.scss'],
  template: `
    <div ngClass="chart_card number_card">
      <span ngClass="title">{{ title }}</span>
      <span ngClass="value">{{ value }}</span>
    </div>
  `,
})
export class CardNumberComponent implements OnInit {
  constructor() {}
  @Input() title = '';
  @Input() value: number = 0;

  ngOnInit(): void {}
}
