import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import saveAs from 'file-saver';
import { Observable, catchError, map, take, throwError } from 'rxjs';
import { IDocument, DocumentApiResponse } from 'src/app/core/models/api/documents.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { DocumentService } from 'src/app/core/services/http/document.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { ShowButtons, ShowColumns, FileEvent } from '../documents.component';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { EmployerDocumentJournal, ProducerDocumentJournal } from 'src/app/shared/components/view-journal/view-journal.component';

@Component({
  selector: 'app-employer-documents',
  styleUrls: ['./employer-documents.component.scss'],
  template: `
    <ng-container *ngIf="documents$ | withLoading | async as documents">
      <ng-container *ngIf="documents.loading">
        <app-documents
          [showButtons]="showButtons"
          [modalProps]="modalProps"
          [openUploadDocumentModal]="openUploadDocumentModal"
          [showColumns]="showColumns"
          [sendNotificationButtonLabel]="sendNotificationButtonLabel"
          [documents$]="[]"
          [isLoading]="true"
          (documentDropped)="onUploadDocument($event)"
          (onDownloadDocumentEvent)="onDownloadDocument($event)"
          (onUnarchiveFileEvent)="onUnarchiveFile($event)"
          (onArchiveFileEvent)="onArchiveFile($event)"
          (onDeleteFileEvent)="onDeleteFile($event)"
          (onSendNotificationEvent)="onSendEmailToProducer()"
          [showViewJournal]="showViewJournal"
          [journalInput]="journalInput" >
        </app-documents>
      </ng-container>

      <ng-container *ngIf="documents.value">
        <app-documents
          [showButtons]="showButtons"
          [modalProps]="modalProps"
          [openUploadDocumentModal]="openUploadDocumentModal"
          [sendNotificationButtonLabel]="sendNotificationButtonLabel"
          [showColumns]="showColumns"
          [documents$]="documents.value"
          [isLoading]="false"
          (documentDropped)="onUploadDocument($event)"
          (onDownloadDocumentEvent)="onDownloadDocument($event)"
          (onUnarchiveFileEvent)="onUnarchiveFile($event)"
          (onArchiveFileEvent)="onArchiveFile($event)"
          (onDeleteFileEvent)="onDeleteFile($event)"
          (onSendNotificationEvent)="onSendEmailToProducer()"
          [showViewJournal]="showViewJournal"
          [journalInput]="journalInput">
        </app-documents>
      </ng-container>
    </ng-container>
  `,
})
export class EmployerDocumentsComponent implements OnInit {
  employerId: string;
  userId?: string;
  file: File;

  //Controls
  openUploadDocumentModal: boolean;

  //Table
  documents$: Observable<IDocument[]>;
  rows$: IDocument[];
  archivedRows$: IDocument[];

  modalProps: ModalProps;
  showButtons: ShowButtons;
  showColumns: ShowColumns;
  showViewJournal: boolean = false;
  journalInput: EmployerDocumentJournal;
  sendNotificationButtonLabel: string = 'Send email to group';

  constructor(
    private authService: AuthService,
    private documentService: DocumentService,
    private userViewService: UserViewService,
    private notifcationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.openUploadDocumentModal = false;
    let userViewState = this.userViewService.GetCurrentUserViewState();
    if (userViewState && userViewState.EmployerId) this.employerId = userViewState.EmployerId;
    let isAdmin = this.authService.IsAdmin();

    this.authService.userSession.pipe(take(1)).subscribe(session => {
      this.userId = session?.UserID.toString();
    });


    this.journalInput = {
      EmployerId: parseInt( this.employerId),
      EventType: 'NG'
    };


    this.showViewJournal = isAdmin === true;

    this.showButtons = {
      ShowArchiveRowButton: isAdmin,
      ShowUnarchiveRowButton: isAdmin,
      ShowUploadDocumentButton: isAdmin,
      ShowDeleteRowButton: isAdmin,
      ShowSendNotificationButton: isAdmin,
    };

    this.showColumns = {
      showUserNameField: isAdmin,
      showCount: false,
      showSize : false,
      showActive: false
    };

    this.modalProps = {
      OkButton: {
        Label: 'UPLOAD',
        OnAction: () => {
          if (this.userId)
            this.documentService.Uploadfile(this.file, this.employerId, this.userId.toString()).subscribe(res => {
              if (res.Status) this.notifcationService.success('Uploaded successfully');
              else this.notifcationService.error('Something went wrong');
              this.updateDocuments();
            });
        },
      },
      CloseButton: {
        OnAction: () => {
          this.openUploadDocumentModal = false;
        },
        show: false,
      },
      CanCloseModal: true,
    };

    this.updateDocuments();
  }

  // Action buttons
  onDownloadDocument(fileEvent: FileEvent) {
    this.documentService.Downloadfile(this.employerId, fileEvent.fileId).subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(data, fileEvent.fileName);
    });
  }

  onUnarchiveFile(fileEvent: FileEvent) {
    this.documentService.Unarchivedoc(this.employerId, fileEvent.fileId).subscribe(res => {
      this.notifcationService.success('File Unarchived successfully');
      this.updateDocuments();
    });
  }

  onArchiveFile(fileEvent: FileEvent) {
    this.documentService.Archivedoc(this.employerId, fileEvent.fileId).subscribe(res => {
      this.notifcationService.success('File archived successfully');
      this.updateDocuments();
    });
  }

  onDeleteFile(fileEvent: FileEvent) {
    this.notifcationService.confirmation('Do you want to delete this Document?', () => {
      this.documentService.Deletedoc(fileEvent.fileId).subscribe(res => {
        if (res.Status) this.notifcationService.success(res.Message);
        else this.notifcationService.error(res.Message);
        this.updateDocuments();
      });
    });
  }

  onUploadDocument(file: File) {
    this.file = file;
    this.openUploadDocumentModal = false;
  }

  onSendEmailToProducer() {
    this.documentService
      .Sendnotification(this.employerId)
      .pipe(
        catchError(err => {
          this.notifcationService.error('Something went wrong');
          return throwError(() => err);
        })
      )
      .subscribe(res => {
        if (res) this.notifcationService.success('Notification Sent');
        else this.notifcationService.error('Something went wrong');
      });
  }

  private mapToTableRows() {
    return map<DocumentApiResponse, IDocument[]>(res => {
      return res.Data.map(document => ({ ...document, UploadedDate: formatDate(document.UploadedDate) }));
    });
  }




  private updateDocuments() {
    this.documents$ = this.documentService.GetDocuments(this.employerId).pipe(
      this.mapToTableRows(),
      map(res => {
        this.rows$ = res.filter(document => !document.Archived);
        this.archivedRows$ = res.filter(document => document.Archived);
        return res;
      })
    );
  }
}
