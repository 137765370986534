import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { Observable } from 'rxjs';
import { ManageEmailApiResponse } from 'src/app/core/models/api/email/manage-email.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { EmailService } from 'src/app/core/services/http/email.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-emails',
  styleUrls: ['./manage-emails.component.scss'],
  template: `
    <app-content-card CustomTitle="Manage Emails">
      <ng-container *ngIf="data$ | withLoading | async as data">
        <app-table
          *ngIf="data.value"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [Rows]="data.value.Data"
          [EnablePagination]="true"
          [EnableLocalSort]="true"
          [EnableSearch]="true"
          [EnableLocalSearch]="true"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionColumnTemplate let-id>
      <a [routerLink]="[emailConfigurationLink, id]">
        <fa-icon [icon]="editIcon" style="color: var(--brandColor);"></fa-icon>
      </a>
    </ng-template>
  `,
})
export class ManageEmailsComponent implements OnInit {
  editIcon = faPencil;
  emailConfigurationLink = RouteEnum.UnderDevelopment;

  displayedColumns: DisplayedColumns[];
  downloadButtons: TableDownloadButtons;
  data$: Observable<ManageEmailApiResponse>;

  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;

  constructor(private emailService: EmailService) {}

  ngOnInit(): void {
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {}, //TODO imeplements manage email download pdf
      },
      DownloadXLSX: {
        callback: () => {}, //TODO imeplements manage email download xlsx
      },
    };

    this.displayedColumns = [
      { columnName: 'Action ', label: 'Action', overrideValueFromColumn: 'Id', template: this.actionColTemplate },
      { columnName: 'MailTemplate ', label: 'Email Template Name', sortable: true },
      { columnName: 'Environment', label: 'Environment', sortable: true },
      { columnName: 'ToMail', label: 'To Mail', sortable: true },
      { columnName: 'Ccmail', label: 'CC Mail', sortable: true },
      { columnName: 'Bccmail', label: 'BCC Mail', sortable: true },
    ];

    this.data$ = this.emailService.GetEmailsManagement();
  }
}
