import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InvoiceResponse } from '../../models/api/invoice/invoice.response';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  constructor(private http: HttpClient) {}

  GetInvoiceIFrameUrl(employerNumber: string): Observable<InvoiceResponse> {
    return this.http.get<InvoiceResponse>(`${environment.alliedApi.baseUrl}/sso/invoices/${employerNumber}`);
  }
}
