import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-log-details',
  styleUrls: ['./log-details.component.scss'],
  template: `
    <div ngClass="log_details">
      <div>
        <i class="md-list-addon-icon material-icons color primary">lock</i>
        <label> Total Logins :</label>
        <label class="ng-binding">{{ logDetailsInfo.TotalLogins }}</label>
      </div>
      <div>
        <i class="md-list-addon-icon material-icons uk-text-success color success">check_circle</i>
        <label> Success Logins :</label>
        <label class="ng-binding">{{ logDetailsInfo.SuccessLogins }}</label>
      </div>
      <div>
        <i class="md-list-addon-icon material-icons uk-text-danger color danger">remove_circle</i>
        <label> Failed Logins :</label>
        <label>{{ logDetailsInfo.FailedLogins }}</label>
      </div>
      <div>
        <i class="md-list-addon-icon material-icons uk-text-warning color warning">watch_later</i>
        <label> Last Login Attempt :</label>
        <label>
          {{ logDetailsInfo.LastLoginAttempt }}
          <span>
            -
            <i
              *ngIf="logDetailsInfo.LastLoginStatus; else noLastLoginStatus"
              class="md-list-addon-icon material-icons color success"
              >check_circle</i
            >
            <ng-template #noLastLoginStatus>
              <i class="md-list-addon-icon material-icons color success">remove_circle</i>
            </ng-template>
          </span>
        </label>
      </div>
      <div>
        <i class="md-list-addon-icon material-icons color warning">exit_to_app</i>
        <label> Last Log Off : </label>
        <label>{{ logDetailsInfo.LastLogOff }}</label>
      </div>
    </div>
  `,
})
export class LogDetailsComponent implements OnInit {
  constructor() {}
  @Input() logDetailsInfo: LogDetailsInfo = {};

  ngOnInit() {}
}

export type LogDetailsInfo = {
  TotalLogins?: number;
  SuccessLogins?: number;
  FailedLogins?: number;
  LastLoginAttempt?: string;
  LastLoginStatus?: boolean;
  LastLogOff?: string;
};
