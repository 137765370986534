import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MpUsersRequest } from '../../models/api/user/mp-users.request';
import { ProducerUsersApiResponse } from '../../models/api/user/mp-all-users.response';
import { MpUsersApiResponse } from '../../models/api/user/mp-users.response';

@Injectable({
  providedIn: 'root',
})
export class MpUsersService {
  constructor(private http: HttpClient) {}

  GetAllMpUsers(
    page: number,
    pageSize: number,
    statusFilter: number = 1,
    search: string = '',
    orderBy: 'asc' | 'dsc' = 'asc',
    orderByColumn: string = 'UserName'
  ) {
    return this.http.post<ProducerUsersApiResponse>(
      `${environment.alliedApi.baseUrl}/UserPrivileges/GetAllPRUsers`,
      new MpUsersRequest({
        orderByColumnName: orderByColumn,
        start: page * pageSize,
        length: pageSize,
        actualPage: page,
        search: search,
        orderBy: orderBy,
        statusFilter: statusFilter,
      }).toRequest(),
      { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
    );
  }

  // Should be in User Control Service but it is here because of its context
  GetMpUsers(producerId: number) {
    return this.http.get<MpUsersApiResponse>(`${environment.alliedApi.baseUrl}/Userctrl/getPRUser/?id=${producerId}`);
  }
}
