import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { MarketResource } from 'src/app/core/models/api/marketing-resource/marketing-resource-api.response';
import { StateInfo } from 'src/app/core/models/api/marketing-resource/states-and-description.response';
import { API_ERROR_MESSAGE } from 'src/app/core/models/constants/error-messages';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { MarkertingResourceService } from 'src/app/core/services/http/markerting-resource.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { MarketingResourceJournal } from 'src/app/shared/components/view-journal/view-journal.component';
import { FileHandle } from 'src/app/shared/directives/dragDrop.directive';

@Component({
  selector: 'app-add-edit-marketing-resource',
  styleUrls: ['./add-edit-marketing-resource.component.scss'],
  template: `
    <div style="display: flex; justify-content: flex-end">
      <custom-button
        [forceCapitalization]="true"
        [routerLink]="marketingResourcesUrl"
        style="align-self: flex-end; margin-bottom: 10px"
        color="secondary"
        [icon]="arrowLeft"
        label="BACK"></custom-button>
    </div>
    <app-content-card [CustomTitle]="CustomTitle">
      <app-skeleton-input style="padding: 20px;" *ngIf="loadingData"></app-skeleton-input>

      <div style="display: flex; flex-direction: column; padding: 20px; gap: 20px;" *ngIf="!loadingData">
        <form [formGroup]="markertingResourceForm" style="width: 50%;">
          <div>
            <span>Select file to upload to be shared with Master Producer</span>
            <app-form-select
              [Options]="shareTypesOptions"
              [parentForm]="markertingResourceForm"
              formControlName="shareType"
              FieldName="shareType"></app-form-select>

            <div *ngIf="showSelectStates">
              <span>Select the state(s) based on where the GA is located:</span>

              <div ngClass="statesSelectGrid">
                <app-form-checkbox
                  *ngFor="let _ of stateFormArray.controls; let i = index"
                  [parentForm]="getFormGroupAtIndex(i)"
                  [name]="getFormGroupAtIndex(i).get('stateName')?.value"
                  fieldName="selected"></app-form-checkbox>
              </div>
            </div>
          </div>
          <app-form-input
            [Wide]="true"
            formControlName="description"
            title="Description"
            [parentForm]="markertingResourceForm"
            fieldName="description"></app-form-input>
        </form>
        <app-drag-drop-download
          (onFileDropped)="onFileDropped($event)"
          [file]="file"
          [errorMessage]="fileErrorMessage"></app-drag-drop-download>
        <custom-button label="SAVE" style="align-self: flex-end;" (onClick)="onSubmit()"></custom-button>
        <custom-button
          [forceCapitalization]="true"
          *ngIf="isEditting"
          style="align-self: center;"
          label="VIEW JOURNAL"
          [color]="'secondary'"
          [Wide]="false"
          (onClick)="openJournal = true"
          [icon]="historyIcon"></custom-button>
        <app-view-journal
          *ngIf="isEditting"
          [journalInput]="journalInput"
          [openJournal]="openJournal"></app-view-journal>
      </div>
    </app-content-card>
  `,
})
export class AddEditMarketingResourceComponent implements OnInit {
  arrowLeft = faArrowLeft;
  CustomTitle: string;
  markertingResourceForm: FormGroup<any>;
  showSelectStates: boolean = false;
  loadingData: boolean = false;
  historyIcon = faClockRotateLeft;
  openJournal: boolean = false;
  marketingResourcesUrl = RouteEnum.MarketingResources;

  file: FileHandle;

  shareTypesOptions: SelectOptions<string>[] = [
    {
      optionText: 'Select',
      key: '',
    },
    {
      optionText: 'To All GA',
      key: 'All',
    },
    {
      optionText: 'Only ABS GA',
      key: 'Abs',
    },
    {
      optionText: 'All GA in These Selected States',
      key: 'AllState',
    },
    {
      optionText: 'Only ABS in These Selected States GA',
      key: 'AbsState',
    },
  ];
  journalInput: MarketingResourceJournal;
  mktResourceId: number;
  isEditting: boolean = false;

  marketingResourceRequest: MarketResource;
  fileErrorMessage: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private marketingResourceService: MarkertingResourceService,
    private notificatioNService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mktResourceId = Number(this.route.snapshot.paramMap.get('id'));
    console.log(this.mktResourceId);
    if (this.mktResourceId) {
      this.isEditting = true;
      this.journalInput = { marketingResourceId: this.mktResourceId };
    }

    this.initForm();

    this.CustomTitle = this.isEditting ? 'Edit Marketing Resource' : 'Add Marketing Resource';

    this.loadingData = true;

    this.marketingResourceService.GetStatesAndDescription().subscribe(res => {
      if (res.status) {
        this.fillStatesInForm(res.response[0].Statelist);
      } else this.notificatioNService.error(API_ERROR_MESSAGE);

      if (this.isEditting) {
        this.marketingResourceService.GetMarketingResourceByID(this.mktResourceId.toString()).subscribe(res => {
          this.marketingResourceRequest = res.response[0];
          this.initForm(res.response[0]);
          this.onShareTypeChange();
          this.file = { file: new File([], res.response[0].Location) } as FileHandle;
          this.loadingData = false;
        });
        return;
      }
      this.onShareTypeChange();
      this.loadingData = false;
    });
  }

  onSubmit() {
    if (this.markertingResourceForm.invalid && this.file === undefined) {
      this.fileErrorMessage = 'File is Required';
      this.markertingResourceForm.markAllAsTouched()
      console.log('Invalid');
      return;
    }

    let {
      shareType,
      description,
      states,
    }: { shareType: string; description: string; states: { stateSymbol: string; selected: boolean }[] } =
      this.markertingResourceForm.value;

    this.marketingResourceService
      .UpsertMarketingResource(
        this.mktResourceId.toString(),
        shareType,
        states
          .filter(x => x.selected)
          .map(x => x.stateSymbol)
          .toString(),
        description,
        this.isEditting ? this.file.file.name : this.file.file
      )
      .subscribe(res => {
        if (res.status) this.notificatioNService.success(res.message);
        else this.notificatioNService.error(res.message);
        this.router.navigateByUrl(this.marketingResourcesUrl)
      });
  }

  onShareTypeChange() {
    let shareTypeField = this.markertingResourceForm.get('shareType');

    shareTypeField?.valueChanges.subscribe(
      shareType => (this.showSelectStates = ['AbsState', 'AllState'].includes(shareType))
    );

    shareTypeField?.updateValueAndValidity();
  }

  onFileDropped(FileHandle: FileHandle) {
    this.file = FileHandle;
    this.fileErrorMessage = ''
  }

  private initForm(data?: MarketResource) {
    if (data) {
      this.markertingResourceForm = this.fb.group({
        shareType: [data.GANR, Validators.required],
        description: [data.FileName, Validators.required],
        states: this.fb.array(
          data.Statelist.map(x =>
            this.fb.group({
              stateName: [x.StateName],
              stateSymbol: [x.StateCd],
              selected: [x.status],
            })
          )
        ),
      });
      return;
    }
    this.markertingResourceForm = this.fb.group({
      shareType: ['', Validators.required],
      description: ['', Validators.required],
      states: this.fb.array([]),
    });
  }

  private fillStatesInForm(stateInfo: StateInfo[]) {
    stateInfo.forEach(state => {
      this.stateFormArray.push(
        this.fb.group({
          stateName: [state.StateName],
          selected: [false],
        })
      );
    });
  }

  get stateFormArray() {
    return this.markertingResourceForm.get('states') as FormArray;
  }

  getFormGroupAtIndex(index: number) {
    return this.stateFormArray.at(index) as FormGroup;
  }
}
