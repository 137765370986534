import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { SelectOptions } from 'src/app/shared/components/select/select.component';

type UserTypeSelected = 'producer' | 'employer' | 'none';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss', '../../styles.scss'],
})
export class SignUpComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  UserTypeSelected: UserTypeSelected = 'employer';
  ShowForm: boolean = false;

  Options: SelectOptions<UserTypeSelected>[] = [
    { key: 'producer', optionText: 'Producer' },
    { key: 'employer', optionText: 'Employer' },
  ];

  constructor(private formBuilder: FormBuilder) {}

  showForm() {
    this.ShowForm = true;
    console.log(this.ShowForm, this.UserTypeSelected);
  }

  showUserSelectType() {
    this.ShowForm = false;
  }

  onSelectOutput(event?: UserTypeSelected) {
    this.UserTypeSelected = event ?? 'none';
  }

  ngOnInit(): void {
    console.log('building sign up', this.ShowForm, this.UserTypeSelected);
  }

  GetUserTypeText(userType: UserTypeSelected): string | undefined {
    return new Map<UserTypeSelected, string>([
      ['producer', 'Producer'],
      ['employer', 'Employer'],
    ]).get(userType);
  }

  GetMainContainerClass() {
    let classes = ['sign_up_container'];
    if (this.UserTypeSelected === 'none' && !this.ShowForm) classes.push('first_step');
    console.log(this.ShowForm, this.UserTypeSelected);
    return classes;
  }
}
