import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VersionHistoryResponse } from "./version-history.response";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root',
  })
  export class VersionHistoryService {
    constructor(private http: HttpClient){

    }


    getLatestVersion(){
        return this.http.get<VersionHistoryResponse>(`${environment.alliedApi.baseUrl}/versionhistory/GetLatestVersion`);
    }

    postVersionHistory(versionHistory: VersionHistoryResponse){
        return this.http.post(`${environment.alliedApi.baseUrl}/versionhistory/PostVersion`, versionHistory);
    }

    archiveVersion(id: number){
        return this.http.put(`${environment.alliedApi.baseUrl}/versionhistory/ArchiveVersion/${id}`, null);
    }

    getArchivedVersions(){
        return this.http.get<VersionHistoryResponse[]>(`${environment.alliedApi.baseUrl}/versionhistory/GetArchivedVersions`);
    }

    versionNumberExists(versionNumber: number){
        return this.http.get<boolean>(`${environment.alliedApi.baseUrl}/versionhistory/VersionExists/${versionNumber}`);
    }
  }