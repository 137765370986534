import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { memoize } from 'lodash-es';
import { map, merge, of } from 'rxjs';
import { AccordionItemDirective } from './diretives/accordion-item.directive';
@Component({
  selector: 'app-accordion',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ]),
  ],
  template: `
    <div ngClass="wrapper">
      <div *ngFor="let item of items; index as i">
        <ng-container
          [ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
          [ngTemplateOutletContext]="{
            $implicit: item,
            index: i,
            toggle: getToggleState(i)
          }"></ng-container>
        <div
          ngClass="expandable-content"
          [class.expanded]="expanded.has(i)"
          [@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'">
          <ng-container *ngTemplateOutlet="item.content.templateRef"></ng-container>
        </div>
      </div>
    </div>

    <ng-template #defaultHeader let-item let-index="index">
      <header ngClass="title" (click)="item.disabled ? {} : toggleState(index)">
        <ng-container *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"></ng-container>
      </header>
      <ng-template #defaultTitle>
        <span>{{ item?.title }}</span>
      </ng-template>
    </ng-template>
  `,
})
export class AccordionComponent implements AfterViewInit {
  expanded = new Set<number>();
  @Input() Open: boolean = false;

  /**
   * Decides if the single item will be open at once or not.
   * In collapsing mode, toggling one would collapse others
   */
  @Input() collapsing = true;

  @ContentChildren(AccordionItemDirective)
  items: QueryList<AccordionItemDirective>;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    merge(this.items.changes, of(this.items))
      .pipe(map(() => this.items.toArray()))
      .subscribe(items => {
        items.forEach((item, index) => {
          if (item.expanded) {
            this.expanded.add(index);
          }
        });
        this.cdr.detectChanges();
      });
  }

  OnClick() {
    this.Open = !this.Open;
  }

  GetContentClasses() {
    let classes = ['expandable-content'];
    if (this.Open) classes.push('open');
    else classes.push('closed');
    return classes;
  }

  /**
   * Make the toggle function available to be called from
   * outside.
   * Memoize to prevent extra calls
   * @param index - index of the accordion item
   */
  getToggleState = memoize((index: number) => {
    return this.toggleState.bind(this, index);
  });

  toggleState = (index: number) => {
    if (this.expanded.has(index)) {
      this.expanded.delete(index);
    } else {
      if (this.collapsing) {
        this.expanded.clear();
      }
      this.expanded.add(index);
    }
  };
}
