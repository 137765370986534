import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './authentication.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';

const routes: Routes = [
  {
    path: '',
    component: AuthenticationComponent,
    children: [
      {
        path: 'signin',
        component: SignInComponent,
        data: { animation: 'isLeft' },
        title: 'Allied Administrators - Sign In'
      },
      {
        path: 'signup',
        component: SignUpComponent,
        data: { animation: 'isRight' },
        title: 'Allied Administrators - Sign Up'
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { animation: 'isRight' },
        title: 'Allied Administrators - Forgot Password'
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
