import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthenticationComponent } from './authentication.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProducerSignUpFormComponent } from './pages/sign-up/forms/producer-form/producer-form.component';
import { EmployerSignUpFormComponent } from './pages/sign-up/forms/employer-form/employer-form.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';

@NgModule({
  declarations: [
    SignInComponent,
    AuthenticationComponent,
    SignUpComponent,
    ProducerSignUpFormComponent,
    EmployerSignUpFormComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    AuthenticationRoutingModule,
  ],
})
export class AuthenticationModule {}
