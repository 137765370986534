import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft, faFloppyDisk, faKey, faLock } from '@fortawesome/free-solid-svg-icons';
import randomatic from 'randomatic';
import { Observable, catchError, debounceTime, of, tap, throwError } from 'rxjs';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { BaUserApiResponse } from 'src/app/core/models/api/ba-user.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { BenefitAdministratorService } from 'src/app/core/services/http/benefit-administrator.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';
import { LogDetailsInfo } from 'src/app/shared/components/profile/log-details/log-details.component';

@Component({
  selector: 'app-edit-ba',
  styleUrls: ['./edit-ba.component.scss'],
  template: `
    <app-content-card CustomTitle="Edit BA">
      <form [formGroup]="benefitAdministratorForm">
        <div
          *ngIf="userData$ | withLoading | async as userData"
          style="display: flex; flex-direction: column; padding: 15px 30px;">
          <app-user-info-form
            [hasMFA]="true"
            [hasBaNameField]="true"
            [isLoading]="userData.loading ?? false"
            [userForm]="benefitAdministratorForm"></app-user-info-form>
          <custom-button
            *ngIf="userData.value"
            [icon]="keyIcon"
            (onClick)="showResetPasswordModal = true"
            style="align-self: flex-start"
            label="CHANGE PASSWORD"></custom-button>

          <div style="display: flex; justify-content:space-between; padding-top:20px">
            <div >
                <custom-button *ngIf="userLocked"
                [icon]="lockedUserIcon"
                style="align-self: flex-start;"
                (onClick)="unlockUser()"
                label="UNLOCK USER">
                </custom-button>
                <span *ngIf="badLoginAttempts > 0" style="align-self: flex-start;"><i class="md-list-addon-icon material-icons uk-text-danger color danger">remove_circle</i>Total Failed Attempts: {{badLoginAttempts}}</span>
            </div>
          </div>
          <div style="padding: 10px; color: #444;" *ngIf="userData.value">
            <span style="font-weight: 700;">Log Details:</span>
            <app-log-details *ngIf="userData.value" [logDetailsInfo]="logDetailsInfo"></app-log-details>
          </div>
          <!-- Buttons -->
          <div style="display: flex; justify-content: flex-end; margin-top: 10px">
            <custom-button
              *ngIf="userData.value"
              label="SAVE BA INFO"
              [Wide]="false"
              [primary]="true"
              [IsLoading]="saveLoading"
              [icon]="floppyDisk"
              [disabled]="!benefitAdministratorForm.dirty"
              (onClick)="userData.value && onSubmit(userData.value)"></custom-button>
          </div>
          <app-password-modal
            *ngIf="userData.value"
            [showResetPasswordModal]="showResetPasswordModal"
            [userForm]="benefitAdministratorForm"
            [isLoading]="changePasswordLoading"
            (onGeneratePassword)="onGeneratePassword()"
            (onResetPassword)="onResetPassword(userData.value)"></app-password-modal>
        </div>
      </form>
    </app-content-card>
  `,
})
export class EditBaComponent implements OnInit {
  floppyDisk = faFloppyDisk;
  keyIcon = faKey;
  arrowLeft = faArrowLeft;
  lockedUserIcon = faLock;


  saveLoading: boolean;
  showResetPasswordModal: boolean = false;
  changePasswordLoading: boolean;

  benefitAdministratorForm: FormGroup;
  userData$: Observable<BaUserApiResponse>;
  logDetailsInfo: LogDetailsInfo;
  benefitAdministratorSelectUrl = RouteEnum.SelectBaUsers;
  userId: number;
  actualUsername: string;
  userLocked: boolean = false;
  badLoginAttempts: number = 0;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private baService: BenefitAdministratorService,
    private userControlService: UserControlService,
    private notificationService: NotificationService,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.userId = Number(this.route.snapshot.paramMap.get('id'));
    this.saveLoading = false;
    this.initEmptyForm();
    this.onUsernameChange()

    this.userData$ = this.baService.GetBaUser(this.userId).pipe(
      tap(res => {
        let baUser = res.response[0];
        this.benefitAdministratorForm = this.fb.group({
          benefitAdministratorName: [baUser.ProducerName],
          firstName: [baUser.FirstName],
          lastName: [baUser.LastName, Validators.required],
          email: [baUser.Email, Validators.required],
          phoneNumber: [baUser.Phone],
          userName: [baUser.Username, Validators.required],
          baName: [{ value: baUser.ProducerName, disabled: true }, Validators.required],
          password: [''],
          disabled: [baUser.Disabled],
          twoFactor: [baUser.mfaEnabled],
        });

        this.userLocked = res.response[0].IsLockOut;
        this.badLoginAttempts = res.response[0].Bad_login;

        this.logDetailsInfo = {
          FailedLogins: baUser.Failed_login,
          SuccessLogins: baUser.Success_login,
          TotalLogins: baUser.Failed_login + baUser.Success_login,
          LastLoginAttempt: baUser.Lastlogin,
          LastLoginStatus: baUser.LastloginStatus,
          LastLogOff: baUser.Lastlogoff,
        };
        this.actualUsername = baUser.Username
        this.onUsernameChange()
      })
    );
  }

  private initEmptyForm() {
    this.benefitAdministratorForm = this.fb.group({
      benefitAdministratorName: [''],
      firstName: [''],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: [''],
      userName: ['', Validators.required],
      baName: [{ value: '', disabled: true }],
      twoFactor: [''],
      password: [''],
      disabled: [''],
    });
  }

  unlockUser(){
    this.notificationService.confirmation("Are you sure you want to Unlock this user?", () => {
      this.authService.UnlockUser(this.userId ?? 0).subscribe(res => {

        if(res.status === true){
          this.notificationService.success(res.response);
          window.location.reload();

        }else{
          this.notificationService.error(res.response);
        }
      });
    });
  }

  onSubmit(response: BaUserApiResponse) {
    if (this.benefitAdministratorForm.invalid) {
      this.benefitAdministratorForm.markAllAsTouched();
      return;
    }

    let { userName, firstName, lastName, password, email, phoneNumber, disabled } = this.benefitAdministratorForm.value;

    let user = response.response[0];
    user = {
      ...user,
      Username: userName,
      FirstName: firstName,
      LastName: lastName,
      Phone: phoneNumber,
      Email: email,
      Disabled: disabled,
      mfaEnabled: this.benefitAdministratorForm.get('twoFactor')?.value,
      Type: AuthenticationUserType.Producer,
    };

    this.saveLoading = true;
    this.userControlService
      .CreateOrUpdateUser(user)
      .pipe(
        catchError(error => {
          this.notificationService.error('Something went wrong');
          this.saveLoading = false;
          return throwError(() => error);
        })
      )
      .subscribe(res => {
        if (res.status) {
          this.notificationService.success(res.message);
          this.router.navigate([this.benefitAdministratorSelectUrl]);
        } else this.notificationService.error(res.message);
        this.saveLoading = false;
      });
  }

  onGeneratePassword() {
    this.benefitAdministratorForm.get('password')?.patchValue(randomatic('Aa0!', 10));
  }

  onResetPassword(response: BaUserApiResponse) {
    let passwordField = this.benefitAdministratorForm.get('password');
    let userField = this.benefitAdministratorForm.get('userName');

    this.changePasswordLoading = true;

    if (passwordField?.valid && this.userId && userField?.value) {
      // TODO payload not correct, returning exception. Fix that
      this.authService
        .ResetPassword(
          passwordField.value,
          this.userId.toString(),
          userField.value,
          AuthenticationUserType.Producer,
          response.response[0].masterproducerid
        )
        .subscribe(res => {
          if (res.status) this.notificationService.success(res.message);
          else this.notificationService.error(res.message);

          this.showResetPasswordModal = false;
          this.changePasswordLoading = false;
        });
    }

  }

  private onUsernameChange() {
    this.benefitAdministratorForm
      .get('userName')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((userName: string) => {
        if (!userName || userName.length === 0 || userName === this.actualUsername) return;

        this.authService.CheckUser({ UserName: userName }).subscribe(res => {
          let userNameField = this.benefitAdministratorForm.get('userName');

          if (!res.status) userNameField?.setErrors({ usernameTaken: true });
          else {
            userNameField?.setErrors(null);
          }
        });
      });
  }
}
