import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, take, tap } from 'rxjs';
import { EmailTemplateApiResponse, EmailTemplateData } from 'src/app/core/models/api/email/email-template.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { EmailService } from 'src/app/core/services/http/email.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';

@Component({
  selector: 'app-edit-email-template',
  styleUrls: ['./edit-email-template.component.scss'],
  template: `
    <app-content-card CustomTitle="Edit Email Template">
      <div style="padding: 20px;" *ngIf="emailData$ | withLoading | async as emailData">
        <app-skeleton-input *ngIf="emailData.loading"></app-skeleton-input>
        <div *ngIf="emailData.value">
          <form [formGroup]="emailTemplateForm">
            <div style="display: flex; gap: 10px;">
              <app-form-select
                style="flex: 1;"
                Label="Email Template Name"
                [Options]="options"
                formControlName="emailTemplateName"
                FieldName="emailTemplateName"
                [parentForm]="emailTemplateForm"></app-form-select>
              <app-form-input
                style="flex: 1;"
                fieldName="subject"
                formControlName="subject"
                title="Subject"
                [parentForm]="emailTemplateForm"
                [Wide]="true"></app-form-input>
            </div>
            <editor
              *ngIf="emailData.value"
              formControlName="emailBody"
              [init]="{
                height: 500,
                menubar: false,
                base_url: '/tinymce',
                suffix: '.min',
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
              }"
              initialValue="<h2>aaa</h2>"></editor>
          </form>
          <br />
          <div style="display: flex; justify-content: space-between;">
            <custom-button
              [forceCapitalization]="true"
              label="BACK"
              [routerLink]="[emailTemplatesUrl]"
              color="secondary"></custom-button>
            <custom-button [forceCapitalization]="true" label="SAVE" (onClick)="onSave()"></custom-button>
          </div>
        </div>
      </div>
    </app-content-card>
  `,
})
export class EditEmailTemplateComponent implements OnInit {
  options: SelectOptions<string>[] = [];
  emailTemplateForm: FormGroup;
  emailId: number;

  emailData$: Observable<EmailTemplateApiResponse>;

  emailTemplatesUrl = RouteEnum.EmailTemplate;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private emailService: EmailService,
    private notificationService: NotificationService
  ) {}
  onSave() {
    if (this.emailTemplateForm.valid) {
      let { emailBody, subject } = this.emailTemplateForm.value;
      this.emailData$.pipe(take(1)).subscribe(data => {
        this.emailService
          .CreateOrUpdateEmailTemplate({
            ...data.Data,
            Body: emailBody,
            Subject: subject,
          } as EmailTemplateData)
          .subscribe(res => {
            if (res.Status) {
              this.notificationService.success(res.Message ?? 'Email template updated successfully!');
              this.router.navigateByUrl(this.emailTemplatesUrl);
            } else this.notificationService.error('Some error ocurred. Please contact allied administrators ');
          });
      });
    }
  }

  ngOnInit(): void {
    this.emailId = Number(this.route.snapshot.paramMap.get('id'));

    this.emailTemplateForm = this.fb.group({
      emailTemplateName: [],
      subject: [],
      emailBody: [],
    });

    this.emailData$ = this.emailService.GetEmailTemplate(this.emailId).pipe(
      tap(res => {
        this.options.push({ key: res.Data.TemplateName, optionText: res.Data.TemplateName ?? res.Data.Templatecode });
        this.emailTemplateForm.patchValue({
          emailBody: res.Data.Body,
          emailTemplateName: res.Data.TemplateName,
          subject: res.Data.Subject,
        });

        this.emailTemplateForm.get('emailTemplateName')?.disable();

        console.log(this.emailTemplateForm);
      })
    );
  }
}
