import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faUser, faEnvelope, faPhone, faKey, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { catchError, debounceTime, throwError } from 'rxjs';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import { BaUserApiRequest } from 'src/app/core/models/api/auth/requests/create-ba-user.request';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';

@Component({
  selector: 'app-add-ba',
  styleUrls: ['./add-ba.component.scss'],
  template: ` <app-content-card CustomTitle="Add BA">
    <form [formGroup]="benefitAdministratorForm" ngClass="ba_grid">
      <app-form-input
        [Wide]="true"
        [Icon]="personIcon"
        [parentForm]="benefitAdministratorForm"
        title="Benefit Administrator Name"
        fieldName="benefitAdministratorName"></app-form-input>
      <app-form-input
        [Wide]="true"
        [Icon]="personIcon"
        [parentForm]="benefitAdministratorForm"
        title="TIN/SSN"
        [mask]="'000-00-0000'"
        fieldName="tinSsn"></app-form-input>
      <app-form-input
        [Wide]="true"
        [Icon]="personIcon"
        [parentForm]="benefitAdministratorForm"
        title="First Name"
        fieldName="firstName"></app-form-input>
      <app-form-input
        [Wide]="true"
        [Icon]="personIcon"
        [parentForm]="benefitAdministratorForm"
        title="Last Name"
        fieldName="lastName"></app-form-input>
      <app-form-input
        [Wide]="true"
        [Icon]="emailIcon"
        [parentForm]="benefitAdministratorForm"
        title="Email"
        fieldName="email"></app-form-input>
      <app-form-input
        [Wide]="true"
        [Icon]="celphoneIcon"
        [parentForm]="benefitAdministratorForm"
        mask="(000) 000-0000"
        inputmode="numeric"
        title="Phone Number"
        fieldName="phoneNumber"></app-form-input>
      <app-form-input
        [Wide]="true"
        [Icon]="personIcon"
        [parentForm]="benefitAdministratorForm"
        title="User Name"
        fieldName="userName"></app-form-input>
      <div style="display: flex; flex-direction: column; gap: 10px; white-space: ;">
        <app-form-input
          [Wide]="true"
          [Icon]="keyIcon"
          [parentForm]="benefitAdministratorForm"
          [enableVisibilityToggle]="true"
          type="password"
          title="Password"
          fieldName="passWord"></app-form-input>
        <span>
          * Password must have one uppercase, one lowercase, 8 characters, 1 number and/or special character.
        </span>
      </div>
    </form>
    <div style="display: flex; justify-content: flex-end; padding: 20px;">
      <custom-button
        label="SAVE"
        [icon]="floppyDisk"
        (onClick)="onSubmit()"
        [IsLoading]="saveLoading"></custom-button>
    </div>
  </app-content-card>`,
})
export class AddBaComponent implements OnInit {
  personIcon = faUser;
  emailIcon = faEnvelope;
  celphoneIcon = faPhone;
  keyIcon = faKey;
  floppyDisk = faFloppyDisk;

  benefitAdministratorSelectUrl = RouteEnum.SelectBaUsers;
  benefitAdministratorForm: FormGroup<any>;
  saveLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userControlService: UserControlService,
    private notificationService: NotificationService,
    private userViewService: UserViewService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.onUsernameChange();
  }

  onSubmit() {
    if (this.benefitAdministratorForm.invalid) {
      this.benefitAdministratorForm.markAllAsTouched();
      return;
    }

    let { benefitAdministratorName, userName, firstName, lastName, passWord, email, phoneNumber, tinSsn } =
      this.benefitAdministratorForm.value;

    let newBaUser: BaUserApiRequest = {
      BaName: benefitAdministratorName,
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      IsBaUser: true,
      Password: passWord,
      Phone: phoneNumber,
      SSN: tinSsn,
      Type: AuthenticationUserType.Producer,
      Username: userName,
    };

    this.saveLoading = true;
    this.userControlService
      .CreateOrUpdateUser(newBaUser)
      .pipe(
        catchError(error => {
          this.notificationService.error('Something went wrong');
          this.saveLoading = false;
          return throwError(() => error);
        })
      )
      .subscribe(res => {
        if (res.status) {
          this.notificationService.success(res.message);
          this.router.navigate([this.benefitAdministratorSelectUrl]);
        } else this.notificationService.error(res.message);
        this.saveLoading = false;
      });
  }

  private initForm() {
    this.benefitAdministratorForm = this.fb.group({
      benefitAdministratorName: ['', Validators.required],
      tinSsn: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      userName: ['', Validators.required],
      passWord: ['', [Validators.required, PasswordValidator.strong]],
    });
  }

  private onUsernameChange() {
    this.benefitAdministratorForm
      .get('userName')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((userName: string) => {
        if (!userName || userName.length === 0) return;

        this.authService.CheckUser({ UserName: userName }).subscribe(res => {
          let userNameField = this.benefitAdministratorForm.get('userName');

          if (!res.status) userNameField?.setErrors({ usernameTaken: true });
          else {
            userNameField?.setErrors(null);
          }
        });
      });
  }
}
