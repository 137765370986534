import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MarketingResourceApiResponse } from '../../models/api/marketing-resource/marketing-resource-api.response';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetStatesAndDescriptionApiResponse } from '../../models/api/marketing-resource/states-and-description.response';
import { MarketingResourceJournalApiResponse } from '../../models/api/marketing-resource/marketing-resource-journal.response';

@Injectable({
  providedIn: 'root',
})
export class MarkertingResourceService {
  constructor(private http: HttpClient) {}

  GetMarketingResources(masterProducerID?: string): Observable<MarketingResourceApiResponse> {
    let params: any;
    if (masterProducerID) params = { prid: masterProducerID };

    return this.http.get<MarketingResourceApiResponse>(`${environment.alliedApi.baseUrl}/MarketingResource`, {
      params: params,
    });
  }

  GetMarketingResourceByID(marketingResourceId?: string): Observable<MarketingResourceApiResponse> {
    return this.http.get<MarketingResourceApiResponse>(`${environment.alliedApi.baseUrl}/MarketingResource/byid?id=${marketingResourceId}`);
  }

  DownloadFilePdf(fileId: string) {
    return this.http.post(
      `${environment.alliedApi.baseUrl}/MarketingResource/getdownloadFilePDF`,
      { fileId },
      {
        responseType: 'arraybuffer',
      }
    );
  }

  DeleteDocument(fileId: string) {
    return this.http.get<any>(`${environment.alliedApi.baseUrl}/MarketingResource/deletedoc?id=${fileId}`);
  }

  GetStatesAndDescription() {
    return this.http.get<GetStatesAndDescriptionApiResponse>(
      `${environment.alliedApi.baseUrl}/MarketingResource/stateanddescription`
    );
  }

  GetJournal(marketingResourceId: number) {
    return this.http.post<MarketingResourceJournalApiResponse>(
      `${environment.alliedApi.baseUrl}/MarketingResource/view_journ`, { id: marketingResourceId }
    );
  }

  /* File: backend accepts a file object (binary) or filename if the file already exist */
  UpsertMarketingResource(marketingResourceId: string, shareType: string, stateList: string, fileName: string, file: any) {
    let formData = new FormData()

    formData.append("marketingresourceid", marketingResourceId ?? 0);
    formData.append("file", file);
    formData.append("GANR", shareType);
    formData.append("Statelist", stateList);
    formData.append("fileName", fileName);

    return this.http.post<{ status: boolean, message: string }>(`${environment.alliedApi.baseUrl}/MarketingResource`, formData)
  }
}
