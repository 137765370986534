
import { UpdateUserProfileRequest } from '../../../../user/update-user-profile.resquest';
import { AuthenticationUserType } from '../../../AuthenticationUserTypeEnum';
import { UserRightCodeEnum } from '../user-rights/user-right-code.enum';

export interface UserRights {
  RightCode: string;
  Name: string;
  assigned: number;
  Sort: number;
}

export interface UserGroup {
  UserGroupId: number;
  Name: string;
  Enrolled: boolean;
  UserId: number;
}

export interface UserResponseI {
  UserID: number;
  Username: string;
  Type: AuthenticationUserType;
  FirstName: string;
  LastName: string;
  Email: string;
  Disabled: number;
  IsLockOut: boolean;
  Phone: string;
  Success_login: number;
  BadLoginAttempts: number;
  Failed_login: number;
  Lastlogin: string;
  LastloginStatus: boolean;
  Lastlogoff: string;
  rights: UserRights[];
  UserGroups: UserGroup[];
}

export class UserPrivilegesResponse implements UserResponseI {
  UserID: number;
  Username: string;
  Type: AuthenticationUserType;
  FirstName: string;
  LastName: string;
  Email: string;
  Disabled: number;
  IsLockOut: boolean;
  Phone: string;
  Success_login: number;
  BadLoginAttempts: number;
  Failed_login: number;
  Lastlogin: string;
  LastloginStatus: boolean;
  Lastlogoff: string;
  MfaEnabled: boolean;
  rights: UserRights[];
  UserGroups: UserGroup[];

  toRequest(): UpdateUserProfileRequest {
    return {
      UserID: this.UserID,
      Username: this.Username,
      Type: this.Type,
      FirstName: this.FirstName,
      LastName: this.LastName,
      Email: this.Email,
      Disabled: this.Disabled,
      IsLockOut: this.IsLockOut,
      Phone: this.Phone,
      Success_login: this.Success_login,
      BadLoginAttempts: this.BadLoginAttempts,
      Failed_login: this.Failed_login,
      Lastlogin: this.Lastlogin,
      LastloginStatus: this.LastloginStatus,
      Lastlogoff: this.Lastlogoff,
      rights: this.rights,
      MfaEnabled: this.MfaEnabled,
      UserGroups: this.UserGroups,
    };
  }
}

export class UsersPrivilegeAPIResponse {
  status: boolean;
  response: UserPrivilegesResponse[];
  message: string;

  constructor(status: boolean, response: UserPrivilegesResponse[], message: string) {
    this.status = status;
    this.response = response;
    this.message = message;
  }
}
