import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentApiResponse } from '../../models/api/documents.response';

@Injectable({
  providedIn: 'root',
})
export class DocumentProducerService {
  constructor(private http: HttpClient) {}
  GetLanvera(): Observable<any> {
    return this.http.get(`${environment.alliedApi.baseUrl}/documents/lanvera`, {
      headers: new HttpHeaders().set('Content-Type', 'text/csv'),
      responseType: 'arraybuffer',
    });
  }

  GetProducerDocuments(employerId: string): Observable<DocumentApiResponse> {
    return this.http.get<DocumentApiResponse>(`${environment.alliedApi.baseUrl}/producer/${employerId}/documents`);
  }

  DeleteProducerDocument(employerId: string, documentId: string) {
    return this.http.delete<{ Status: boolean; Message: string }>(
      `${environment.alliedApi.baseUrl}/producer/${employerId}/documents/delete/${documentId}`
    );
  }

  UnarchiveProducerDocument(employerId: string, documentId: string) {
    return this.http.put<{ Status: boolean; Message: string }>(
      `${environment.alliedApi.baseUrl}/producer/${employerId}/documents/unarchiveDocument/${documentId}`,
      {}
    );
  }

  ArchiveProducerDocument(employerId: string, documentId: string) {
    return this.http.put<{ Status: boolean; Message: string }>(
      `${environment.alliedApi.baseUrl}/producer/${employerId}/documents/ArchiveDocument/${documentId}`,
      {}
    );
  }

  UploadProducerDocument(file: Blob, employerId: string, userId: string) {
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('UploadedBy', userId.toString());

    return this.http.post<{ Status: boolean; Message: string }>(
      `${environment.alliedApi.baseUrl}/producer/${employerId}/documents`,
      formdata
    );
  }

  DownloadProducerDocuments(employerId: string, documentId: string) {
    return this.http.get(
      `${environment.alliedApi.baseUrl}/producer/${employerId}/documents/DownloadDocument/${documentId}`,
      {
        responseType: 'arraybuffer',
      }
    );
  }

  NotifyProducers(producerId: string) {
    return this.http.post<boolean>(`${environment.alliedApi.baseUrl}/XmlExcelDocuments/notifyproducer`, parseInt(producerId));
  }
}
