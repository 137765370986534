<div id="employee-print"  style="display: none;">
  <div class="print-navbar">
    <span>

    Enrollee Information - {{employeeData.Firstname}} {{employeeData.Lastname}}
  </span>
</div>
  <app-content-card [CustomTitle]="'General Details'">
    <div class="details">
      <div>
        <div>
          <label>First Name: </label>
          <span>{{ employeeData.Firstname }}</span>
        </div>
        <div>
          <label>MI: </label>
          <span>{{ employeeData.MiddleName }}</span>
        </div>
        <div>
          <label>Last Name: </label>
          <span>{{ employeeData.Lastname }}</span>
        </div>
        <div>
          <label>SSN: </label>
          <span>{{ employeeData.SSN }}</span>
        </div>
      </div>
      <div>
        <div>
          <label>Date of Birth: </label>
          <span>{{ getPdfFormatDate(employeeData.BirthDate) }}</span>
        </div>
        <div>
          <label>Gender: </label>
          <span>{{ employeeData.Sex }}</span>
        </div>
        <div>
          <label>Hire Date: </label>
          <span>{{ getPdfFormatDate(employeeData.HireDate) }}</span>
        </div>
        <div>
          <label>Cobra: </label>
          <span>{{ employeeData.Cobra === 'Y' ? 'Y' : 'N' }}</span>
        </div>
        <div *ngIf="employeeData.TerminationDate">
          <label>Termination Date: </label>
          <span>{{ getPdfFormatDate(employeeData.TerminationDate) }}</span>
        </div>
        <div *ngIf="employeeData.TerminationReason">
          <label>Termination Reason: </label>
          <span>{{ getTerminationReasonName(employeeData.TerminationReason) }}</span>
        </div>

        <div *ngIf="employeeData.QualifyingEvent">
          <label>Qualifying Event: </label>
          <span>{{ getQualifyingEventName( employeeData.QualifyingEvent) }}</span>
        </div>

        <div *ngIf="employeeData.QualifyingEventDate">
          <label>Qualifying Event Date: </label>
          <span>{{ getPdfFormatDate(employeeData.QualifyingEventDate) }}</span>
        </div>
      </div>
    </div>
  </app-content-card>
  <app-content-card [CustomTitle]="'Contact Information'" [HasTopBorder]="false">
    <div class="details">
      <div>
        <div>
          <label>Address: </label>
          <span>{{ employeeData.StreetAddress }}</span>
        </div>
        <div>
          <label>Address 2: </label>
          <span>{{ employeeData.StreetAddress2 }}</span>
        </div>
        <div>
          <label>City: </label>
          <span>{{ employeeData.City }}</span>
        </div>
        <div>
          <label>State: </label>
          <span>{{ employeeData.State }}</span>
        </div>
      </div>
      <div>
        <div>
          <label>Zip5: </label>
          <span>{{ employeeData.Zip5 }}</span>
        </div>
        <div>
          <label>Zip 4: </label>
          <span>{{ employeeData.Zip4 }}</span>
        </div>
        <div>
          <label>Phone: </label>
          <span>{{ employeeData.Phone }}</span>
        </div>
        <div>
          <label>Email: </label>
          <span>{{ employeeData.Email }}</span>
        </div>
      </div>
    </div>
  </app-content-card>
  <app-content-card [CustomTitle]="'Benefits'" [HasTopBorder]="false">
    <div *ngFor="let plan of employeeData?.Plans" class="plans">
      <div>
        <label>{{ plan.ProductCode }} - </label>
      </div>
      <div>
        <label>Effective Date:</label>
        <span>{{ getPdfFormatDate(plan.EffectiveDate) }}</span>
      </div>
      <div *ngIf="plan.WaiverEffectiveDate">
        <label>Waiver Date:</label>
        <span>{{ getPdfFormatDate(plan.WaiverEffectiveDate) }}</span>
      </div>
      <div *ngIf="plan.DentalOffice">
        <label>Dental Office:</label>
        <span>{{ plan.DentalOffice }}</span>
      </div>
      <div>
        <label>Do Not Enroll:</label>
        <span>{{ plan.DoNotEnroll === true ? 'Y' : 'N' }}</span>
      </div>
      <div *ngIf="plan.TerminationDate">
        <label>Termination Date:</label>
        <span>{{ getPdfFormatDate(plan.TerminationDate) }}</span>
      </div>
    </div>
  </app-content-card>
  <app-content-card [CustomTitle]="'Dependents'" *ngIf="employeeData?.dependent?.length ?? 0 > 0" [HasTopBorder]="false">
    <div *ngFor="let dependent of employeeData?.dependent; let indexOfelement = index" class="dependents">
      <div>
        <label>Dependent: {{ indexOfelement + 1 }}</label>
      </div>
      <div>
        <label>Relationship:</label>
        <span>{{ dependent.RelationshipCode }}</span>
      </div>
      <div>
        <label>First Name:</label>
        <span>{{ dependent.FirstName }}</span>
      </div>
      <div>
        <label>Last Name:</label>
        <span>{{ dependent.LastName }}</span>
      </div>
      <div>
        <label>Birth Date:</label>
        <span>{{ getPdfFormatDate(dependent.Birthdate) }}</span>
      </div>
      <div *ngIf="dependent.QualifyEventName">
        <label>Qualifying Event:</label>
        <span>{{ getDepQualifyingEventName( dependent.QualifyEventName) }}</span>
      </div>
      <div *ngIf="dependent.QualifyEventDate">
        <label>Qualifying Event Date:</label>
        <span>{{ getPdfFormatDate(dependent.QualifyEventDate) }}</span>
      </div>

      <div *ngIf="dependent.TerminationDate">
        <label>Termination Date: </label>
        <span>{{ getPdfFormatDate(dependent.TerminationDate) }}</span>
      </div>
      <div *ngIf="dependent.TerminationReason">
        <label>Termination Reason: </label>
        <span>{{ getDepTerminationReason( dependent.TerminationReason) }}</span>
      </div>
      <div *ngFor="let plan of dependent.plans" class="plans">
        <div>
          <label>{{ plan.ProductCode }} - </label>
        </div>
        <div>
          <label>Effective Date:</label>
          <span>{{ getPdfFormatDate(plan.EffectiveDate) }}</span>
        </div>
        <div *ngIf="plan.WaiverEffectiveDate">
          <label>Waiver Date:</label>
          <span>{{ getPdfFormatDate(plan.WaiverEffectiveDate) }}</span>
        </div>
        <div *ngIf="plan.DentalOffice">
          <label>Dental Office:</label>
          <span>{{ plan.DentalOffice }}</span>
        </div>
        <div>
          <label>Do Not Enroll:</label>
          <span>{{ plan.DoNotEnroll === true ? 'Y' : 'N' }}</span>
        </div>
        <div *ngIf="plan.TerminationDate">
          <label>Termination Date:</label>
          <span>{{ getPdfFormatDate(plan.TerminationDate) }}</span>
        </div>
      </div>
    </div>
  </app-content-card>
</div>
