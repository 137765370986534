import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SuppliesComponent } from './supplies.component';
import { ManageSuppliesComponent } from './manage-supplies/manage-supplies.component';
import { AddEditSupplyComponent } from './add-edit-supplies/add-edit-supply.component';

const routes: Routes = [
  {
    path: '',
    component: SuppliesComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: 'add-supply',
        component: AddEditSupplyComponent,
      },
      {
        path: 'edit-supply/:id',
        component: AddEditSupplyComponent,
      },
      {
        path: 'manage-supplies',
        component: ManageSuppliesComponent,
        canActivate: [],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuppliesRoutingModule {}
