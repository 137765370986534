import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-avatar-menu',
  styleUrls: ['./avatar-menu.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
  template: `
    <div ngClass="wrapper_avatar_menu">
      <div (click)="ToggleAvatarMenu()">
        <img src="assets/img/avatars/admin.png" width="34" height="34" />
      </div>
      <div [class.open]="Open">
        <div></div>
        <div *ngFor="let item of menuLinks" [routerLink]="item.route" (click)="onMenuLinkClick(item)">
          {{ item.label }}
        </div>
      </div>
    </div>
  `,
})
export class AvatarMenuComponent implements OnInit {
  ToggleAvatarMenu() {
    this.Open = !this.Open;
  }

  @Input() Open: boolean = false;

  @Input() menuLinks: AvatarMenuLinksInput[] = [];

  constructor(private _eref: ElementRef) {}

  onMenuLinkClick(item: AvatarMenuLinksInput) {
    if (item.func) {
      item.func();
      this.Open = false;
    }
  }

  onClick(event: Event) {
    if (!this._eref.nativeElement.contains(event.target)) this.Open && this.ToggleAvatarMenu();
  }
  ngOnInit() {}
}

export type AvatarMenuLinksInput = { label: string; route?: string; func?: () => void };
