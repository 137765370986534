import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { distinctUntilChanged, tap } from 'rxjs';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { environment } from 'src/environments/environment';
import { AddEmployeeComponent } from '../add-employee/add-employee.component';
import { EmployeeFormService } from '../components/employee-form/employee-form.service';

@Component({
  selector: 'app-qualifying-event',
  templateUrl: './qualifying-event.component.html',
  styleUrls: ['./qualifying-event.component.scss'],
})
export class QualifyingEventComponent implements OnInit {
  @ViewChild(AddEmployeeComponent) addEmployeeComponent: AddEmployeeComponent;
  qualifyingEventForm: FormGroup;
  employeeForm: FormGroup;
  showEmployeeForm: boolean;
  showQeDate: boolean;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  options: SelectOptions<any>[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private employeeFormService: EmployeeFormService
  ) {}

  ngOnInit() {
    this.minDate = this.employeeFormService.GetQualifyingEventMinDate();
    this.maxDate = this.employeeFormService.GetQualifyingEventMaxDate();

    this.employeeForm = this.formBuilder.group({});

    this.qualifyingEventForm = this.formBuilder.group({
      qualifyingEvent: ['', Validators.required],
      qualifyingEventDate: ['', Validators.required],
    });

    this.http.get<IQualifyingEventOption[]>(`${environment.alliedApi.baseUrl}/QualifyingEvent`).subscribe(result => {
      result.map(option => {
        this.options.push({
          key: option.Id,
          optionText: option.Description,
        });
      });
    });

    let eventChanges = this.qualifyingEventForm.get('qualifyingEvent')?.valueChanges.pipe(distinctUntilChanged());
    let qeDateChanges = this.qualifyingEventForm.get('qualifyingEventDate')?.valueChanges.pipe(distinctUntilChanged());

    eventChanges &&
      eventChanges.subscribe(qe => {
        if (qe != undefined && qe != null) {
          this.showQeDate = true;
        }
      });

    qeDateChanges &&
      qeDateChanges.subscribe(qeDate => {
        if (this.employeeForm != undefined) {
          let hireDate = this.employeeForm.get(['info', 'hireDate'])?.value;
          let hiredateControl = this.employeeForm.get(['info', 'hireDate']);
          if (hireDate !== undefined && hireDate !== '' && hireDate !== null) {
            if (moment(hireDate, 'L').isAfter(moment(qeDate, 'L'))) {
              hiredateControl?.setErrors({
                customErrorMessage: 'Hire date cannot be beyond the Qualifying Event Date',
              });
            }
          }
          if (this.addEmployeeComponent !== undefined) {
            setTimeout(() => {
              this.addEmployeeComponent.onHireDateChange(hireDate);
            }, 100);
          }
        }

        let minDate = moment().add(-60, 'day');
        if (moment(qeDate, 'L').isAfter(moment())) {
          this.qualifyingEventForm
            .get('qualifyingEventDate')
            ?.setErrors({ customErrorMessage: 'Please select a date that is either today or before.' });
          this.showEmployeeForm = false;
        } else if (moment(qeDate, 'L').isBefore(moment(minDate, 'L'))) {
          this.qualifyingEventForm
            .get('qualifyingEventDate')
            ?.setErrors({ customErrorMessage: 'Event date cannot be more than 60 days in the past.' });
          this.showEmployeeForm = false;
        } else {
          this.qualifyingEventForm.get('qualifyingEventDate')?.setErrors({ customerrorMessage: null });
          this.qualifyingEventForm.get('qualifyingEventDate')?.updateValueAndValidity();

          if (this.qualifyingEventForm.valid) {
            this.showEmployeeForm = true;
          }
        }
      });
  }

  setForm(form: FormGroup) {
    this.employeeForm = form;
  }
}

export interface IQualifyingEventOption {
  Id: number;
  Description: string;
}
