import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UpdateRegionProductsRequest } from '../../models/api/auth/requests/update-region-products.request.model';
import { RegionProductsResponse } from '../../models/api/auth/responses/region-products.response.model';
import { UpdateRegionProductsResponse } from '../../models/api/auth/responses/update-region-products.response.model';
import { GetAnalyticsApiResponse } from '../../models/api/get-analytics.response';
import { GetWebPortalPdfRequest } from '../../models/api/get-web-portal-pdf.request';
import { WebPortalPdfResponse } from '../../models/api/log-report.response';
import { EmployeeStatisticsApiResponse } from '../../models/api/employee/employee-statistics.response';
import { AllEmpStatusApiResponse } from '../../models/api/employee/all-emp-status.response';
import { UserStatisticsApiResponse } from '../../models/api/user/users-statistics.response';
@Injectable({
  providedIn: 'root',
})
export class GeneralControlService {
  constructor(private http: HttpClient) {}

  GetRegions(region: string): Observable<RegionProductsResponse> {
    return this.http.get<RegionProductsResponse>(
      `${environment.alliedApi.baseUrl}/Generalctrl/RegionProducts?region=${region}`
    );
  }

  UpdateRegion(request: UpdateRegionProductsRequest[]) {
    return this.http.post<UpdateRegionProductsResponse>(
      `${environment.alliedApi.baseUrl}/Generalctrl/UpdateProducts`,
      request
    );
  }

  // A = Admin R = Employer P = Producer
  GetAnalyticalreport(fromDate: string, toDate: string, user: 'All' | 'A' | 'R' | 'P') {
    return this.http.get<GetAnalyticsApiResponse>(`${environment.alliedApi.baseUrl}/Generalctrl/getAnalyticalreport`, {
      params: {
        fromDate: fromDate,
        toDate: toDate,
        user: user,
      },
    });
  }

  GetWebPortalPdf(request: GetWebPortalPdfRequest) {
    let params: any = {};
    if (request.fromDate) params['from'] = request.fromDate;
    if (request.toDate) params['to'] = request.toDate;

    if (request.users?.length) {
      let userParam = '';
      request.users.forEach(user => (userParam = userParam + user + ','));
      params['users'] = userParam;
    }

    return this.http.get<WebPortalPdfResponse>(`${environment.alliedApi.baseUrl}/Generalctrl/WebPortalPdf`, {
      params: params,
    });
  }

  GetReportPdf(fromDate?: string, toDate?: string) {
    let params: any = {};
    if (fromDate) params['from'] = fromDate;
    if (toDate) params['to'] = toDate;

    return this.http
      .get<Blob>(`${environment.alliedApi.baseUrl}/Generalctrl/ReportPdf`, {
        params: params,
        responseType: 'blob' as 'json',
      })
      .pipe(
        map(res => {
          return new Blob([res], { type: 'application/pdf' });
        })
      );
  }

  GetEmployeeStatistics() {
    return this.http.get<EmployeeStatisticsApiResponse>(`${environment.alliedApi.baseUrl}/Generalctrl/employeeStatics`);
  }

  GetAllEmpStatus() {
    return this.http.get<AllEmpStatusApiResponse>(`${environment.alliedApi.baseUrl}/Generalctrl/allEmpStatus`);
  }

  GetUsersStatistics() {
    return this.http.get<UserStatisticsApiResponse>(`${environment.alliedApi.baseUrl}/Generalctrl/usersStatics`);
  }
}
