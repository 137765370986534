import { Component, Input, OnInit } from '@angular/core';
import { ApexChart } from 'ng-apexcharts';

@Component({
  selector: 'app-bar-chart',
  styleUrls: ['./bar-chart.component.scss'],
  template: `
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [title]="chartOptions.title"
      [subtitle]="chartOptions.subtitle"
      [dataLabels]="chartOptions.dataLabels"
      [colors]="chartOptions.colors"
      [fill]="chartOptions.fill"></apx-chart>
  `,
})
export class BarChartComponent implements OnInit {
  constructor() {}
  @Input() title: string = '';

  /** Needs to match the Apex Series pattern, see documentation https://apexcharts.com/docs/series/*/
  @Input() series: any[];

  /** Needs to match the Apex label pattern, see documentation */
  @Input() labels: any[];

  @Input() colors: string[];

  chartOptions: any;
  ngOnInit(): void {
    this.chartOptions = {
      fill: {
        colors: this.colors,
      },
      colors: this.colors,
      chart: {
        type: 'bar',
        height: 380,
        width: '100%',
        stacked: false,
      } as ApexChart,
      plotOptions: {
        bar: {
          columnWidth: '45%',
        },
      },
      dataLabels: {},
      series: this.series,
      labels: this.labels,
      title: {
        text: this.title,
        style: {
          fontSize: '14 px',
        },
      },
    };
  }
}
