import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployersApiResponse } from '../../models/api/employer/employer.response';
import { environment } from 'src/environments/environment';
import { ViewEmployerJournal } from '../../models/api/employer/view-employer-journal.reponse';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class EmployerService {
  constructor(private http: HttpClient) {}

  GetEmployerInfoById(employerId: string): Observable<EmployersApiResponse> {
    return this.http.get<EmployersApiResponse>(`${environment.alliedApi.baseUrl}/employers/${employerId}`);
  }

  GetP3Mandatory(employerId: string): Observable<any> {
    return this.http.get<any>(`${environment.alliedApi.baseUrl}/employers/p3mandatory/${employerId}`);
  }

  ShouldSelectCobraType(employerId: number) {
    return this.http.get<boolean>(`${environment.alliedApi.baseUrl}/employers/ShouldSelectCobraType/${employerId}`);
  }

  IsOpenEnrollmentAsync(employerId: number) {
    return this.http.get<boolean>(`${environment.alliedApi.baseUrl}/employers/${employerId}/isOpenEnrollment`);
  }

  IsOpenEnrollmentSync(oeMonth: number) {
    let previousMonth = oeMonth - 1;

    if (previousMonth === 0) previousMonth = 12;

    let currentMonth = parseInt(moment().format('M'));

    return currentMonth === previousMonth || currentMonth === oeMonth;
  }

  GetOpenEnrollmentFirstMonth(oeMonth: number){
    if(oeMonth === 1)
    return this.GetMonthName(12);
    
    return this.GetMonthName(oeMonth-1);
  }

  GetMonthName(oeMonth: number){
    const date = new Date();

    date.setMonth(oeMonth -1);
    return date.toLocaleString('en-US', { month: 'long' });
  }

  GetEmployerJournal(employerId: number) {
    return this.http.get<ViewEmployerJournal>(`${environment.alliedApi.baseUrl}/employers/journal/${employerId}`);
  }
}
