import {
  Component,
  Input,
  OnInit,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { FormControlWarn } from '../form-group-warn';
import { GetModalErrorMessage, getErrorMessage } from '../form-field-errors/CustomErrorMessages';
import { ModalService } from 'src/app/core/services/modal.service';
import moment from 'moment';

@Component({
  selector: 'app-form-input-date',
  styleUrls: ['./form-input-date.component.scss'],
  template: `
    <form
      *ngIf="parentForm"
      [formGroup]="parentForm"
      class="datepicker_container"
      [style.width.%]="wide ? 100 : 'unset'"
      [class.readonly-field]="readonly">
      <mat-label class="label" [class.open]="floatLabel">{{ title }} {{ isRequired ? '*' : '' }}</mat-label>
      <p-calendar
        #inputElement
        dateMask
        [showIcon]="true"
        [selectOtherMonths]="true"
        (onShow)="onOpen(true)"
        (onClose)="onOpen(false)"
        [readonlyInput]="readonly === true"
        [disabled]="readonly === true"
        (onSelect)="doInput($event)"
        (onInput)="doInput($event)"
        (onBlur)="doInput($event)"
        [formControl]="formField"></p-calendar>
      <div class="underline" [class.readonly]="isFieldDisabled()" [class.error]="isFieldWithError()"></div>
      <mat-hint *ngIf="!isFieldWithError()" id="hint">MM/DD/YYYY</mat-hint>
      <mat-error *ngIf="isFieldWithError()">{{ setErrorMessage() }}</mat-error>
      <mat-error *ngIf="setErrorMessageModal()?.length > 0" (click)="openModal()" class="modal_link"> 
        {{ setErrorMessageModal() }}
      </mat-error>
    </form>
    <div style="padding-top:10px">
      <span
        *ngIf="formField.warnings != undefined && formField.warnings != null && formField.warnings != ''"
        ngClass="warning"
        >{{ formField.warnings }}</span
      >
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputDateComponent),
      multi: true,
    },
  ],
})
export class FormInputDateComponent implements OnInit, ControlValueAccessor {
  
  isEnglish = true;
  dateFormat = 'MM/dd/yyyy';
  disabled: boolean;
  hasModal: boolean = false;
  floatLabel: boolean = false;
  inputMinDate: Date = new Date(1900, 0, 1);
  inputMaxDate: Date = new Date(2100, 0, 1);

  onChange = (_: any) => {};
  onTouched = () => {};

  @Input() value: any = null;
  @Input() type = 'text';
  @Input() title: string = '';
  @Input() wide: boolean = false;
  @Input() fieldName: string = '';
  @Input() minDate: Date = new Date(1900, 0, 1);
  @Input() maxDate: Date = new Date(2100, 0, 1);
  @Input() readonly?: boolean = false;

  @Input() public parentForm?: FormGroup;

  constructor(
    private modalService: ModalService
  ) {}

  ngOnInit() {
    if (this.value || this.formField.value) this.floatLabel = true;
    this.inputMinDate = this.minDate;
    this.inputMaxDate = this.maxDate;
  }

  // onDisableDate = (date: Date) => {
  //   let maxDateValidation = date != undefined ? moment(date).isAfter(moment(this.inputMaxDate)) : false;
  //   let minDateValidation = date != undefined ? moment(date).isBefore(this.inputMinDate) : false;
  //   return maxDateValidation && minDateValidation;
  // };
  onModelChange(event: any) {
    console.log(event);
    if (this.value || this.formField.value) this.floatLabel = true;
  }
  onOpen(isOpen: boolean) {
    console.log(isOpen, this.value);
    if (!isOpen && !this.formField.touched) {
      setTimeout(() => {
        // this.formField.markAsTouched()
        // this.formField.updateValueAndValidity({ onlySelf: true });
      }, 100)
    }
    if (this.value || this.formField.value) this.floatLabel = true;
    else this.floatLabel = isOpen;
  }

  isFieldDisabled(): boolean {
    if (this.readonly === true) return true;

    if (this.formField.disabled === true) return true;

    return false;
  }

  isFieldWithError() {
    return this.isInvalid && this.isTouched;
  }

  setErrorMessage() {
    return getErrorMessage(this.formField);
  }

  setErrorMessageModal() {
    return GetModalErrorMessage(this.formField);
  }

  openModal() {
    this.modalService.openModal.next(true);
  }

  doInput(input: any) {
    if (input == undefined || input == null) this.floatLabel = false;

    this.value = input;
  }

  writeValue(obj: any): void {
    if (typeof obj === 'string') {
      obj = obj ? moment(obj).toDate() : undefined;
      let field = this.formField;
      field.setValue(obj, { emitEvent: false });
    }

    this.value = obj;
    if (obj) this.floatLabel = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // setDisabledState?(isDisabled: boolean): void {
  //   throw new Error('Method not implemented.');
  // }

  get isRequired(): boolean {
    return this.formField.hasValidator(Validators.required);
  }

  get isDirty(): boolean {
    return this.formField.dirty;
  }

  get isInvalid(): boolean {
    return this.formField.invalid;
  }

  get isTouched(): boolean {
    return this.formField.touched;
  }

  get formField(): FormControlWarn {
    return this.parentForm?.get(this.fieldName) as FormControlWarn;
  }
}
