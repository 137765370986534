<div style="display: flex; justify-content: flex-end">
  <custom-button
    [forceCapitalization]="true"
    [routerLink]="[subProducersGroupsUrl]"
    style="align-self: flex-end; margin-bottom: 10px"
    color="secondary"
    [icon]="arrowLeft"
    label="BACK"></custom-button>
</div>
<app-content-card CustomTitle="Assign Groups to Sub Producer">


    <ng-container *ngIf="data$ | withLoading | async as data">
        <app-table
          [IsLoading]="data.loading ?? false"
          [EnableSearch]="true"
          [DisplayedColumns]="displayedColumns"
          [DownloadButtons]="downloadButtons"
          [Rows]="data.value"
          [EnablePagination]="false"
          [EnableLocalSearch]="true"
          [EnableSearch]="true"
          [EnableLocalSort]="true"></app-table>
          <div style="display: flex; justify-content: flex-end; padding: 10px;">
            <custom-button
              [forceCapitalization]="true"
              label="Save" (click)="onSubmit()" ></custom-button>
          </div>
    </ng-container>
    

</app-content-card>


<ng-template #actionColumnTemplate let-data>
<app-checkbox
    [checked]="data.GroupAssigned"
    (onCheck)="onCheckEmployer(data)"
    (onRemoveCheck)="onRemoveCheckEmployer(data)"
    [enableOnRemoveCheckEvent]="true"></app-checkbox>
</ng-template>