import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import saveAs from 'file-saver';
import moment from 'moment';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { API_ERROR_MESSAGE } from 'src/app/core/models/constants/error-messages';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { ArchiveService } from 'src/app/core/services/http/archive.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { VerticalMenuService } from 'src/app/core/services/vertical-menu.service';

@Component({
  selector: 'app-eligibility-archive-logs-xml-excel',
  styleUrls: ['./eligibility-archive-logs-xml-excel.component.scss'],
  template: `
    <div style="display: flex; justify-content: flex-end">
      <custom-button
        [forceCapitalization]="true"
        (onClick)="onBackClick()"
        style="align-self: flex-end; margin-bottom: 10px"
        color="secondary"
        [icon]="arrowLeft"
        label="BACK"></custom-button>
    </div>
    <app-content-card CustomTitle="Logs">
      <app-skeleton-input *ngIf="isLoading.value"></app-skeleton-input>
      <div *ngIf="!isLoading.value">
        <div style="display: flex; flex-direction: column; gap: 10px; padding: 10px;">
          <span *ngIf="!hasErrors">File upload was successful.</span>
          <span *ngIf="hasErrors">File upload failed.</span>
          <div style="display: flex; gap: 10px; justify-content: flex-start;">
            <custom-button
              [forceCapitalization]="true"
              (onClick)="downloadUploadedFile()"
              label="Download File"
              [disabled]="!canDownloadArchive"></custom-button>
            <custom-button
              [forceCapitalization]="true"
              *ngIf="hasErrors"
              [color]="'secondary'"
              (onClick)="downloadErrorReport()"
              label="Download Error Report File"
              [disabled]="!canDownloadArchive"></custom-button>
          </div>
        </div>
      </div>
    </app-content-card>
  `,
})
export class EligibilityArchiveLogsXmlExcelComponent implements OnInit {
  arrowLeft = faArrowLeft;
  fileId: number;
  IsXml: boolean;
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  canDownloadArchive: boolean;
  hasErrors: boolean;

  documentId?: string;
  fileManagementUrl: RouteEnum;
  employerId: string | undefined;

  constructor(
    private route: ActivatedRoute,
    private routerNavitagion: Router,
    private archiveService: ArchiveService,
    private userViewService: UserViewService,
    private notificationService: NotificationService,
    private verticalMenuService: VerticalMenuService
  ) {}

  ngOnInit(): void {
    this.employerId = this.userViewService.GetCurrentUserViewState()?.EmployerId;
    this.fileId = Number(this.route.snapshot.paramMap.get('id'));
    this.IsXml = this.route.snapshot.url.findIndex(url => url.path.includes('xml')) >= 0;
    this.fileManagementUrl = this.IsXml ? RouteEnum.XmlFileManagement : RouteEnum.FileManagement;

    this.isLoading.next(true);

    if(IsStringNullOrEmpty( this.employerId)){
      this.archiveService.GetArchive(this.fileId.toString()).subscribe(archive => {
        this.documentId = archive.Data.DocumentId.toString();
        this.loadArchiveSettings();
      });
    }
    else{
      this.archiveService.GetArchives(this.employerId ?? '', this.IsXml ? 'xml' : 'excel').subscribe(archive => {
        let archiveFound = archive.Data.find(x => this.fileId === x.ArchiveId);
        this.documentId = archiveFound?.DocumentId.toString();

        this.loadArchiveSettings();
      });
    }
    
  }


  loadArchiveSettings(){

    this.archiveService.GetIsUserAbleToDownload(this.documentId?.toString() ?? '').subscribe(res => {
      this.canDownloadArchive = res.response;

      this.archiveService.GetArchiveErrors(this.fileId.toString()).subscribe({
        next: archiveErrors => {
          this.hasErrors = archiveErrors.Data.ArchiveErrors.length > 0;
          this.isLoading.next(false);
        },
        error: _ => this.notificationService.error(API_ERROR_MESSAGE),
      });
    });
  }

  downloadUploadedFile() {
    if (this.documentId)
      this.archiveService.DownloadArchive(this.documentId.toString()).subscribe({
        next: res => saveAs(res, this.IsXml === true ? 'source xml '+moment().format('MM-DD-yyyy')+'.xml': 'EligibilityTemplate.xlsx'),
        error: _ => this.notificationService.error(API_ERROR_MESSAGE),
      });
  }
  downloadErrorReport() {
    if (this.documentId)
      this.archiveService.DownloadErrorReport(this.fileId.toString()).subscribe({
        next: res => saveAs(res, 'ErrorReport.xlsx'),
        error: _ => this.notificationService.error(API_ERROR_MESSAGE),
      });
  }

  onBackClick() {
    if(this.employerId === undefined || this.employerId === null) {
      this.routerNavitagion.navigate([RouteEnum.MasterFileManagement.toString()]);
      return;
    }
    console.log('navigating to', this.fileManagementUrl);
    this.routerNavitagion.navigate([this.fileManagementUrl.toString()]);
  }
}
