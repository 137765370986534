import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { tapOnce } from 'src/app/core/helpers/form-helpers';
import {
  Supplier,
  SupplySendEmailGeneral,
  SupplySendEmailRequest,
} from 'src/app/core/models/api/supply/supply-send-email.request';
import { SuppliesData } from 'src/app/core/models/api/supply/supplies.response';
import { SupplyService } from 'src/app/core/services/http/supply.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
@Component({
  selector: 'app-supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.scss'],
})
export class SuppliesComponent implements OnInit {
  Supplies$: Observable<SuppliesData[]>;
  suppliesFormGroup: FormGroup;
  employerInfo: {
    EmployerID: string;
    EmployerNR: string;
    companyName: string;
  };
  placeOrderLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private supplyService: SupplyService,
    private userViewService: UserViewService,
    private notificationService: NotificationService
  ) {}
  icon = faBookmark;

  ngOnInit() {
    let userView = this.userViewService.GetCurrentUserViewState();
    this.suppliesFormGroup = this.fb.group({
      supplies: this.fb.array([]),
    });

    if (userView?.EmployerId && userView?.PlanNumber) {
      this.employerInfo = {
        companyName: userView.CompanyName,
        EmployerID: userView.EmployerId,
        EmployerNR: userView.EmployerNumber,
      };

      this.Supplies$ = this.supplyService.GetSuppliesByEmployer(userView.PlanNumber, userView.EmployerId).pipe(
        map(response => response.Data),
        tapOnce(response => {
          response.forEach((supplier, i) => this.addSupplierFormControlToSuppliesForm(supplier.SupplyId));
          console.log(this.suppliesFormGroup);
        })
      );
    }
  }

  addSupplierFormControlToSuppliesForm(supplierId: number) {
    this.suppliesForm.push(
      this.fb.group({
        supplierId: [supplierId],
        qty: [],
      })
    );
  }

  hasQuantityError(supplierData: SuppliesData, index: number): boolean {
    let supplier = this.getQtyFormControlAtIndex(index);
    if (supplier?.value > supplierData.LimitAbs) {
      supplier.setErrors({ quantityError: true });
      return true;
    }

    supplier.setErrors(null);
    return false;
  }

  getQtyFormControlAtIndex(index: number) {
    return this.suppliesForm.at(index).get('qty') as FormControl;
  }

  getSuppliesFormFormGroupAtIndex(index: number) {
    return this.suppliesForm.at(index) as FormControl;
  }

  sendEmail() {
    if (this.suppliesForm.invalid) {
      this.notificationService.error('Please select the correct quantities');
      return;
    }
    let suppliersToSend = (this.suppliesForm.value as { supplierId: number; qty: number }[]).filter(
      supplier => supplier.qty != undefined && Number(supplier.qty) > 0
    );

    if (suppliersToSend.length === 0) {
      this.notificationService.error('Supply quantity is empty.');

      return;
    }

    this.placeOrderLoading = true;
    let request = {
      general: {
        employerInfo: {
          companyName: this.employerInfo.companyName,
          EmployerID: this.employerInfo.EmployerID,
          EmployerNR: this.employerInfo.EmployerNR,
        },
      },
      suplie: suppliersToSend.map(
        supplierFromForm => ({ qty: supplierFromForm.qty, SupplyID: supplierFromForm.supplierId } as Supplier)
      ),
    } as SupplySendEmailRequest;
    this.supplyService.SendEmail(request).subscribe(res => {
      if (res.status) this.notificationService.success(res.message);
      else this.notificationService.error(res.message);
      this.placeOrderLoading = false;
    });
  }

  onDownload(supplyId: number, fileName: string) {
    this.supplyService
      .DownloadSupply(supplyId, this.employerInfo.EmployerID)
      .pipe(
        catchError(x => {
          this.notificationService.error('Something went wrong. Please try again later.');
          return throwError(() => x);
        })
      )
      .subscribe(res => {
        saveAs(res, fileName);
      });
  }

  get suppliesForm() {
    return this.suppliesFormGroup.get('supplies') as FormArray;
  }
}
