import { Component, OnInit } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Observable, tap } from 'rxjs';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';

@Component({
  selector: 'app-onboarding',
  styleUrls: ['./onboarding.component.scss'],
  template: `
    <div
      style="display: flex; justify-content: center; flex-direction: column; align-items: center;"
      *ngIf="asyncData$ | withLoading | async as asyncData">
      <div>
        <fa-icon [icon]="spinnerIcon" [spin]="true" size="8x" style="color: var(--brandColor)"></fa-icon>
      </div>
      <div>Redirecting to Onboarding...</div>
    </div>
  `,
})
export class OnboardingComponent implements OnInit {
  spinnerIcon = faCircleNotch;

  asyncData$: Observable<any>;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.asyncData$ = this.authService.GetSSOUrl().pipe(
      tap(res => {
        location.href = res.Data.url;
      })
    );
  }
}
