import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  host: {
    '(document:click)': 'onClick($event)',
  },
  selector: 'app-dropdown',
  styleUrls: ['./dropdown.component.scss'],
  template: `
    <div ngClass="dropdown-wrapper">
      <div style="display: flex; justify-content: space-between; cursor:pointer" (click)="ToggleMenuList()">
        <input readonly style="cursor:pointer" ngClass="dropdown-input" [value]="ItemValue" />
        <fa-icon style="color: white;" [icon]="faArrowDown" color></fa-icon>
      </div>
      <div [ngClass]="getDropdownMenuListClasses()">
        <div ngClass="item" *ngFor="let item of Items" (click)="OnItemSelect(item.key)">{{ item.value }}</div>
      </div>
    </div>
  `,
})
export class DropdownComponent<T> implements OnInit {
  faArrowDown = faCaretDown;
  @Input()
  Items: DropdownItems<T>[] = [];

  @Input()
  Selected?: T;

  @Output()
  OnClickOutput = new EventEmitter<T>();

  ShowDropdownList: boolean = false;

  constructor(private _eref: ElementRef) {}

  ToggleMenuList() {
    this.ShowDropdownList = !this.ShowDropdownList;
    console.log(this.ShowDropdownList);
  }

  OnItemSelect(value: T) {
    console.log(value);
    this.Selected = value;
    this.ToggleMenuList();
    this.OnClickOutput.emit(value);
  }
  ngOnInit() {}

  onClick(event: Event) {
    if (!this._eref.nativeElement.contains(event.target)) this.ShowDropdownList && this.ToggleMenuList();
  }

  getDropdownMenuListClasses() {
    let classes = ['dropdown-list'];
    if (this.ShowDropdownList) classes.push('show');
    else classes.push('hide');
    return classes;
  }

  get ItemValue() {
    return this.Items.find(x => this.Selected == x.key)?.value;
  }
}

export type DropdownItems<T> = { key: T; value: string };
