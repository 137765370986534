import { RegionCodes } from './api/auth/responses/get-regions.response';
import { UserTypeEnum } from './user-type.enum';
import { ViewType } from './user-view.model';

export class UserViewState {
  ActualView: ViewType;
  ActualRegion: RegionCodes;
  PrevView: ViewType;
  UserType: UserTypeEnum;
  IsLoading: boolean;
  ShowModulePickerView: boolean;
  Impersonate?: boolean;
  EmployerNumber: string;
  EmployerId?: string;
  MasterProducerId?: string;
  IsSubProducer?: boolean;
  PlanNumber?: string;
  CompanyName: string;
  ABSNewFeed: boolean;
  WaitingPeriod: string;
  ProducerName: string;
  QualifyingEvent: boolean;
  OeMonth: number;
  MasterProducerRegion: string;
  EmployerManageEligibility: boolean;
  Propagate: boolean;
  IsReturningFromOnboarding: boolean;
  IsBaUser: boolean;
  IsViewOnly: boolean;
  MasterProducerNr: string;
  IsNewBaUser: boolean;
  PrevRegion: RegionCodes;

  constructor(userType: UserTypeEnum) {
    this.ActualView = ViewType.EmployerView;
    this.PrevView = ViewType.EmployerView;
    this.UserType = userType;
    this.IsLoading = false;
    this.ShowModulePickerView = false;
    this.ActualRegion = RegionCodes.None;
    this.Propagate = false;
  }
  SetIsBaUser(isBaUser: boolean) {
    this.IsBaUser = isBaUser
  }

  SetIsNewBaUser(isNewBaUser: boolean) {
    this.IsNewBaUser = isNewBaUser
  }

  SetMasterProducerNr(masterProducerNr: string) {
    this.MasterProducerNr = masterProducerNr
  }

  SetABSNewFeed(absNewFeed: boolean) {
    this.ABSNewFeed = absNewFeed;
  }

  SetPropagate(propagate: boolean) {
    this.Propagate = propagate;
  }

  SetEmployerManageEligibility(ManageEligibility: boolean) {
    this.EmployerManageEligibility = ManageEligibility;
  }

  SetView(newView: ViewType) {
    this.ActualView = newView;
  }

  SetPrevView(view: ViewType) {
    this.PrevView = view;
  }

  SetEmployerNumber(employerNumber: string) {
    this.EmployerNumber = employerNumber;
  }

  SetSubProducer(subProducer: boolean){
    this.IsSubProducer = subProducer;
  }

  SetMasterProducerId(masterProducerId: string) {
    this.MasterProducerId = masterProducerId;
  }

  SetMasterProducerName(producerName: string) {
    this.ProducerName = producerName;
  }
  SetMasterProducerRegion(region: string) {
    this.MasterProducerRegion = region;
  }

  SetEmployerId(employerId: string) {
    this.EmployerId = employerId;
  }
  SetCompanyName(companyName: string) {
    this.CompanyName = companyName;
  }
  SetWaitingPeriod(waitingPeriod: string) {
    this.WaitingPeriod = waitingPeriod;
  }

  SetEmployerOeMonth(oeMonth: number) {
    this.OeMonth = oeMonth;
  }
  SetPlanNumber(planNumber: string) {
    this.PlanNumber = planNumber;
  }

  SetInpersonateOtherViews(state: boolean) {
    this.Impersonate = state;
  }

  SetModulePickerView(state: boolean) {
    this.ShowModulePickerView = state;
  }

  SetRegion(region: RegionCodes) {
    this.ActualRegion = region;
  }

  SetViewOnly(viewOnly: boolean)
  {
    this.IsViewOnly = viewOnly;
  }

  SetPrevRegion(prevRegion: RegionCodes) {
    this.PrevRegion = prevRegion;
  }

  SetQualifyingEvent(qualifyingEvent: boolean) {
    this.QualifyingEvent = qualifyingEvent;
  }

  SetViewAsLoading = () => (this.IsLoading = true);
  SetViewAsLoaded = () => (this.IsLoading = false);

  IsAdmin = (): boolean => this.UserType == UserTypeEnum.Admin;
}
