import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { ReplicationStatusService } from 'src/app/core/services/http/replication-status.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { Tab } from 'src/app/shared/components/tab-menu/tab-menu.component';

@Component({
  selector: 'app-replication-status',
  styleUrls: ['./replication-status.component.scss'],
  template: `
    <app-content-card CustomTitle="Replication Results">
      <form [formGroup]="replicationStatusForm" style="display: flex; gap: 10px; align-items: center;">
        <!-- TODO CHange this date input to new one after add-employee merge -->
        <app-form-input-date
        
          title="Date"
          placeholder=""
          formControlName="date"
          [parentForm]="replicationStatusForm"
          fieldName="date"></app-form-input-date>

        <app-form-select FieldName="type" [Options]="options" [parentForm]="replicationStatusForm"></app-form-select>

        <custom-button
          [forceCapitalization]="true"
          (onClick)="onViewReport()"
          [disabled]="loading"
          [IsLoading] = "loading"
          [icon]="magnifyingIcon"
          label="VIEW REPORT"></custom-button>
      </form>
      <ng-container >
        <ng-container >
          <!-- <div *ngIf="data.loading"><app-skeleton-input></app-skeleton-input></div>

          <ng-container *ngIf="data.value && data.value.Data.length === 0"> No data found </ng-container> -->

          <ng-container >
            <app-tab-menu [tabs]="tabs" [itemOpened]="showTab">
              <ng-template #DDCA let-data>
                <iframe
                  title="DDCA result"
                  [srcdoc]="ddcaContent"
                  id="DDCAIframe"
                  style="width: 100%;height: 500px;"></iframe>
              </ng-template>
              <ng-template #DDIC let-data>
                <iframe
                  title="DDIC result"
                  #DDICIframe
                  [srcdoc]="ddicContent"
                  id="DDICIframe"
                  style="width: 100%;height: 500px;"></iframe>
              </ng-template>
              <ng-template #DDPA let-data>
                <iframe
                  title="DDPA result"
                  #DDPAIframe
                  [srcdoc]="ddpaContent"
                  style="width: 100%;height: 500px;"></iframe>
              </ng-template>
              <ng-template #Producer let-data>
                <iframe
                  title="Producer result"
                  #ProducerIframe
                  [srcdoc]="producerContent"
                  id="ProducerIframe"
                  style="width: 100%;height: 500px;"></iframe>
              </ng-template>
            </app-tab-menu>
          </ng-container>
        </ng-container>
      </ng-container>
    </app-content-card>
  `,
})
export class ReplicationStatusComponent implements OnInit {
  magnifyingIcon = faMagnifyingGlass;
  replicationStatusForm: FormGroup;
  options: SelectOptions<any>[] = [
    { key: 'export', optionText: 'Export' },
    { key: 'import', optionText: 'Import' },
  ];
  tabs: Tab[];
  showResults: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showTab: string;

  replicationStatusData$: Observable<any>;

  loading: boolean;

  ddcaContent: string;
  ddicContent: string;
  ddpaContent: string;
  producerContent: string;

  @ViewChild('DDCA', { static: true }) DDCATemplate: TemplateRef<unknown>;
  @ViewChild('DDIC', { static: true }) DDICTemplate: TemplateRef<unknown>;
  @ViewChild('DDPA', { static: true }) DDPATemplate: TemplateRef<unknown>;
  @ViewChild('Producer', { static: true }) ProducerTemplate: TemplateRef<unknown>;

  @ViewChild('DDCAIframe') DDCAIframe: ElementRef;
  @ViewChild('DDICIframe') DDICIframe: ElementRef;
  @ViewChild('DDPAIframe') DDPAIframe: ElementRef;
  @ViewChild('ProducerIframe') ProducerIframe: ElementRef;

  constructor(private replicationStatusService: ReplicationStatusService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.tabs = [
      { id: 'DDCA', label: 'DDCA', content: this.DDCATemplate },
      { id: 'DDIC', label: 'DDIC', content: this.DDICTemplate },
      { id: 'DDPA', label: 'DDPA', content: this.DDPATemplate },
      { id: 'Producer', label: 'Producer Result', content: this.ProducerTemplate },
    ];

    this.replicationStatusForm = this.formBuilder.group({
      type: new FormControl('export', [Validators.required]),
      date: new FormControl('', [Validators.required]),
    });
  }
  onViewReport() {
    this.loading = true;
    if (this.replicationStatusForm.valid) {
      let { type, date } = this.replicationStatusForm.value;
      if (type && date) this.replicationStatusService;
      this.replicationStatusService
        .GetReplicationResults(type, formatDate(date, 'MM/DD/YYYY'))
        .subscribe( res => {
          this.loading = false;
            if (res.Data.length === 0) return;
            res.Data.map((data: any) => {
              
              if (data.SiteId == 2) {
                this.ddcaContent = data.EmailBody;
                this.showTab = 'DDCA';
              } else if (data.SiteId == 3) {
                this.showTab = 'DDIC';
                this.ddicContent = data.EmailBody;
                // this.setHtmlToIframe(this.DDICIframe, data.EmailBody);
              } else if (data.SiteId == 4) {
                this.showTab = 'DDPA';
                this.ddpaContent = data.EmailBody;
                // this.setHtmlToIframe(this.DDPAIframe, data.EmailBody);
              } else if (data.SiteId == 8) {
                this.showTab = 'ProducerResult';
                this.producerContent = data.EmailBody;
                // this.setHtmlToIframe(this.ProducerIframe, data.EmailBody);
              }
            });
          });
      this.showResults.next(true);
    }
  }
}
