import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fader } from 'src/app/route-animations';
import { VersionHistoryService } from '../user-dashboard/pages/version-history/version-history.service';

@Component({
  selector: 'app-authentication',
  // templateUrl: './authentication.component.html',
  animations: [fader],
  styleUrls: ['./authentication.component.scss'],
  template: `
    <div ngClass="authentication-container">
      <div ngClass="authentication-header">
        <div ngClass="image-container">
          <a href="https://www.alliedadministrators.com/" style="cursor:pointer" target="_blank">
            <img ngClass="logo-header" src="assets/img/header.jpg" />
          </a>
        </div>
        <div ngClass="image-container">
          <a href="https://www1.deltadentalins.com/" style="cursor:pointer" target="_blank">
            <img ngClass="logo" src="assets/img/dd_logo.png" />
          </a>
        </div>
      </div>
      <div ngClass="authentication-background">
        <div ngClass="authentication-content" [@routeAnimations]="prepareRoute(outlet)">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
      <div style="height: 40px;">

        <div ngClass="information_container">
          <div ngClass="information_text" [innerHtml]="getCopyrightMessage()">
            
            <app-term-of-use-modal></app-term-of-use-modal>
            <!-- <a class="term_of_use_link" (click)="openTermOfUseModal = true">Term of Use</a> -->
          </div>

          <div ngClass="information_text">Software Version {{currentVersion | number: '1.1'}}</div>
        </div>
      </div>
    </div>
  `,
})
export class AuthenticationComponent implements OnInit {
  currentVersion: string;
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  constructor(private versionHistoryService: VersionHistoryService) {}

  ngOnInit(): void {
    this.getLatestVersion();
  }

  getCopyrightMessage(){
    return `©${(new Date()).getFullYear() } Allied Administrators Inc. All Rights Reserved.`
  }

  getLatestVersion() {
    this.versionHistoryService.getLatestVersion().subscribe(res => {
      this.currentVersion = res.VersionNumber.toString();
    });
  }
}
