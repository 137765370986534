import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import AvailableStates from 'src/app/core/common/AvailableStates';
import { ContactFields, InfoFields } from '../../employee-form/employee-form.component';

@Component({
  selector: 'employee-contact-information-form-section',
  styleUrls: ['./contact-information-form-section.component.scss', '../styles.scss'],
  template: `
    <form [formGroup]="formGroup" ngClass="form_section">
      <app-form-input
        *ngIf="contactFields.address.show"
        type="text"
        [Wide]="true"
        [primary]="true"
        title="Address"
        placeholder=""
        formControlName="address"
        [forceCaps]="true"
        [parentForm]="formGroup"
        fieldName="address"></app-form-input>

      <app-form-input
        *ngIf="contactFields.address2.show"
        type="text"
        [Wide]="true"
        [primary]="true"
        title="Address Line 2"
        placeholder=""
        formControlName="address2"
        [parentForm]="formGroup"
        [forceCaps]="true"
        fieldName="address2"></app-form-input>

      <app-form-input
        *ngIf="contactFields.city.show"
        type="text"
        [Wide]="true"
        [primary]="true"
        title="City"
        placeholder=""
        formControlName="city"
        [parentForm]="formGroup"
        [forceCaps]="true"
        fieldName="city"></app-form-input>

      <app-form-select
        *ngIf="contactFields.state.show"
        [Options]="stateOptions"
        Label="State"
        formControlName="state"
        [parentForm]="formGroup"
        FieldName="state"></app-form-select>
      <app-form-input
        *ngIf="contactFields.zip5.show"
        type="text"
        [Wide]="true"
        [primary]="true"
        title="Zip"
        placeholder=""
        formControlName="zip5"
        [mask]="'99999'"
        [parentForm]="formGroup"
        fieldName="zip5"></app-form-input>
      <app-form-input
        *ngIf="contactFields.zip4.show"
        type="text"
        [Wide]="true"
        [primary]="true"
        title=""
        placeholder=""
        formControlName="zip4"
        [mask]="'9999'"
        [parentForm]="formGroup"
        fieldName="zip4"></app-form-input>

      <app-form-input
        *ngIf="contactFields.phone.show"
        type="text"
        [Wide]="true"
        [primary]="true"
        title="Phone"
        placeholder=""
        formControlName="phone"
        [parentForm]="formGroup"
        [mask]="'(000) 000-0000'"
        fieldName="phone"></app-form-input>
        <app-form-input
        *ngIf="contactFields.email?.show"
        type="text"
        [Wide]="true"
        [primary]="true"
        title="Email"
        placeholder=""
        formControlName="email"
        [parentForm]="formGroup"
        fieldName="email"></app-form-input>
    </form>
  `,
})
export class ContactInformationFormSectionComponent implements OnInit {
  contactInformationForm: FormGroup;
  @Input() contactFields: ContactFields;
  @Input() parentForm: FormGroup;
  stateOptions: SelectOptions<string>[];

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.stateOptions = AvailableStates.map(
      state => ({ key: state.StateName, optionText: state.StateName } as SelectOptions<string>)
    );
    // this.contactInformationForm = this.formBuilder.group({
    //   address: [''],
    //   address2: [''],
    //   city: [''],
    //   state: [''],
    //   zip5: [''],
    //   zip4: [''],
    //   phone: [''],
    // });

    // this.parentForm.addControl('contactInformationForm', this.contactInformationForm);

    // console.log('Parent Form', this.parentForm);
    console.log('aaa', this.parentForm);
  }
  get formGroup() {
    return this.parentForm.get('info') as FormGroup;
  }
}
