import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal',
  styleUrls: ['./modal.component.scss'],
  template: `
    <div ngClass="modal_wrapper" [class.hide]="!open">
      <div ngClass="modal">
        <div ngClass="modal_header">
          <span style="color:#444; font: 500 18px/28px Roboto,sans-serif;">{{ modalHeader }}</span>
          <a *ngIf="shouldAllowCloseModal()" (click)="onClose()">X</a>
        </div>
        <div style="padding: 10px 10px 20px 10px;">
          <ng-content></ng-content>
        </div>
        <div ngClass="modal_buttons" *ngIf="!modalProps?.disableButtons">
          <custom-button
            [forceCapitalization]="true"
            (onClick)="onClose()"
            *ngIf="shouldDisplayCloseButton()"
            [label]="modalProps?.CloseButton?.Label ?? 'Close'"
            [color]="'tertiary'"></custom-button>
          <custom-button
            [forceCapitalization]="true"
            (onClick)="onOk()"
            *ngIf="shouldDisplayOkButton()"
            [label]="modalProps?.OkButton?.Label ?? 'Ok'"></custom-button>
        </div>
      </div>
      {{ log() }}
    </div>
  `,
})
export class ModalComponent implements OnInit {
  @Input() modalProps?: ModalProps;
  @Input() modalHeader: string;
  @Input() open: Boolean = false;

  log() {
    // console.log(this.open)
  }
  constructor() {}

  ngOnInit() {}

  onClose() {
    this.open = false;
    if (!this.modalProps || !this.modalProps.CloseButton) return;
    let { OnAction } = this.modalProps.CloseButton;
    OnAction && OnAction();
  }

  onOk() {
    this.open = false;
    if (!this.modalProps || !this.modalProps.OkButton) return;
    let { OnAction } = this.modalProps.OkButton;
    OnAction && OnAction();
  }

  shouldDisplayCloseButton() {
    return this.modalProps?.CloseButton?.show ?? true;
  }

  shouldDisplayOkButton() {
    return this.modalProps?.OkButton?.show ?? true;
  }

  shouldAllowCloseModal() {
    return this.modalProps?.CanCloseModal ?? true;
  }
}

export type ModalProps = {
  disableButtons?: boolean;
  OkButton?: ModalButton;
  CloseButton?: ModalButton;
  CanCloseModal?: boolean;
};

type ModalButton = {
  Label?: string;
  show?: boolean;
  OnAction?: () => void;
};
