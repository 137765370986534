import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tab-menu',
  styleUrls: ['./tab-menu.component.scss'],
  template: `
    <div ngClass="tab_menu_wrapper">
      <!-- Header -->
      <div ngClass="tab">
        <ng-container *ngFor="let item of tabs">
          <div ngClass="tab_item" (click)="onTabClick(item.id)" [class.selected_tab]="selectedItem.value == item.id">
            {{ item.label }}
          </div>
        </ng-container>
      </div>
      <!-- Content -->
      <div *ngIf="selectedTemplate.asObservable() | async">
        <ng-template *ngTemplateOutlet="selectedTemplate.value"></ng-template>
      </div>
    </div>
  `,
})
export class TabMenuComponent implements OnInit {
  @Input() tabs: Tab[];
  @Input() firstItemOpened: boolean;
  @Input() itemOpened: string;

  selectedItem: BehaviorSubject<string | null>;
  selectedTemplate: BehaviorSubject<TemplateRef<any> | null>;

  constructor() {}

  ngOnInit() {
    this.selectedTemplate = new BehaviorSubject<TemplateRef<any> | null>(null);
    this.selectedItem = new BehaviorSubject<string | null>(null);
    if (this.firstItemOpened && this.tabs.length > 0 && this.tabs[0].content) {
      this.selectedItem.next(this.tabs[0].id);
      this.selectedTemplate.next(this.tabs[0].content);
    }

    if (this.itemOpened && this.tabs.length > 0) {
      let foundItem = this.tabs.find(x => x.id === this.itemOpened);
      if (foundItem && foundItem.content) {
        this.selectedItem.next(foundItem.id);
        this.selectedTemplate.next(foundItem.content);
      }
    }
  }

  onTabClick(item: string) {
    this.selectedItem.next(item);
    let template = this.tabs.find(tab => tab.content && tab.id === item);
    if (template?.content) this.selectedTemplate.next(template.content);
    else this.selectedTemplate.next(null);
  }
}

export type Tab = {
  label: string;
  id: string;
  content?: TemplateRef<any>;
};
