import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DemoMaterialModule } from '../material-modules';
import { ClickOutsideDirectiveDirective } from './directives/click-outside-directive.directive';

import { FormSelectComponent } from './components/form-elements/form-select/form-select.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { VerticalMenuComponent } from './components/vertical-menu/vertical-menu.component';
import { SelectComponent } from './components/select/select.component';
import { CustomButtonComponent } from './components/button/button.component';
import { FormInputComponent } from './components/form-elements/form-input/form-input.component';
import { FormFieldErrorsComponent } from './components/form-elements/form-field-errors/form-field-errors.component';
import { AlertDialogComponent } from './components/dialog/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from './components/dialog/confirmation-dialog/confirmation-dialog.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { SkeletonInputComponent } from './components/skeleton/skeleton-input/skeleton-input.component';
import { MenuItemComponent } from './components/vertical-menu/components/menu-item/menu-item.component';
import { MenuItemWrapperComponent } from './components/vertical-menu/components/menu-item-wrapper/menu-item-wrapper.component';
import { ContentCardComponent } from './components/content-card/content-card.component';
import { FormCheckboxComponent } from './components/form-elements/form-checkbox/form-checkbox.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { AccordionModule } from './components/accordion/accordion.module';
import { SafePipe } from '../core/pipes/safe.pipe';
import { WithLoadingPipe } from '../core/pipes/with-loading.pipe';
import { LeftSideDirective } from './components/navbar/directives/left-side.directive';
import { LeftSideDirectiveContent } from './components/navbar/directives/left-side-content.directive';
import { RightSideContentDirective } from './components/navbar/directives/right-side-content.directive';
import { RightSideDirective } from './components/navbar/directives/right-side.directive';
import { AvatarMenuComponent } from './components/avatar-menu/avatar-menu.component';
import { NgxMaskModule } from 'ngx-mask';
import { TableComponent } from './components/table/table.component';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MyCustomPaginatorIntl } from './components/table/PaginatorIntl';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { DragDirective } from './directives/dragDrop.directive';
import { DragDropDownloadComponent } from './components/drag-drop-download/drag-drop-download.component';
import { FormInputDateComponent } from './components/form-elements/form-input-date/form-input-date.component';
import { FormRadioButtomComponent } from './components/form-elements/form-radio-buttom/form-radio-buttom.component';
import { FormSlideToggleComponent } from './components/form-elements/form-slide-toggle/form-slide-toggle.component';
import { ModalComponent } from './components/modal/modal.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CustomAlertComponent } from './components/custom-alert/custom-alert.component';
import { LogDetailsComponent } from './components/profile/log-details/log-details.component';
import { ViewJournalComponent } from './components/view-journal/view-journal.component';
import { TermOfUseModalComponent } from './components/term-of-use-modal/term-of-use-modal.component';
import { DateMaskDirective } from './components/form-elements/form-input-date/date-mask.directive';
import { CalendarModule } from 'primeng/calendar';
import { StylePaginatorDirective } from './components/table/custom-paginator.directive';

@NgModule({
  declarations: [
    // Navbar
    NavbarComponent,
    LeftSideDirective,
    LeftSideDirectiveContent,
    RightSideContentDirective,
    RightSideDirective,
    FormInputDateComponent, 
    DateMaskDirective,

    //Common Components
    MenuItemComponent,
    VerticalMenuComponent,
    CustomButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormFieldErrorsComponent,
    SelectComponent,
    FormSelectComponent,
    AlertDialogComponent,
    ConfirmationDialogComponent,
    DropdownComponent,
    ClickOutsideDirectiveDirective,
    SkeletonInputComponent,
    MenuItemWrapperComponent,
    ContentCardComponent,
    FormCheckboxComponent,
    FormRadioButtomComponent,
    CheckboxComponent,
    AvatarMenuComponent,
    TableComponent,
    RadioButtonComponent,
    IconButtonComponent,
    TabMenuComponent,
    DragDropDownloadComponent,
    ModalComponent,
    FormSlideToggleComponent,
    ChangePasswordComponent,
    CustomAlertComponent,
    TermOfUseModalComponent,
    LogDetailsComponent,
    ViewJournalComponent,
    // Pipes
    SafePipe,
    WithLoadingPipe,

    //Directive
    DragDirective,
    DateMaskDirective,
    StylePaginatorDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    DemoMaterialModule,
    AccordionModule,
    CommonModule,
    CalendarModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    // Navbar
    NavbarComponent,
    LeftSideDirective,
    LeftSideDirectiveContent,
    RightSideContentDirective,
    RightSideDirective,
    MenuItemComponent,
    FormInputDateComponent,

    // Other Components
    VerticalMenuComponent,
    CustomButtonComponent,
    FormInputComponent,
    SelectComponent,
    FormFieldErrorsComponent,
    FormSelectComponent,
    AlertDialogComponent,
    ConfirmationDialogComponent,
    DropdownComponent,
    SkeletonInputComponent,
    MenuItemWrapperComponent,
    ContentCardComponent,
    FormCheckboxComponent,
    FormRadioButtomComponent,
    CheckboxComponent,
    AccordionModule,
    AvatarMenuComponent,
    TableComponent,
    RadioButtonComponent,
    IconButtonComponent,
    TabMenuComponent,
    DragDropDownloadComponent,
    ModalComponent,
    FormSlideToggleComponent,
    ChangePasswordComponent,
    CustomAlertComponent,
    LogDetailsComponent,
    ViewJournalComponent,
    TermOfUseModalComponent,

    // Pipes
    SafePipe,
    WithLoadingPipe,

    //Directive
    DragDirective,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MyCustomPaginatorIntl }],
})
export class SharedModule {}
