import { NgModule } from '@angular/core';
import { UserDashboardModule } from './user-dashboard/user-dashboard.module';
import { AuthenticationModule } from './authentication/authentication.module';

@NgModule({
  imports: [],
  declarations: [],
  exports: [UserDashboardModule, AuthenticationModule],
})
export class FeatureModule {}
