import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnInit,
  QueryList,
} from '@angular/core';
import { RegionCodes } from 'src/app/core/models/api/auth/responses/get-regions.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { ChangeViewByRegion } from 'src/app/features/user-dashboard/helpers/user-view.helper';
import { DropdownItems } from '../dropdown/dropdown.component';
import { SelectOptions } from '../select/select.component';
import { LeftSideDirective } from './directives/left-side.directive';
import { RightSideDirective } from './directives/right-side.directive';

export type NavbarItem = {
  name: string;
  link: string;
};

@Component({
  selector: 'app-navbar',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div ngClass="navbar-wrapper">
      <div ngClass="left_side">
        <ng-container [ngTemplateOutlet]="leftSide?.content?.templateRef || empty"></ng-container>
      </div>
      <div *ngIf="ShowModulePicker" ngClass="choose_module">
        <span>Choose Module:</span>
        <div>
          <app-skeleton-input *ngIf="IsLoading$; else loaded"></app-skeleton-input>
          <ng-template #loaded>
            <app-dropdown
              [Items]="OptionList"
              [Selected]="SelectedItem"
              (OnClickOutput)="OnOptionClick(dropdown.Selected)"
              #dropdown></app-dropdown>
          </ng-template>
        </div>
      </div>
      <div>
        <ng-container [ngTemplateOutlet]="rightSide?.content?.templateRef || empty"></ng-container>
      </div>
    </div>

    <ng-template #empty></ng-template>
  `,
})
export class NavbarComponent implements OnInit, AfterViewInit {
  @Input() NavbarItens: NavbarItem[] = [];

  @ContentChild(LeftSideDirective)
  leftSide?: LeftSideDirective;

  @ContentChild(RightSideDirective)
  rightSide?: RightSideDirective;

  OptionList: DropdownItems<RegionCodes>[] = [];
  SelectedItem: RegionCodes = RegionCodes.None;
  IsLoading$: boolean = false;
  ShowModulePicker: boolean = false;

  constructor(
    private authService: AuthService,
    private userViewService: UserViewService,
    private cdr: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  OnOptionClick(value?: RegionCodes) {
    var view = this.userViewService.GetCurrentUserViewState();
    var alreadyChangedView = view?.ActualRegion && view.PrevView;

    if (view?.Impersonate) {
      this.userViewService
        .Propagate(false)
        .ChangeEmployerId('')
        .ChangePlanNumber('')
        .ChangeCompanyName('')
        .ChangeWaitingPeriod('')
        .ChangeMasterProducerId('')
        .ChangeProducerNameAndRegion('', '')
        .ChangeImpersonationState(false);
    }

    if (value) ChangeViewByRegion(this.userViewService, value);
  }

  ngOnInit(): void {
    this.IsLoading$ = true;
    let userView = this.userViewService.GetCurrentUserViewState();
    if (userView) this.ShowModulePicker = userView.IsAdmin();
    if (this.ShowModulePicker) {
      this.authService.GetRegions().subscribe(regions => {
        this.SelectedItem = this.userViewService.GetSelectedRegion();
        this.OptionList = regions.response.map<DropdownItems<RegionCodes>>(region => ({
          key: region.Code,
          value: region.Key,
        }));
        this.IsLoading$ = false;
        this.cdr.detectChanges();
      });
    }
  }

  GetItens(): NavbarItem[] {
    return this.NavbarItens;
  }
}
