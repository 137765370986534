import { AbstractControl, ValidationErrors } from '@angular/forms';

export default function EmptyStringValidator(control: AbstractControl): ValidationErrors | null {
  let inputValue = control.value;

  if (inputValue === undefined || inputValue === '' || inputValue === null) {
    return { customErrorMessage: 'Value required.' };
  }

  return null;
}
