import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-select',
  styleUrls: ['./select.component.scss'],
  template: `
    <mat-form-field appearance="standard" style="width: 100%;">
      <!-- <div>{{OptionList | json}}</div> -->
      <mat-label>{{ Label }}</mat-label>
      <mat-select #select (selectionChange)="onSelect(select.value)" [value]="value">
        <mat-option *ngFor="let item of OptionList" [value]="item.key">
          {{ item.optionText }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <div>{{OptionList | json}}</div>
    <div>{{OptionList2 | json}}</div> -->
  `,
})
export class SelectComponent<T> implements OnInit {
  SelectedItem?: T;

  @Input() Label: string = '';
  @Input() OptionList: SelectOptions<T>[] = [];
  @Input() value: any;

  @Output() onSelectOutput = new EventEmitter<T>();

  get HasOptions(): boolean {
    return this.OptionList.length > 0;
  }

  get AA() {
    return true;
  }

  public onSelect(value: T) {
    this.SelectedItem = value;
    this.onSelectOutput.emit(value);
  }

  constructor() {
    console.log('build select');
  }

  ngOnInit(): void {}
}

export type SelectOptions<T> = { key: T; optionText: string };
