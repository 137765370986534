import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InvoiceService } from 'src/app/core/services/http/invoice.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-invoices',
  styleUrls: ['./invoices.component.scss'],
  template: `
    <app-content-card CustomTitle="Invoices">
      <div class="issue-text">
      
      Are you having issues accessing the invoices below? <a style="cursor:pointer; text-decoration: underline; margin-left:5px" (click)="OpenNewUrl()">Click here</a>
      </div>
      <iframe title="invoices" height="1000px" width="100%" [src]="EmployerInvoiceLink | safe"></iframe>
    </app-content-card>
  `,
})
export class InvoicesComponent implements OnInit {
  EmployerInvoiceLink: string = '';

  constructor(private userViewSerivce: UserViewService, private invoiceService: InvoiceService) {}

  ngOnInit() {
    let employerNumber = this.userViewSerivce.GetCurrentUserViewState()?.EmployerNumber;
    if (employerNumber)
      this.invoiceService
        .GetInvoiceIFrameUrl(employerNumber)
        .subscribe(response => this.setEmployerInvoiceLink(response.url));
  }

  OpenNewUrl() {
    window.open(this.EmployerInvoiceLink, '_blank');
  }
  private setEmployerInvoiceLink(url: string) {
    this.EmployerInvoiceLink = url;
  }
}
