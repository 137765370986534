import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { EditEmployee } from 'src/app/core/models/api/employee/get-employee.response';
import { SelectOptions } from 'src/app/shared/components/select/select.component';

@Component({
  selector: 'app-print-employee',
  templateUrl: './print-employee.component.html',
  styleUrls: ['./print-employee.component.scss']
})
export class PrintEmployeeComponent implements OnInit {

  constructor() { }

  @Input() employeeData: EditEmployee;
  @Input() qualifyingEventOptions: SelectOptions<any>[];
  @Input() terminationReasonOptions: SelectOptions<string>[];
  @Input() qualifyingEventDepOptions: SelectOptions<string>[];

  depterminationReasonOptions: SelectOptions<string>[] = [
    {
      key: 'ID',
      optionText: 'Ineligible Dependent',
    },
    {
      key: 'LC',
      optionText: 'Legal Change',
    },
    {
      key: 'OC',
      optionText: 'Other Coverage',
    },
    {
      key: 'OR',
      optionText: 'Other Reason',
    },
  ];

  ngOnInit(): void {
  }

  getTerminationReasonName(terminationReasonCode: string){
    return this.terminationReasonOptions.filter(x => x.key === terminationReasonCode)[0]?.optionText;
  }

  getDepTerminationReason(terminationReasonCode: string){
    return this.depterminationReasonOptions.filter(x => x.key === terminationReasonCode)[0]?.optionText;
  }

  getDepQualifyingEventName(qualifyingEventCode: string){
    return this.qualifyingEventDepOptions.filter(x => x.key === qualifyingEventCode)[0]?.optionText;
   }

  getQualifyingEventName(qualifyingEventCode: number){
   return this.qualifyingEventOptions.filter(x => x.key === qualifyingEventCode)[0]?.optionText;
  }
  
  getRelationShipName(relationshipCode: string){
    switch(relationshipCode)
    {
      case 'W':
      return 'Wife';
        case 'H':
        return 'Husband';

        case 'D':
        return 'Daughter';

        case 'S':
        return 'Son';

        case 'F':
        return 'DP-Female';

        case 'P':
        return 'DP-Male';

        case 'A':
        return 'Non-Binary-Adult';

        case 'C':
        return 'Non-Binary-Child';
        default:
          return '';
    }
  }



  getPdfFormatDate(date: any)
  {
    if(IsStringNullOrEmpty(date) || date === 'Invalid Date') return '';

    return moment(date).format('L');
  }


}
