import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { Dependent } from '../../employee-form/employee-form.component';

@Component({
  selector: 'employee-dependent-form-section',
  styleUrls: ['./dependent-form-section.component.scss', '../styles.scss'],
  template: `
    <form [formGroup]="dynamicForm" ngClass="dependent_fields_wrapper">
      <div style="flex-grow: 1; width:15%">
        <app-form-select
          *ngIf="dependent.relationshipCode.show"
          Label="Relationship"
          title="Relationship"
          placeholder="Relationship"
          formControlName="relationshipCode"
          FieldName="relationshipCode"
          [parentForm]="dynamicForm"
          [Options]="options">
        </app-form-select>
      </div>
      <div style="flex-grow: 1;">
        <app-form-input
          *ngIf="dependent.firstName.show"
          type="text"
          [primary]="true"
          [Wide]="true"
          title="First Name"
          placeholder="First Name"
          formControlName="firstName"
          fieldName="firstName"
          [forceCaps]="true"
          [parentForm]="dynamicForm"></app-form-input>
      </div>
      <div style="flex-grow: 1;">
        <app-form-input
          *ngIf="dependent.lastName.show"
          type="text"
          [primary]="true"
          [Wide]="true"
          title="Last Name"
          placeholder="Last Name"
          formControlName="lastName"
          fieldName="lastName"
          [forceCaps]="true"
          [parentForm]="dynamicForm"></app-form-input>
      </div>
      <div style="flex-grow: 1;">
        <app-form-input-date
          *ngIf="dependent.birthDate.show"
          [wide]="true"
          title="Birth Date"
          placeholder="Birth Date"
          formControlName="birthDate"
          fieldName="birthDate"
          [parentForm]="dynamicForm">
        </app-form-input-date>
      </div>
      <!-- <div style="flex-grow: 1;"> -->
        <app-form-select
          *ngIf="dependent.status.show"
          Label="Status"
          title="Status"
          placeholder="Status"
          formControlName="status"
          FieldName="status"
          [parentForm]="dynamicForm"
          [Options]="statusOptions">
        </app-form-select>
      <!-- </div> -->
      <div style="flex-grow: 1;">
        <app-form-input-date
          *ngIf="dependent.terminationDate.field.show && !dependent.terminationDate.showTerminationDateAsSelect"
          [wide]="true"
          title="Termination Date"
          placeholder="Termination Date"
          formControlName="terminationDate"
          fieldName="terminationDate"
          [readonly]="dependent.terminationDate.field.readonly"
          [parentForm]="dynamicForm">
        </app-form-input-date>
        <app-form-select
          *ngIf="dependent.terminationDate.field.show && dependent.terminationDate.showTerminationDateAsSelect"
          title="Termination Date"
          [Options]="dependent.terminationDate.terminationDateOptions ?? []"
          placeholder=""
          formControlName="terminationDate"
          Label="Termination Date"
          [readonly]="dependent.terminationDate.field.readonly"
          [parentForm]="dynamicForm"
          FieldName="terminationDate"></app-form-select>
      </div>
      <!-- <div style="flex-grow: 1;"> -->
        <app-form-select
          *ngIf="dependent.terminationReason.field.show"
          [Options]="dependent.terminationReason.terminationReasonOptions ?? terminationReasonOptions"
          Label="Termination Reason"
          title="Termination Reason"
          placeholder="Select Termination Reason"
          formControlName="terminationReason"
          FieldName="terminationReason"
          [parentForm]="dynamicForm">
        </app-form-select>
      <!-- </div> -->
    </form>
  `,
})
export class DependentFormSectionComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() dynamicForm: FormGroup;
  @Input() dependent: Dependent;
  dependentForm: FormGroup;
  options: SelectOptions<string>[] = [
    {
      key: 'W',
      optionText: 'Wife',
    },
    {
      key: 'H',
      optionText: 'Husband',
    },
    {
      key: 'D',
      optionText: 'Daughter',
    },
    {
      key: 'S',
      optionText: 'Son',
    },
    {
      key: 'F',
      optionText: 'DP-Female',
    },
    {
      key: 'P',
      optionText: 'DP-Male',
    },
    {
      key: 'A',
      optionText: 'Non-Binary-Adult',
    },
    {
      key: 'C',
      optionText: 'Non-Binary-Child',
    },
  ];

  statusOptions: SelectOptions<string>[] = [
    { key: '', optionText: 'Selected' },
    { key: 'X', optionText: 'Disabled' },
  ];
  terminationReasonOptions: SelectOptions<string>[] = [
    {
      key: 'ID',
      optionText: 'Ineligible Dependent',
    },
    {
      key: 'LC',
      optionText: 'Legal Change',
    },
    {
      key: 'OC',
      optionText: 'Other Coverage',
    },
    {
      key: 'OR',
      optionText: 'Other Reason',
    },
  ];

  // get formControl {
  //   return this.dynamicForm as FormGroup
  // }
  // dependentFormGroup: FormGroup;
  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    // this.dependentForm = this.formBuilder.group({
    //   relationship: ['', Validators.required],
    //   firstName: [''],
    //   lastName: ['', Validators.required],
    // });
    // console.log('Parent Form', this.parentForm);
    // this.dependentFormGroup = this.formBuilder.group({
    //   dependentFirstName: ['', Validators.required],
    // });
    // this.parentForm.addControl('dependentFormGroup', this.dependentForm);
    // const creds = this.parentForm.controls.dependentFormGroups as FormArray;
    // creds.push(this.dependentFormGroup);
  }
}
