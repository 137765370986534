<!-- <app-content-card CustomTitle="Home">
  <div ngClass="wrapper">
    <div ngClass="inner old_front_end_styles" *ngIf="staticPageAsync$ | withLoading | async as staticPage">
      <app-skeleton-input style="width: 100%;" *ngIf="staticPage.loading"></app-skeleton-input>
      <div style="padding: 30px" *ngIf="staticPage.value" [innerHtml]="staticPage.value.employerDashboardL"></div>
      <div style="padding: 30px" *ngIf="staticPage.value" [innerHtml]="staticPage.value.employerDashboardR"></div>
    </div>
    <div *ngIf="staticPageAsync$ | withLoading | async as staticPage">
      <img style="margin: 0px -10px -14px -10px; width: calc(100% + 20px)" src="assets/img/IMG_2611.jpg" *ngIf="staticPage.value"/>
    </div>
  </div>
</app-content-card> -->
<app-content-card CustomTitle="Home" [HasFullWidth]="false">
  <div ngClass="static_home_wrapper" *ngIf="staticPageAsync$ | withLoading | async as staticPage">
    <app-skeleton-input style="width: 100%;" *ngIf="staticPage.loading"></app-skeleton-input>
    <!-- Left Side -->
    <div style="display: flex; gap: 20px; flex-direction: column; padding: 10px" *ngIf="staticPage.value" >
      <div ngClass="text_lg bold" style="display: flex; flex-direction: column; gap: 40px">
        <span>Welcome to Delta Dental's</span>
        <span ngClass="text_lg brand_color bold">Small Business Program</span>
      </div>
      <div ngClass="text_md brand_color text_mark">Quality dental coverage for Small and Growing companies</div>

      <!-- Cards -->
      <div ngClass="btn-group">
        <div
          *ngFor="let button of buttons;"
          class="btn home-page-button"
          (click)="openLink(button.link)">
          <span>{{ button.btnText }}</span>
        </div>
      </div>

      <!-- Bulletin -->
      <div style="display: flex; gap: 21px; margin-left: -20px">
        <div ngClass="strip_wrapper">
          <div ngClass="strip">
            <span ngClass="text_sm bold white">Important Bulletin:</span>
          </div>
          <div ngClass="triangule"></div>
        </div>
        <div ngClass="text_sm brand_color"
        [innerHtml]="staticPage.value.employerDashboardL">
          
          <!-- Allied Administrators is continuing full operations during the current public health situation. Our team is
          available to help and can be reached via our main line (415) 989-7443, or by emailing
          <a href="mailto:cs@alliedadministrators.com">cs@alliedadministrators.com</a>. -->
        </div>
      </div>
    </div>

    <!-- Right Side -->
    <div ngClass="image_container" *ngIf="staticPage.value">
      <!-- fixed Tooltip -->
      <div ngClass="fixed_tooltip_wrapper">
        <div ngClass="fixed_tooltip white" [innerHtml]="staticPage.value.employerDashboardR">
        </div>
        <div ngClass="fixed_tooltip_leg"></div>
      </div>
      <!-- Image PLaceholder -->
      <div ngClass="employer_image">
        <img src="{{pictureData}}" height="590" width="400" >
      </div>
    </div>
  </div>
</app-content-card>
