import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import saveAs from 'file-saver';
import { catchError, throwError, map, take, Subject, Observable } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { DocumentApiResponse, IDocument } from 'src/app/core/models/api/documents.response';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { DocumentService } from 'src/app/core/services/http/document.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { FileEvent, ShowButtons, ShowColumns } from '../documents.component';
import { DocumentProducerService } from 'src/app/core/services/http/document-producer.service';
import { ProducerDocumentJournal } from 'src/app/shared/components/view-journal/view-journal.component';

@Component({
  selector: 'app-producer-documents',
  styleUrls: ['./producer-documents.component.scss'],
  template: `
    <ng-container *ngIf="documents$ | withLoading | async as documents">
      <ng-container *ngIf="documents.loading">
        <app-documents
          [showButtons]="showButtons"
          [modalProps]="modalProps"
          [sendNotificationButtonLabel]="sendNotificationButtonLabel"
          [showColumns]="showColumns"
          [documents$]="[]"
          [isLoading]="true"
          (documentDropped)="onUploadDocument($event)"
          (onDownloadDocumentEvent)="onDownloadDocument($event)"
          (onUnarchiveFileEvent)="onUnarchiveFile($event)"
          (onArchiveFileEvent)="onArchiveFile($event)"
          (onDeleteFileEvent)="onDeleteFile($event)"
          (onSendNotificationEvent)="onSendEmailToProducer()"
          [showViewJournal]="showViewJournal"
          [journalInput]="journalInput">
        </app-documents>
      </ng-container>

      <ng-container *ngIf="documents.value">
        <app-documents
          [showButtons]="showButtons"
          [modalProps]="modalProps"
          [sendNotificationButtonLabel]="sendNotificationButtonLabel"
          [showColumns]="showColumns"
          [documents$]="documents.value"
          [isLoading]="false"
          (documentDropped)="onUploadDocument($event)"
          (onDownloadDocumentEvent)="onDownloadDocument($event)"
          (onUnarchiveFileEvent)="onUnarchiveFile($event)"
          (onArchiveFileEvent)="onArchiveFile($event)"
          (onDeleteFileEvent)="onDeleteFile($event)"
          (onSendNotificationEvent)="onSendEmailToProducer()"
          [showViewJournal]="showViewJournal"
          [journalInput]="journalInput">
        </app-documents>
      </ng-container>
    </ng-container>
  `,
})
export class ProducerDocumentsComponent implements OnInit {
  masterProducerId: string;
  userId: any;
  file: File;

  //Controls
  isAdmin: boolean;
  openUploadDocumentModal: boolean;

  //Table
  documents$: Observable<IDocument[]>;
  rows$: IDocument[];
  archivedRows$: IDocument[];

  showViewJournal: boolean = false;
  journalInput: ProducerDocumentJournal;

  modalProps: ModalProps;
  showButtons: ShowButtons;
  showColumns: ShowColumns;
  sendNotificationButtonLabel: string = 'SEND EMAIL TO PRODUCER';

  constructor(
    private authService: AuthService,
    private documentService: DocumentProducerService,
    private userViewService: UserViewService,
    private notifcationService: NotificationService
  ) {}

  ngOnInit() {
    let userViewState = this.userViewService.GetCurrentUserViewState();
    if (userViewState && userViewState.MasterProducerId) this.masterProducerId = userViewState.MasterProducerId;

    if(userViewState?.IsBaUser === true){
      this.sendNotificationButtonLabel = "SEND EMAIL TO BA";
    }
    this.isAdmin = this.authService.IsAdmin();

    this.authService.userSession.pipe(take(1)).subscribe(session => {
      this.userId = session?.UserID.toString();
    });

 
    this.journalInput = {
      ProducerId: parseInt( this.masterProducerId),
      EventType: 'NP'
    };


    this.showViewJournal = this.isAdmin === true;



    this.showButtons = {
      ShowArchiveRowButton: this.isAdmin,
      ShowUnarchiveRowButton: this.isAdmin,
      ShowUploadDocumentButton: this.isAdmin,
      ShowDeleteRowButton: this.isAdmin,
      ShowSendNotificationButton: this.isAdmin,
    };

    this.showColumns = {
      showUserNameField: this.isAdmin,
      showCount: true,
      showSize: true,
      showActive: false
    };

    this.modalProps = {
      OkButton: {
        Label: 'UPLOAD',
        OnAction: () => {
          if (this.userId)
            this.documentService
              .UploadProducerDocument(this.file, this.masterProducerId, this.userId.toString())
              .subscribe(res => {
                if (res.Status) this.notifcationService.success('Uploaded successfully');
                else this.notifcationService.error('Something went wrong');
                this.updateDocuments();
              });
        },
      },
      CloseButton: {
        OnAction: () => {
          this.openUploadDocumentModal = false;
        },
        show: false,
      },
      CanCloseModal: true,
    };

    this.updateDocuments();
  }

  // Action buttons
  onDownloadDocument(fileEvent: FileEvent) {
    this.documentService.DownloadProducerDocuments(this.masterProducerId, fileEvent.fileId).subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: 'text/csv;charset=utf-8',
      });
      saveAs(data, fileEvent.fileName);
    });
  }

  onUnarchiveFile(fileEvent: FileEvent) {
    this.documentService.UnarchiveProducerDocument(this.masterProducerId, fileEvent.fileId).subscribe(res => {
      this.notifcationService.success('File Unarchived successfully');
      this.updateDocuments();
    });
  }

  onArchiveFile(fileEvent: FileEvent) {
    this.documentService.ArchiveProducerDocument(this.masterProducerId, fileEvent.fileId).subscribe(res => {
      this.notifcationService.success('File archived successfully');
      this.updateDocuments();
    });
  }

  onDeleteFile(fileEvent: FileEvent) {
    this.notifcationService.confirmation('Are you sure you want to delete this document?', () => {
      this.documentService.DeleteProducerDocument(this.masterProducerId, fileEvent.fileId).subscribe(res => {
        if (res.Status) this.notifcationService.success(res.Message);
        else this.notifcationService.error(res.Message);
        this.updateDocuments();
      });
    });
  }

  onUploadDocument(file: File) {
    this.file = file;
    this.openUploadDocumentModal = true;
  }

  onSendEmailToProducer() {
    this.documentService
      .NotifyProducers(this.masterProducerId)
      .pipe(
        catchError(err => {
          this.notifcationService.error('Something went wrong');
          return throwError(() => err);
        })
      )
      .subscribe(res => {
        if (res) this.notifcationService.success('Notification Sent');
        else this.notifcationService.error('Something went wrong');
      });
  }

  private mapToTableRows() {
    return map<DocumentApiResponse, IDocument[]>(res => {
      return res.Data.map(document => ({ ...document, UploadedDate: formatDate(document.UploadedDate) }));
    });
  }

  private updateDocuments() {
    this.documents$ = this.documentService.GetProducerDocuments(this.masterProducerId).pipe(
      this.mapToTableRows(),
      map(res => {
        this.rows$ = res.filter(document => !document.Archived);
        this.archivedRows$ = res.filter(document => document.Archived);
        return res;
      })
    );
    // .subscribe(res => this.documents$.next(res));
  }
}
