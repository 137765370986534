import { Injectable } from '@angular/core';
import moment from 'moment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}
  setUp() {
    var newConsole = this.newConsole(window.console);
    window.console = newConsole;
  }

  newConsole(oldCons: Console) {
    return {
      ...oldCons,
      log: function (...args: any[]) {
        environment.logger.enableInfo && traceableLog(oldCons, enrichLog(), ...args);
      },
      info: function (...args: any[]) {
        environment.logger.enableInfo && traceableLog(oldCons, enrichLog(), ...args);
      },
      debug: function (...args: any[]) {
        environment.logger.enableDebug && traceableLog(oldCons, enrichLog(), ...args);
      },
      warn: function (...args: any[]) {
        environment.logger.enableWarning && oldCons.warn(enrichLog(), ...args);
      },
      error: function (...args: any[]) {
        environment.logger.enableError && oldCons.error(enrichLog(), ...args);
      },
    };
  }
}

function traceableLog(oldCons: Console, ...args: any[]) {
  oldCons.groupCollapsed(...args);
  oldCons.trace(); // hidden in collapsed group
  oldCons.groupEnd();
}

function enrichLog(): string {
  return `[${moment()}]`;
}
