import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Observable, map, take } from 'rxjs';
import { ViewType } from 'src/app/core/models/user-view.model';
import { FileService } from 'src/app/core/services/file.service';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { MasterProducerService } from 'src/app/core/services/http/master-producer.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';
import { parseStringToRegionCode } from '../../helpers/user-view.helper';
import { MasterProducerEmployersEligibilityApiRequest } from 'src/app/core/models/api/master-producer/master-producer-employers-eligibility.request';
import { NotificationService } from 'src/app/core/services/notification.service';
import { VerticalMenuService } from 'src/app/core/services/vertical-menu.service';
import { MasterProducersEmployerData } from 'src/app/core/models/api/master-producer/master-producer-employers-api.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { ActivatedRoute } from '@angular/router';
import { BenefitAdministratorService } from 'src/app/core/services/http/benefit-administrator.service';


@Component({
  selector: 'app-your-clients',
  styleUrls: ['./your-clients.component.scss'],
  template: `
    <app-content-card CustomTitle="Your Clients">
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <app-table
          [DisplayedColumns]="displayedColumns"
          [IsLoading]="rows.loading ?? false"
          [EnableSearch]="true"
          [EnableLocalSearch]="true"
          [EnableLocalSort]="true"
          [EnablePagination]="true"
          [Rows]="rows.value"
          emptyRowsTextMessage="If no companies are listed, there are no active employers at this time."
          [DownloadButtons]="downloadButtons"></app-table>
      </ng-container>
      <div style="display: flex; justify-content: flex-end;">
        <custom-button
          *ngIf="showEdit"
          [forceCapitalization]="true"
          [label]="isEditting ? 'SAVE' : 'EDIT'"
          (onClick)="onEditToggle()"
          [disabled]="saveEmployerEligibilityLoading"
          [IsLoading]="saveEmployerEligibilityLoading"></custom-button>
      </div>
    </app-content-card>

    <ng-template #actionCol let-data>
      <div (click)="onImpersonateUser(data)" style="cursor: pointer;" [matTooltip]="'View ' + data.Name + ' as ER'">
        <fa-icon ngClass="view_as_user_eye" [icon]="viewIcon"></fa-icon>
      </div>
    </ng-template>
    <ng-template #subProducerCol let-data>
      <span>{{data.SubProducer === true ? 'Y' : 'N'}}</span>
    </ng-template>
    <ng-template #manageEligibilityCol let-data>
      <div *ngIf="isEditting">
        <app-checkbox
          [checked]="data.ManageEligibility"
          [enableOnRemoveCheckEvent]="true"
          (onCheck)="onEligibilityCheck(data.EmployerID, data.Region)"
          (onRemoveCheck)="onEligibilityRemoveCheck(data.EmployerID, data.Region)"></app-checkbox>
      </div>
      <span *ngIf="!isEditting"> {{ data.ManageEligibility ? 'Y' : '' }} </span>
    </ng-template>

    <ng-template #employeeCol let-data>
      <span *ngIf="showTotal; else hideTotal">{{ data.ActiveEmp }}/{{ data.Total }}</span>
      <ng-template #hideTotal
        ><span>{{ data.ActiveEmp }}</span></ng-template
      >
    </ng-template>
  `,
})
export class YourClientsComponent implements OnInit {
  viewIcon = faEye;
  rows$: Observable<any[]>;
  displayedColumns: DisplayedColumns[];
  downloadButtons: TableDownloadButtons | undefined;
  producerName: string | undefined;
  masterProducerId: string;

  eligibilityRequest: MasterProducerEmployersEligibilityApiRequest;

  isEditting: boolean = false;
  showEdit: boolean = false;
  showTotal: boolean = false;
  saveEmployerEligibilityLoading: boolean;

  //Column Templates
  @ViewChild('manageEligibilityCol', { static: true }) manageEligibilityTemplate: TemplateRef<unknown>;
  @ViewChild('subProducerCol', { static: true }) subProducerTemplate: TemplateRef<unknown>;
  @ViewChild('actionCol', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('employeeCol', { static: true }) employeeColTemplate: TemplateRef<unknown>;
  isXmlBaClients: boolean;
  isActionDisabled: boolean;

  constructor(
    private route: ActivatedRoute,
    private masterProducerService: MasterProducerService,
    private benefitAdministratorService: BenefitAdministratorService,
    private authService: AuthService,
    private userViewService: UserViewService,
    private fileService: FileService,
    private notificationService: NotificationService,
    private verticalMenuService: VerticalMenuService
  ) {}

  ngOnInit(): void {
    let isAdmin = this.authService.IsAdmin();
    this.isActionDisabled = isAdmin;
    this.isXmlBaClients = this.route.snapshot.url.findIndex(url => url.path.includes('xml-ba-producer-clients')) >= 0;
    this.showEdit = isAdmin;
    this.showTotal = isAdmin;
    this.userViewService;
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        disableColumn: this.isActionDisabled,
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'EmployerNR', label: 'Employer Number', sortable: true },
      { columnName: 'Name', label: 'Name', sortable: true },
      { columnName: 'BrokerofRecord', label: 'Broker of Record', sortable: true },
      { columnName: 'ErGroupState', label: 'Group Contract State', sortable: true },
      {
        columnName: 'ActiveEmp',
        label: 'Employees',
        template: this.employeeColTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      },
      {
        columnName: 'SubProducer',
        label: 'Sub Producer',
        template: this.subProducerTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: false,
      },

      {
        columnName: 'ManageEligibility',
        label: 'Manage Eligibility',
        template: this.manageEligibilityTemplate,
        enableFullDataColumnTemplateContext: true,
        sortable: true,
      },
      { columnName: 'PlanName', label: 'Product', sortable: true },
      { columnName: 'Dental', label: 'Dental', sortable: true },
      { columnName: 'Vision', label: 'Vision', sortable: true },
      { columnName: 'Life', label: 'Life', sortable: true },
      { columnName: 'LTD', label: 'LTD', sortable: true },
      { columnName: 'P3', label: 'P3', sortable: true },
      { columnName: 'STD', label: 'STD', sortable: true },
    ];

    let view = this.userViewService.GetCurrentUserViewState();
    if (!view?.MasterProducerId) {
      console.debug('MasterProducerID not returned');
      return;
    }
    this.masterProducerId = view.MasterProducerId.toString();
    this.eligibilityRequest = {
      DisableEligibility: [],
      EnableEligibility: [],
      MasterProducerId: this.masterProducerId,
    };

    this.masterProducerService.GetMasterProducer(view.MasterProducerId.toString()).subscribe(res => {
      this.producerName = res.Data?.Name;
    });

    this.loadData();
    if (this.isXmlBaClients) {
      this.bindBaDownloadButtons()
      return;
    }

    this.bindMPDownloadButtons()
  }

  onEditToggle() {
    if (this.isEditting) {
      this.saveEmployerEligibilityLoading = true;
      this.masterProducerService.UpdateEmployersEligibility(this.eligibilityRequest).subscribe(res => {
        if (res.Status) this.notificationService.success(res.Message);
        else this.notificationService.error(res.Message);
        this.saveEmployerEligibilityLoading = false;
        this.loadData();
        this.isEditting = false;
      });
    } else {
      this.isEditting = !this.isEditting;
    }
  }

  onEligibilityCheck(employerId: string, region: string) {
    this.eligibilityRequest.DisableEligibility = this.eligibilityRequest.DisableEligibility.filter(
      x => x.EmployerId != Number(employerId)
    );

    let notExistInEnableEligibility = !this.eligibilityRequest.EnableEligibility.some(
      x => x.EmployerId == Number(employerId)
    );

    if (notExistInEnableEligibility)
      this.eligibilityRequest.EnableEligibility.push({
        EmployerId: Number(employerId),
        Region: region,
      });
    console.log(this.eligibilityRequest);
  }

  onEligibilityRemoveCheck(employerId: string, region: string) {
    this.eligibilityRequest.EnableEligibility = this.eligibilityRequest.EnableEligibility.filter(
      x => x.EmployerId != Number(employerId)
    );
    let notExistInDisableEligibility = !this.eligibilityRequest.DisableEligibility.some(
      x => x.EmployerId == Number(employerId)
    );

    if (notExistInDisableEligibility)
      this.eligibilityRequest.DisableEligibility.push({
        EmployerId: Number(employerId),
        Region: region,
      });
    console.log(this.eligibilityRequest);
  }

  onImpersonateUser(employer: MasterProducersEmployerData) {
    let region = employer.Region;

    let oeMonth: number | undefined = undefined;

    if(employer.Oemonth !== undefined){
      oeMonth = employer.Oemonth;
    }
    else if(employer.OEMonth !== undefined){
      oeMonth = parseInt(employer.OEMonth);
    }

    this.userViewService
      .Propagate(false)
      .ChangeRegion(parseStringToRegionCode(region))
      .ChangeEmployerId(employer.EmployerID.toString())
      .ChangeEmployerNumber(employer.EmployerNR)
      .ChangePlanNumber(employer.PlanNr)
      .ChangeCompanyName(employer.Name)
      .ChangeEmployerManageEligibility(employer.ManageEligibility)
      .ChangeWaitingPeriod(employer.WaitingPeriod)
      .ChangeEmployerOeMonth(oeMonth)
      .ChangeImpersonationState(true)
      .ChangeView(ViewType.EmployerView)
      .Propagate(true)
      .NavigateToPageView(this.userViewService.GetPrimaryLink().toString());
  }

  private loadData() {
    if (this.isXmlBaClients) {
      this.rows$ = this.benefitAdministratorService.GetBaEmployers(this.masterProducerId).pipe(
        map(res => {
          let employers = res.response;

          employers.forEach(employer => {
            employer.Dental = employer.Dental ? 'Y' : '';
            employer.Vision = employer.Vision ? 'Y' : '';
            employer.Life = employer.Life ? 'Y' : '';
            employer.LTD = employer.LTD ? 'Y' : '';
            employer.P3 = employer.P3 ? 'Y' : '';
            employer.STD = employer.STD ? 'Y' : '';
          });
          return employers;
        })
      );
      return;
    }

    this.rows$ = this.masterProducerService.GetEmployers(this.masterProducerId).pipe(
      map(res => {
        let employers = res.Data;

        employers.forEach(employer => {
          employer.BrokerofRecord = employer.BrokerFirstName + ' ' + employer.BrokerLastName;
          employer.Dental = employer.EmployerProducts.some(x => x.ProductCode === 'Dental') ? 'Y' : '';
          employer.Vision = employer.EmployerProducts.some(
            x => x.ProductCode === 'VSP' || x.ProductCode === 'EyeMed' || x.ProductCode === 'DeltaVision'
          )
            ? 'Y'
            : '';
          employer.Life = employer.EmployerProducts.some(x => x.ProductCode === 'Life') ? 'Y' : '';
          employer.LTD = employer.EmployerProducts.some(x => x.ProductCode === 'LTD') ? 'Y' : '';
          employer.P3 = employer.EmployerProducts.some(x => x.ProductCode === 'PPP') ? 'Y' : '';
          employer.STD = employer.EmployerProducts.some(x => x.ProductCode === 'STD') ? 'Y' : '';
        });

        return employers;
      })
    );
  }

  private mapToDownloadableObject(employerData: MasterProducersEmployerData[]) {
    return employerData.map(employer => ({
      EmployerNR: employer.EmployerNR,
      Name: employer.Name,
      BrokerofRecord: employer.BrokerofRecord?.replace(/\s+/g, ' ')?? '',
      GroupContractState: employer.ErGroupState,
      ActiveEmp: employer.ActiveEmp,
      ManageEligibility: employer.ManageEligibility ? 'Y' : '',
      PlanName: employer.PlanName,
      Dental: employer.Dental,
      Vision: employer.Vision,
      Life: employer.Life,
      LTD: employer.LTD,
      P3: employer.P3,
      STD: employer.STD,
    }));
  }

  private bindBaDownloadButtons() {
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              EmployerNR: 'Employer Number',
              Name: 'Name',
              BrokerofRecord: 'Broker of Record',
              GroupContractState: 'Group Contract State',
              ActiveEmp: 'Employees',
              ManageEligibility: 'Manage Eligibility',
              PlanName: 'Product',
              Dental: 'Dental',
              Vision: 'Vision',
              Life: 'Life',
              LTD: 'LTD',
              P3: 'P3',
              STD: 'STD',
            },
          ];
          this.rows$.pipe(take(1)).subscribe(rows => {
            this.fileService
              .GeneratePDF(this.producerName + ' -   Your Clients', header, this.mapToDownloadableObject(rows), 'l')
              .save(this.producerName + ' -   Your Clients');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.rows$.pipe(take(1)).subscribe(rows => {
            this.fileService.GenerateXLSX(this.producerName + ' -  Your Clients', this.mapToDownloadableObject(rows));
          });
        },
      },
    };
  }

  private bindMPDownloadButtons() {
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              EmployerNR: 'EmployerNR',
              Name: 'Name',
              BrokerofRecord: 'Broker of Record',
              GroupContractState: 'Group Contract State',
              ActiveEmp: 'ActiveEmp',
              ManageEligibility: 'ManageEligibility',
              PlanName: 'PlanName',
              Dental: 'Dental',
              Vision: 'Vision',
              Life: 'Life',
              LTD: 'LTD',
              P3: 'P3',
              STD: 'STD',
            },
          ];
          this.rows$.pipe(take(1)).subscribe(rows => {
            this.fileService
              .GeneratePDF(this.producerName + ' -   Your Clients', header, this.mapToDownloadableObject(rows), 'l')
              .save(this.producerName + ' -   Your Clients');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.rows$.pipe(take(1)).subscribe(rows => {
            this.fileService.GenerateXLSX(this.producerName + ' -  Your Clients', this.mapToDownloadableObject(rows));
          });
        },
      },
    };
  }
}
