import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-input',
  styleUrls: ['./skeleton-input.component.scss'],
  template: ` <div ngClass="line"></div> `,
})
export class SkeletonInputComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
