import { RegionCodes } from 'src/app/core/models/api/auth/responses/get-regions.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { ViewType } from 'src/app/core/models/user-view.model';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { environment } from 'src/environments/environment';

/**
Changes the view based on the region code.
@param {UserViewService} service - The UserViewService instance.
@param {RegionCodes} region - The region code.
@returns {void}
*/
export function ChangeViewByRegion(service: UserViewService, region: RegionCodes) {
  let mapRegionToView = new Map<RegionCodes, () => void>([
    [
      RegionCodes.Admin,
      () => {
        service
          .Propagate(false)
          .ChangeRegion(RegionCodes.Admin)
          .ChangeView(ViewType.Controller)
          .Propagate(true)
          .NavigateToPageView(RouteEnum.UserProfile);
      },
    ],
    [
      RegionCodes.Onboarding,
      () => {
        service.NavigateToPageView(RouteEnum.Onboarding);
      },
    ],
    [
      RegionCodes.CMS,
      () => {
        service.NavigateToPageView(RouteEnum.CMS);
      },
    ],
    [
      RegionCodes.DDCA,
      () => {
        service
          .Propagate(false)
          .ChangeRegion(RegionCodes.DDCA)
          .ChangeView(ViewType.RegionView)
          .Propagate(true)
          ?.NavigateToPageView(RouteEnum.SelectEmployer);
      },
    ],
    [
      RegionCodes.DDIC,
      () => {
        service
          .Propagate(false)
          .ChangeRegion(RegionCodes.DDIC)
          .ChangeView(ViewType.RegionView)
          .Propagate(true)
          ?.NavigateToPageView(RouteEnum.SelectEmployer);
      },
    ],
    [
      RegionCodes.DDPA,
      () => {
        service
          .Propagate(false)
          .ChangeRegion(RegionCodes.DDPA)
          .ChangeView(ViewType.RegionView)
          .Propagate(true)
          ?.NavigateToPageView(RouteEnum.SelectEmployer);
      },
    ],
    [
      RegionCodes.DDNJ,
      () => {
        service
          .Propagate(false)
          .ChangeRegion(RegionCodes.DDNJ)
          .ChangeView(ViewType.RegionView)
          .Propagate(true)
          ?.NavigateToPageView(RouteEnum.SelectEmployer);
      },
    ],
  ]).get(region);

  mapRegionToView && mapRegionToView();
}

export const parseStringToRegionCode = (value: string): RegionCodes => {
  if (value in RegionCodes) return value as RegionCodes;
  return RegionCodes.None;
};
