import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VersionHistoryService } from '../version-history.service';
import { VersionHistoryResponse } from '../version-history.response';
import { Observable, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { version } from 'chai';

@Component({
  selector: 'app-view-latest',
  template: `
    <app-content-card CustomTitle="View Latest Version">
      <div style="padding:20px" *ngIf="versionHistoryData$ | withLoading | async as versionHistoryData">
        <app-skeleton-input *ngIf="versionHistoryData.loading"></app-skeleton-input>
        <div *ngIf="versionHistoryData.value || noCurrentVersion">
          <span *ngIf="noCurrentVersion"><b>All versions are archived, please create a new one.</b></span>
          <form [formGroup]="versionHistoryForm">
            <div style="display: flex; gap: 10px;">
              <app-form-input
                style="flex:1"
                fieldName="versionNumber"
                formControlName="versionNumber"
                title="Version Number"
                [type]="'text'"
                [sanitizeMask]="false"
                [parentForm]="versionHistoryForm">
              </app-form-input>
            </div>
            <editor
              formControlName="content"
              [init]="{
                height: 200,
                menubar: false,
                base_url: '/tinymce',
                suffix: '.min',
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
              }">
            </editor>
          </form>
          <br />
          <div style="display: flex; justify-content: space-between;">
            <custom-button label="SAVE" (onClick)="onSave()"></custom-button>
            <custom-button label="ARCHIVE" color="secondary" (onClick)="onArchive()"></custom-button>
          </div>
        </div>
      </div>
    </app-content-card>
  `,
  styleUrls: ['./view-latest.component.scss'],
})
export class ViewLatestComponent implements OnInit {
  versionHistoryData$: Observable<VersionHistoryResponse>;
  versionHistoryForm: FormGroup;
  noCurrentVersion: boolean = false;

  constructor(
    private fb: FormBuilder,
    private versionHistoryService: VersionHistoryService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.versionHistoryForm = this.fb.group({
      versionNumber: [],
      content: [],
      id: [],
    });
    this.loadData();
    this.onValidateVersionNumber();
  }

  onValidateVersionNumber(){

    this.versionHistoryForm.get('versionNumber')?.valueChanges.pipe(distinctUntilChanged())
    .subscribe(versionNumber => {
      versionNumber = this.versionHistoryForm.get('versionNumber')?.value;
      
      if(!this.validateVersionNumber())return;

      
      
      this.versionHistoryService.versionNumberExists(versionNumber).subscribe(res => {
        if(res){
          this.versionHistoryForm.get('versionNumber')?.setErrors({ customErrorMessage: 'Version Number already exists'});
        }
      });
    })
  }


  validateVersionNumber(){
    let versionNumber = this.versionHistoryForm.get('versionNumber')?.value;
    let valid = true;
    if(IsStringNullOrEmpty(versionNumber)){
      return false;
    }
    if(isNaN(versionNumber)){
      setTimeout(() => {
        this.versionHistoryForm.get('versionNumber')?.setErrors({ customErrorMessage: 'Version Number must be a number'});        
      }, 100);
      valid = false;
    }
    //regex that validates number on 00.0 format
    let regex = /^\d{1,2}(\.\d)?$/;
    if(!regex.test(versionNumber)){
      setTimeout(() => {
        this.versionHistoryForm.get('versionNumber')?.setErrors({ customErrorMessage: 'Version Number must be in format 00.0'});        
      }, 100);
      valid = false;
    }

    let versionNumberArray = versionNumber.toString().split('.');
    if(versionNumberArray[1]?.length >1){
      setTimeout(() => {
        this.versionHistoryForm.get('versionNumber')?.setErrors({ customErrorMessage: 'Version Number must be in format 00.0'});        
      }, 100);
      valid = false;
    }
    return valid;
  }

  loadData() {
    this.versionHistoryData$ = this.versionHistoryService.getLatestVersion().pipe(
      tap(res => {
        if(res === null || res === undefined){
          this.noCurrentVersion = true;
          return;
        }
        if(res.ArchivedDate !== null && res.ArchivedDate !== undefined)
        {
          this.noCurrentVersion = true;
          return;
        }
        this.noCurrentVersion = false;
        this.versionHistoryForm.patchValue({
          versionNumber: res.VersionNumber,
          content: res.Content,
          id: res.Id,
        });
        this.versionHistoryForm.get('versionNumber')?.disable();
      })
    );
  }

  //TODO - VERIFY IF VERSION NUMBER ALREADY EXISTS WHILE ADDING

  onArchive() {
    let id = this.versionHistoryForm.get('id')?.value;
    this.versionHistoryService.archiveVersion(id).subscribe(res => {
      this.notificationService.success('Version History Archived Successfully');

      this.versionHistoryForm.reset();
      this.versionHistoryForm.get('versionNumber')?.enable();
    });
  }
  onSave() {
    let { id, content } = this.versionHistoryForm.value;
    let data = {
      Id: id,
      Content: content,
      VersionNumber: this.versionHistoryForm.controls.versionNumber.value,
    } as VersionHistoryResponse;

    this.versionHistoryService.postVersionHistory(data).subscribe(res => {
      this.notificationService.success('Version History Saved Successfully');
      this.loadData();
    });
  }
}
