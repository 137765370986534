import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { StaticPage } from 'src/app/core/helpers/view-helper';
import { DashboardEmailResponse } from 'src/app/core/models/api/dashboard.email.response';
import { ViewType } from 'src/app/core/models/user-view.model';
import { EmailService } from 'src/app/core/services/http/email.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-business-program',
  templateUrl: './business-program.component.html',
  styleUrls: ['./business-program.component.scss', '../site-navigation.scss', '../old-front-end-styles.scss'],
})
export class BusinessProgramComponent implements OnInit {
  staticPageAsync$: Observable<DashboardEmailResponse>;

  @ViewChild('content') contentHtml: ElementRef;

  constructor(private emailService: EmailService, private viewService: UserViewService) {}

  ngOnInit() {
    console.log('Business');
    let view = this.viewService.GetCurrentUserViewState();
    if (!view) return;

    let isProducerView = [
      ViewType.ProducerView,
      ViewType.BenefitAdministratorNewBaUserView,
      ViewType.BenefitAdministratorView,
    ].includes(view.ActualView);

    let id = this.viewService.GetUserStaticPageIdByName(
      isProducerView ? StaticPage.DeltaDentalSBP : StaticPage.BusinessProgram
    );

    if (!id) return;
    this.staticPageAsync$ = this.emailService.GetDashboard(id);
  }
}
