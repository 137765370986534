import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, Form, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faKey,
  faUser,
  faUserCheck,
  faEnvelope,
  faPhone,
  faFloppyDisk,
  faClockRotateLeft,
  faRotate,
  faArrowLeft,
  faLock,
} from '@fortawesome/free-solid-svg-icons';
import randomatic from 'randomatic';
import { BehaviorSubject, Observable, debounceTime, map, of, switchMap, tap } from 'rxjs';
import { AuthenticationUserType } from 'src/app/core/models/api/auth/AuthenticationUserTypeEnum';
import {
  AdminUserApiRequest,
  AdminUserGroup,
} from 'src/app/core/models/api/auth/requests/update-create-admin-user.request';
import { Right } from 'src/app/core/models/api/auth/responses/rights.response';
import {
  UserGroup,
  UserRights,
  UsersPrivilegeAPIResponse,
} from 'src/app/core/models/api/auth/responses/user/info/user-privileges.response.model';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { AuthService } from 'src/app/core/services/http/auth/auth.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PasswordValidator } from 'src/app/shared/components/form-elements/password.validator';
import { ModalProps } from 'src/app/shared/components/modal/modal.component';
import { LogDetailsInfo } from 'src/app/shared/components/profile/log-details/log-details.component';
import { UserJournal } from 'src/app/shared/components/view-journal/view-journal.component';

@Component({
  selector: 'app-add-edit-admin-user',
  styleUrls: ['./add-edit-admin-user.component.scss'],
  templateUrl: './add-edit-admin-user.component.html',
})
export class AddEditAdminUserComponent implements OnInit {
  keyIcon = faKey;
  arrowLeft = faArrowLeft;
  generatePWIcon = faRotate;
  personIcon = faUser;
  personCheckIcon = faUserCheck;
  emailIcon = faEnvelope;
  phoneIcon = faPhone;
  saveIcon = faFloppyDisk;
  historyIcon = faClockRotateLeft;
  resetPasswordButtonIcon = faClockRotateLeft;
  lockedUserIcon = faLock;

  //Controls
  showOnboarding: boolean = false;
  showResetPasswordModal: boolean = false;
  changePasswordLoading: boolean = false;
  saveLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  openJournal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isEdittingUser: boolean;
  isAdmin: boolean = false;
  loaded: boolean = false;

  userLocked: boolean = false;
  badLoginAttempts: number = 0;

  adminUsersUrl = RouteEnum.AdminUsers;

  adminUserFormGroup: FormGroup<any>;
  userData$: Observable<UsersPrivilegeAPIResponse>;
  logDetailsInfo: LogDetailsInfo = {};
  userId: number | null;
  journalInput: UserJournal;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.authService.IsAdmin();

    this.userId = Number(this.route.snapshot.paramMap.get('id'));
    this.isEdittingUser = this.userId !== 0;
    this.initUserForm();
    if (!this.isEdittingUser) {
      this.userData$ = of({} as UsersPrivilegeAPIResponse).pipe(
        switchMap(res => {
          return this.authService.GetRights().pipe(
            map(rights => {
              this.initUserForm({
                privileges: this.mapPrivileges(rights.response.rights),
                userGroups: this.mapUserGroups(rights.response.groups),
                regionPrivileges: this.mapPrivileges(rights.response.rights, true),
              });

              this.onOnboardingSelected();
              this.onUsernameChange();
              this.changeDetectorRef.detectChanges();
              return res;
            })
          );
        })
      );
      return;
    }

    this.loadUserData();
    this.onUsernameChange();
  }

  unlockUser() {
    this.notificationService.confirmation('Are you sure you want to Unlock this user?', () => {
      this.authService.UnlockUser(this.userId ?? 0).subscribe(res => {
        if (res.status === true) {
          this.notificationService.success(res.response);
          window.location.reload();
        } else {
          this.notificationService.error(res.response);
        }
      });
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  onSubmit() {
    if (this.adminUserFormGroup.valid) {
      let { userName, firstName, lastName, password, email, phoneNumber, disabled } = this.adminUserFormGroup.value;

      let {
        privileges,
        regionPrivileges,
        manageUsers,
        userGroups,
      }: {
        privileges?: PrivilegeFields[];
        regionPrivileges?: PrivilegeFields[];
        manageUsers?: PrivilegeFields;
        userGroups?: UserGroupFields[];
      } = this.adminUserFormGroup.value;

      privileges = privileges?.filter(x => x.isChecked);
      regionPrivileges = regionPrivileges?.filter(x => x.isChecked);

      let userRights = [...(privileges ?? []), ...(regionPrivileges ?? [])];

      manageUsers && userRights.push(manageUsers);

      let userrights = '';
      userRights.forEach(right => (userrights += right.regionName + ','));

      let userPayload = {
        FirstName: firstName,
        Username: userName,
        LastName: lastName,
        Password: password,
        Email: email,
        Phone: phoneNumber,
        Disabled: disabled,
        UserID: this.userId === 0 ? '' : this.userId,
        userrights: userrights.replace(/,\s*$/, ''),
        UserGroups: userGroups?.map<AdminUserGroup>(x => ({
          Enrolled: x.enrolled,
          Name: x.name,
          UserGroupId: x.userGroupId,
          UserId: x.userId,
        })),
      } as AdminUserApiRequest;

      this.authService.CreateOrUpdateUser(userPayload).subscribe(res => {
        if (res.status) {
          this.notificationService.success(res.message);
          this.router.navigate([this.adminUsersUrl]);
        } else this.notificationService.error(res.message);
      });
    } else {
      this.adminUserFormGroup.markAllAsTouched();
    }
  }

  onResetPassword() {
    let passwordField = this.adminUserFormGroup.get('password');
    let userField = this.adminUserFormGroup.get('userName');
    this.changePasswordLoading = true;
    if (passwordField?.valid && this.userId && userField?.value) {
      this.authService
        .ResetPassword(passwordField.value, this.userId.toString(), userField.value, AuthenticationUserType.Admin)
        .subscribe(res => {
          if (res.status) this.notificationService.success(res.message);
          else this.notificationService.error(res.message);
          this.changePasswordLoading = false;
          this.showResetPasswordModal = false;
        });
    }
  }

  onViewJournalClick() {
    this.openJournal.next(!this.openJournal.value);
  }

  get regionPrivilegesForm() {
    return this.adminUserFormGroup.controls['regionPrivileges'] as FormArray;
  }

  get privilegesForm() {
    return this.adminUserFormGroup.controls['privileges'] as FormArray;
  }

  get manageUserPrivilegeForm() {
    let a = this.privilegesForm.controls.find(x => x.get('regionName')?.value === 'ManageUsers') as FormGroup;
    return a;
  }

  get userGroupForm() {
    return this.adminUserFormGroup.controls['userGroups'] as FormArray;
  }

  getRegionFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }

  onGeneratePassword() {
    this.adminUserFormGroup.get('password')?.patchValue(randomatic('Aa0!', 10));
  }

  private onOnboardingSelected() {
    this.adminUserFormGroup.get('privileges')?.valueChanges.subscribe((privileges: PrivilegeFields[]) => {
      let onboardingSelectedFields = privileges.filter(
        field => field.regionName.includes('Onboarding') && field.isChecked
      );
      let atLeastOneSelected = onboardingSelectedFields.length >= 1;
      if (atLeastOneSelected) this.showOnboarding = true;
      else this.showOnboarding = false;
    });
  }

  private onUsernameChange() {
    this.adminUserFormGroup
      .get('userName')
      ?.valueChanges.pipe(debounceTime(1000))
      .subscribe((userName: string) => {
        if (!userName || userName.length === 0) return;

        this.authService.CheckUser({ UserName: userName }).subscribe(res => {
          let userNameField = this.adminUserFormGroup.get('userName');

          if (!res.status) userNameField?.setErrors({ usernameTaken: true });
          else {
            userNameField?.setErrors(null);
          }
        });
      });
  }

  private isAssigned = (right: UserRights | undefined) => right && right.assigned === 1;

  private loadUserData() {
    if (this.userId)
      this.userData$ = this.authService.GetUser(this.userId).pipe(
        tap(res => {
          let {
            rights,
            Username,
            FirstName,
            LastName,
            Email,
            Phone,
            Disabled,
            Failed_login,
            Success_login,
            Lastlogin,
            LastloginStatus,
            Lastlogoff,
            UserGroups,
            Type,
            UserID,
          } = res.response[0];

          this.initUserForm({
            userName: Username,
            firstName: FirstName,
            lastName: LastName,
            email: Email,
            phoneNumber: Phone,
            disabled: Disabled === 1 ?? false,
            regionPrivileges: this.getRegionPrivileges(rights, true),
            privileges: this.getRegionPrivileges(rights),
            userGroups: UserGroups.map<UserGroupFields>(x => ({
              enrolled: x.Enrolled,
              name: x.Name,
              userGroupId: x.UserGroupId,
              userId: x.UserId,
            })),
          });

          this.journalInput = { UserId: UserID, UserType: Type };
          this.userLocked = res.response[0].IsLockOut;
          this.badLoginAttempts = res.response[0].BadLoginAttempts;
          this.logDetailsInfo = {
            FailedLogins: Failed_login,
            SuccessLogins: Success_login,
            TotalLogins: Failed_login + Success_login,
            LastLoginAttempt: Lastlogin,
            LastLoginStatus: LastloginStatus,
            LastLogOff: Lastlogoff,
          };

          this.onOnboardingSelected();
          this.onUsernameChange();

          this.changeDetectorRef.detectChanges();
        })
      );
  }

  private getRegionPrivileges(rights: UserRights[], onlyRegions: boolean = false): PrivilegeFields[] {
    let hasSomeOnboardingItem = rights.some(x => x.RightCode.includes('Onboarding') && x.assigned == 1);
    if (hasSomeOnboardingItem) this.showOnboarding = true;

    return rights
      .filter(right => {
        let isRegion = ['DDCARegion', 'DDICRegion', 'DDPARegion', 'DDNJRegion'].includes(right.RightCode);

        if (onlyRegions) return isRegion;
        return !isRegion;
      })
      .map<PrivilegeFields>(right => ({
        isChecked: this.isAssigned(right) ? true : false,
        regionLabel: right.Name,
        regionName: right.RightCode,
      }));
  }

  private mapPrivileges(rights: Right[], onlyRegions: boolean = false): PrivilegeFields[] {
    return rights
      .map<PrivilegeFields>(right => ({
        isChecked: false,
        regionLabel: right.Name,
        regionName: right.RightCode,
      }))
      .filter(right => {
        let isRegion = ['DDCARegion', 'DDICRegion', 'DDPARegion', 'DDNJRegion'].includes(right.regionName);
        if (onlyRegions) return isRegion;
        return !isRegion;
      });
  }

  private mapUserGroups(userGroups: UserGroup[]): UserGroupFields[] {
    return userGroups.map<UserGroupFields>(x => ({
      enrolled: x.Enrolled,
      name: x.Name,
      userGroupId: x.UserGroupId,
      userId: x.UserId,
    }));
  }

  private initUserForm(fields?: AdminFormFields) {
    let regionPrivileges = fields?.regionPrivileges?.map(x =>
      this.fb.group({
        regionName: x.regionName,
        regionLabel: x.regionLabel,
        isChecked: x.isChecked,
      })
    );

    let privileges = fields?.privileges?.map(x =>
      this.fb.group({
        regionName: x.regionName,
        regionLabel: x.regionLabel,
        isChecked: x.isChecked,
      })
    );

    let userGroups = fields?.userGroups?.map(x =>
      this.fb.group({
        userGroupId: x.userGroupId,
        name: x.name,
        enrolled: x.enrolled,
        userId: x.userId,
      })
    );

    let passwordValidations =
      this.isEdittingUser && this.isAdmin
        ? []
        : [Validators.minLength(8), PasswordValidator.strong, Validators.required];

    this.adminUserFormGroup = this.fb.group({
      userName: [fields?.userName ?? '', Validators.required],
      firstName: [fields?.firstName ?? '', Validators.required],
      lastName: [fields?.lastName ?? '', Validators.required],
      password: [fields?.password ?? '', passwordValidations],
      email: [fields?.email ?? '', [Validators.required, Validators.email]],
      phoneNumber: [fields?.phoneNumber ?? '', Validators.required],
      disabled: [fields?.disabled ?? false, Validators.required],
      userGroups: this.fb.array(userGroups ?? []),
      privileges: this.fb.array(privileges ?? []),
      regionPrivileges: this.fb.array(regionPrivileges ?? []),
    });
  }
}

type AdminFormFields = {
  userName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  disabled?: boolean;
  manageUsers?: boolean;
  userGroups?: UserGroupFields[];
  privileges?: PrivilegeFields[];
  regionPrivileges?: PrivilegeFields[];
};

type PrivilegeFields = {
  regionName: string;
  regionLabel: string;
  isChecked: boolean;
  show?: boolean;
};
type UserGroupFields = {
  userGroupId: number;
  name: string;
  enrolled: boolean;
  userId: number;
};
