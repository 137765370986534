import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCircleNotch, IconLookup } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'custom-button',
  styleUrls: ['./button.component.scss'],
  template: ` <button
    type="button"
    (click)="onClick.emit($event)"
    ngClass="custom-button"
    [ngStyle]="{ 'background-color': backgroundColor }"
    [class.wide]="Wide"
    [disabled]="disabled"
    [class.disabled]="disabled"
    [class.custom-button--primary]="color == 'primary' || primary"
    [class.custom-button--secondary]="color == 'secondary'"
    [class.custom-button--tertiary]="color == 'tertiary'"
    [class.custom-button--quaternary]="color == 'quaternary'"
    [class.left-align]="alignItens == 'left'"
    [class.right-align]="alignItens == 'right'"
    [class.center-align]="alignItens == 'center'">
    <ng-container *ngIf="iconPosition == 'left'">
      <fa-icon *ngIf="IsLoading; else showIconLeft" [spin]="true" [icon]="faCircleNotch"></fa-icon>
      <ng-template #showIconLeft>
        <fa-icon *ngIf="icon" ngClass="custom_icon" [icon]="icon"></fa-icon>
      </ng-template>
    </ng-container>

    {{ forceCapitalization ? label.toUpperCase() : label }}

    <ng-container *ngIf="iconPosition == 'right'">
      <fa-icon *ngIf="IsLoading; else showIconRight" [spin]="true" [icon]="faCircleNotch"></fa-icon>

      <ng-template #showIconRight>
        <fa-icon *ngIf="icon" ngClass="custom_icon" [icon]="icon"></fa-icon>
      </ng-template>
    </ng-container>
  </button>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomButtonComponent),
      multi: true,
    },
  ],
})
export class CustomButtonComponent implements OnInit {
  faCircleNotch = faCircleNotch;

  constructor() {}

  ngOnInit(): void {}

  @Input() forceCapitalization: boolean;

  @Input() color: 'primary' | 'secondary' | 'tertiary' | 'quaternary' = 'primary';

  @Input()
  primary = false;

  @Input()
  backgroundColor?: string;

  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input() alignItens: 'center' | 'right' | 'left' = 'center';

  @Input()
  iconPosition: 'left' | 'right' = 'left';

  @Input()
  label = 'Button';

  @Input()
  IsLoading?: boolean = false;

  @Input()
  Wide: boolean = true;

  @Input()
  icon?: IconLookup;

  @Input()
  disabled?: boolean = false;

  @Output()
  onClick = new EventEmitter<Event>();
}
