import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { StaticPage } from 'src/app/core/helpers/view-helper';
import { EmailService } from 'src/app/core/services/http/email.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss', '../old-front-end-styles.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ContactUsComponent implements OnInit, AfterViewInit {
  @ViewChild('content') contentHtml: ElementRef;

  constructor(private emailService: EmailService, private viewService: UserViewService) {}
  ngAfterViewInit(): void {
    let id = this.viewService.GetUserStaticPageIdByName(StaticPage.ContactUS);
    if (!id) return;

    this.emailService
      .GetDashboard(id)
      .subscribe(res => (this.contentHtml.nativeElement.innerHTML = res.Data.TextEditor));
  }

  ngOnInit() {}
}
