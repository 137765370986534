import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Observable, map, take } from 'rxjs';
import { SubProducerGroupsDataRequest, SubProducerGroupsRequest } from 'src/app/core/models/api/sub-producers/sub-producer-groups.request';
import { SubProducerGroup } from 'src/app/core/models/api/sub-producers/sub-producer-groups.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { SubProducerService } from 'src/app/core/services/http/sub-producer.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-assign-groups-sub-producer',
  styleUrls: ['./assign-groups-sub-producer.component.scss'],
  templateUrl: './assign-groups-sub-producer.component.html'
})
export class AssignGroupsSubProducerComponent implements OnInit {

  subProducerUserId: number;
  data$: Observable<SubProducerGroup[]>;
  downloadButtons: TableDownloadButtons;
  displayedColumns: DisplayedColumns[];
  arrowLeft = faArrowLeft;
  subProducersGroupsUrl: string = RouteEnum.SubProducersGroups;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;


  
  constructor(
    private subProducerService: SubProducerService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit() {

    this.subProducerUserId =  Number(this.route.snapshot.paramMap.get('id'));

    this.downloadButtons = {};

    this.displayedColumns = [
      { columnName: 'Region', label: 'Region', sortable: true },
      { columnName: 'EmployerNr', label: 'Employer Nr', sortable: true },
      {
        columnName: 'Action',
        label: 'Assigned',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
    ];

    this.data$ = this.subProducerService.GetSubProducerGroups(this.subProducerUserId);
  }

  onCheckEmployer(groupData: SubProducerGroup)
  {
    groupData.GroupAssigned = true;
    this.updateData(groupData);
    
  }


  private updateData(groupData: SubProducerGroup)
  {
    this.data$ = this.data$.pipe(map(data => {
      return data.map(item => {
        // Update the item with the matching ID
        if (item.EmployerNr === groupData.EmployerNr && item.Region === groupData.Region) {
          return { ...item, GroupAssigned: groupData.GroupAssigned };
        }
        return item;
      });
    }));
  }

  onRemoveCheckEmployer(data: SubProducerGroup)
  {
    data.GroupAssigned = false;
    this.updateData(data);

  }

  onSubmit()
  {
    this.data$.pipe(take(1)).subscribe(groups =>
      {
        let groupsPayload = groups.filter(x => x.GroupAssigned === true).map(group => ({
          employerNr: group.EmployerNr,
          region: group.Region
        } as SubProducerGroupsDataRequest));

        let payload = {
          subProducerId: this.subProducerUserId,
          groups: groupsPayload
        } as SubProducerGroupsRequest;

        this.subProducerService.UpdateSubProducerGroups(payload).subscribe(response => {

          this.notificationService.success("Groups updated successfully");
          this.router.navigate([RouteEnum.SubProducersGroups]);
        });
      });
      
  }

}
