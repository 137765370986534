import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  styleUrls: ['./radio-button.component.scss'],
  template: `
    <mat-radio-group ngClass="custom_radio_button" [class.vertically_alligned]="orientation == 'horizontal'">
      <ng-container *ngIf="options.length > 0; else labelsOnly">
        <mat-radio-button
          *ngFor="let item of options"
          [id]="item.key"
          name="fav_language"
          (change)="handleChange(item.key)"
          [value]="item.key"
          [checked]="checked === item.key">
          {{ item.value }}
        </mat-radio-button>
      </ng-container>

      <ng-template #labelsOnly>
        <mat-radio-button
          *ngFor="let item of labels"
          [id]="item"
          name="fav_language"
          (change)="handleChange(item)"
          [value]="item"
          [checked]="checked === item">
          {{ item }}
        </mat-radio-button>
      </ng-template>
    </mat-radio-group>
  `,
})
export class RadioButtonComponent implements OnInit {
  @Input() labels: string[] = [];
  @Input() options: { key: string; value: string }[] = [];

  @Input() orientation: 'vertical' | 'horizontal' = 'horizontal';
  @Input() checked: string;
  @Output() onRadioButtonClick: EventEmitter<string> = new EventEmitter();
  constructor() {}

  handleChange(arg0: string) {
    this.onRadioButtonClick.emit(arg0);
  }
  ngOnInit() {}
}
