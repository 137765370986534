import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faEdit, faEye, faPencil } from '@fortawesome/free-solid-svg-icons';
import { Observable, map, take } from 'rxjs';
import { EmployerUserResponse } from 'src/app/core/models/api/employer/employers-users.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { FileService } from 'src/app/core/services/file.service';
import { UserControlService } from 'src/app/core/services/http/user-control.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-manage-employer-users',
  styleUrls: ['./manage-employer-users.component.scss'],
  template: `
    <app-content-card CustomTitle="Manage ER Users">
      <div style="display: flex; justify-content: flex-end; padding-right: 10px;">
        <custom-button
          [forceCapitalization]="true"
          label="+ ADD ER USER"
          [routerLink]="[addEmployerUrl]"></custom-button>
      </div>
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <app-table
          [DisplayedColumns]="displayedColumns"
          [EnableLocalSearch]="true"
          [EnableLocalSort]="true"
          [EnableSearch]="true"
          [IsLoading]="rows.loading ?? false"
          [Rows]="rows.value"
          [EnablePagination]="true"
          [DownloadButtons]="downloadButtons"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionColumnTemplate let-data>
      <div [routerLink]="[editEmployerUrl, data.UserID]">
        <fa-icon ngClass="edit_employer_icon" title="Edit" [icon]="viewIcon"></fa-icon>
      </div>
    </ng-template>
    <ng-template #disabledColumnTemplate let-data>
      <div>
        <app-checkbox [checked]="data == 1" [disabled]="true"></app-checkbox>
      </div>
    </ng-template>
    <ng-template #phoneColumnTemplate let-data>
      {{ data | mask : '(000) 000-0000' }}
    </ng-template>
  `,
})
export class ManageEmployerUsersComponent implements OnInit {
  viewIcon = faPencil;
  displayedColumns: DisplayedColumns[];
  rows$: Observable<EmployerUserResponse[]>;
  editEmployerUrl: string = RouteEnum.EditEmployerUser;
  addEmployerUrl: string = RouteEnum.AddEmployerUser;

  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('disabledColumnTemplate', { static: true }) disabledColumnTemplate: TemplateRef<unknown>;
  @ViewChild('phoneColumnTemplate', { static: true }) phoneColumnTemplate: TemplateRef<unknown>;
  downloadButtons: TableDownloadButtons;

  constructor(
    private userControlService: UserControlService,
    private userViewService: UserViewService,
    private fileService: FileService
  ) {}

  ngOnInit() {
    this.downloadButtons = {
      DownloadPDF: {
        callback: () => {
          let header = [
            {
              Username: 'Username',
              FirstName: 'FirstName',
              Email: 'Email',
              Phone: 'Phone',
              Disabled: 'Disabled',
            },
          ];
          this.rows$.pipe(take(1)).subscribe(rows => {
            this.fileService
              .GeneratePDF('Allied Administrators - ER Users', header, this.mapRowsToDownloadableTable(rows))
              .save('Allied Administrators - ER Users');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.rows$.pipe(take(1)).subscribe(rows => {
            this.fileService.GenerateXLSX('Allied Administrators - ER Users', this.mapRowsToDownloadableTable(rows));
          });
        },
      },
    };
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'Username', label: 'User Name', sortable: true },
      { columnName: 'FirstName', label: 'First Name', sortable: true },
      { columnName: 'Email', label: 'Email', sortable: true },
      { columnName: 'Phone', label: 'Phone', template: this.phoneColumnTemplate, sortable: true },
      { columnName: 'Disabled', label: 'Disabled', template: this.disabledColumnTemplate, sortable: true },
    ];
    let view = this.userViewService.GetCurrentUserViewState();
    if (view && view.EmployerId)
      this.rows$ = this.userControlService.GetERUser(view.EmployerId).pipe(map(res => res.response));
  }

  private mapRowsToDownloadableTable(rows: EmployerUserResponse[]) {
    return rows.map(row => ({
      Username: row.Username,
      FirstName: row.FirstName,
      Email: row.Email,
      Phone: row.Phone,
      Disabled: row.Disabled,
    }));
  }
}
