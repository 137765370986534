import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faEdit, faPencil } from '@fortawesome/free-solid-svg-icons';
import { map, Observable, take } from 'rxjs';
import { formatDate } from 'src/app/core/helpers/date-helpers';
import { EmailTemplate } from 'src/app/core/models/api/email/emails-template.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { FileService } from 'src/app/core/services/file.service';
import { EmailService } from 'src/app/core/services/http/email.service';
import { DisplayedColumns, TableDownloadButtons } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-email-template',
  styleUrls: ['./email-template.component.scss'],
  template: `
    <app-content-card CustomTitle="Email Template">
      <ng-container *ngIf="emailTemplates$ | withLoading | async as emailTemplates">
        <ng-container *ngIf="emailTemplates.loading">
          <app-table [DisplayedColumns]="columns" [LoadingColumns]="3" [IsLoading]="true"></app-table>
        </ng-container>
        <ng-container *ngIf="emailTemplates.value">
          <div ngClass="table_section">
            <app-table
              [DownloadButtons]="DownloadButtons"
              [DisplayedColumns]="columns"
              [EnableSearch]="true"
              [EnableLocalSearch]="true"
              [EnableLocalSort]="true"
              [EnablePagination]="true"
              [Rows]="emailTemplates.value"></app-table>
          </div>
        </ng-container>
      </ng-container>
    </app-content-card>

    <!-- Column Template Region -->
    <ng-template #actionColumnTemplate let-data>
      <a [routerLink]="[editEmailTemplateUrl, data]" style="color: var(--brandColor);">
        <fa-icon [icon]="editIcon"></fa-icon>
      </a>
    </ng-template>
  `,
})
export class EmailTemplatesComponent implements OnInit {
  editIcon = faPencil;
  columns: DisplayedColumns[] = [];
  editEmailTemplateUrl: RouteEnum = RouteEnum.EditEmailTemplate;
  emailTemplates$: Observable<EmailTemplate[]>;
  DownloadButtons: TableDownloadButtons;

  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;

  constructor(private email: EmailService, private fileService: FileService) {}

  ngOnInit() {
    this.columns = [
      { columnName: 'Id', label: 'Action', template: this.actionColTemplate },
      { columnName: 'TemplateName', label: 'Email Template Name', sortable: true },
      { columnName: 'Subject', label: 'Subject', sortable: true },
      { columnName: 'CreatedBy', label: 'Create By', sortable: true },
      { columnName: 'CreatedAt', label: 'Created At', sortable: true },
    ];

    this.DownloadButtons = {
      DownloadPDF: { callback: () => this.downloadPdf() },
      DownloadXLSX: { callback: () => this.downloadXLSX() },
    };
    this.emailTemplates$ = this.email.GetEmailsTemplates().pipe(
      map(res => {
        res.Data = res.Data.map(emailTemplate => {
          emailTemplate.CreatedAt = formatDate(emailTemplate.CreatedAt);
          emailTemplate.CreatedBy = formatDate(emailTemplate.CreatedBy);
          return emailTemplate;
        });

        return res.Data;
      })
    );
  }

  private downloadPdf() {
    this.emailTemplates$.pipe(take(1)).subscribe(emailTemplates => {
      this.fileService
        .GeneratePDF(
          'Allied Administrators - Email Template',
          [
            {
              id: 'Id',
              templateName: 'TemplateName',
              subject: 'Subject',
              createdBy: 'CreatedBy',
              createdAt: 'CreatedAt',
            },
          ],
          this.mapToRowObject(emailTemplates)
        )
        .save('Allied Administrators - Email Template.pdf');
    });
  }
  private downloadXLSX() {
    this.emailTemplates$.pipe(take(1)).subscribe(emailTemplates => {
      this.fileService.GenerateXLSX('Allied Administrators - Email Template', this.mapToRowObject(emailTemplates));
    });
  }

  private mapToRowObject = (emailTemplates: EmailTemplate[]) => {
    return emailTemplates.map(emailTemplate => ({
      id: emailTemplate.Id,
      templateName: emailTemplate.TemplateName,
      subject: emailTemplate.Subject,
      createdBy: emailTemplate.CreatedBy,
      createdAt: emailTemplate.CreatedAt,
    }));
  };
}
