import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';
import { AnalyticsData } from 'src/app/core/models/api/get-analytics.response';
import { GeneralControlService } from 'src/app/core/services/http/general-control.service';
import { SelectOptions } from 'src/app/shared/components/select/select.component';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-web-analytics',
  styleUrls: ['./web-analytics.component.scss'],
  template: `
    <app-content-card CustomTitle="Web Analytics">
      <!-- Form  -->
      <div>
        <form [formGroup]="GetAnalyticsForm" ngClass="search_section">
          <app-form-input
            fieldName="fromDate"
            ngClass="form_item wide"
            title="From Date"
            type="date"
            [Wide]="true"
            formControlName="fromDate"
            [parentForm]="GetAnalyticsForm"></app-form-input>
          <app-form-input
            fieldName="toDate"
            ngClass="form_item wide"
            title="To Date"
            type="date"
            [Wide]="true"
            formControlName="toDate"
            [parentForm]="GetAnalyticsForm"></app-form-input>
          <app-form-select
            Label="Select user type"
            FieldName="userType"
            [Options]="userTypeOptions"
            [parentForm]="GetAnalyticsForm"></app-form-select>
          <custom-button
            [forceCapitalization]="true"
            [icon]="searchIcon"
            label="View Report"
            (onClick)="onViewReport()"></custom-button>
        </form>
      </div>
      <!-- Table render -->
      <ng-container *ngIf="openTableView && (rows$ | withLoading | async) as rows">
        <app-table
          [Rows]="rows.value"
          [IsLoading]="rows.loading ?? false"
          [EnableSearch]="true"
          [EnableLocalSearch]="true"
          [EnableLocalSort]="true"
          [DisplayedColumns]="displayedColumns"
          [EnablePagination]="true"></app-table>
      </ng-container>
    </app-content-card>
  `,
})
export class WebAnalyticsComponent implements OnInit {
  searchIcon = faSearch;

  GetAnalyticsForm: FormGroup = this.formBuilder.group({
    fromDate: ['', Validators.required],
    toDate: ['', Validators.required],
    userType: ['', Validators.required],
  });

  displayedColumns: DisplayedColumns[];
  userTypeOptions: SelectOptions<string>[] = [
    { key: 'A', optionText: 'Admin' },
    { key: 'R', optionText: 'Employer' },
    { key: 'P', optionText: 'Producer' },
  ];

  //Controls
  openTableView: boolean = false;

  //Async Data
  rows$: Observable<AnalyticsData[]> = new Observable(x => x.next([]));

  constructor(private formBuilder: FormBuilder, private generalControlService: GeneralControlService) {}

  ngOnInit() {
    this.displayedColumns = [
      { columnName: 'Key', label: 'Pages', sortable: true },
      { columnName: 'count', label: 'Count', sortable: true },
    ];
  }

  onViewReport() {
    if (!this.GetAnalyticsForm.valid) {
      return;
    }
    console.log(this.GetAnalyticsForm.value);
    this.openTableView = true;

    let { fromDate, toDate, userType } = this.GetAnalyticsForm.value;

    this.rows$ = this.generalControlService
      .GetAnalyticalreport(fromDate, toDate, userType)
      .pipe(map(res => res.response));
  }
}
