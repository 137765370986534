import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentApiResponse } from '../../models/api/documents.response';
import { AuthenticationUserType } from '../../models/api/auth/AuthenticationUserTypeEnum';
import { ViewJournalResponse } from '../../models/api/view-journal.response';
import { InvoiceDocumentApiResponse } from '../../models/invoice-documents.response';
import { IInvoiceDocumentProgress, InvoiceDocumentProgressApiResponse } from '../../models/invoice-upload-progress.response';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private http: HttpClient) {}
  GetLanvera(): Observable<any> {
    return this.http.get(`${environment.alliedApi.baseUrl}/documents/lanvera`, {
      headers: new HttpHeaders().set('Content-Type', 'text/csv'),
      responseType: 'arraybuffer',
    });
  }

  GetDocuments(employerId: string): Observable<DocumentApiResponse> {
    return this.http.get<DocumentApiResponse>(`${environment.alliedApi.baseUrl}/documents/employers/${employerId}`);
  }

  GetInvoiceArchives(): Observable<InvoiceDocumentApiResponse> {
    return this.http.get<InvoiceDocumentApiResponse>(`${environment.alliedApi.baseUrl}/documents/invoices/archive`);
  }

  GetInvoiceDocuments(employerId: string, type: string): Observable<InvoiceDocumentApiResponse> {
    return this.http.get<InvoiceDocumentApiResponse>(`${environment.alliedApi.baseUrl}/documents/invoices/${employerId}&${type}`);
  }


  GetProgress(){
    return this.http.get<InvoiceDocumentProgressApiResponse>(`${environment.alliedApi.baseUrl}/documents/invoices/progress`);
  }

  CheckInvoiceAlreadyExists(fileName: string){
    return this.http.get<{ Status: boolean; Message: string }>(`${environment.alliedApi.baseUrl}/documents/InvoiceArchiveExists/${fileName}`)
  }
  

  UploadInvoiceDocument(file: Blob, userId: string) {
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('UploadedBy', userId.toString());

    return this.http.post<{ Status: boolean; Message: string }>(`${environment.alliedApi.baseUrl}/documents/invoices`, formdata);
  }

  Uploadfile(file: Blob, employerId: string, userId: string) {
    var formdata = new FormData();
    formdata.append('file', file);
    formdata.append('employerID', employerId);
    formdata.append('UploadedBy', userId.toString());

    return this.http.post<{ Status: boolean; Message: string }>(`${environment.alliedApi.baseUrl}/documents`, formdata);
  }

  Sendnotification(producerId: string) {
    return this.http.post<boolean>(`${environment.alliedApi.baseUrl}/XmlExcelDocuments/notifyGroup`, producerId);
  }

  Archivedoc(employerId: string, documentId: string) {
    return this.http.put<{ Status: boolean; Message: string }>(
      `${environment.alliedApi.baseUrl}/documents/archiveDoc`,
      {
        employerId: employerId,
        fileId: documentId,
      }
    );
  }

  Unarchivedoc(employerId: string, documentId: string) {
    return this.http.put<{ Status: boolean; Message: string }>(
      `${environment.alliedApi.baseUrl}/documents/unarchiveDoc`,
      {
        employerId: employerId,
        fileId: documentId,
      }
    );
  }

  DeleteInvoice(invoiceId: string){
    return this.http.delete(`${environment.alliedApi.baseUrl}/documents/invoices/delete/${invoiceId}`);
  }
  Deletedoc(documentId: string) {
    return this.http.delete<{ Status: boolean; Message: string }>(
      `${environment.alliedApi.baseUrl}/documents/delete/${documentId}`
    );
  }

  DownloadInvoiceArchive( invoiceId: string){
    return this.http.get(`${environment.alliedApi.baseUrl}/documents/invoices/archive/download/${invoiceId}`, {
      responseType: 'arraybuffer',
    }); 
  }

  DownloadInvoiceDocument(employerId: string, invoiceId: string){
    return this.http.get(`${environment.alliedApi.baseUrl}/documents/invoices/download/${invoiceId}&${employerId}`, {
      responseType: 'arraybuffer',
    });
  }

  Downloadfile(employerId: string, documentId: string) {
    return this.http.get(`${environment.alliedApi.baseUrl}/documents/download/pdf/${documentId}&${employerId}`, {
      responseType: 'arraybuffer',
    });
  }

  GetEmployerDocumentsViewJournal(employerId: number){
    return this.http.get<ViewJournalResponse[]>(`${environment.alliedApi.baseUrl}/XmlExcelDocuments/employerlogs/`+employerId);
  }

  GetProducerDocumentsViewJournal(producerId: number){
    return this.http.get<ViewJournalResponse[]>(`${environment.alliedApi.baseUrl}/XmlExcelDocuments/brokerLogs/`+producerId);
  }
}
