import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, debounceTime, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LastActiveService {
  private localStorageKey: string = '__lastActive';

  private lastActive: BehaviorSubject<Date>;
  public lastActive$: Observable<Date>;

  constructor(private router: Router) {
    const lastActiveDate = this.getLastActiveFromLocalStorage() ?? new Date();
    this.lastActive = new BehaviorSubject<Date>(lastActiveDate);
    this.lastActive$ = this.lastActive.asObservable();
  }

  public setUp() {
    this.setUpByPageChanges();
  }

  private setUpByPageChanges() {
    this.router.events.subscribe(_ => this.recordLastActiveDate());
  }

  public getLastActiveDate(): Date {
    return this.lastActive.value;
  }

  private recordLastActiveDate() {
    var currentDate = new Date();
    localStorage.setItem(this.localStorageKey, currentDate.toString());
    this.lastActive.next(currentDate);
  }

  private getLastActiveFromLocalStorage(): Date | null {
    const valueFromStorage = localStorage.getItem(this.localStorageKey);
    if (!valueFromStorage) {
      return null;
    }

    return new Date(valueFromStorage);
  }
}
