import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeRoutingModule } from './employee-routing.module';
import { SharedModule } from '../../../../shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DependentFormSectionComponent } from './components/forms-sections/dependent-form-section/dependent-form-section.component';
import { InfoFormSectionComponent } from './components/forms-sections/info-form-section/info-form-section.component';
import { ContactInformationFormSectionComponent } from './components/forms-sections/contact-information-form-section/contact-information-form-section.component';
import { BenefitsFormSectionComponent } from './components/forms-sections/benefits-form-section/benefits-form-section.component';
import { EmployeeFormComponent } from './components/employee-form/employee-form.component';
import { OpenEnrollmentComponent } from './open-enrollment/open-enrollment.component';
import { QualifyingEventComponent } from './qualifying-event/qualifying-event.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { ViewEmployeeComponent } from './view-employee/view-employee.component';
import { PrintEmployeeComponent } from './print-employee/print-employee.component';

@NgModule({
  declarations: [
    AddEmployeeComponent,
    InfoFormSectionComponent,
    ContactInformationFormSectionComponent,
    BenefitsFormSectionComponent,
    DependentFormSectionComponent,
    EmployeeFormComponent,
    OpenEnrollmentComponent,
    EditEmployeeComponent,
    QualifyingEventComponent,
    ViewEmployeeComponent,
    PrintEmployeeComponent,
  ],
  imports: [CommonModule, EmployeeRoutingModule, SharedModule, FontAwesomeModule, FormsModule, ReactiveFormsModule],
})
export class EmployeeModule {}
