<div style="display: flex; justify-content: flex-end">
  <custom-button [forceCapitalization]="true"
    [routerLink]="[producerUsersUrl]"
    style="align-self: flex-end; margin-bottom: 10px"
    color="secondary"
    [icon]="arrowLeft"
    label="BACK"></custom-button>
</div>
<app-content-card [CustomTitle]="getCustomTitle()">
  <div *ngIf="userData$ | withLoading | async as userData">
    <!-- User info form -->
    <form [formGroup]="producerForm" style="display: flex; flex-direction: column; padding: 15px 30px;">
      <app-user-info-form
        [isLoading]="userData.loading || false"
        [userForm]="producerForm"
        [hasPasswordField]="!isEdittingUser"
        [hasMpField]="isEdittingUser"
        [isSubProducer]="isSubProducer"></app-user-info-form>
      



      <custom-button [forceCapitalization]="true"
        *ngIf="userData.value && isEdittingUser"
        [icon]="resetPasswordButtonIcon"
        (onClick)="showResetPasswordModal = true"
        style="align-self: flex-start"
        label="CHANGE PASSWORD"></custom-button>

      <app-password-modal
      [showResetPasswordModal]="showResetPasswordModal"
      [userForm]="producerForm"
      [isLoading]="changePasswordLoading"
      (onGeneratePassword)="onGeneratePassword()"
      (onResetPassword)="onResetPassword()"
      (onClose)="showResetPasswordModal = false"
      ></app-password-modal>

      <div style="display: flex; justify-content:space-between; padding-top:20px">
        <div >
            <custom-button *ngIf="userLocked && isEdittingUser"
            [icon]="lockedUserIcon"
            style="align-self: flex-start;"
            (onClick)="unlockUser()"
            label="UNLOCK USER">
            </custom-button>
            <span *ngIf="badLoginAttempts > 0" style="align-self: flex-start;"><i class="md-list-addon-icon material-icons uk-text-danger color danger">remove_circle</i>Total Failed Attempts: {{badLoginAttempts}}</span>
        </div>
      </div>
    </form>

    

    <!-- Log Details -->
    <div style="padding: 10px" *ngIf="isEdittingUser">
      Log Details:
      <app-log-details *ngIf="userData.value" [logDetailsInfo]="logDetailsInfo"></app-log-details>
    </div>

    <!-- Buttons -->
    <div style="display: flex; justify-content: center; margin-top: 10px">
      <custom-button [forceCapitalization]="true"
        *ngIf="userData.value && isEdittingUser"
        label="VIEW JOURNAL"
        [color]="'secondary'"
        [Wide]="false"
        (onClick)="onViewJournalClick()"
        [icon]="historyIcon"></custom-button>
      <custom-button [forceCapitalization]="true"
        label="SAVE"
        [Wide]="false"
        [primary]="true"
        [IsLoading]="saveLoading"
        [icon]="saveIcon"
        [disabled]="!producerForm.dirty"
        (onClick)="userData.value && onSubmit(userData.value)"></custom-button>
    </div>

    <!-- Journal -->
    <app-view-journal
      *ngIf="openJournal && userData.value"
      [openJournal]="openJournal"
      [journalInput]="journalInput"></app-view-journal>
  </div>
</app-content-card>
