import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable, from, lastValueFrom } from 'rxjs';
import { StaticPage } from 'src/app/core/helpers/view-helper';
import { EmailService } from 'src/app/core/services/http/email.service';
import { UserViewService } from 'src/app/core/services/user-view.service';

@Component({
  selector: 'app-producer-home',
  templateUrl: './producer-home.component.html',
  styleUrls: ['../../home.component.scss'],
})
export class ProducerHomeComponent implements OnInit {
  staticPageAsync$: Observable<ProducerDashboardPagesHtmlAsString>;
  showAbsNewsFeed: boolean;

  @ViewChild('content') contentHtml: ElementRef;
  constructor(private emailService: EmailService, private viewService: UserViewService) {}

  ngOnInit(): void {
    this.loadProducerHome();
  }

  private loadProducerHome() {
    let producerDashboardPageId = this.viewService.GetUserStaticPageIdByName(StaticPage.ProducerDashboard);
    if (!producerDashboardPageId) return;

    let absNewsFeedPageId = undefined;
    if (this.hasABSNewFeed) {
      absNewsFeedPageId = this.viewService.GetUserStaticPageIdByName(StaticPage.ABSNewsFeed);
    }

    this.staticPageAsync$ = from(
      this.getProducerDashboardPagesHtmlAsString(producerDashboardPageId, absNewsFeedPageId)
    );
  }
  async getProducerDashboardPagesHtmlAsString(
    producerDashboardPageId: number,
    absNewsFeedPageId?: number
  ): Promise<ProducerDashboardPagesHtmlAsString> {
    let producerDashboard = (await lastValueFrom(this.emailService.GetDashboard(producerDashboardPageId))).Data
      .TextEditor;

    let absNewsFeed = undefined;
    if (absNewsFeedPageId)
      absNewsFeed = (await lastValueFrom(this.emailService.GetDashboard(absNewsFeedPageId))).Data.TextEditor;

    return { producerDashboard, absNewsFeed };
  }

  get hasABSNewFeed() {
    return this.viewService.GetCurrentUserViewState()?.ABSNewFeed;
  }
}

type ProducerDashboardPagesHtmlAsString = { producerDashboard: string; absNewsFeed?: string };
