import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faEye, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IsStringNullOrEmpty } from 'src/app/core/helpers/validation-helpers';
import { ArchiveApiResponse } from 'src/app/core/models/api/archive.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { ArchiveService } from 'src/app/core/services/http/archive.service';
import { UserViewService } from 'src/app/core/services/user-view.service';
import { DisplayedColumns } from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-file-management',
  styleUrls: ['./file-management.component.scss'],
  template: `
    <app-content-card CustomTitle="File Management">
      <div style="padding: 20px;" *ngIf="rows$ | withLoading | async as rows">
        Files uploaded and status are listed.
        <app-table
          [IsLoading]="rows.loading ?? false"
          [DisplayedColumns]="displayedColumns"
          [Rows]="rows.value?.Data"
          [EnablePagination]="true"
          [EnableSearch]="true"
          emptyRowsTextMessage='No data available in table'
          [EnableLocalSearch]="true"></app-table>
      </div>
    </app-content-card>

    <!--#region column template  -->
    <ng-template #actionColumnTemplate let-data>
      <a title="Details" style="color: var(--brandColor);" [routerLink]="[archiveUrl, data.ArchiveId]">
        <fa-icon [icon]="eyeIcon"></fa-icon>
      </a>
    </ng-template>

    <ng-template #documentColumnTemplate let-data>
      {{ data.Size }}
    </ng-template>

    <!--#endregion -->
  `,
})
export class FileManagementComponent implements OnInit {
  eyeIcon = faEye;
  loadingIcon = faSpinner;
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  archiveUrl: string;

  displayedColumns: DisplayedColumns[];
  rows$: Observable<ArchiveApiResponse>;
  employerId: string | undefined;
  IsXml: boolean;
  isMasterEligibilityUpload: boolean;
  openArchiveDownloadModal: boolean;
  canDownloadArchive: boolean;
  hasErrors: boolean;

  documentId: string;
  IsMaster: boolean;

  constructor(
    private archiveService: ArchiveService,
    private userViewService: UserViewService,
    private route: ActivatedRoute
  ) {}
  //Column Templates
  @ViewChild('actionColumnTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('documentColumnTemplate', { static: true }) documentColumnTemplate: TemplateRef<unknown>;

  ngOnInit(): void {
    this.IsXml = this.route.snapshot.url.findIndex(url => url.path.includes('xml')) >= 0;

    this.isMasterEligibilityUpload = this.route.snapshot.url.findIndex(url => url.path.includes('master-file-management')) >= 0;

    this.employerId = this.userViewService.GetCurrentUserViewState()?.EmployerId;
    this.archiveUrl = this.IsXml ? RouteEnum.ArchiveLogsXml : RouteEnum.ArchiveLogsExcel;

    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'UserName', label: 'Username', sortable: true },
      { columnName: 'FileName', label: 'File Name', sortable: true },
      { columnName: 'TypeView', label: 'File Type', sortable: true },
      { columnName: 'Document', label: 'Size', template: this.documentColumnTemplate, sortable: true },
      { columnName: 'Region', label: 'Region', sortable: true },
      { columnName: 'StatusView', label: 'Status', sortable: true },
      { columnName: 'DateFormated', label: 'Date', sortable: true },
    ];

    //This needs to be refactored urgently in the backend. It needs to create a specific endpoint to search for the user and not reuse this one!
    if(this.isMasterEligibilityUpload){
      this.rows$ = this.archiveService.GetMasterArchives();

    }else{
      this.rows$ = this.archiveService.GetArchives(this.employerId ?? 'null', this.IsXml ? 'xml' : 'excel');

    }
  }
}
