import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, debounceTime, map, Observable, take, tap } from 'rxjs';
import {
  EmployerUserResponse,
  EmployersUsersApiResponse,
} from 'src/app/core/models/api/employer/employers-users.response';
import { RouteEnum } from 'src/app/core/models/routes.enum';
import { FileService } from 'src/app/core/services/file.service';
import { EmployersService } from 'src/app/core/services/http/employers.service';
import {
  DisplayedColumns,
  ManualPagination,
  TableDownloadButtons,
} from 'src/app/shared/components/table/table.component';

@Component({
  selector: 'app-employer-users',
  styleUrls: ['./employer-users.component.scss'],
  template: `
    <app-content-card CustomTitle="Employer Users">
      <ng-container *ngIf="rows$ | withLoading | async as rows">
        <div style="display: flex; justify-content: space-between; padding: 10px;">
          <div ngClass="search_filter">
            <span>Select or search Employer to navigate to the group.</span>
            <app-radio-button
              (onRadioButtonClick)="onFilter($event)"
              [labels]="['All', 'Active', 'Disabled']"
              [checked]="checkedFilter.value"></app-radio-button>
          </div>
        </div>
        <app-table
          [DisplayedColumns]="displayedColumns"
          (PaginationEvent)="onPaginatorEvent($event)"
          (onSearchEvent)="onSearch($event)"
          (onSort)="onSort($event)"
          [searchedText]="searchedText.value"
          [EnableSearch]="true"
          [Pagination]="Pagination"
          [EnablePagination]="true"
          [IsLoading]="rows.loading ?? false"
          [Rows]="rows.value?.data"
          [DownloadButtons]="DownloadButtons"></app-table>
      </ng-container>
    </app-content-card>

    <ng-template #actionColTemplate let-data>
      <app-icon-button
        [color]="'secondary'"
        [customIcon]="editIcon"
        [title]="'Edit ' + data.Username"
        [routerLink]="[editEmployerUrl, data.UserID]"></app-icon-button>
    </ng-template>
    <ng-template #disabledColTemplate let-data>
      {{ data.Disabled}}
    </ng-template>
  `,
})
export class EmployerUsersComponent implements OnInit {
  editIcon = faPencil;
  displayedColumns: DisplayedColumns[];
  rows$: Observable<EmployersUsersApiResponse>;
  editEmployerUrl = RouteEnum.EditEmployerUser;

  Pagination?: ManualPagination;
  searchedText: BehaviorSubject<string> = new BehaviorSubject('');
  checkedFilter: BehaviorSubject<string> = new BehaviorSubject('All');
  DownloadButtons: TableDownloadButtons;
  sortColumn?: string;
  sortDirection: 'asc' | 'desc';

  filters: Map<string, number> = new Map([
    ['All', 0],
    ['Disabled', 2],
    ['Active', 1],
  ]);

  //Column Templates
  @ViewChild('actionColTemplate', { static: true }) actionColTemplate: TemplateRef<unknown>;
  @ViewChild('disabledColTemplate') disabledColTemplate: TemplateRef<unknown>;

  constructor(private employerService: EmployersService, private fileService: FileService) {}

  ngOnInit() {
    this.displayedColumns = [
      {
        columnName: 'Action',
        label: 'Action',
        template: this.actionColTemplate,
        enableFullDataColumnTemplateContext: true,
      },
      { columnName: 'Username', label: 'User Name', sortable: true },
      { columnName: 'FirstName', label: 'First Name', sortable: true },
      { columnName: 'Email', label: 'Email', sortable: true },
      { columnName: 'EmployerName', label: 'Employer Name' },
      { columnName: 'Disabled', label: 'Disabled', template: this.disabledColTemplate },
    ];

    this.rows$ = this.employerService.GetEmployersUsers(0, 10).pipe(
      this.updateDisplayedValues(),
      tap(res => {
        this.Pagination = { Total: res.recordsFiltered, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
      })
    );

    this.DownloadButtons = {
      DownloadPDF: {
        callback: () => {
          this.rows$.pipe(take(1)).subscribe(res => {
            this.fileService
              .GeneratePDF(
                'Allied Administrators - Employer Users',
                [
                  {
                    UserName: 'User Name',
                    FirstName: 'First Name',
                    Email: 'Email',
                    EmployerName: 'Employer Name',
                    Disabled: 'Disabled',
                  },
                ],
                this.mapToDownloadTableObject(res.data)
              )
              .save('Allied Administrators - Employer Users.pdf');
          });
        },
      },
      DownloadXLSX: {
        callback: () => {
          this.rows$.pipe(take(1)).subscribe(res => {
            this.fileService.GenerateXLSX(
              'Allied Administrators - Employer Users',
              this.mapToDownloadTableObject(res.data)
            );
          });
        },
      },
    };
  }

  onSort(sort: Sort) {
    this.sortColumn = sort.active;
    this.sortDirection = sort.direction === 'asc' ? 'asc' : 'desc';
    this.rows$ = this.employerService
      .GetEmployersUsers(
        0,
        10,
        this.getStatusFilter(this.checkedFilter.value),
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(this.updateDisplayedValues());
  }

  onSearch(searchText: string) {
    this.searchedText.next(searchText);
    this.rows$ = this.employerService
      .GetEmployersUsers(
        0,
        10,
        this.getStatusFilter(this.checkedFilter.value),
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        this.updateDisplayedValues(),
        tap(res => {
          this.Pagination = { Total: res.recordsFiltered, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
        })
      );
  }

  onPaginatorEvent(event: PageEvent) {
    this.rows$ = this.employerService
      .GetEmployersUsers(
        event.pageIndex,
        event.pageSize,
        this.getStatusFilter(this.checkedFilter.value),
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        debounceTime(500),
        this.updateDisplayedValues(),
        tap(res => {
          if (this.Pagination)
            this.Pagination = {
              Index: event.pageIndex,
              ActualPage: event.pageIndex,
              Total: event.length,
              PageSize: event.pageSize,
            };
        })
      );
  }

  onFilter(checkedItem: string) {
    this.checkedFilter.next(checkedItem);
    this.rows$ = this.employerService
      .GetEmployersUsers(
        0,
        10,
        this.filters.get(checkedItem),
        this.searchedText.value,
        this.sortDirection,
        this.sortColumn
      )
      .pipe(
        this.updateDisplayedValues(),
        tap(res => {
          this.Pagination = { Total: res.recordsFiltered, ActualPage: Number(res.draw), Index: 0, PageSize: 10 };
        })
      );
  }

  private updateDisplayedValues() {
    return map<EmployersUsersApiResponse, EmployersUsersApiResponse>(res => {
      return {
        ...res,
        data: res.data.map(employer => {
          employer.Disabled = employer.Disabled == '0' ? 'N': 'Y';
          return employer;
        }),
      };
    });
  }

  private mapToDownloadTableObject(res: EmployerUserResponse[]) {
    return res.map(employer => ({
      UserName: employer.Username,
      FirstName: employer.FirstName,
      Email: employer.Email,
      EmployerName: employer.EmployerName,
      Disabled: employer.Disabled == '0' ? 'N' : 'Y',
    }));
  }

  private getStatusFilter(checkedItem: string) {
    return this.filters.get(checkedItem) || 0;
  }
}
